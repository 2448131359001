import * as Yup from "yup";
import { CONSTANT } from "../core/StaticConstant";

export const medicalSchema = Yup.object({
    bloodGrp:Yup.string().required(CONSTANT.BLOOD_GROUP),
    dob: Yup.date().required(CONSTANT.DATE_OF_BIRTH),
    weight: Yup.string().required(CONSTANT.WEIGHT),
    height: Yup.string().required(CONSTANT.HEIGHT),
    medicalConditions: Yup.string().required(CONSTANT.MEDICAL_CONDITIONS),
    disability: Yup.string().required(CONSTANT.DISABILITY)
})

export const loginSecuritySchema = Yup.object({
    firstName:Yup.string().required(CONSTANT.ENTER_FIRST_NAME),
    lastName:Yup.string().required(CONSTANT.ENTER_LAST_NAME),
    contactNo: Yup.string().required(CONSTANT.MOBILE_NUMBER),
    password: Yup.string().required(CONSTANT.ENTER_PASSWORD),
    confirm_password: Yup.string()
    .required()
    .oneOf([Yup.ref("password")].filter(Boolean), CONSTANT.SAME_PASSWORD),
})

export const profileSchema = Yup.object({
    firstName:Yup.string().required(CONSTANT.ENTER_FIRST_NAME),
    lastName:Yup.string().required(CONSTANT.ENTER_LAST_NAME),
    contactNo: Yup.string()
    .required()
    .matches(/^[0-9]+$/, "Must be only digits")
    .min(10, 'Must be exactly 10 digits')
    .max(10, 'Must be exactly 10 digits'),
    // primaryAdd: Yup.string().required(CONSTANT.PRIMARY_ADDRESS)
})
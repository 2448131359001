import React, { useRef, useState, useEffect } from "react";
import Slider from "react-slick";
import { toast } from "react-toastify";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline";

import "./AllProduct.css";
import { decryptData, encryptData, getUserData } from "../../../core/Utils";
import { CONSTANT } from "../../../core/StaticConstant";
import { isArrayEmpty, isObjEmpty } from "../../../core/Utils";
import ProductCard from "../../../components/product-card/ProductCard";
import { CategoryService, ProductService } from "../../../services/index";
import BannerComponent from "../../../components/banner-component/BannerComponent";
import { Localstorage } from "../../../core/AuthService";

const Product = (props: any) => {
  const [productDetail, setProductDetail] = useState<any>({});
  const [likeUnlikeData, setLikeUnlike] = useState<any[]>([]);
  const [categoryData, setCategoryData] = useState([]);
  const params = useParams();
  const navigate = useNavigate();

  let productId = decryptData(params?.Id);
  const location = useLocation();

  useEffect(() => {
    if(location.pathname === "/shop"){
        getAllCategoryForTop();
    }
    const init = async () => {
      if (productId) {
        onIngredientHandleClick(productId);
      } else {
        let obj = {
            fields:["productName","productTitle","productType","isVeg","skuIds"]
        }
        fetchAllProductCategoryWise(obj);
      }
    };
    const userData: any = getUserData();
    const userId: any = userData?.userId;
    init();
    if (userId) {
      like();
    }
  }, [params.Id]);

  const like = async () => {
    try {
      let response = await ProductService.GetlikeUnlikeProductByUser();
      if (response && response.status === CONSTANT.SUCCESS) {
        setLikeUnlike(response.data.product);
      }
    } catch (err: any) {
      console.error("Error occurred in like:", err);
      toast.error(err?.response?.data?.error);
    }
  };

  const likeUnlikeProduct = async (id: any, skuId: any) => {
    const userData: any = getUserData();
    const userId: any = userData?.userId;
    if (userId) {
      try {
        let obj = {
          productId: productId, // Use productId instead of skuId
          skuId: skuId?._id,
        };
        let response = await ProductService.likeUnlikeProductByUser(obj);
        if (response.status === CONSTANT.SUCCESS) {
          setLikeUnlike(response.data.product);

          toast.success(response.message);
        }
        await like();
      } catch (err: any) {
        console.error("Error occurred in likeUnlikeProduct:", err);
        toast.error(err?.response?.data?.error);
      }
    } else {
      Localstorage(CONSTANT.SET,CONSTANT.LAST_VISITED_PAGE,window.location.href);
      navigate("/login");
    }
  };

  const fetchAllProductCategoryWise = async (filterObj: any) => {
    try {
      const response = await ProductService.getProductByCategoryWise(filterObj);
      if (response.status === CONSTANT.SUCCESS) {
        if (response.data && response.data.products) {
          setProductDetail(response.data.products);
        } else {
          toast.error(response.message);
        }
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const onIngredientHandleClick = async (id: any) => {
    try {
      const filterObj = {
        filter: id,
      };
      fetchAllProductCategoryWise(filterObj);
    } catch (error) {
      console.error(error);
    }
  };

  const onAddToCart = (id: any) => {
    navigate(`/shop/productdetail/${encryptData(id)}`);
  };

  const onViewAllProduct = (i: any, data: any) => {
    navigate(`/shop/productCategory/${encryptData(i)}`, {
      state: { productData: productDetail[i], data },
    });
  };

  let slider1: any = useRef();
  let slider2: any = useRef();
  let slider3: any = useRef();
  let slider4: any = useRef();

  const next = (index: number) => {
    index === 1
      ? slider1.slickNext()
      : index === 2
      ? slider2.slickNext()
      : index === 3
      ? slider3.slickNext()
      : slider4.slickNext();
  };
  const previous = (index: any) => {
    index === 1
      ? slider1.slickPrev()
      : index === 2
      ? slider2.slickPrev()
      : index === 3
      ? slider3.slickPrev()
      : slider4.slickPrev();
    // slider.slickPrev();
  };

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    // centerMode: true,
    // initialSlide: 0,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          // infinite: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          // infinite: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const getAllCategoryForTop = async () => {
    try {
      let response = await CategoryService.getAllCategoryForTopBar();

      if (response.status === CONSTANT.SUCCESS) {
        setCategoryData(response.data.categories);
      }
    } catch (error) {
      console.error(
        "Error occurred while fetching marketing ingredients:",
        error
      );
    }
  };

  return (
    <>
      {/* <div className='p-10 bg-whiteSmoke pt-[70px] pb-[40px] pl-[115px]'> absolute*/}
      {categoryData &&
        categoryData.map((ele: any, index: number) => {
          if (ele.name === "All Products") {
            return (
              <BannerComponent
                key={index}
                banner={
                  ele.categoryImg
                    ? ele.categoryImg
                    : "https://drive.google.com/uc?export=view&id=1CHTxgtTnG1YzdrfGdhewUgjCnfpjzDF4"
                }
                title={ele.name ? ele.shortDescription : "N/A"}
                description={ele.longDescription ? ele.longDescription : "N/A"}
                titleStyle={
                  "xl:text-6xl font-bold text-midnightDream lg:text-[3rem] md:text-4xl sm:text-3xl main-text"
                }
                imageStyle={
                  "w-full h-[42rem] lg:h-[42rem] md:h-[36.5rem] sm:h-[31rem] xs:h-[19rem] s:h-[19rem] grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-2 md:gap-4 lg:gap-6 p-5 s:p-3 bg-no-repeatbg-no-repeat bg-cover"
                }
                mainDivStyle={
                  "pt-24 pl-44 2xl:w-[32%] xl:w-[42%] md:w-[46%] md:pl-32 md:pt-20 sm:pl-20 sm:w-[44%] desc-div"
                }
                hrStyle={
                  "mt-5 xl:w-[83%] border-midnightDream xl:border-4 lg:w-[56%] md:w-[65%] sm:border-[3px] sm:w-[68%] banner-hr"
                }
                descStyle={
                  "text-midnightDream mt-[10px] w-[80%] xl:text-xs xl:text-[15px] xl:w-[100%] font-semibold lg:w-[62%] sm:text-[10px] md:w-[60%] sm:w-[85%] banner-desc"
                }
              />
            );
          }
        })}
      <>
        {/* slider start */}

        <>
          {!isObjEmpty(productDetail) &&
            Object.keys(productDetail).map((ele: any, index: any) => {
              return (
                <div
                  className={`md:p-10 xs:p-[12px] s:p-[12px] sm:p-[12px] ${
                    index % 2 === 0 ? "bg-fullwhite" : "bg-greenFooter"
                  }`}
                  key={"category" + index}
                >
                  <div className="mb-8 inline-flex w-full justify-between">
                    <h1 className="text-capeCod text-4xl font-bold category-name xs:flex s:flex xs:justify-between s:justify-between  xs:items-center s:items-center xs:w-full s:w-full">
                      {ele}
                      {productDetail[ele].length > 0 ? (
                        <>
                          <span className="text-capeCod text-4xl font-light xs:hidden s:hidden">
                            {" "}
                            |{" "}
                          </span>
                          <span
                            className="text-capeCod text-base s:text-[12px] font-light cursor-pointer c-view-all-products"
                            onClick={() =>
                              onViewAllProduct(
                                ele,
                                productDetail[ele][0].productCategory
                              )
                            }
                          >
                            View all products
                          </span>
                        </>
                      ) : (
                        ""
                      )}
                    </h1>
                    <div className="sm:inline-flex hidden">
                    {productDetail[ele].length > 4 ? (
                      <span className="sm:inline-flex hidden">
                        <button
                          className="mr-6 s:mr-4"
                          onClick={() => previous(index + 1)}
                        >
                          <ChevronLeftIcon className="h-8 text-capeCod" />
                        </button>

                      </span>
                    ) : (
                      ""
                    )}
                    {productDetail[ele].length > 4 ? (
                      <span className="sm:inline-flex hidden">
                        <button onClick={() => next(index + 1)}>
                          <ChevronRightIcon className="h-8 text-capeCod" />
                        </button>
                      </span>
                    ) : (
                      ""
                    )}
                    </div>
                        
                  </div>
                  {productDetail[ele] && productDetail[ele].length > 0 ? (
                    <div className="slider-div">
                      <Slider
                        ref={(c) => {
                          return index === 0
                            ? (slider1 = c)
                            : index === 1
                            ? (slider2 = c)
                            : index === 2
                            ? (slider3 = c)
                            : (slider4 = c);
                        }}
                        {...settings}
                      >
                        {!isArrayEmpty(productDetail[ele]) &&
                          productDetail[ele].map((product: any, i: any) => {
                            return (
                              <ProductCard
                                key={product?._id}
                                productId={product?._id ? product?._id : "N/A"}
                                // productFormat={
                                //   product?.productFormat
                                //     ? product?.productFormat
                                //     : "N/A"
                                // }
                                image={
                                  product?.skuIds?.[0]?.productImage?.[0] ||
                                  "https://drive.google.com/uc?export=view&id=1F64VND3vJuFtCPxekGycvTrfr-xcRab1"
                                }
                                name={
                                  product?.productName
                                    ? product?.productName
                                    : "N/A"
                                }
                                isveg={
                                    product?.isVeg ? product?.isVeg : "N/A"
                                }
                                // quantity={
                                //   product?.availableQuantity
                                //     ? product?.availableQuantity
                                //     : "N/A"
                                // }
                                description={
                                  product?.productType
                                    ? product?.productType
                                    : "N/A"
                                }
                                productTitle={
                                  product?.productTitle
                                    ? product?.productTitle
                                    : "N/A"
                                }
                                ratings={
                                  product?.review ? product?.review : "N/A"
                                }
                                mrp={
                                  product?.skuIds[0]?.productSingleAmount
                                    ? product?.skuIds[0]?.productSingleAmount?.toFixed(
                                        2
                                      )
                                    : "N/A"
                                }
                                // deliverDays={
                                //   product?.deliverDays
                                //     ? product?.deliverDays
                                //     : "N/A"
                                // }
                                skuId={
                                  product?.skuIds[0]?._id
                                    ? product?.skuIds[0]?._id
                                    : "N/A"
                                }
                                onAddToCart={() => onAddToCart(product?._id)}
                                // likeUnlikeProduct={likeUnlikeProduct}
                                likeUnlikeData={likeUnlikeData}
                                // onClick={() => likeUnlikeProduct(productId)}
                              />
                            );
                          })}
                      </Slider>
                    </div>
                  ) : (
                    <div>
                      <h2 className="text-[20px] text-center text-black font-medium">
                        No Products Found
                      </h2>
                    </div>
                  )}
                </div>
              );
            })}
        </>
        {/* slider end */}
      </>
    </>
  );
};

export default Product;

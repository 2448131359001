import React, { useRef, WheelEvent, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
    ArrowUpRightIcon,
    BeakerIcon,
    BoltIcon,
    EyeIcon,
    FaceSmileIcon,
    HeartIcon,
    MoonIcon,
    ScaleIcon
} from "@heroicons/react/24/outline";

import shopBanner from "../../assets/images/banner1.png";
import CategoryNav from "../../components/sub-nav/SubNav";
import Packages from "../../components/body-scans/packages/Packages";
import HowItWorks from "../../components/body-scans/how-it-works/HowItWorks";
import BannerComponent from "../../components/banner-component/BannerComponent";
import "../../App.css";
import FaqData from "../../components/product/faq";
import { bodyScan } from "../../services/body-scan.service";
import CommonFaq from "../../components/product/faq/CommonFaq";

const BodyScans = () => {
    const navigate = useNavigate();
    const scrollRef = useRef<HTMLDivElement>(null);
    const [selected, setSelected] = useState<any>("");
    const [faqData, setFaqData] = useState<any>()

    const menuList: any = [
        { _id: 1, name: "All Test Packages" },
        { _id: 2, name: "Build Custom Test Packages" }
    ];

    const filterItem: any = [
        { name: "Fitness", icon: <HeartIcon className="rotate-0 w-5 h-5" /> },
        { name: "Mood", icon: <FaceSmileIcon className="rotate-0 w-5 h-5" /> },
        { name: "Sleep", icon: <MoonIcon className="rotate-0 w-5 h-5" /> },
        { name: "Energy", icon: <BoltIcon className="rotate-0 w-5 h-5" /> },
        { name: "Concentration", icon: <EyeIcon className="rotate-0 w-5 h-5" /> },
        { name: "Libido", icon: <HeartIcon className="rotate-0 w-5 h-5" /> },
        { name: "Weight", icon: <ScaleIcon className="rotate-0 w-5 h-5" /> }
    ];

    const itemsFilter = [
        { name: "Option 1", icon: "icon1" },
        { name: "Option 2", icon: "icon2" },
        { name: "Option 3", icon: "icon3" }
        // ...
    ];

    const [selectedItems, setSelectedItems] = useState(["all"]);

    useEffect(() => {
        if (selectedItems.length > 1 && selectedItems.includes("all")) {
            setSelectedItems(selectedItems.filter((item) => item !== "all"));
        }
    }, [selectedItems]);

    const handleSelect = (name: any) => {
        if (name === "all") {
            setSelectedItems(["all"]);
        } else {
            setSelectedItems((selectedItems) => {
                if (selectedItems.includes(name)) {
                    return selectedItems.filter((item: any) => item !== name);
                } else {
                    return selectedItems
                        .filter((item: any) => item !== "all")
                        .concat(name);
                }
            });
        }
    };

    const handleWheel = (event: any) => {
        const delta = Math.max(
            -1,
            Math.min(1, event?.nativeEvent?.wheelDelta || -event?.nativeEvent?.detail)
        );
        if (scrollRef.current) {
            scrollRef.current.scrollLeft -= delta * 30; // Adjust the scrolling speed here
        }
    };



    useEffect(() => {
        let obj = {
            fields : ["faqName","faqImage","faqShortDescription","faqLongDescription","isCommonFaq"]
        }
        allFaqs(obj)
    }, [])

    const allFaqs = async (obj:any) => {

        const res = await bodyScan.getAllCommonFaqs(obj)

        if (res.status === "success") {

            setFaqData(res.data.faqs)
        }

    }



    return (
        <div>
            <CategoryNav pageName="body-scans" subMenuList={menuList} />
            <BannerComponent
                banner={shopBanner}
                title={"Blood Test Packages"}
                btn={
                    <button
                        onClick={() => navigate("/body-scans/custom-package")}
                        className="flex gap-3 bg-graymetal md:px-8 sm:px-4 px-3 md:py-3 py-2 mt-3 sm:w-auto w-full justify-center hover:bg-[#5c7386]"
                    >
                        <span className="text-white md:text-[16px] text-[12px] ">
                            Build Custom Test Packages
                        </span>
                        <ArrowUpRightIcon className="text-white w-5 h-5" />
                    </button>
                }
                description={
                    "Get the health insights you deserve with our range of blood tests. Choose a one-off test or a subscription — it’s up to you. <br /><br /> Test arrives in 1-2 working days • Results in 48 hours"
                }
                titleStyle={
                    "text-greenFooter xl:text-[3.5rem] lg:text-[3rem] md:text-[2.5rem] sm:text-[1.875rem] main-text heavy-font-heading leading-none"
                }
                imageStyle={
                    "body-scans-image relative w-full 2xl:h-[42rem] xl:h-[36rem] lg:h-[32rem] md:h-[24rem] sm:h-[31rem] xs:h-[19rem] s:h-[19rem] grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-2 md:gap-4 lg:gap-6 p-5 s:p-3 bg-no-repeatbg-no-repeat bg-cover"
                }
                mainDivStyle={
                    "pt-2 pr-4 xl:max-w-[550px] lg:max-w-[400px] md:max-w-[375px] sm:max-w-[44%] w-full md:pr-22 md:pt-20 sm:pt-12 sm:pr-12 pl-4 absolute sm:right-0 sm:left-auto sm:top-auto top-[100%]"
                }
                hrStyle={
                    "mt-5 xl:w-[83%] border-greenFooter border-4 lg:w-[56%] md:w-[65%] sm:w-[68%] banner-hr"
                }
                descStyle={
                    "text-greenFooter mt-[10px] w-[80%] xl:text-xs xl:text-[15px] font-semibold sm:text-[10px] banner-desc"
                }
            />
            <div className="sm:bg-capeCod bg-tuatara 2xl:px-40 md:px-11 px-4 sm:pt-12 pt-52 sm:pb-20 pb-14">
                <div
                    ref={scrollRef}
                    onWheel={handleWheel}
                    className="overflow-auto scrollbar-hide lg:max-w-[90%] lg:mx-auto"
                >
                    <ul className="flex xl:justify-between sm:gap-3 gap-2 sm:mb-[60px] mb-[40px]">
                        <li>
                            <button
                                onClick={() => handleSelect("all")}
                                className={`${selectedItems.includes("all")
                                        ? "text-capeCod bg-greenFooter"
                                        : "text-greenFooter lg:hover:text-capeCod lg:hover:bg-greenFooter"
                                    } flex sm:px-6 px-4 py-2 sm:gap-2 gap-1 items-center duration-300 sm:text-[16px] text-[14px]`}
                            >
                                <BeakerIcon className="rotate-0 w-5 h-5" />
                                <span>All</span>
                            </button>
                        </li>
                        {filterItem.map((item: any, i: any) => (
                            <li key={i}>
                                <button
                                    onClick={() => handleSelect(item?.name)}
                                    className={`${selectedItems.includes(item?.name)
                                            ? "text-capeCod bg-greenFooter"
                                            : "text-greenFooter lg:hover:text-capeCod lg:hover:bg-greenFooter"
                                        } flex sm:px-6 px-4 py-2 sm:gap-2 gap-1 items-center duration-300 sm:text-[16px] text-[14px]`}
                                >
                                    {item?.icon}
                                    <span>{item?.name}</span>
                                </button>
                            </li>
                        ))}
                    </ul>
                </div>
                <ul className="flex gap-3 flex-wrap lg:max-w-[90%] lg:mx-auto">
                    {[1, 2, 3, 4, 5, 6, 7, 8].map((item: any, i: any) => (
                        <Packages item={item} key={i} />
                    ))}
                </ul>
            </div>
            <HowItWorks />
            {faqData && <CommonFaq  pagName="body-scans" faqData = {faqData}/>}
        </div>
    );
};

export default BodyScans;

import React, { useState } from 'react'
import VideoComponent from '../video-component/VideoComponent'

const Lessons = () => {
    const [show, setShow] = useState(false);

    return (
        <div className='bg-darkBlack p-4 pt-12'>
            <div className='py-16 lg:px-16'>
            <div className='sm:flex sm:justify-between items-baseline pb-4'>
                <div className='flex justify-end items-baseline gap-4'>
                    <p className='text-darkGray font-bold text-[20px] lg:text-[35px] leading-10'>Introduction to Yoga</p>
                    <p className='text-steelBlue border-l-[1px] h-[40px]'></p>
                    <p className='text-darkGray font-bold text-[15px] lg:text-[20px] tracking-[2px] leading-none'>9 Lessons</p>
                </div>
                <div>
                <div className="flex items-center relative z-10">
                  <div className="lg:col-span-3 xl:col-span-9 text-align-webkit-center text-tuatara h-[3rem] inline-flex items-center justify-end lg:mt-0">
                    <p className="font-[400] text-[16px] text-white mr-[7px]">Select Topic</p>
                    <div className="group inline-block dropdown relative cursor-pointer">
                      <button
                        onClick={() => setShow(!show)}
                        className="bg-black text-gray85 pl-[8px] pb-[2px] inline-flex items-center"
                      >
                        <span className="mr-[70px] font-medium text-[14px]">
                          Introduction to Yoga
                        </span>
                        <span className="mt-[3px]">
                          <svg
                            className={`${show ? "-rotate-180" : "-rotate-270"
                              } fill-current h-5 w-5 transform-gpu duration-500 ease-in-out`}
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 20 20"
                          >
                            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                          </svg>
                        </span>
                      </button>
                      {show && (
                        <div
                          onClick={() => setShow(false)}
                          className="fixed top-0 left-0 w-full h-screen"
                        ></div>
                      )}
                      <div className='absolute'>
                       <div className={`flex justify-between bg-darkBlack pr-12 s:pr-4 pl-6 s:pl-4 py-2 ${show ? "block" : "hidden"}`}>
                  <p className='text-silverChalice text-[14px] font-[500]'>1. Introduction to Yoga</p>
                        
                  <p className='text-silverChalice text-[14px] font-[500] tracking-[1px]'>1hr9min</p>
                </div>
                <div className={`flex justify-between bg-darkBlack pr-12 s:pr-4 pl-6 s:pl-4 py-2 ${show ? "block" : "hidden"}`}>
                  <p className='text-silverChalice text-[14px] font-[500]'>2. Introduction to Yoga</p>
                  <p className='text-silverChalice'>1hr9min</p>
                </div>
                <div className={`flex justify-between bg-darkBlack pr-12 s:pr-4 pl-6 s:pl-4 py-2 ${show ? "block" : "hidden"}`}>
                  <p className='text-silverChalice text-[14px] font-[500]'>3. Introduction to Yoga</p>
                  <p className='text-silverChalice'>1hr9min</p>
                </div>
                <div className={`flex justify-between bg-darkBlack pr-12 s:pr-4 pl-6 s:pl-4 py-2 ${show ? "block" : "hidden"}`}>
                  <p className='text-silverChalice text-[14px] font-[500]'>4. Introduction to Yoga</p>
                  <p className='text-silverChalice'>1hr9min</p>
                </div>
                <div className={`flex justify-between bg-darkBlack pr-12 s:pr-4 pl-6 s:pl-4 py-2 ${show ? "block" : "hidden"}`}>
                  <p className='text-silverChalice text-[14px] font-[500]'>5. Introduction to Yoga</p>
                  <p className='text-silverChalice'>1hr9min</p>
                </div>
                <div className={`flex justify-between bg-darkBlack pr-12 s:pr-4 pl-6 s:pl-4 py-2 ${show ? "block" : "hidden"}`}>
                  <p className='text-silverChalice text-[14px] font-[500]'>6. Introduction to Yoga</p>
                  <p className='text-silverChalice'>1hr9min</p>
                </div>
                      </div>
                    </div>
                  </div>
                </div>
                </div>
            </div>
            <div className='flex justify-between pb-4'>
                <div className='flex justify-end items-center gap-4'>
                    <p className='text-steelBlue'>Your progress</p>
                    <p className='text-steelBlue border-l-[1px] h-[40px]'></p>
                    <p className='text-steelBlue'><span className='font-bold text-[25px]'>71</span>% - 130 min complete</p>
                </div>
                <div>
                    <p className='text-steelBlue'>35 min remaining</p>
                </div>
            </div>
                <div className="w-full flex items-center px-[3px] overflow-x-auto bg-gray-200 rounded-full ">
                    <div className='flex bg-midnightDream h-[30px] px-4 rounded-full gap-8'>
                        <div className='flex items-center gap-2'><div className='w-[12px] h-[12px] bg-white rounded-[50%]'></div><div className='text-white font-[500] tracking-[0.5px] text-[15px] whitespace-nowrap'>Lesson 1</div></div>
                        <div className='flex items-center gap-2'><div className='w-[12px] h-[12px] bg-white rounded-[50%]'></div><span className='text-white font-[500] tracking-[0.5px] text-[14px] whitespace-nowrap'>Lesson 2</span></div>
                        <div className='flex items-center gap-2'><div className='w-[12px] h-[12px] bg-white rounded-[50%]'></div><span className='text-white font-[500] tracking-[0.5px] text-[14px] whitespace-nowrap'>Lesson 3</span></div>
                        <div className='bg-midnightDream rounded-full flex items-center gap-2'><div className='w-[12px] h-[12px] bg-white rounded-[50%]'></div><span className='text-white font-[500] tracking-[0.5px] text-[14px] whitespace-nowrap'>Lesson 4</span></div>
                        <div className='bg-midnightDream rounded-full flex items-center gap-2'><div className='w-[12px] h-[12px] bg-white rounded-[50%]'></div><span className='text-white font-[500] tracking-[0.5px] text-[14px] whitespace-nowrap'>Lesson 5</span></div>
                        <div className='bg-midnightDream rounded-full flex items-center gap-2'><div className='w-[12px] h-[12px] bg-white rounded-[50%]'></div><span className='text-white font-[500] tracking-[0.5px] text-[14px] whitespace-nowrap'>Lesson 6</span></div>
                        <div className='bg-midnightDream rounded-full flex items-center gap-2'><div className='w-[12px] h-[12px] bg-white rounded-[50%]'></div><span className='text-white font-[500] tracking-[0.5px] text-[14px] whitespace-nowrap'>Lesson 7</span></div>
                        <div className='bg-midnightDream rounded-full flex items-center gap-2'><div className='w-[12px] h-[12px] bg-white rounded-[50%]'></div><span className='text-white font-[500] tracking-[0.5px] text-[14px] whitespace-nowrap'>Lesson 8</span></div>
                        <div className='bg-midnightDream rounded-full flex items-center gap-2'><div className='w-[12px] h-[12px] bg-white rounded-[50%]'></div><span className='text-white font-[500] tracking-[0.5px] text-[14px] whitespace-nowrap'>Lesson 9</span></div>
                    </div>
                    <div className='px-4 flex items-center gap-2'><div className='w-[12px] h-[12px] bg-midnightDream rounded-[50%]'></div><span className='text-midnightDream font-[600] tracking-[0.5px] text-[14px] whitespace-nowrap'>Lesson 10</span></div>
                    <div className='px-4 flex items-center gap-2'><div className='w-[12px] h-[12px] bg-midnightDream rounded-[50%]'></div><span className='text-midnightDream font-[600] tracking-[0.5px] text-[14px] whitespace-nowrap'>Lesson 11</span></div>
                    <div className='px-4 flex items-center gap-2'><div className='w-[12px] h-[12px] bg-midnightDream rounded-[50%]'></div><span className='text-midnightDream font-[600] tracking-[0.5px] text-[14px] whitespace-nowrap'>Lesson 10</span></div>
                    <div className='px-4 flex items-center gap-2'><div className='w-[12px] h-[12px] bg-midnightDream rounded-[50%]'></div><span className='text-midnightDream font-[600] tracking-[0.5px] text-[14px] whitespace-nowrap'>Lesson 11</span></div>
                    <div className='px-4 flex items-center gap-2'><div className='w-[12px] h-[12px] bg-midnightDream rounded-[50%]'></div><span className='text-midnightDream font-[600] tracking-[0.5px] text-[14px] whitespace-nowrap'>Lesson 10</span></div>
                    <div className='px-4 flex items-center gap-2'><div className='w-[12px] h-[12px] bg-midnightDream rounded-[50%]'></div><span className='text-midnightDream font-[600] tracking-[0.5px] text-[14px] whitespace-nowrap'>Lesson 11</span></div>
                    <div className='px-4 flex items-center gap-2'><div className='w-[12px] h-[12px] bg-midnightDream rounded-[50%]'></div><span className='text-midnightDream font-[600] tracking-[0.5px] text-[14px] whitespace-nowrap'>Lesson 10</span></div>
                    <div className='px-4 flex items-center gap-2'><div className='w-[12px] h-[12px] bg-midnightDream rounded-[50%]'></div><span className='text-midnightDream font-[600] tracking-[0.5px] text-[14px] whitespace-nowrap'>Lesson 11</span></div>
                    <div className='px-4 flex items-center gap-2'><div className='w-[12px] h-[12px] bg-midnightDream rounded-[50%]'></div><span className='text-midnightDream font-[600] tracking-[0.5px] text-[14px] whitespace-nowrap'>Lesson 10</span></div>
                    <div className='px-4 flex items-center gap-2'><div className='w-[12px] h-[12px] bg-midnightDream rounded-[50%]'></div><span className='text-midnightDream font-[600] tracking-[0.5px] text-[14px] whitespace-nowrap'>Lesson 11</span></div>
                    <div className='px-4 flex items-center gap-2'><div className='w-[12px] h-[12px] bg-midnightDream rounded-[50%]'></div><span className='text-midnightDream font-[600] tracking-[0.5px] text-[14px] whitespace-nowrap'>Lesson 10</span></div>
                    <div className='px-4 flex items-center gap-2'><div className='w-[12px] h-[12px] bg-midnightDream rounded-[50%]'></div><span className='text-midnightDream font-[600] tracking-[0.5px] text-[14px] whitespace-nowrap'>Lesson 11</span></div>
                    <div className='px-4 flex items-center gap-2'><div className='w-[12px] h-[12px] bg-midnightDream rounded-[50%]'></div><span className='text-midnightDream font-[600] tracking-[0.5px] text-[14px] whitespace-nowrap'>Lesson 10</span></div>
                    <div className='px-4 flex items-center gap-2'><div className='w-[12px] h-[12px] bg-midnightDream rounded-[50%]'></div><span className='text-midnightDream font-[600] tracking-[0.5px] text-[14px] whitespace-nowrap'>Lesson 11</span></div>
                    <div className='px-4 flex items-center gap-2'><div className='w-[12px] h-[12px] bg-midnightDream rounded-[50%]'></div><span className='text-midnightDream font-[600] tracking-[0.5px] text-[14px] whitespace-nowrap'>Lesson 10</span></div>
                    <div className='px-4 flex items-center gap-2'><div className='w-[12px] h-[12px] bg-midnightDream rounded-[50%]'></div><span className='text-midnightDream font-[600] tracking-[0.5px] text-[14px] whitespace-nowrap'>Lesson 11</span></div>
                </div>
            </div>
            <div className='lg:px-16'>
              <VideoComponent />
            </div>
        </div>
    )

}

export default Lessons
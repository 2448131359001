import React from 'react';

const Options = (props: any) => {
    const { botNames, onSelectBot, setSelectedBotName, setPage } = props;

    const buttonsMarkup = botNames?.map((bot: any) => (
        <div key={bot._id} className="w-1/2 md:w-1/3 sm:w-1/2 flex flex-col justify-center cursor-pointer" onClick={() => {
            onSelectBot(bot);
            setPage(1);
            setSelectedBotName(bot.botName);
        }}>
            <div className="w-50 h-24 flex flex-col items-center m-2 hover:bg-slate-300 transition ease-in-out delay-150 hover:-translate-y-1 duration-300 rounded-lg border-solid border-2 border-black-500 hover:scale-[1.05] border-black-500 xs:justify-center s:justify-center">
                <div className='flex justify-center items-center flex-col my-1 sm:my-2 md:my-2 xs:my-2'>
                    <div className="w-10 max-h-10 h-10 rounded-full flex justify-center items-center text-lg mr-1 bg-[#9b7d00de] capitalize text-white min-h-40 min-w-40 cursor-pointer">
                        {bot.botName.substring(0, 1)}
                    </div>
                    <p className="botnames text-[10px] md:text-[14px] xs:text-[12px] sm:text-[14px] text-center mt-1 overflow-hidden overflow-ellipsis">
                        {bot.botName}
                    </p>
                </div>
            </div>
        </div>

    ));

    return <div className="flex flex-wrap">{buttonsMarkup}</div>;
};


export default Options;


import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { profileSchema } from "../../schema/Schema";
import { UserService } from "../../services";
import { CONSTANT } from "../../core/StaticConstant";
import { getUserData, isUndefineOrNull } from "../../core/Utils";
import { toast } from "react-toastify";
import { AddressService } from "../../services/address.service";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../loader/Loader";
import moment from "moment";
import { selectedAddressId } from "../../redux/reducer/cartReducer";
import { Localstorage } from "../../core/AuthService";
import { useUser } from "../Contexts/UserContext";

const ProfileDetails = (props: any) => {
    const { updateUsername }:any = useUser();
    const { setIsMedicalProfileClicked } = props;
    const dispatch = useDispatch();
    const [edit, setEdit] = useState(false);
    const [editAddress, setEditAddress] = useState(false);
    const [userId, setUserId] = useState<String>("");
    const [userData, setUserData] = useState<any>();
    const [primaryAddress, setPrimaryAddress] = useState<any>();
    const [showInitialLevel, setShowInitialLevel] = useState(false);
    const [showButtons, setShowButtons] = useState(false);
    const [healthHistory, setHealthHistory] = useState(true);
    const [isLoading, setIsLoading] = useState(false)
    const primaryAddressId = useSelector((state: any) => state.addressId);
    const [email, setEmail] = useState('');
    const [Age, setAge] = useState();

    const initialValues = {
        firstName: "",
        lastName: "",
        contactNo: "",
        email: "",
        recoveryEmail: "",
        postcode: "",
        address1: "",
        landMark: "",
        admin_district: "",
        region: "",
        fullName: "",
        addressCategory: "Home",
        isDefault: false,
    };

    const updateAddress: any = async () => {
        let obj = {
            addressId: primaryAddressId,
            userAddress: values,
        };
        try {
            await AddressService.registerAddress(obj).then((res: any) => {
                if (res.status === CONSTANT.SUCCESS) {
                    toast.success(res.message);
                }
                else {
                    toast.error(res.message)
                }
            });
        } catch (err) {
            // console.log(err);
        }
    }

    const { values, errors, touched, handleChange, setValues, handleSubmit } = useFormik({
        initialValues,
        validationSchema: profileSchema,
        onSubmit: async (values) => {
            try {
                const response: any = await UserService.updateUserBasicDetails(values);
                if (response && response.status === CONSTANT.SUCCESS) {
                    toast.success(response.message);
                    setUserData(response.data.user);
                    setValues(response.data.user)
                    let username = response.data.user.firstName;
                    updateUsername(username);
                    Localstorage(CONSTANT.SET,CONSTANT.USER_NAME,username)
                }
            } catch (error) {
                console.error(error);
            }
        }
    });

    useEffect(() => {
        const userData: any = getUserData();
        setUserId(userData?.userId);
        getUserDetailsById()
        // getAllAddressByUser();
    }, [setValues])

    const getAllAddressByUser = async () => {
        setIsLoading(true);
        const guest: any = getUserData();
        var newUserId = userId;
        if (isUndefineOrNull(userId) && guest) {
            setUserId(guest["userId"]);
            newUserId = guest["userId"];
        }

        try {
            const response = await AddressService.getAllAddressByUserId();
            setIsLoading(false);
            if (response.status === CONSTANT.SUCCESS) {
                let selectAddress = response.data.addresses.userAddress.filter(
                    (ele: any) => ele.isDefault === true
                );
                (selectAddress && selectAddress.length > 0) && dispatch(selectedAddressId(selectAddress[0]?._id));
                getPrimaryAddressById()
            }
        } catch (error: any) {
            if (error?.response?.status === 404) {
                setIsLoading(false);
            } else {
                console.error(error);
                setIsLoading(false);
            }
        }
    };

    const getUserDetailsById = async () => {
        try {
            setIsLoading(true)
            const response: any = await UserService.getUserBasicDetailsById();
            setTimeout(() => {
                setIsLoading(false)
            }, 2000)
            if (response && response.status === CONSTANT.SUCCESS) {
                setUserData(response.data.user);
                setValues({
                    ...values,
                    ...response.data.user
                });
                setEmail(response.data.user.email)
            }
        } catch (error: any) {
            if (error?.response?.status === 404) {
                setIsLoading(false);
            } else {
                console.error(error);
                setIsLoading(false);
            }
        }
    };

    const getPrimaryAddressById = async () => {
        let obj = {
            Id: primaryAddressId
        }
        try {
            const response: any = await AddressService.getSpecificAddressByUserId(obj);
            if (response && response.status === CONSTANT.SUCCESS) {
                setPrimaryAddress(response.data.address);

                setValues({
                    ...values,
                    ...response.data.address
                });

            }
        } catch (err: any) {
            // if (err?.response?.data?.error !== CONSTANT.ADDRESS_NOT_FOUND) {
            //     toast.error((err?.response?.data?.error));
            // }
            setIsLoading(false);
        }
    };


    const updateUser: any = async () => {
        try {
            setIsLoading(true)
            if (email !== values.email) {
                toast.error(CONSTANT.EMAIL_UPDATE)
            } else {
                const response: any = await UserService.updateUserBasicDetails(values);
                setIsLoading(false)

                if (response && response.status === CONSTANT.SUCCESS) {
                    toast.success(response.message);
                    setUserData(response.data.user);
                    setEdit(false);
                    setValues(response.data)
                    setShowInitialLevel(true);
                    let username = response.data.user.firstName;
                    updateUsername(username);
                    Localstorage(CONSTANT.SET,CONSTANT.USER_NAME,username)
                }
            }
            setIsLoading(false);
        } catch (err: any) {
            toast.error((err?.response?.data?.error));
            setIsLoading(false);
        }
    }

    const handleClick = (mode: any) => {

        if (mode === "edit") {
            setEdit(true);
            setShowInitialLevel(false);
            getUserDetailsById();
            setHealthHistory(false);
            // getAllAddressByUser();
        } else {
            setEdit(false);
            setShowButtons(true);
            setHealthHistory(false);
            setShowInitialLevel(true);
            // getAllAddressByUser();
            getPrimaryAddressById();
        }
    };

    const handleBack = () => {
        setShowInitialLevel(false);
        setShowButtons(false);
        setHealthHistory(true);
    };
    const handleClickMedicalProfile = () => {
        setIsMedicalProfileClicked(true);
    };

    function handleInput(event: any) {
        const input = event.target;
        const inputValue = input.value.trim();
        const numericValue = inputValue.replace(/\D/g, "");
        const restrictedValue = numericValue.slice(0, 10);
        input.value = restrictedValue;
    }

    const UserAge = Localstorage(CONSTANT.GET,CONSTANT.USER_AGE)
    useEffect(() => {
        if (userData?.birthDate) {
            const birthdateMoment = moment.utc(userData.birthDate, 'YYYY-MM-DDTHH:mm:ss.SSSZ', true);

            if (birthdateMoment.isValid()) {
                const age: any = moment().utc().diff(birthdateMoment.startOf('day'), 'years');
                setAge(age);
            }
        }
    }, [userData]);

    return (
        <>
            {isLoading && (
                <div className="absolute top-[50%] z-50 left-[50%] transform translate-x-[-50%] translate-y-[-50%]">
                    <Loader />
                </div>)}
            <div className="lg:w-6/12 w-full lg:mb-0 mb-4 bg-graygreen 2xl:p-8 p-4 x:p-3 s:p-3">
                <div className="flex justify-between gap-4 mb-5">
                    <h4 className="text-silverChalice uppercase x:text-[14px] s:text-[14px] font-medium	tracking-wide">
                        Profile Details
                    </h4>
                    {showButtons && (
                        <>
                            {edit ? (
                                <div className="flex gap-4">
                                    <button
                                        type="submit"
                                        // onClick={() => updateUser()}
                                        onClick={() => {
                                            setEdit(false);
                                            setShowInitialLevel(true);
                                            updateUser();
                                        }}
                                        className="bg-capeCod text-white sm:px-6 px-4 py-2 text-[12px] min-w-[120px] x:min-w-[100px] s:min-w-[100px] hover:text-white"
                                    >
                                        Save
                                    </button>
                                    <button
                                        type="button"
                                        onClick={() => {
                                            setEdit(false);
                                            setShowInitialLevel(true);
                                        }}
                                        className="bg-capeCod text-white sm:px-6 px-4 py-2 text-[12px] min-w-[120px] x:min-w-[100px] s:min-w-[100px] hover:text-white"
                                    >
                                        Cancel
                                    </button>
                                </div>
                            ) : (
                                <div className="flex gap-4">
                                    <button
                                        type="button"
                                        onClick={() => handleClick("edit")}
                                        className="bg-capeCod text-white sm:px-6 px-4 py-2 text-[12px] min-w-[120px] x:min-w-[100px] s:min-w-[100px] hover:text-white"
                                    >
                                        Edit Details
                                    </button>
                                    <button
                                        type="button"
                                        onClick={() => handleBack()}
                                        className="bg-capeCod text-white sm:px-6 px-4 py-2 text-[12px] min-w-[120px] x:min-w-[100px] s:min-w-[100px] hover:text-white"
                                    >
                                        Back
                                    </button>
                                </div>
                            )}
                        </>
                    )}
                </div>
                <div className={`${edit ? "" : "bg-silverChalice"} flex mb-4`}>
                    <div className="w-[35%]">
                        <img
                            src={
                                "https://us.123rf.com/450wm/pavelstasevich/pavelstasevich1811/pavelstasevich181101065/112815953-no-image-available-icon-flat-vector.jpg?ver=6"
                            }
                            className="h-[110px] w-[110px]"
                            alt=""
                        />
                    </div>
                    {!edit && (
                        <div className="w-[65%] py-2 px-4">
                            <div className="flex flex-col justify-between h-full">
                                <div>
                                    <h2 className="text-capeCod sm:text-[18px] s:text-[14px] font-bold">
                                        {userData?.firstName} {userData?.lastName}
                                    </h2>
                                    <p className="text-capeCod text-[12px] s:text-[12px] font-bold">
                                        {UserAge ? UserAge : Age } years, {userData?.gender}
                                    </p>
                                </div>
                                <div>
                                    <p className="text-capeCod text-[12px] s:text-[12px] font-bold">

                                        {userData?.contactNo ? ` ${userData?.contactNo},` : ""}{userData?.email}

                                    </p>
                                    <p className="text-capeCod text-[10px]">
                                        Green Lab + Member since {userData?.createdAt ? moment(userData.createdAt).format("YYYY") : null}
                                    </p>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                <div>
                    {healthHistory ? <div className="flex justify-between mb-4">
                        <h3 className="text-white text-[18px] x:text-[14px] s:text-[14px] font-medium tracking-wide">
                            Profile Completion
                        </h3>
                        <h3 className="text-white x:text-[14px] s:text-[14px] font-normal tracking-wide xs:h-9 s:h-9 sm:text-[18px] px-4 mr-16">
                            78%
                        </h3>
                    </div> : <></>}

                    <div className="mb-4 mt-4">
                        {healthHistory ? <h3 className="text-white text-[18px] x:text-[14px] s:text-[14px] font-medium	tracking-wide">
                            Basic Information
                        </h3> : <></>}

                        <div className="ml-16 mr-16">
                            {healthHistory ? <div className="flex justify-between">
                                <h3 className="text-white text-[18px] x:text-[14px] s:text-[14px] font-medium tracking-wide leading-9">
                                    Personal Information
                                </h3>
                                <h3 className="text-white  x:text-[14px] s:text-[14px] font-normal	tracking-wide leading-9 flex items-center" onClick={() => handleClick("view")}>
                                    <p>COMPLETED</p>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="3" stroke="currentColor" className="w-6 h-4 mb-1 ml-1 cursor-pointer">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125" />
                                    </svg>
                                </h3>
                            </div> : <></>}

                            <div>
                                <ul>
                                    {edit ? (
                                        <>
                                            <li className="flex sm:py-4 py-2 items-center">
                                                <div className="w-[35%]">
                                                    <h6 className="sm:text-[14px] text-[12px] text-mantle">
                                                        First Name
                                                    </h6>
                                                </div>
                                                <div className="w-[65%]">
                                                    <input
                                                        type="text"
                                                        disabled={!edit}
                                                        value={values.firstName}
                                                        name="firstName"
                                                        onChange={handleChange}
                                                        className={`${edit
                                                            ? "text-gray85 bg-capeCod inner-shadow"
                                                            : "text-mantle bg-transparent"
                                                            } focus focus:outline-none w-full h-10 xs:h-9 s:h-9 sm:text-[14px] text-[12px] px-4`}
                                                    />
                                                    {errors.firstName && touched.firstName && (
                                                        <div className="text-[#e53e34]">{errors.firstName}</div>
                                                    )}
                                                </div>
                                            </li>
                                            <li className="flex sm:py-4 py-2 items-center">
                                                <div className="w-[35%]">
                                                    <h6 className="sm:text-[14px] text-[12px] text-mantle">
                                                        Last Name
                                                    </h6>
                                                </div>
                                                <div className="w-[65%]">
                                                    <input
                                                        type="text"
                                                        disabled={!edit}
                                                        value={values.lastName}
                                                        name="lastName"
                                                        onChange={handleChange}
                                                        className={`${edit
                                                            ? "text-gray85 bg-capeCod inner-shadow"
                                                            : "text-mantle bg-transparent"
                                                            } focus focus:outline-none w-full h-10 xs:h-9 s:h-9 sm:text-[14px] text-[12px] px-4`}
                                                    />
                                                    {errors.lastName && touched.lastName && (
                                                        <div className="text-[#e53e34]">{errors.lastName}</div>
                                                    )}
                                                </div>
                                            </li>
                                            <li className="flex sm:py-4 py-2 items-center">
                                                <div className="w-[35%]">
                                                    <h6 className="sm:text-[14px] text-[12px] text-mantle">
                                                        Email
                                                    </h6>
                                                </div>
                                                <div className="w-[65%]">
                                                    <input
                                                        type="text"
                                                        disabled={!edit}
                                                        value={values.email}
                                                        name="email"
                                                        readOnly
                                                        onChange={handleChange}
                                                        className={`${edit
                                                            ? "text-gray85 bg-capeCod inner-shadow"
                                                            : "text-mantle bg-transparent"
                                                            } focus focus:outline-none w-full h-10 xs:h-9 s:h-9 sm:text-[14px] text-[12px] px-4`}
                                                    />
                                                    {/* <p className="text-mantle bg-transparent w-full h-10 xs:h-9 s:h-9 sm:text-[14px] text-[12px] pt-2">{userData?.email}</p> */}
                                                    {errors.email && touched.email && (
                                                        <div className="text-[#e53e34]">{errors.email}</div>
                                                    )}
                                                </div>
                                            </li>
                                            <li className="flex sm:py-4 py-2 items-center">
                                                <div className="w-[35%]">
                                                    <h6 className="sm:text-[14px] text-[12px] text-mantle">
                                                        Mobile Number
                                                    </h6>
                                                </div>
                                                <div className="w-[65%]">
                                                    <input
                                                        type="number"
                                                        disabled={!edit}
                                                        value={values.contactNo}
                                                        name="contactNo"
                                                        onChange={handleChange}
                                                        onInput={handleInput}
                                                        maxLength={10}
                                                        className={`${edit
                                                            ? "text-gray85 bg-capeCod inner-shadow"
                                                            : "text-mantle bg-transparent"
                                                            } focus focus:outline-none w-full h-10 xs:h-9 s:h-9 sm:text-[14px] text-[12px] px-4`}
                                                    />
                                                    {errors.contactNo && touched.contactNo && (
                                                        <div className="text-[#e53e34]">{errors.contactNo}</div>
                                                    )}
                                                </div>
                                            </li>
                                        </>
                                    ) : showInitialLevel && (
                                        <>
                                            <li className="flex sm:py-4 py-2">
                                                <div className="w-[35%]">
                                                    <h6 className="sm:text-[14px] text-[12px] text-mantle">
                                                        Full Name
                                                    </h6>
                                                </div>
                                                <div className="w-[65%]">
                                                    <p className="text-mantle bg-transparent w-full h-10 xs:h-9 s:h-9 sm:text-[14px] text-[12px] px-4">{userData?.firstName} {userData?.lastName}</p>
                                                </div>
                                            </li>
                                            <li className="flex sm:py-4 py-2">
                                                <div className="w-[35%]">
                                                    <h6 className="sm:text-[14px] text-[12px] text-mantle">Email</h6>
                                                </div>
                                                <div className="w-[65%]">
                                                    <p className="text-mantle bg-transparent w-full h-10 xs:h-9 s:h-9 sm:text-[14px] text-[12px] px-4">{userData?.email}</p>
                                                </div>
                                            </li>
                                            <li className="flex sm:py-4 py-2">
                                                <div className="w-[35%]">
                                                    <h6 className="sm:text-[14px] text-[12px] text-mantle">Mobile number
                                                    </h6>
                                                </div>
                                                <div className="w-[65%]">
                                                    <p className="text-mantle bg-transparent w-full h-10 xs:h-9 s:h-9 sm:text-[14px] text-[12px] px-4">{userData?.contactNo}</p>
                                                </div>
                                            </li>
                                            <div className="flex justify-between gap-4 mb-5">
                                                <h4 className="text-white uppercase x:text-[14px] s:text-[14px] font-medium	tracking-wide">
                                                    Address Details
                                                </h4>
                                                {editAddress ? (
                                                    <>

                                                        <div className="flex gap-4">

                                                            <button
                                                                type="button"
                                                                onClick={() => updateAddress()}
                                                                className="bg-capeCod text-white sm:px-6 px-4 py-2 text-[12px] min-w-[120px] x:min-w-[100px] s:min-w-[100px] hover:text-white"
                                                            >
                                                                Save
                                                            </button>

                                                            <button
                                                                type="button"
                                                                onClick={() => setEditAddress(false)}
                                                                className="bg-capeCod text-white sm:px-6 px-4 py-2 text-[12px] min-w-[120px] x:min-w-[100px] s:min-w-[100px] hover:text-white"
                                                            >
                                                                Cancel
                                                            </button>
                                                        </div>
                                                    </>
                                                ) : (
                                                    <button
                                                        type="button"
                                                        onClick={() => setEditAddress(true)}
                                                        className="bg-capeCod text-white sm:px-6 px-4 py-2 text-[12px] min-w-[120px] x:min-w-[100px] s:min-w-[100px] hover:text-white"
                                                    >
                                                        Edit Address
                                                    </button>
                                                )}
                                            </div>
                                            <div>
                                                <ul>
                                                    <li className="flex sm:py-4 py-2">
                                                        <div className="w-[35%]">
                                                            <h6 className="sm:text-[14px] text-[12px] text-mantle">
                                                                Primary Address
                                                            </h6>
                                                        </div>
                                                        <div className="w-[65%]">
                                                            <textarea
                                                                rows={4}
                                                                // disabled={!editAddress}
                                                                value={`${primaryAddress && primaryAddress.fullName ? `${primaryAddress.fullName},` : ""} ${primaryAddress && primaryAddress.address1 ? `${primaryAddress.address1},` : ""} ${primaryAddress && primaryAddress.admin_district ? `${primaryAddress.admin_district},` : ""}${primaryAddress && primaryAddress.landMark ? `${primaryAddress.landMark},` : ""}${primaryAddress && primaryAddress.region ? `${primaryAddress.region},` : ""}${primaryAddress && primaryAddress.postcode ? `${primaryAddress.postcode},` : ""} ${primaryAddress && primaryAddress.contactNo ? `${primaryAddress.contactNo}` : ""} `}
                                                                name="primaryAddress"
                                                                onChange={handleChange}
                                                                className={`${editAddress
                                                                    ? "text-gray85 bg-capeCod inner-shadow"
                                                                    : "text-mantle bg-transparent resize-none"
                                                                    } focus focus:outline-none w-full sm:text-[14px] text-[12px] px-4`}
                                                            />
                                                            {/* {errors.primaryAdd && touched.primaryAdd && (
                    <div className="text-[#e53e34]">{errors.primaryAdd}</div>
                  )} */}
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>

                                        </>
                                    )}
                                </ul>
                            </div>
                            {healthHistory ? <div className="flex justify-between">
                                <h3 className="text-white text-[18px] x:text-[14px] s:text-[14px] font-medium tracking-wide leading-9">
                                    Genetics & Race
                                </h3>
                                <h3 className="text-white  x:text-[14px] s:text-[14px] font-normal leading-9 tracking-wide flex items-center">
                                    <p>COMPLETED</p>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="3" stroke="currentColor" className="w-6 h-4 mb-1 ml-1 cursor-pointer">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125" />
                                    </svg>
                                </h3>
                            </div> : <></>}

                        </div>
                    </div>
                    {healthHistory ? <div className="mb-4 mt-4">
                        <h3 className="text-white text-[18px] x:text-[14px] s:text-[14px] font-medium	tracking-wide">
                            Health & Medical History
                        </h3>
                        <div className="ml-16 mr-16">
                            <div className="flex justify-between">
                                <h3 className="text-white text-[18px] x:text-[14px] s:text-[14px] font-medium tracking-wide leading-9">
                                    Medical Profile
                                </h3>
                                <h3 className="text-white  x:text-[14px] s:text-[14px] font-normal	tracking-wide leading-9 flex items-center" onClick={handleClickMedicalProfile}>
                                    <p>RESUME</p>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="3" stroke="currentColor" className="w-6 h-4 mb-1 ml-1 cursor-pointer">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125" />
                                    </svg>
                                </h3>
                            </div>
                            <div className="flex justify-between">
                                <h3 className="text-white text-[18px] x:text-[14px] s:text-[14px] font-medium tracking-wide leading-9">
                                    Parental History
                                </h3>
                                <h3 className="text-white  x:text-[14px] s:text-[14px] font-normal leading-9	tracking-wide flex">
                                    START FILLING
                                </h3>
                            </div>
                            <div className="flex justify-between">
                                <h3 className="text-white text-[18px] x:text-[14px] s:text-[14px] font-medium tracking-wide leading-9">
                                    Allergies
                                </h3>
                                <h3 className="text-white  x:text-[14px] s:text-[14px] font-normal leading-9	tracking-wide flex">
                                    START FILLING
                                </h3>
                            </div>
                            <div className="flex justify-between">
                                <h3 className="text-white text-[18px] x:text-[14px] s:text-[14px] font-medium tracking-wide leading-9">
                                    Personal Goals
                                </h3>
                                <h3 className="text-white  x:text-[14px] s:text-[14px] font-normal leading-9	tracking-wide flex">
                                    START FILLING
                                </h3>
                            </div>
                            <div className="flex justify-between">
                                <h3 className="text-white text-[18px] x:text-[14px] s:text-[14px] font-medium tracking-wide leading-9">
                                    Device Connections
                                </h3>
                                <h3 className="text-white  x:text-[14px] s:text-[14px] font-normal leading-9	tracking-wide flex">
                                    START FILLING
                                </h3>
                            </div>
                        </div>
                    </div> : <></>}

                </div>

            </div>
            <div id="staticModal" data-modal-backdrop="static" tabIndex={-1} aria-hidden="true" className="fixed top-0 left-0 right-0 z-50 hidden w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full">
                <div className="relative w-full max-w-2xl max-h-full">
                    {/* Modal content */}
                    <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                        {/* Modal header */}
                        <div className="flex items-start justify-between p-4 border-b rounded-t dark:border-gray-600">
                            <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                                Static modal
                            </h3>
                            <button type="button" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="staticModal">
                                <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" /></svg>
                            </button>
                        </div>
                        {/* Modal body */}
                        <div className="p-6 space-y-6">
                            <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                                With less than a month to go before the European Union enacts new consumer privacy laws for its citizens, companies around the world are updating their terms of service agreements to comply.
                            </p>
                            <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                                The European Union’s General Data Protection Regulation (G.D.P.R.) goes into effect on May 25 and is meant to ensure a common set of data rights in the European Union. It requires organizations to notify users as soon as possible of high-risk data breaches that could personally affect them.
                            </p>
                        </div>
                        {/* Modal footer */}
                        <div className="flex items-center p-6 space-x-2 border-t border-gray-200 rounded-b dark:border-gray-600">
                            <button data-modal-hide="staticModal" type="button" className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">I accept</button>
                            <button data-modal-hide="staticModal" type="button" className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600">Decline</button>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
};

export default ProfileDetails;

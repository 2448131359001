import moment from "moment";
import { useNavigate } from "react-router-dom";
import { encryptData } from "../../core/Utils";

const WishList = (props: any) => {
    
    const { index, item, likeUnlikeProduct } = props;
    const navigate = useNavigate();
    const futureDate = moment().add(5, "days").format("MMM DD YYYY");

    return (
        <li
            key={index}
            className="md:flex w-full items-center xl:gap-8 gap-4 mb-5 border-graygreen border-b"
        >
            <div className="2xl:max-w-[289px] mx-auto xl:max-w-[250px] lg:max-w-[180px] md:max-w-[140px] max-w-[180px] w-full bg-white p-2">
                <img
                    className="h-full w-auto 2xl:max-h-[289px] xl:max-h-[250px] lg:max-h-[180px] md:max-h-[140px] max-h-[180px] mx-auto"
                    src={item?.skuId?.productImage?.[0]}
                    alt="Product"
                />

            </div>
            <div className="w-full md:pt-0 pt-4">
                <div className="flex text-white justify-between gap-3">
                    <div className="lg:flex items-center gap-4">
                        <h2 className="2xl:text-[45px] xl:text-[40px] lg:text-[26px] text-[22px] font-medium cursor-pointer" onClick={()=>navigate(
                                `/shop/productdetail/${encryptData(item?.productId?._id)}`
                            )}>
                            {item?.productId?.productName}
                        </h2>
                        <div className="lg:block hidden w-[1px] bg-greenFooter h-full"></div>
                        <h4 className="2xl:text-[24px] xl:text-[20px] lg:text-[18px] text-[16px] font-medium">
                            30 Tablets
                        </h4>
                    </div>
                    <h2 className="2xl:text-[45px] xl:text-[40px] lg:text-[26px] text-[22px] font-medium">
                    £ {item?.skuId?.productSingleAmount?.toFixed(2)}
                    </h2>
                </div>
                {item?.skuId?.productQty > 0 ? (
                    <p className="lg:text-[16px] text-[14px] text-darkgreen">
                        Hurry up, Only {item?.skuId?. productQty} Available
                    </p>
                ) : (
                    <p className="lg:text-[16px] text-[14px] text-blueSmoke">
                        Out of stock
                    </p>
                )}
                <p className="lg:text-[16px] text-[14px] text-blueSmoke">
                    Estimated delivery: {futureDate}
                </p>
                <div className="flex items-center gap-3 lg:pt-4 pt-2 c-sm:pb-2 xs:pb-2">
                    <button
                        type="button"
                        className="xl:text-[18px] text-[16px] text-greenFooter hover:text-white"
                        onClick={() =>
                            likeUnlikeProduct(item?.productId?._id, item?.skuId?._id)
                        }
                    >
                        Remove
                    </button>
                    <div className="w-[1px] bg-greenFooter h-[24px]"></div>

                    {item?.skuId?.productQty > 0 ? (
                        <button
                            type="button"
                            className="xl:text-[18px] text-[16px] text-greenFooter hover:text-white"
                            onClick={() =>
                                navigate(`/shop/productdetail/${encryptData(item?.productId?._id)}`)
                            }
                        >
                            Add to Cart
                        </button>
                    ) : (
                        <button
                            disabled
                            type="button"
                            className="xl:text-[18px] text-[16px] text-blueSmoke"
                        >
                            Add to Cart
                        </button>
                    )}
                </div>
            </div>
        </li>
    );
};

export default WishList;

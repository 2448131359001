import { useState, useEffect } from "react";
import "./ProductDetail.css";
import { useParams } from "react-router-dom";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import { ProductService } from "../../../services/product.service";
import { ReviewService } from "../../../services/review.service";
import { CONSTANT } from "../../../core/StaticConstant";
import ProductCardDetail from "../../../components/product/product-card-detail/ProductCardDetails";
import MarketingDetail from "../../../components/product/marketing-details/MarketingDetails";
import ProductInformation from "../../../components/product/product-information/ProductInformation";
import ProductUse from "../../../components/product/product-use/ProductUse";
import FaqData from "../../../components/product/faq";
import Review from "../../../components/product/reviews";
import { toast } from "react-toastify";
import Loader from "../../../components/loader/Loader";
import ProductBenefit from "../../../components/product/product-benefit/ProductBenefit";
import { decryptData } from "../../../core/Utils";

const ProductDetail = (props: any) => {
  const [productData, setProductData] = useState<any>({});
  const [faqData, setFaqData] = useState<any[]>([]);
  const [productSkuImg, setProductSkuImg] = useState<any[]>([]);
  const [skuData, setSkuData] = useState<any[]>([]);
  const [selectedSkuItemData, setSelectedSkuItemData] = useState<any>({});
  const [marketData, setMarketData] = useState<any>({});
  const [skuId, setSkuId] = useState<String>("");
  const [reviewsToShow, setReviewsToShow] = useState(0);
  const [review, setReview] = useState<any[]>([]);
  const [count, setCount] = useState<any>(0);
  const [reviewAverage, setReviewAverage] = useState<number>();
  const [dosageAssistanceData, setDosageAssistanceData] = useState<any>({});
  const [benefitsData, setBenefitsData] = useState<any>([]);
  const [productDataFound, setProductDataFound] = useState(false);
  const [showLoading, setShowLoading] = useState(true);
  const [isSkeleton, setIsSkeleton] = useState<boolean>(false);

  let params: any = useParams();
  let productId = decryptData(params?.Id);

  const getProductById = async () => {
    try {
      setShowLoading(true);
      let response:any = await ProductService.getProductById(productId);
      setTimeout(() => {
        setShowLoading(false);
      }, 2000)
      if (response && response.status === CONSTANT.SUCCESS) {
        setProductDataFound(true);
        await setProductData(response?.data?.product);
        await setFaqData(response?.data?.product?.faqIds);
        await setBenefitsData(response?.data?.product?.benefitIds);
        const skuIDS =
          (response && response.data && response.data.product && response.data.product.skuIds) || [];
        await setSkuData(skuIDS);
        const filterData = skuIDS.filter((sku: any) => sku.isDefault === "Yes");
        if (filterData.length > 0) {
          setSelectedSkuItemData(filterData?.[0]);

          setProductSkuImg(filterData[0].productImage);
          setSkuId(filterData[0]);
        }
        setMarketData(response && response.data && response.data.product && response.data.product.marketingId);
        setDosageAssistanceData(
            response && response.data && response.data.product && response.data.product.dosageAssistance
        );
      } else {
        setProductDataFound(false);
      }
    } catch (error) {
      setTimeout(() => {
        console.error("Error occurred in getProductById:", error);
        setShowLoading(false);
      }, 2000)
    }
  };

  useEffect(() => {
    // const userData: any = getUserData();
    // if (userData?.userId) {
    //   like(userData?.userId);
    // }
    getCustomerReviewByProductId();
    increaseProductViewController();
    getAverageReviewByProductId();
    // eslint-disable-next-line
  }, []);

  // const like = async (id: any) => {
  //   try {
  //     let obj = {
  //       productId: productId,
  //       skuId: skuId
  //     };
  //     let response = await ProductService.likeUnlikeProductByUser(obj);
  //     if (response && response.status === CONSTANT.SUCCESS) {
  //       // setLikeUnlike(response.data);
  //     }
  //   } catch (error) {
  //     console.error("Error occurred in like:", error);
  //   }
  // };

  const getCustomerReviewByProductId = async () => {
    try {
      let obj = {
        limit: reviewsToShow + 3,
      };
      let response = await ReviewService.getCustomerReviewByProductId(
        productId,
        obj
      );
      if (response && response.status === CONSTANT.SUCCESS) {
        setReview(response.data.reviews);
        setCount(response.data.totalRecords);
        setReviewsToShow(reviewsToShow + 3);
      }
    } catch (err:any) {
      console.error("Error occurred in getCustomerReviewByProductId:", err.response);
      if(err?.response?.data?.error !== CONSTANT.REVIEW_NOT_FOUND){
          toast.error(err?.response?.data?.error);
      }

    }
  };

  const getAverageReviewByProductId = async () => {
    try {
      let response = await ReviewService.getAverageReviewByProductId(productId);
      if (response && response.status === CONSTANT.SUCCESS) {
        setReviewAverage(response.data.averageReview);
      }
    } catch (err:any) {
      console.error("Error occurred in getAverageReviewByProductId:", err.response);
      toast.error(err?.data?.error);
    }
  };

  let arr = [];
  arr.push(...review);

  const increaseProductViewController = async () => {
    try {
      await ProductService.increaseProductViewCount(productId);
    } catch (error) {
      console.error("Error occurred in increaseProductViewController:", error);
    }
  };

  useEffect(() => {
    getProductById();
    window.scrollTo(0, 0)
    setTimeout(() => {
      setIsSkeleton(true);
    }, 3000);
  }, []);

  const scrollDownNextElment = (id: string) => {
    const reviewsSection: any = document.getElementById(id);
    reviewsSection.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <>
      <>
        {showLoading && (
          <div className="absolute top-[50%] z-50 left-[50%]  transform translate-x-[-50%] translate-y-[-50%]">
            <Loader />
          </div>
        )}

        {(showLoading) && <div className="bg-whiteSmoke">
            <div
              className={`xl:pt-[40px] xl:px-[100px] lg:px-[10px] lg:pt-[50px] sm:px-[30px] sm:pt-[30px] c-product-detail-div-one bg-whiteSmoke ${
                false ? "" : "mb-6"
              }`}
            > 
              <ProductCardDetail
                className="lg:px-[30px]"
                isSkeleton={false}
                productData={productData}
                skuId={skuId}
                setSkuId={setSkuId}
                selectedSkuItemData={selectedSkuItemData}
                setSelectedSkuItemData={setSelectedSkuItemData}
                productSkuImg={productSkuImg}
                setProductSkuImg={setProductSkuImg}
                skuData={skuData}
                dosageAssistanceData={dosageAssistanceData}
                reviewAverage={reviewAverage}
              />
          </div>
        </div>}

        {productDataFound && !showLoading && (
          <div className="bg-whiteSmoke">
            <div
              className={`xl:pt-[40px] xl:px-[100px] lg:px-[10px] lg:pt-[50px] sm:px-[30px] sm:pt-[30px] c-product-detail-div-one bg-whiteSmoke ${
                isSkeleton ? "" : "mb-6"
              }`}
            >
              <ProductCardDetail
                className="lg:px-[30px]"
                isSkeleton={isSkeleton}
                productData={productData}
                skuId={skuId}
                setSkuId={setSkuId}
                selectedSkuItemData={selectedSkuItemData}
                setSelectedSkuItemData={setSelectedSkuItemData}
                productSkuImg={productSkuImg}
                setProductSkuImg={setProductSkuImg}
                skuData={skuData}
                dosageAssistanceData={dosageAssistanceData}
                reviewAverage={reviewAverage}
              />
              {isSkeleton && (
                <>
                  <div className="sm:pt-[4rem] pt-[1rem] bg-whiteSmoke">
                    <div className="text-align-webkit-center">
                      <button
                        className="text-[#5D5D5D] xs:text-[14px] text-align-webkit-center font-light text-xl"
                        onClick={() => scrollDownNextElment("marketingDetail")}
                      >
                        Know more about your product{" "}
                        <div className="text-align-webkit-center">
                          <ChevronDownIcon className="w-[11%] text-scorpion stroke-[3]" />
                        </div>
                      </button>
                    </div>
                  </div>
                  <hr className="border-silver md:border-t-[3px] sm:border-t-[2px] xs:border-t-[2px] xs:mx-4 s:mx-4" />
                  <MarketingDetail marketData={marketData} />
                  <div className="pt-[2rem] know-more bg-whiteSmoke">
                    <div className="text-align-webkit-center">
                      <button
                        className="text-[#5D5D5D] xs:text-[14px] text-align-webkit-center font-light text-xl"
                        onClick={() =>
                          scrollDownNextElment("productInformation")
                        }
                      >
                        Know more about your product{" "}
                        <div className="text-align-webkit-center">
                          <ChevronDownIcon className="w-[11%] text-scorpion stroke-[3]" />
                        </div>
                      </button>
                    </div>
                  </div>
                  <ProductInformation
                    selectedSkuItemData={selectedSkuItemData}
                    productData={productData}
                  />
                  <div className="pt-[2rem] know-more bg-whiteSmoke">
                    <div className="text-align-webkit-center">
                      <button
                        className="text-[#5D5D5D] xs:text-[14px] text-align-webkit-center font-light text-xl"
                        onClick={() => scrollDownNextElment("productUse")}
                      >
                        Know more about your product{" "}
                        <div className="text-align-webkit-center">
                          <ChevronDownIcon className="w-[11%] text-scorpion stroke-[3]" />
                        </div>
                      </button>
                    </div>
                  </div>
                  <ProductBenefit benefitsData={benefitsData} />
                  <ProductUse productData={productData} />
                  <div className="pt-[2rem] know-more bg-whiteSmoke">
                    <div className="text-align-webkit-center">
                      <button
                        className="text-[#5D5D5D] xs:text-[14px] text-align-webkit-center font-light text-xl"
                        onClick={() => scrollDownNextElment("faqData")}
                      >
                        Know more about your product{" "}
                        <div className="text-align-webkit-center">
                          <ChevronDownIcon className="w-[11%] text-scorpion stroke-[3]" />
                        </div>
                      </button>
                    </div>
                  </div>
                </>
              )}
            </div>
            {isSkeleton && (
              <>
                <div className="sm:mt-[10rem] mt-[4rem]" id="faqData">
                  <div className="font-bold text-nero text-[25px] text-center items-center bg-whiteSmoke mb-[2rem]">
                    FAQ
                  </div>
                  <FaqData
                    faqData={faqData}
                    isExpand={true}
                    className="bg-gray85 sm:pt-[2.5rem] pt-[20px] sm:pb-[4rem] pb-[30px]"
                  />
                </div>
                <div className="pt-[4rem] know-more bg-whiteSmoke">
                  <div className="text-align-webkit-center">
                    <button
                      className="text-[#5D5D5D] xs:text-[14px] text-align-webkit-center font-light text-xl"
                      onClick={() => scrollDownNextElment("review")}
                    >
                      Know more about your product{" "}
                      <div className="text-align-webkit-center">
                        <ChevronDownIcon className="w-[11%] text-scorpion stroke-[3]" />
                      </div>
                    </button>
                  </div>
                </div>
                <div className="bg-whiteSmoke px-[9rem] sm:block hidden">
                  <hr className="border-silver border-t-[3px]" />
                </div>
                <Review
                  review={review}
                  reviewAverage={reviewAverage}
                  reviewsToShow={reviewsToShow}
                  count={count}
                  getCustomerReviewByProductId={getCustomerReviewByProductId}
                />
              </>
            )}
          </div>
        )}
      </>
      <>
        {productDataFound === false && showLoading === false && (
          <div>No Product Found</div>
        )}
      </>
    </>
  );
};

export default ProductDetail;

import React, { useState } from "react";

const PaymentDetails = () => {
  const [edit, setEdit] = useState(false);
  return (
    <div className="lg:w-6/12 w-full lg:mb-0 mb-4 bg-graygreen 2xl:p-8 p-4 x:p-3 s:p-3">
      <div className="flex justify-between gap-4 mb-5">
        <h4 className="text-silverChalice uppercase x:text-[14px] s:text-[14px] font-medium	tracking-wide">
          PAYMENT DETAILS
        </h4>
        {edit ? (
          <button
            type="button"
            onClick={() => setEdit(false)}
            className="bg-capeCod text-silverChalice sm:px-6 px-4 py-2 text-[12px] min-w-[120px] x:min-w-[100px] s:min-w-[100px]"
          >
            Save
          </button>
        ) : (
          <button
            type="button"
            onClick={() => setEdit(true)}
            className="bg-capeCod text-silverChalice sm:px-6 px-4 py-2 text-[12px] min-w-[120px] x:min-w-[100px] s:min-w-[100px] hover:text-white"
          >
            Edit Details
          </button>
        )}
      </div>
      <div>
        <ul>
          <li className="flex sm:py-4 py-2 items-center">
            <div className="w-[35%]">
              <h6 className="sm:text-[14px] text-[12px] text-mantle">
                Saved cards
              </h6>
            </div>
            <div className="w-[65%]">
              <input
                type="text"
                disabled={!edit}
                value={"VISA - XXXX-XXXX-XXXX-7777"}
                className={`${
                  edit
                    ? "text-gray85 bg-capeCod inner-shadow"
                    : "text-mantle bg-transparent"
                } focus focus:outline-none w-full h-10 xs:h-9 s:h-9 sm:text-[14px] text-[12px] px-4`}
              />
            </div>
          </li>
          <li className="flex sm:py-4 py-2 items-center">
            <div className="w-[35%]"></div>
            <div className="w-[65%]">
              <input
                type="text"
                disabled={!edit}
                value={"VISA - XXXX-XXXX-XXXX-7777"}
                className={`${
                  edit
                    ? "text-gray85 bg-capeCod inner-shadow"
                    : "text-mantle bg-transparent"
                } focus focus:outline-none w-full h-10 xs:h-9 s:h-9 sm:text-[14px] text-[12px] px-4`}
              />
            </div>
          </li>
          <li className="flex sm:py-4 py-2 items-center">
            <div className="w-[35%]">
              <h6 className="sm:text-[14px] text-[12px] text-mantle">
                Preferred mode
              </h6>
            </div>
            <div className="w-[65%]">
              <input
                type="text"
                disabled={!edit}
                value={"Card"}
                className={`${
                  edit
                    ? "text-gray85 bg-capeCod inner-shadow"
                    : "text-mantle bg-transparent"
                } focus focus:outline-none w-full h-10 xs:h-9 s:h-9 sm:text-[14px] text-[12px] px-4`}
              />
            </div>
          </li>
          <li className="flex sm:py-4 py-2 items-center">
            <div className="w-[35%]">
              <h6 className="sm:text-[14px] text-[12px] text-mantle">
                Manage bank account
              </h6>
            </div>
            <div className="w-[65%]">
              <input
                type="text"
                disabled={!edit}
                value={"AMEX - XXX-XXX-XXX-XXXX-1729"}
                className={`${
                  edit
                    ? "text-gray85 bg-capeCod inner-shadow"
                    : "text-mantle bg-transparent"
                } focus focus:outline-none w-full h-10 xs:h-9 s:h-9 sm:text-[14px] text-[12px] px-4`}
              />
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default PaymentDetails;

import React, { useEffect, useState } from "react";
import { AddressService } from "../../../../services/address.service";
import { CONSTANT } from "../../../../core/StaticConstant";
import {
    selectEditAddressId,
    selectedAddressId,
    setOrder,
} from "../../../../redux/reducer/cartReducer";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { AddressSchema } from "./Address.Schema";
import { getUserData } from "../../../../core/Utils";
import { COUNTRY_LIST } from "../../../../core/Constant";
import { toast } from "react-toastify";
import Loader from "../../../loader/Loader";
import { Localstorage } from "../../../../core/AuthService";

const initialValues = {
    fullName: "",
    contactNo: "",
    postcode: "",
    address1: "",
    addressCategory: "Home",
    landMark: "",
    admin_district: "",
    region: "",
    isDefault: false,
};

const AddAddress = (props: any) => {
    
    const { onLoaderChange, isParentLoading, getCustomerByEmail } = props;

    const dispatch = useDispatch();
    const [userId, setUserId] = useState<String>("");
    const addressId = useSelector((state: any) => state.editAddressId);
    const editAddressId = useSelector((state: any) => state.editAddressId);

    const [showAlert, setShowAlert] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const { values, errors, touched, handleChange, handleSubmit, setValues } =
        useFormik({
            initialValues,
            validationSchema: AddressSchema,
            onSubmit: async (values, action) => {
                onLoaderChange(true);
                setTimeout(async () => {
                    if (!addressId) {
                        let obj = {
                            userId: userId,
                            userAddress: [values],
                        };
                        try {
                            let data = await AddressService.registerAddress(obj);
                            setTimeout(() => {
                                onLoaderChange(false);
                            }, 2000)
                            if (data.status === CONSTANT.SUCCESS) {
                                toast.success(data.message);
                                dispatch(setOrder("addNewAddress"));
                                const customerId = Localstorage(CONSTANT.GET, CONSTANT.CUSTOMER_ID);
                                if(!customerId) {
                                    getCustomerByEmail();
                                }
                            }
                        } catch (err: any) {
                            onLoaderChange(false);
                            toast.error((err?.response?.data?.error));
                        }
                    } else {
                        let obj = {
                            userId: userId,
                            addressId: addressId,
                            userAddress: values,
                        };

                        try {
                            await AddressService.registerAddress(obj).then((res: any) => {
                                setTimeout(() => {
                                    onLoaderChange(false);
                                }, 2000)
                                if (res.status === CONSTANT.SUCCESS) {
                                    toast.success(res.message);
                                    dispatch(setOrder("addNewAddress"));
                                    dispatch(selectEditAddressId(""));
                                }
                            });
                        } catch (err: any) {
                            toast.error((err?.response?.data?.error));
                            onLoaderChange(false);
                        }
                    }
                }, 3000);
            },
        });

    const onBackClick = () => {
        window.scrollTo(0, 0);
        dispatch(setOrder("addNewAddress"));
        dispatch(selectEditAddressId(""));
    };

    const getAddressById = async (id: any) => {
        onLoaderChange(true);
        let obj = {
            Id: addressId,
        };
        try {
            let response = await AddressService.getSpecificAddressByUserId(obj);
            setTimeout(() => {
                onLoaderChange(false);
              }, 1000)
            if (response && response.status === CONSTANT.SUCCESS) {
                setValues(response.data.address);
            }
        } catch (err: any) {
            if(err?.response?.data?.error === CONSTANT.DATA_NOT_FOUND){
                toast.error((err?.response?.data?.error));
            }
            setTimeout(() => {
                onLoaderChange(false);
              }, 1000)
        }
    };

    useEffect(() => {
        const userData: any = getUserData();
        setUserId(userData?.userId);
        if (addressId) {
            getAddressById(userData?.userId);
        }
    }, []);
    const axios = require("axios");
    function handleInput(event: any) {
        const input = event.target;
        const inputValue = input.value?.trim();
        const numericValue = inputValue?.replace(/\D/g, "");
        const restrictedValue = numericValue?.slice(0, 10);
        input.value = restrictedValue;
    }

    const handleSearch = async () => {
        try {
            if (values?.postcode) {
                onLoaderChange(true);
                const response = await axios.get(
                    `https://api.postcodes.io/postcodes/${values?.postcode}`
                );
                onLoaderChange(false);
                if (response.data.status === 200) {
                    const data = response.data.result;
                    setValues((prevValues) => ({
                        ...prevValues,
                        admin_district: data.admin_district,
                        region: data.region,
                    }));
                    setShowAlert(false);
                }
            }
        } catch (err: any) {
            toast.error((err?.response?.data?.error));
            setShowAlert(true);
            onLoaderChange(false);
            setValues((prevValues) => ({
                ...prevValues,
                admin_district: "",
                region: "",
            }));
        }
    };

    const toggleModal = () => {
        setIsModalOpen(!isModalOpen);
    };
    const closeModal = () => {
        setIsModalOpen(false);
    };

    const [isFormValid, setIsFormValid] = useState(false);

    useEffect(() => {
        setIsFormValid(
            !values.fullName ||
            !values.address1 ||
            !values.admin_district ||
            !values.contactNo ||
            !values.postcode ||
            !values.postcode
        );
    }, [errors, values]);

    return (
        <>
        {isParentLoading && (
                <div className="absolute top-[50%] z-50 left-[50%]  transform translate-x-[-50%] translate-y-[-50%]">
                    <Loader />
                </div>
            )}
            <div className="relative">
                <div className="col-span-2 bg-midnightDream w-full lg:w-[432px] s:p-[6px] mt-4 xs:p-[6px] c-sm:p-4 c-md:p-8 py-4 px-6 align">
                    <form>
                        <p className="text-greenFooter xs:text-[9px] s:text-[9px] xs:tracking-[3.6px] s:tracking-[2.1px] font-[12] tracking-[4.0px] text-[14px]">
                            FULL NAME
                        </p>
                        <input
                            className={`!bg-gray85 text-darkshadegreen font-bold w-full mt-1 text-[white] focus focus:outline-none md:px-8 xs:px-4 s:px-4 inner-shadow-signup h-10 xs:h-9 s:h-9`}
                            name="fullName"
                            onChange={handleChange}
                            placeholder="Enter your full name"
                            defaultValue={values?.fullName}
                        />
                        {errors?.fullName && touched.fullName ? (
                            <p className="form-error text-[red]">{errors?.fullName}</p>
                        ) : null}

                        <p className="mt-2 text-greenFooter xs:text-[9px] s:text-[9px] xs:tracking-[3.6px] s:tracking-[2.1px] font-[12] 2xl:pt-[4px] tracking-[4.0px] text-[14px]">
                            POSTAL CODE
                        </p>

                        <div className="sm:flex justify-between gap-4">
                            <input
                                className="!bg-gray85 text-darkshadegreen w-full 2xl:w-[13rem] font-bold mt-1 text-[white] focus focus:outline-none md:px-5 xs:px-4 s:px-4 inner-shadow-signup  h-10 xs:h-9 s:h-9"
                                name="postcode"
                                placeholder="Enter postal code for find address"
                                onChange={handleChange}
                                defaultValue={values?.postcode}
                            />

                            <button
                                className="text-[15px] px-4 xs:mt-[4px] sm:mt-[3.5px] font-bold bg-greenFooter text-midnightDream whitespace-nowrap"
                                onClick={handleSearch}
                                type="button"
                                disabled={isParentLoading}
                            >
                                Find Your Address
                            </button>
                        </div>
                        {errors?.postcode && touched.postcode ? (
                            <p className="form-error text-[red]">{errors?.postcode}</p>
                        ) : null}
                        {showAlert && (
                            <div
                                className=" inline-flex w-[400px] -ml-[30px] items-center rounded-lg text-[#e32323] text-[13px] px-6 py-5 "
                                role="alert"
                            >
                                <span className="mr-2">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 24 24"
                                        fill="currentColor"
                                        className="h-5 w-5"
                                    >
                                        <path
                                            fill-rule="evenodd"
                                            d="M9.401 3.003c1.155-2 4.043-2 5.197 0l7.355 12.748c1.154 2-.29 4.5-2.599 4.5H4.645c-2.309 0-3.752-2.5-2.598-4.5L9.4 3.003zM12 8.25a.75.75 0 01.75.75v3.75a.75.75 0 01-1.5 0V9a.75.75 0 01.75-.75zm0 8.25a.75.75 0 100-1.5.75.75 0 000 1.5z"
                                            clip-rule="evenodd"
                                        />
                                    </svg>
                                </span>
                                <p className="form-error text-[red]">
                                    Sorry we don't recognize that postcode, please check if it has
                                    been entered correctly.
                                </p>
                            </div>
                        )}

                        <div>
                            <p className=" text-greenFooter xs:text-[9px] s:text-[9px] xs:tracking-[3.6px] s:tracking-[2.1px] font-[12] 2xl:pt-[4px] tracking-[4.0px] text-[14px]">
                                CITY
                            </p>
                            <input
                                className="!bg-gray85 !opacity-70 text-darkshadegreen font-bold w-full mt-1 text-[white] focus focus:outline-none px-4 inner-shadow-signup  h-10 xs:h-9 s:h-9"
                                defaultValue={values?.admin_district}
                                disabled
                                placeholder="City name"
                                onChange={(e) => {
                                    setValues((prevValues) => ({
                                        ...prevValues,
                                        admin_district: e.target.value,
                                    }));
                                }}
                            />
                        </div>

                        <div>
                            <p className="mt-2 text-greenFooter xs:text-[9px] s:text-[9px] xs:tracking-[3.6px] s:tracking-[2.1px] font-[12] 2xl:pt-[4px] tracking-[4.0px] text-[14px]">
                                COUNTRY
                            </p>
                            <input
                                className="!bg-gray85 !opacity-70 text-darkshadegreen font-bold w-full mt-1 text-[white] focus focus:outline-none px-4 inner-shadow-signup  h-10 xs:h-9 s:h-9"
                                defaultValue={values?.region}
                                disabled
                                placeholder="Country name"
                                onChange={(e) => {
                                    setValues((prevValues) => ({
                                        ...prevValues,
                                        region: e.target.value,
                                    }));
                                }}
                            />
                        </div>

                        <p className="mt-2 text-greenFooter xs:text-[9px] s:text-[9px] xs:tracking-[3.6px] s:tracking-[2.1px] font-[12] 2xl:pt-[4px] tracking-[4.0px] text-[14px]">
                            ADDRESS 1
                        </p>
                        <div className="relative">
                            <input
                                className="!bg-gray85 text-darkshadegreen font-bold w-full mt-1 text-[white] focus focus:outline-none px-4 inner-shadow-signup  h-10 xs:h-9 s:h-9"
                                placeholder="Enter your address"
                                name="address1"
                                onChange={handleChange}
                                defaultValue={values?.address1}
                            />
                        </div>
                        {errors?.address1 && touched.address1 ? (
                            <p className="form-error text-[red]">{errors?.address1}</p>
                        ) : null}
                        <p className="mt-2 text-greenFooter xs:text-[9px] s:text-[9px] xs:tracking-[3.6px] s:tracking-[2.1px] font-[12] 2xl:pt-[4px] tracking-[4.0px] text-[14px]">
                            LAND MARK
                        </p>
                        <div className="relative">
                            <input
                                className="!bg-gray85 text-darkshadegreen font-bold w-full mt-1 text-[white] focus focus:outline-none px-4 inner-shadow-signup  h-10 xs:h-9 s:h-9"
                                name="landMark"
                                placeholder="Enter your landmark"
                                onChange={handleChange}
                                defaultValue={values?.landMark}
                            />
                        </div>
                        {errors?.landMark && touched.landMark ? (
                            <p className="form-error text-[red]">{errors?.landMark}</p>
                        ) : null}

                        <p className="mt-2 text-greenFooter xs:text-[9px] s:text-[9px] xs:tracking-[3.6px] s:tracking-[2.1px] font-[12] 2xl:pt-[4px] tracking-[4.0px] text-[14px]">
                            PHONE NUMBER
                        </p>
                        <input
                            className={`!bg-gray85 text-darkshadegreen font-bold w-full mt-1 text-[white] focus focus:outline-none px-4 inner-shadow-signup  h-10 xs:h-9 s:h-9 ${errors.contactNo ? "border-red-500" : ""
                                }`}
                            type="number"
                            name="contactNo"
                            placeholder="Enter your contact number"
                            defaultValue={values.contactNo}
                            onInput={handleInput}
                            onChange={(event) => {
                                const { value } = event.target;
                                const trimmedValue = value?.replace(/\D/g, "")?.substring(0, 10);
                                setValues({ ...values, contactNo: trimmedValue });
                            }}
                            maxLength={10}
                        />
                        {errors.contactNo && touched.contactNo ? (
                            <p className="form-error text-[red]">{errors?.contactNo}</p>
                        ) : null}

                        <div className="mt-[10px]">
                            <a
                                href="#"
                                className="text-[15px] text-gray85"
                                onClick={toggleModal}
                            >
                                Why do we need your number?
                            </a>

                            {isModalOpen && (
                                <div
                                    className="fixed inset-0 flex items-center justify-center z-50"
                                    onClick={closeModal}
                                >
                                    <div
                                        className="bg-white p-4 w-[28rem]"
                                        onClick={(e) => e.stopPropagation()}
                                    >
                                        <p className="mb-2 font-bold">
                                            {" "}
                                            Why do we need your number?
                                        </p>
                                        <hr />

                                        <p className="text-[13px] mt-4">
                                            We will use your phone number to send you delivery
                                            updates. Also, some countries may print your phone number
                                            on the delivery label so they can contact you if they have
                                            difficulty delivering your parcel.
                                        </p>
                                        <button
                                            className="bg-midnightDream w-full mt-3 text-white font-bold py-2 px-4 "
                                            onClick={closeModal}
                                        >
                                            Close
                                        </button>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="flex gap-8 sm:py-4 pt-2">
                            <div>
                                <label>
                                    <input
                                        type="radio"
                                        name="addressCategory"
                                        value='Home'
                                        checked={values?.addressCategory === "Home"}
                                        onChange={handleChange}
                                    />
                                    <span className="pl-2 text-greenFooter text-[10px] md:text-[13px] tracking-[2px]">
                                        HOME (ALL TIME)
                                    </span>
                                </label>
                            </div>
                            <div>
                                <label>
                                    <input
                                        type="radio"
                                        name="addressCategory"
                                        value='Office'
                                        checked={values?.addressCategory === "Office"}
                                        onChange={handleChange}
                                    />
                                    <span className="pl-2 text-greenFooter tracking-[2px] text-[10px] md:text-[13px] tracking-[2px]">
                                        OFFICE (9AM - 5PM)
                                    </span>
                                </label>
                            </div>
                            <div>
                                <label>
                                    <input
                                        type="checkbox"
                                        name="isDefault"
                                        checked={values?.isDefault ? true : false}
                                        onChange={handleChange}
                                    />
                                    <span className="pl-2 text-greenFooter tracking-[2px] text-[10px] md:text-[13px] tracking-[2px]">
                                        SAVE AS DEFAULT
                                    </span>
                                </label>
                            </div>
                        </div>
                    </form>
                </div>
                <div className="text-end mt-5 sm:mr-[0rem] 2xl:mr-[0rem] xl:nr-[0rem]">
                    <button
                        className={`text-[15px] px-10 py-3 mr-4 font-bold bg-midnightDream text-white  ${isParentLoading ? "bg-greenFooter" : "bg-midnightDream"
                            } `}
                        onClick={() => onBackClick()}
                        disabled={isParentLoading}
                    >
                        Back
                    </button>
                    <button
                        className={`text-[15px] px-10 py-3 font-bold text-white ${isParentLoading || isFormValid
                                ? "bg-greenFooter"
                                : "bg-midnightDream"
                            }`}
                        onClick={() => handleSubmit()}
                        disabled={isParentLoading || isFormValid}
                    >
                        Save
                    </button>
                </div>
            </div>
        </>
    );
};

export default AddAddress;


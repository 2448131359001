import { API_CONSTANT } from "../core/Constant";
import httpCommon from "../core/HttpCommonCore";

export const bodyScan = {
    getAllFaqs,
    getAllCommonFaqs
}


function getAllFaqs() {
    let url = API_CONSTANT.GET_ALL_FAQS;
    return httpCommon.post(url).then((response: any) => {
      if (response.statusText !== "OK") {
        const error = response.statusText;
        return Promise.reject(error);
      }
      return response.data;
    });
  }

  function getAllCommonFaqs(data:any) {
    let url = API_CONSTANT.GET_ALL_COMMON_FAQS;
    return httpCommon.post(url,data).then((response: any) => {
      if (response.statusText !== "OK") {
        const error = response.statusText;
        return Promise.reject(error);
      }
      return response.data;
    });
  }
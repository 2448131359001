import Swal, { SweetAlertOptions } from "sweetalert2";
export const BASE_URL: any = process.env.REACT_APP_BASE_URL;

export const API_CONSTANT = {
  /* Category API */
  GET_ALL_CATEGORY_FOR_TOPBAR: "/category/getAllCategoryForTopbar",

  /* Sub Category API */
  CREATE_SUB_CATEGORY: "/subCategory/createSubCategory",
  UPDATE_SUB_CATEGORY_BY_ID: "/subCategory/updateSubCategoryById/",
  GET_SUB_CATEGORY_BY_ID: "/subCategory/getSubCategoryById/",
  DELETE_SUB_CATEGORY_BY_ID: "/subCategory/deleteSubCategoryById/",
  GET_ALL_SUB_CATEGORY: "/subCategory/getAllSubCategory",

  /* Product API */
  GET_PRODUCT_BY_ID: "/product/getProductById/",
  GET_ALL_PRODUCT: "/product/getAllProduct",
  GET_PRODUCT_BY_CATEGORY_WISE: "/product/getProductByCategoryWise",
  GET_PRODUCT_BY_CATEGORY: "/product/getProductByCategory",
  LIKE_UNLIKE_PRODUCT_BY_USER_ID: "/product/likeUnlikeProductByUserId",
  GET_LIKE_UNLIKE_PRODUCT_BY_USER_ID: "/product/getLikeUnlikeProductByUserId",
  INCREASE_PRODUCT_VIEW_COUNTER: "/product/productViewCounterIncreaseById/",
  GET_ALL_RECOMMENDED_PRODUCT: "/product/getAllRecommendedProduct",
  GET_SEARCHING_PRODUCT: "/product/getSearchingProductAndKeyword",
  GET_PRODUCT_AND_CLASS: "/product/getProductAndClassByKeyword",

  /* Address api */
  GET_ALL_ADDRESS_BY_USERId: "address/getAllAddressByUserId",
  GET_SPECIFIC_ADDRESS_BY_USER_ID: "address/getSpecificAddressByUserId",
  UPDATE_SPECIFIC_ADDRESS_BY_USER_ID: "address/updateSpecificAddressByUserId",
  ADD_ADDRESS: "address/registerAddress",

  /* User API */
  REGISTER_USER: "/user/registerUser",
  LOGIN_USER: "/user/loginUser",
  UPDATE_USER_BASIC_DETAILS_BY_ID: "/user/updateUserBasicDetailsById/",
  GET_USER_BASIC_DETAILS_BY_ID: "/user/getUserBasicDetailsById",
  DELETE_USER_BY_ID: "/user/deleteUserById/",
  GET_ALL_USER: "/user/getAllUser",
  UPDATE_USER_CART_BY_USER_ID: "/user/updateUserCartByUserId/",
  GUEST_USER: "user/guestUserRegistration",
  RESET_PASSWORD: "user/resetPassword",
  RESET_PASSWORD_WITH_TOKEN: "user/resetPasswordWithToken",
  FORGOT_PASSWORD: "user/forgotPassword",
  VERIFY_FORGOT_PASSWORD_OTP: "user/verifyForgotPasswordOtp",
  FORGOT_USER_PASSWORD: "user/createAdminUserPassword",
  VERIFY_TOKEN: "/user/verifyToken",
  VERIFY_USER_OTP:"/user/verifyUserOtp",

  /* Review API */
  CREATE_REVIEW: "/review/createReview",
  UPDATE_REVIEW_BY_ID: "/review/updateReviewById/:Id",
  GET_REVIEW_BY_ID: "/review/getReviewById/:Id",
  GET_ALL_REVIEW: "/review/getAllReview",
  GET_CUSTOMER_REVIEW_BY_PRODUCT_ID: "/review/getCustomerReviewByProductId/",
  GET_AVERAGE_REVIEW_BY_PRODUCT_ID: "/review/getAverageReviewByProductId/",
  DELETE_REVIEW_BY_ID: "/review/deleteReviewById/:Id",

  /* Keyword API */
  GET_ALL_BENEFIT: "/benefit/getAllBenefit",

  /* MarketingIngredients API */
  GET_ALL_INGREDIENT: "ingredient/getAllIngredient",

  /* Cart API */
  CREATE_CART: "/cart/createCart",
  UPDATE_CART_BY_ID: "/cart/updateCartById/",
  PRODUCT_CART_ADD_COUNTER_BY_ID: "/cart/productCartAddCounterById",
  GET_CART_BY_USER_ID: "/cart/getCartByUserId",
  DELETE_CARD_PRODUCTS: "/cart/deleteCartByUserId",
  DELETE_PRODUCT_CART_BY_USER_ID_ANDS_PRODUCT_ID:
    "/cart/deleteProductCartByUserIdAndProductId",

  /* Medical Details */
  GET_MEDICAL_DETAILS: "/medicalDetails/getMedicalDetailsByUserId",
  UPDATE_MEDICAL_DETAILS: "/medicalDetails/updateMedicalDetailsById/",
  REMOVE_CLINICAL_DOCUMENTS: "/medicalDetails/removeClinicalDocumentById/",
  CREATE_MEDICAL_DETAILS: "/medicalDetails/createMedicalDetails",
  DOWNLOAD_CLINICAL_DOCUMENTS: "/medicalDetails/downloadClinicalDocuments",
  CREATE_MEDICAL_DETAILS_WITH_UPLOAD_CLINICAL_DOCUMENTS: "/medicalDetails/createMedicalDetailsWithUploadClinicalDocuments",
  UPLOAD_CLINICAL_DOCUMENTS: "/medicalDetails/uploadClinicalDocumentsById/",

  /* Order API */
  CREATE_ORDER: "/order/createOrder",
  GET_ORDER_NUMBER_BY_ID: "/order/getOrderNumberById/",
  GET_ORDER_BY_USERID: "/order/getOrderByUserId",
  GET_ORDER_BY_ID: "/order/getOrderById/",
  UPDATE_ORDER_BY_ID: "/order/updateOrderById/",
  CANCEL_ORDER: "/order/cancelOrder/",
  PAYMENT_REFUND: "/stripePayment/applyForRefundPayment",
  GET_ALL_PROMOCODE: "promoCode/getPromoCodeDetailsByPromoCode/",

  /*Delivery API */
  GET_DELIVERY_OPTIONS: "/deliveryOption/getAllDeliveryOption",

  /*Payment API*/
  CREATE_PAYMENT: "/stripePayment/createNewPayment",
  ADD_NEW_CARD: "/stripePayment/addNewCard",
  GET_CUSTOMER_BY_EMAIL: "stripePayment/getCustomerByEmail",
  CREATE_CUSTOMER: "/stripePayment/createCustomer",
  GET_CARD_DETAILS: "/stripePayment/getCardDetails",
  PAYMENT_STATUS: "/stripePayment/updatePaymentStatus/",
  DELETE_EXISTING_CARD: "/stripePayment/deleteExistingCard",
  CREATE_PAYMENT_APPLE: "/stripePayment/addPaymentHistory",

  /* Self-train */
  GET_MASTER: "/master/getAllMaster",
  GET_COURSES: "/course/getAllCourse",
  GET_COURSES_BY_ID: "course/getCourseById/",
  GET_CHAPTER: "chapter/getAllChapter",
  GET_FAQ: "courseFaq/getAllCourseFaq",
  GET_TRAINER: "trainer/getAllTrainer",

  /*  Body-scan */
  GET_ALL_FAQS: "faq/getAllFaq",
  GET_ALL_COMMON_FAQS: "faq/getAllCommonFaq",

  /* bot */
  GET_CONVERSATION_RESPONSE: "conversation/getConversationResponse",
  GET_LAST_CONVERSATION_RESPONSE: "conversation/getConversationsByUserId",
  GET_ALL_BOTS: "bot/getAllBots",

  /* Plaid Api */
  CREATE_LINK_TOKEN: "/plaid/createLinkToken",
  PLAID_TRANSACTION: "/plaid/plaidTransaction",
  GET_PLAID_TRANSACTION_BY_USER_ID:"/plaid/getPlaidTransactionByUserId"
};

export const COUNTRY_LIST = {
  Wales: "GB",
  England: "GB",
  Scotland: "GB",
  "Northern Ireland": "GB",
};

export const showConfirmDialog = (message: any, callback: any) => {
  Swal.fire({
    iconColor: "black",
    className: "alert",
    title: message,
    text: "You won't be able to revert this!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#c12424",
    cancelButtonColor: "black",
    confirmButtonText: "Yes!",
    width: "30rem",
    height: "18rem",
  } as SweetAlertOptions).then((result: any) => {
    if (result.value) {
      callback(result.value);
    } else {
      callback(false);
    }
  });
};

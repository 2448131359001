import httpCommon from "../core/HttpCommonCore";
import { API_CONSTANT } from "../core/Constant";

export const OrderService = {
  createOrder,
  getOrderNumberById,
  getOrderIdByUserId,
  getOrderById,
  updateOrderById,
  CancelOrderById,
  paymentRefund,
  getAllPromoCode,
};

function createOrder(data: any) {
  let url = API_CONSTANT.CREATE_ORDER;
  return httpCommon.post(url, data).then((response: any) => {
    if (response.statusText !== "OK") {
      const error = response.statusText;
      return Promise.reject(error);
    }
    return response.data;
  });
}

function paymentRefund(data: any) {
    let url = API_CONSTANT.PAYMENT_REFUND;
    return httpCommon.post(url, data).then((response: any) => {
      if (response.statusText !== "OK") {
        const error = response.statusText;
        return Promise.reject(error);
      }
      return response.data;
    });
  }



function getOrderNumberById(id: any) {
  let url = API_CONSTANT.GET_ORDER_NUMBER_BY_ID + id;
  return httpCommon.get(url).then((response: any) => {
    if (response.statusText !== "OK") {
      const error = response.statusText;
      return Promise.reject(error);
    }
    return response.data;
  });
}

function getOrderById(id: any) {
  let url = API_CONSTANT.GET_ORDER_BY_ID + id;
  return httpCommon.get(url).then((response: any) => {
    if (response.statusText !== "OK") {
      const error = response.statusText;
      return Promise.reject(error);
    }
    return response.data;
  });
}

function CancelOrderById(id: any) {
    let url = API_CONSTANT.CANCEL_ORDER + id;
    return httpCommon.get(url).then((response: any) => {
      if (response.statusText !== "OK") {
        const error = response.statusText;
        return Promise.reject(error);
      }
      return response.data;
    });
  }



function getOrderIdByUserId(data: any) {
  let url = API_CONSTANT.GET_ORDER_BY_USERID;
  return httpCommon.post(url, data).then((response: any) => {
    if (response.statusText !== "OK") {
      const error = response.statusText;
      return Promise.reject(error);
    }
    return response.data;
  });
}

function updateOrderById(id: any, data: any) {
  let url = API_CONSTANT.UPDATE_ORDER_BY_ID + id;
  return httpCommon.post(url, data).then((response: any) => {
    if (response.statusText !== "OK") {
      const error = response.statusText;
      return Promise.reject(error);
    }
    return response.data;
  });
}


function getAllPromoCode(promoCode: any) {
    let url = API_CONSTANT.GET_ALL_PROMOCODE + promoCode;
    return httpCommon.get(url).then((response: any) => {
      if (response.statusText !== "OK") {
        const error = response.statusText;
        return Promise.reject(error);
      }
      return response.data;
    });
  }



import React, { useState } from 'react'

const SessionContent = (props: any) => {
    const { chapterData } = props
    const [expanded, setExpanded] = useState(Array(chapterData.chapters.length).fill(false));

    const [expandedIndex, setExpandedIndex] = useState(-1);

    let upArrow = (
        <svg
            className="fill-current h-10 s:h-6 w-10 s:w-6 -rotate-300 transition duration-150 ease-in-out"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
        >
            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
        </svg>
    );

    let downArrow = (
        <svg
            className="fill-current h-10 s:h-6 w-10 s:w-6 -rotate-180 transition duration-150 ease-in-out"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
        >
            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
        </svg>
    );


    return (
        <div className='bg-midnightDream px-12 md:px-16 lg:px-12 xl:px-28 x:px-4 s:px-4 xxs:px-4' id="Session Content">
            <p className='text-gray85 text-center text-[40px] md:text-[48px] s:text-[36px] font-bold py-6 md:py-12 lg:py-32'>Session Content</p>
            <div className='grid xl:grid-cols-6 sm:grid-cols-4 gap-8 2xl:px-12 '>
                {chapterData.chapters && chapterData.chapters.map((ele: any, index: number) => {
                    const show = index === expandedIndex;
                    return (
                        <div className='w-full sm:col-span-4 xl:col-span-3' key={index}>
                            <div className='col-span-4 bg-darkBlack mb-8'>
                                <div className='flex gap-4 items-end'>
                                    <h2 className='text-midnightDream leading-[165px] ml-[-28px]  font-bold text-[250px] s:text-[170px]'>{ele.chapterCount}</h2>
                                    <div className='w-full pr-12 xs:pr-4 s:pr-4 '>
                                        <div className='flex justify-between items-center py-4'>
                                            <p className='text-silverChalice font-bold'>9 <span className='pl-2 tracking-[3px]'>LESSONS</span></p>
                                            <button
                                                className={`text-gray85 font-bold inline-flex items-center gap-5 sm:text-[15px] text-[12px]`}
                                                type="button"
                                                onClick={() => {
                                                    if (expandedIndex === index) {
                                                        setExpandedIndex(-1);
                                                    } else {
                                                        setExpandedIndex(index);
                                                    }
                                                }}
                                            >
                                                {expandedIndex === index ? downArrow : upArrow}
                                            </button>

                                        </div>
                                        <h2 className='font-bold text-[30px] s:text-[20px] text-silverChalice pb-2'>{ele.chapterTitle ? ele.chapterTitle : "N/A"}</h2>
                                        <p className={`text-silverChalice border-l-2 border-silverChalice pl-4 s:pl-3 text-[13px] s:text-[12px] ${expanded[index] ? "block" : "hidden"}`}>
                                            {ele?.chapterLongDescription}
                                        </p>
                                        <p className={`text-silverChalice border-l-2 border-silverChalice pl-4 s:pl-3 text-[13px] s:text-[12px] ${expanded[index] ? "hidden" : "block"}`}>
                                            {`${ele?.chapterLongDescription?.split(' ').slice(0, 10).join(' ')}...`}
                                        </p>
                                        <button
                                            className={`text-silverChalice text-[12px] py-4`}
                                            onClick={() => {
                                                const newExpanded = [...expanded];
                                                newExpanded[index] = !newExpanded[index];
                                                setExpanded(newExpanded);
                                            }}
                                        >
                                            {expanded[index] ? "READ LESS" : "READ MORE"}
                                        </button>
                                    </div>
                                </div>
                                <div className="flex w-full items-center relative z-10">
                                    <div className="text-align-webkit-center w-full text-tuatara inline-flex items-center justify-end lg:mt-0">
                                        <div className={`group w-full inline-block dropdown cursor-pointer absolute top-[100%] ${show ? "block" : "hidden"}`}>
                                            <p className={`text-silverChalice bg-darkBlack pl-6 s:pl-4 font-[500] text-start py-8 s:py-4 tracking-[3px]`}>LESSONS</p>
                                            {ele.classes && ele.classes.map((ele: any, index: number) => {
                                                return (
                                                    <div className={`flex justify-between bg-darkBlack pr-12 s:pr-4 pl-6 s:pl-4 py-2 ${show ? "block" : "hidden"}`} key={index}>
                                                        <p className='text-silverChalice text-[16px] font-[500]'>{ele.classTitle}</p>
                                                        <p className="text-silverChalice text-[16px] font-[500] tracking-[1px]">
                                                            {ele.hours ? `${ele.hours.padStart(2, '0')}hr` : '00hr'} {ele.minutes ? `${ele.minutes.padStart(2, '0')}min` : '00min'}
                                                        </p>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default SessionContent	
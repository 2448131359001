import rootReducer from "./cartReducer";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const persistConfig = {
  key: "root",
  storage,
  blacklist: [],
};

export const persistedReducer = persistReducer(persistConfig, rootReducer);

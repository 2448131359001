import httpCommon from '../core/HttpCommonCore';
import { API_CONSTANT } from '../core/Constant';

export const selfTrainService = {
    getMasters,
    getCourses,
    getCoursesById,
    getChapters,
    getFaqs,
    getAllTrainer,
    getProductAndClass
};

function getMasters(data:any) {
    let url = API_CONSTANT.GET_MASTER
    return httpCommon.post(url,data).then((response: any) => {
        if (response.statusText !== "OK") {
            const error = response.statusText;
            return Promise.reject(error);
        }
        return response.data
    })
}

function getCourses(data : any) {
    let url = API_CONSTANT.GET_COURSES
    return httpCommon.post(url, data).then((response: any) => {
        if (response.statusText !== "OK") {
            const error = response.statusText;
            return Promise.reject(error);
        }
        return response.data
    })
}

function getCoursesById(id: any) {
    let url = API_CONSTANT.GET_COURSES_BY_ID + id
    return httpCommon.get(url).then((response: any) => {
        if (response.statusText !== "OK") {
            const error = response.statusText;
            return Promise.reject(error);
        }
        return response.data
    })
}
function getChapters() {
    let url = API_CONSTANT.GET_CHAPTER
    return httpCommon.post(url).then((response: any) => {
        if (response.statusText !== "OK") {
            const error = response.statusText;
            return Promise.reject(error);
        }
        return response.data
    })
}

function getProductAndClass(data: any) {
    let url = API_CONSTANT.GET_PRODUCT_AND_CLASS
    return httpCommon.post(url, data).then((response: any) => {
        if (response.statusText !== "OK") {
            const error = response.statusText;
            return Promise.reject(error);
        }
        return response.data
    })
}


function getFaqs(data:any) {
    let url = API_CONSTANT.GET_FAQ
    return httpCommon.post(url,data).then((response: any) => {
        if (response.statusText !== "OK") {
            const error = response.statusText;
            return Promise.reject(error);
        }
        return response.data
    })
}



function getAllTrainer(page: any) {

    let data = {
        pageCount: page,
        limit: 6,
        fields:["trainerName","trainerImage1","trainerImage2","trainerIntroTitle","trainerIntroLongDesc","totalClasses"]
    }
    let url = API_CONSTANT.GET_TRAINER
    return httpCommon.post(url, data).then((response: any) => {
        if (response.statusText !== "OK") {
            const error = response.statusText;
            return Promise.reject(error);
        }
        return response.data
    })
}

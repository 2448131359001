import React, { useState } from "react";
import {
  ArrowLeftIcon,
  ArrowUpRightIcon,
  BoltIcon,
} from "@heroicons/react/24/outline";

const BodyScansProduct = (props: any) => {
  const { open, setOpen } = props;
  const [expandedIds, setExpandedIds] = useState<any[]>([]);
  const [visibleOne, setVisibleOne] = useState<boolean>(false);

  const onClickArrow = (id: any) => {
    if (expandedIds.includes(id)) {
      setExpandedIds(expandedIds.filter((i) => i !== id));
      setVisibleOne(!visibleOne);
    } else {
      setExpandedIds([...expandedIds, id]);
    }
  };

  const faqData = [
    {
      _id: "64465aa16b5ba980bf34ccd7",
      faqName: "How does you subscription work?",
      faqLongDescription:
        "Your blood test arrives every 3 months. You can change your subscription and how often you’d like a test on your account dashboard. We’ll remind you that payment is due 3 days before your kit arrives. And you can pause, change, or cancel at any time.",
    },
    {
      _id: "64465aa16b5ba980bf34ccd8",
      faqName: "Data protection",
      faqLongDescription:
        "Your blood test arrives every 3 months. You can change your subscription and how often you’d like a test on your account dashboard. We’ll remind you that payment is due 3 days before your kit arrives. And you can pause, change, or cancel at any time.",
    },
    {
      _id: "64465aa16b5ba980bf34ccd9",
      faqName: "What’s included in your home testing kit?",
      faqLongDescription:
        "Your blood test arrives every 3 months. You can change your subscription and how often you’d like a test on your account dashboard. We’ll remind you that payment is due 3 days before your kit arrives. And you can pause, change, or cancel at any time.",
    },
    {
      _id: "64465aa16b5ba980bf34ccd1",
      faqName: "How to collect your sample at home",
      faqLongDescription:
        "Your blood test arrives every 3 months. You can change your subscription and how often you’d like a test on your account dashboard. We’ll remind you that payment is due 3 days before your kit arrives. And you can pause, change, or cancel at any time.",
    },
    {
      _id: "64465aa16b5ba980bf34ccd2",
      faqName: "Will a GP write your report?",
      faqLongDescription:
        "Your blood test arrives every 3 months. You can change your subscription and how often you’d like a test on your account dashboard. We’ll remind you that payment is due 3 days before your kit arrives. And you can pause, change, or cancel at any time.",
    },
    {
      _id: "64465aa16b5ba980bf34ccd3",
      faqName: "How long does it take to get your results?",
      faqLongDescription:
        "Your blood test arrives every 3 months. You can change your subscription and how often you’d like a test on your account dashboard. We’ll remind you that payment is due 3 days before your kit arrives. And you can pause, change, or cancel at any time.",
    },
    {
      _id: "64465aa16b5ba980bf34ccd4",
      faqName: "How do you know when your results are ready?",
      faqLongDescription:
        "Your blood test arrives every 3 months. You can change your subscription and how often you’d like a test on your account dashboard. We’ll remind you that payment is due 3 days before your kit arrives. And you can pause, change, or cancel at any time.",
    },
  ];

  let upArrow = (
    <svg
      className="fill-current h-5 w-5 -rotate-270
            transition duration-150 ease-in-out"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
    >
      <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
    </svg>
  );

  let downArrow = (
    <svg
      className="fill-current h-5 w-5 -rotate-180
            transition duration-150 ease-in-out"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
    >
      <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
    </svg>
  );

  return (
    <>
      {open && (
        <div
          onClick={() => setOpen(false)}
          className="w-full h-screen fixed z-[999] top-0 "
        ></div>
      )}
      <div
        className={`${
          open ? "left-0" : "-left-[100%]"
        } duration-500 bg-capeCod border-[4px] border-gray85 p-3 fixed top-[68px] w-full max-w-[717px] z-[999] h-[calc(100vh-68px)]`}
      >
          <div>
            <button
              onClick={() => setOpen(false)}
              className="flex items-center gap-2 text-gray85 ml-auto"
            >
              <ArrowLeftIcon className="w-5 h-5" />
              <span>Back</span>
            </button>
          </div>
          <div className="sm:px-5 px-1 mt-3 max-h-[calc(100vh-140px)] overflow-y-auto">
            <div className="flex justify-between mb-3">
              <h2 className="2xl:text-[32px] sm:text-[22px] text-[20px] font-medium text-gray85">
                Advance iron profile
              </h2>
              <h2 className="2xl:text-[32px] sm:text-[22px] text-[20px] font-medium text-gray85">
                £10.00
              </h2>
            </div>
            <div className="flex sm:gap-4 gap-3 sm:pr-4 flex-wrap">
              <div className="flex items-center gap-2 text-gray85">
                <BoltIcon className="rotate-0 2xl:w-5 2xl:h-5 w-4 h-4" />
                <span className="text-[11px]">Energy</span>
              </div>
              <div className="flex items-center gap-2 text-gray85">
                <BoltIcon className="rotate-0 2xl:w-5 2xl:h-5 w-4 h-4" />
                <span className="text-[11px]">Sleep</span>
              </div>
              <div className="flex items-center gap-2 text-gray85">
                <BoltIcon className="rotate-0 2xl:w-5 2xl:h-5 w-4 h-4" />
                <span className="text-[11px]">Fitness</span>
              </div>
            </div>
            <div className="mt-12">
              <button className="flex items-center text-capeCod lg:py-2 py-1 lg:px-8 px-3 bg-gray85 gap-2 font-bold lg:text-[20px] text-[16px]">
                <span>Add</span>
                <ArrowUpRightIcon className="w-5 h-5" />
              </button>
            </div>
            <hr className="border-gray85 my-8" />
            <h6 className="text-[15px] font-bold text-gray85 mb-4">
              About this test
            </h6>
            <p className="sm:text-[14px] text-[12px] text-gray85 mb-4">
              A detailed look at the fats and proteins that transport these fats
              in your blood. Compared to a standard cholesterol test, this will
              give you more advanced insights into your risk of heart disease.
            </p>
            <p className="sm:text-[14px] text-[12px] text-gray85 mb-4">
              This test measures the levels of different types of fats
              (cholesterol and triglycerides) in your blood. It also measures
              your apolipoprotein levels (proteins that transport fats in your
              blood) — called apo A1 and apo B. Apo A1 helps to protect against
              heart disease, while apo B is linked to an increased risk of heart
              disease. Measuring your apolipoprotein levels is considered a
              better predictor of your risk of heart disease than a standard
              cholesterol test.
            </p>
            <h6 className="text-[15px] font-bold text-gray85 mb-4">
              What’s tested?
            </h6>
            {faqData.map((ele: any, index: number) => {
              return (
                <div key={index}>
                  <button
                    onClick={() => onClickArrow(ele?._id)}
                    className="text-gray85 font-bold mb-[10px] mt-[10px] inline-flex items-center gap-5 sm:text-[15px] text-[12px]"
                    type="button"
                  >
                    {expandedIds.includes(ele?._id) ? downArrow : upArrow}{" "}
                    <span className="text-left">{ele?.faqName}</span>
                  </button>
                  <br />
                  {expandedIds.includes(ele?._id) && (
                    <div
                      className={`${
                        expandedIds.includes(ele?._id) ? "expand" : "collapse"
                      } text-set ml-10 bg-transperant mb-[5px] text-gray85 sm:text-[14px] text-[12px]`}
                    >
                      {ele?.faqLongDescription}
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
    </>
  );
};

export default BodyScansProduct;

import { useEffect, useState } from 'react';
import './VideoComponent.css';
import ReactPlayer from 'react-player';
import logo from '../../../assets/images/greenlabexpert.png';
import { useNavigate } from 'react-router-dom';
import Skeleton from "react-loading-skeleton";
import Slider from 'react-slick';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/solid';

const VideoComponent = (props: any) => {
    const { data, pageName } = props;
    const [courseData, setCourseData] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [slidersRef, setSlidersRef] = useState<any>(null);


    useEffect(() => {
        setTimeout(() => {
            setIsLoading(true)
        }, 3000)
    }, [])

    const navigate = useNavigate();
    const onClickVideo = (ele: any) => {
        navigate('/self-train/yoga-details/' + ele._id);
    };

    useEffect(() => {
        if (data) {
            setCourseData(data)
        }
    }, [data]);

    const playbutton = (
        <svg
            width="15%"
            height="15%"
            viewBox="0 0 24 24"
            fill="none"
            className="rounded-3xl bg-gray-300 "
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M15 12.3301L9 16.6603L9 8L15 12.3301Z"
                fill="currentColor"
                className="text-midnightDream"
            />
        </svg>
    );
 
    const itemsPerPage = 3;
    const settings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: itemsPerPage,
      slidesToScroll: itemsPerPage,
      responsive: [
        {
          breakpoint: 1280,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            initialSlide: 1,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          },
        },
      ],
    };

    const sliderNavigation = (direction: any) => {
        if (slidersRef) {
            if (direction === "next") {
                slidersRef.slickNext();
            } else if (direction === "previous") {
                slidersRef.slickPrev();
            }
        }
    };

    return (
        <>
            {pageName === "search-result" &&
                <div className="w-20 flex ml-auto cursor-pointer text-gray-300
                            ">
                    <ChevronLeftIcon onClick={() => sliderNavigation("previous")} />
                    <ChevronRightIcon onClick={() => sliderNavigation("next")} />
                </div>}
            {pageName === "search-result" ?
                <div className="slider-div">
                    <Slider {...settings} ref={(slider) => setSlidersRef(slider)} className='select'>
                        {courseData && courseData.map((ele: any, index: number) => {
                            return (
                                <div className="grid w-full 2xl:px-2 2xl:-ml-[0px] lg:px-13 xl:grid-cols-9 sm:grid-cols-8 px-3 gap-4">
                                <Video ele={ele} playbutton={playbutton} isLoading={isLoading} index={index} onClickVideo={onClickVideo} />
                                </div>)
                        })}
                    </Slider>
                </div>
                :
                <div className="grid w-full 2xl:px-20 2xl:-ml-[0px] lg:px-13 xl:grid-cols-9 sm:grid-cols-8 px-3 gap-4">
                    {courseData && courseData.map((ele: any, index: number) => {
                        return (
                            <Video ele={ele} playbutton={playbutton} isLoading={isLoading} index={index} onClickVideo={onClickVideo} />)
                    })}
                </div>}
        </>
    );
};

const Video = (props: any) => {
    const { ele, playbutton, isLoading, index, onClickVideo } = props
    return (
        <div className={`max-w-full sm:col-span-4 xl:col-span-3 mt-4 ${isLoading ? "skeleton-container" : ""}`} key={index}>
            {isLoading ? (
                <div
                    className="bg-white shadow bg-gray-800 rounded-[14px] overflow-hidden cursor-pointer"
                    onClick={() => {
                        onClickVideo(ele);
                    }}
                >
                    <div className="relative group 3xl:min-h-[290px] 2xl:min-h-[250px] xl:min-h-[200px] lg:min-h-[290px] md:min-h-[180px] xxs:min-h-[270px] xs:min-h-[330px] x:min-h-[240px]">
                        <div className="flex bg-gray justify-center visible group-hover:invisible absolute top-2/4 left-2/4 -translate-x-1/2 -translate-y-1/2 z-[2]">
                            {playbutton}
                        </div>
                        <img
                            src={ele.courseThumbImage || 'https://www.linkpicture.com/q/woman-1.png'}
                            className="player visible group-hover:invisible w-[-webkit-fill-available] h-[-webkit-fill-available]"
                            alt="Video Thumbnail"
                        />
                        <div className="">
                            <ReactPlayer
                                width="100%"
                                height="100%"
                                url={ele.courseHoverVideo || ""}
                                playing={true}
                                loop={true}
                            />
                        </div>
                    </div>
                    <div className="p-4">
                        <p className="2xl:text-[20px] lg:text-[18px] font-bold !text-gray-700 text-gray-400">
                            {`${ele.courseCardTitle ? ele.courseCardTitle.slice(0, 30) : "N/A"}${ele.courseCardTitle && ele.courseCardTitle.length > 30 ? '...' : ''}`}
                        </p>
                        <div className="flex justify-between">
                            <div className="flex items-center mt-2 gap-1">
                                <p className="font-bold text-[12px] text-gray-700">
                                    {ele.courseExpertId && ele.courseExpertId.trainerName ? `WITH ${ele.courseExpertId.trainerName.toUpperCase()}` : 'N/A'}
                                </p>
                                <img src="https://drive.google.com/uc?export=view&id=1dq6og6OI9GvxG8Rso9zTvPu_L9ApfmVQ" alt="Trainer" className="w-5 h-5 mr-2" />
                            </div>
                            <p className="font-bold text-[14px] h-[20px] mt-[10px]">{ele.coursePrice ? `£${ele.coursePrice.toFixed(2)}` : "N/A"}</p>
                        </div>
                        <img className="w-[90px]" src={logo} alt="Logo" />
                    </div>
                </div>
            ) : (

                <div className="bg-white h-full min-h-[385px] rounded-[14px]">
                    <Skeleton
                        className="!leading-[28px] h-[300px]"
                        containerClassName="h-full"
                    />
                    <div className="p-4">
                        <Skeleton
                            className="!leading-[22px] h-[22px] max-w-[70%]"
                            containerClassName="h-full"
                        />
                        <div className="flex justify-between mt-2 gap-1">
                            <div className="max-w-[60%] h-[24px] w-full">
                                <Skeleton
                                    className="!leading-[18px] h-[18px]"
                                    containerClassName="h-full"
                                />
                            </div>
                            <div className="max-w-[20%] h-[24px] w-full">
                                <Skeleton
                                    className="!leading-[18px] h-[18px]"
                                    containerClassName="h-full"
                                />
                            </div>
                        </div>
                        <Skeleton
                            className="!leading-[28px] h-[10px] max-w-[90px] mt-2"
                            containerClassName="h-full"
                        />
                    </div>
                </div>




            )}
        </div>
    );
};



export default VideoComponent;



import React, {  useState } from "react";
import { useNavigate } from "react-router-dom";
import LoginSecurity from "../login-security/LoginSecurity";
import PaymentDetails from "../payment-details/PaymentDetails";
import AboutUs from "../about-us/AboutUs";
import SignOut from "../sign-out/SignOut";
import ContactUs from "../contact-us/ContactUs";
import WishList from "../wish-list/WishList";
import OrderTracking from "../../pages/user-profile/OrderTracking";
import Order from "../order-tracking/OrderTracking";
import Profile from "../../pages/user-profile/Profile";
import FixAppointments from "../../pages/user-profile/FixAppointment";
import Plaid from "../plaid/PlaidHistory";

type Props = {
  title: string;
  children: any;
};

const userProfile = [
  {
    name: "My Profile",
    path: "/user/my-profile",
    component: (
      <>
        <Profile/>
      </>
    ),
  },
  {
    name: "Account settings",
    path: "/user/account-settings",
    component: (
      <>
        <LoginSecurity />
        <PaymentDetails />
      </>
    ),
  },
  {
    name: "Plaid Transaction",
    path: "/user/plaid",
    component: (
      <>
        <Plaid/>
      </>
    ),
  },
  {
    name: "Orders & Tracking",
    path: "/user/order-tracking",
    component: (
      <>
        <Order />
      </>
    ),
  },
  {
    name: "Wishlist",
    path: "/user/wishlist",
    component: <WishList />,
  },
  {
    name: "Expert classes",
    path: "/user/expert-classes",
    component: (
      <>
        <div className="py-20"></div>
      </>
    ),
  },
  {
    name: "Appointments",
    path: "/user/appointments",
    component: (
      <>
        <div className="py-20"></div>
        <FixAppointments />
      </>
    ),
  },
  {
    name: "Contact us",
    path: "/user/contact",
    component: <ContactUs />,
  },
  {
    name: "About us",
    path: "/user/about",
    component: <AboutUs />,
  },
  {
    name: "Sign Out",
    path: "/user/sign-out",
    component: <SignOut />,
   },
];

const UserChildren: React.FC<Props> = ({ children, title }) => {
    
  const navigate = useNavigate();

  return (
    <div className="bg-capeCod sm:flex min-h-[calc(100vh-68px)]">
      <div className="block border-r border-[#fff3] flex flex-col xl:pt-40 lg:pt-32 md:pt-24 sm:pt-16 pt-8 2xl:min-w-[300px] sm:min-w-[260px] 2xl:max-w-[300px] sm:max-w-[260px]">
        <ul className="px-4">
          {userProfile.map((item, ind) => (
            <>
              <li
                className={`${
                  item.path === window.location.pathname
                    ? "text-white"
                    : "text-blueSmoke"
                } sm:px-4 py-3 sm:mb-2 cursor-pointer hover:text-white`}
                key={ind}
                onClick={() => {
                  navigate(item.path);
                }}
              >
                {item.name}
              </li>
              {window.screen.width < 640 && item.path === window.location.pathname && (
                <div className="sm:hidden">{item.component}</div>
              )}
            </>
          ))}
        </ul>
      </div>
      {window.screen.width > 640 && 
      <div className="2xl:p-12 md:p-8 p-4 w-full 2xl:w-[calc(100%-300px)] sm:w-[calc(100%-260px)] sm:block hidden">
        <h1 className="mb-4 uppercase xl:text-[92px] lg:text-[62px] md:text-[42px] text-[32px] font-medium text-graygreen tracking-wide">
          {title}
        </h1>
        <div>{children}</div>
      </div>}
    </div>
  );
};

export default UserChildren;

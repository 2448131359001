import React from 'react'

const Testimonials = () => {
    return (
        <div className='bg-midnightDream md:px-28 2xl:px-40 xl:px-11 lg:px-11 md:px-11  x:px-4 s:px-4 xxs:px-4 px-4'>
            <h2 className='text-gray85 text-center text-[40px] md:text-[48px] font-bold md:py-10'>Testimonials</h2>
            <div className='md:grid md:grid-cols-4 gap-16 pt-8 pb-24'>
                <div className='col-span-1'>
                    <p className='text-lightGray text-[14px] xxs:py-2 x:py-2 s:py-2'>I have been doing yoga for 30 years. I have had teachers of all kinds. Taking lessons from [Rachel] has been more useful to me than taking yoga from anyone else. </p>
                    <h2 className='text-lightGray text-[14px] xxs:py-2 x:py-y s:py-4 pt-4'>- Deepak Chopra</h2>
                </div>
                <div className='col-span-1'>
                    <p className='text-lightGray text-[14px] xxs:py-2 x:py-2 s:py-2'>Love Rachel Green. Best yoga instructor I’ve worked with. I didn’t think yoga would be challenging for someone athletic like me. I really couldn’t have been more wrong. It made me control and move my body in ways I never could before, and it was an amazing outlet for stress. </p>
                    <h2 className='text-lightGray text-[14px] xxs:py-2 x:py-y s:py-4 pt-4'>- Brooklyn Decker</h2>
                </div>
                <div className='col-span-1'>
                    <p className='text-lightGray text-[14px] xxs:py-2 x:py-2 s:py-2'>One of the things I like about Rachel is her ability to make yoga accessible to people who might be scared of it or think it might be too esoteric.</p>
                    <h2 className='text-lightGray text-[14px] xxs:py-2 x:py-y s:py-4 pt-4'>- Jane Fonda</h2>
                </div>
                <div className='col-span-1'>
                    <p className='text-lightGray text-[14px] xxs:py-2 x:py-2 s:py-2'>Rachel Green has got to be the coolest yoga instructor ever. </p>
                    <h2 className='text-lightGray text-[14px] xxs:py-2 x:py-y s:py-4 pt-4'>- Vanity Fair</h2>
                </div>
            </div>
        </div>
    )
}

export default Testimonials;
import React from "react";
import { ArrowUpRightIcon } from "@heroicons/react/24/outline";
import HowItWorksStep1 from "../../../assets/images/how-it-works-step-1.png";
import HowItWorksStep2 from "../../../assets/images/how-it-works-step-2.png";
import HowItWorksStep3 from "../../../assets/images/how-it-works-step-3.png";
import HowItWorksStep4 from "../../../assets/images/how-it-works-step-4.png";

const HowItWorks = () => {
  return (
    <div className="bg-silverChalice 2xl:px-40 md:px-11 px-4 sm:pt-12 pt-8 sm:pb-20 pb-14">
      <h1 className="text-center xl:text-[60px] md:text-[48px] text-[32px] text-capeCod font-medium md:mb-6 mb-3">
        How It Works?
      </h1>
      <ul className="md:flex md:pl-[5%]">
        <li className="relative md:ml-[calc(-5%-5px)] lg:pl-20 pl-12 2xl:pr-16 lg:pr-14 pr-8 lg:py-20 md:py-12 c-sm:pt-8 c-sm:pb-20 xs:pt-8 xs:pb-20 s:pt-8 s:pb-20 md:w-[31%] w-full bg-capeCod polygonWrap z-[4] ">
          <div className="min-h-[200px]">
            <img
              className="max-h-[200px] mx-auto"
              src={HowItWorksStep1}
              alt="HowItWorksStep1"
            />
          </div>
          <div>
            <h3 className="text-greenFooter 2xl:text-[24px] lg:text-[20px] md:text-[16px] text-[20px] font-medium lg:mt-16 md:mt-10 mt-4 c-sm:text-center xs:text-center s:text-center">
              ORDER YOUR TEST
            </h3>
            <p className="text-greenFooter 2xl:text-[16px] lg:text-[14px] md:text-[12px] text-[16px] sm:max-w-full max-w-[280px] mx-auto c-sm:text-center xs:text-center s:text-center">
              We’ll post your kit the next working day (arrives in 1-2 days)
            </p>
          </div>
        </li>
        <li className="relative c-sm:mt-[-140px] xs:mt-[-140px] s:mt-[-140px] md:ml-[calc(-5%-5px)] lg:pl-20 pl-12 2xl:pr-16 lg:pr-14 pr-8 lg:py-20 md:py-12 c-sm:pt-40 c-sm:pb-20 xs:pt-40 xs:pb-20 s:pt-40 s:pb-20 md:w-[31%] w-full bg-tuatara polygonWrap z-[3] ">
          <div className="min-h-[200px]">
            <img
              className="max-h-[200px] mx-auto"
              src={HowItWorksStep2}
              alt="HowItWorksStep2"
            />
          </div>
          <div>
            <h3 className="text-greenFooter 2xl:text-[24px] lg:text-[20px] md:text-[16px] text-[20px] font-medium lg:mt-16 md:mt-10 mt-4 c-sm:text-center xs:text-center s:text-center">
              DO YOUR TEST
            </h3>
            <p className="text-greenFooter 2xl:text-[16px] lg:text-[14px] md:text-[12px] text-[16px] sm:max-w-full max-w-[280px] mx-auto c-sm:text-center xs:text-center s:text-center">
              Your kit contains everything you need to collect and post your
              sample from home.
            </p>
          </div>
        </li>
        <li className="relative c-sm:mt-[-140px] xs:mt-[-140px] s:mt-[-140px] md:ml-[calc(-5%-5px)] lg:pl-20 pl-12 2xl:pr-16 lg:pr-14 pr-8 lg:py-20 md:py-12 c-sm:pt-40 c-sm:pb-20 xs:pt-40 xs:pb-20 s:pt-40 s:pb-20 md:w-[31%] w-full bg-[#394A58] polygonWrap z-[2] ">
          <div className="min-h-[200px]">
            <img
              className="max-h-[200px] mx-auto 2xl:max-w-[90%]"
              src={HowItWorksStep3}
              alt="HowItWorksStep3"
            />
          </div>
          <div>
            <h3 className="text-greenFooter 2xl:text-[24px] lg:text-[20px] md:text-[16px] text-[20px] font-medium lg:mt-16 md:mt-10 mt-4 c-sm:text-center xs:text-center s:text-center">
              RESULTS IN 48 HOURS
            </h3>
            <p className="text-greenFooter 2xl:text-[16px] lg:text-[14px] md:text-[12px] text-[16px] sm:max-w-full max-w-[280px] mx-auto c-sm:text-center xs:text-center s:text-center">
              Your results, along with a personalised GP report, will be
              available online or on our app.
            </p>
          </div>
        </li>
        <li className="relative c-sm:mt-[-140px] xs:mt-[-140px] s:mt-[-140px] md:ml-[calc(-5%-5px)] lg:pl-20 pl-12 2xl:pr-16 lg:pr-14 pr-8 lg:py-20 md:py-12 c-sm:pt-40 c-sm:pb-20 xs:pt-40 xs:pb-20 s:pt-40 s:pb-20 md:w-[31%] w-full bg-capeCod polygonWrap ">
          <div className="min-h-[200px]">
            <img
              className="max-h-[200px] mx-auto"
              src={HowItWorksStep4}
              alt="HowItWorksStep4"
            />
          </div>
          <div>
            <h3 className="text-greenFooter 2xl:text-[24px] lg:text-[20px] md:text-[16px] text-[20px] font-medium lg:mt-16 md:mt-10 mt-4 c-sm:text-center xs:text-center s:text-center">
              DOCTOR REVIEW
            </h3>
            <p className="text-greenFooter 2xl:text-[16px] lg:text-[14px] md:text-[12px] text-[16px] sm:max-w-full max-w-[280px] mx-auto c-sm:text-center xs:text-center s:text-center">
              + Review by Doctor + Nutrition planning + Supplements advice
            </p>
          </div>
        </li>
      </ul>
    </div>
  );
};

export default HowItWorks;

import httpCommon from "../core/HttpCommonCore";
import { API_CONSTANT } from "../core/Constant";

export const CartService = {
  createCart,
  updateCartById,
  productAddCounterById,
  getCartByUserId,
  deleteCartByUserIdAndProductId,
  deleteCartProducts,
};

function createCart(data: any) {
  let url = API_CONSTANT.CREATE_CART;
  return httpCommon.post(url, data).then((response: any) => {
    if (response.statusText !== "OK") {
      const error = response.statusText;
      return Promise.reject(error);
    }
    return response.data;
  });
}

function updateCartById(id: any, data: any) {
  let url = API_CONSTANT.UPDATE_CART_BY_ID + id;
  return httpCommon.post(url, data).then((response: any) => {
    if (response.statusText !== "OK") {
      const error = response.statusText;
      return Promise.reject(error);
    }
    return response.data;
  });
}

function productAddCounterById(data: any) {
  let url = API_CONSTANT.PRODUCT_CART_ADD_COUNTER_BY_ID;
  return httpCommon.post(url, data).then((response: any) => {
    if (response.statusText !== "OK") {
      const error = response.statusText;
      return Promise.reject(error);
    }
    return response.data;
  });
}

function getCartByUserId() {
  let url = API_CONSTANT.GET_CART_BY_USER_ID;
  return httpCommon.post(url).then((response: any) => {
    if (response.statusText !== "OK") {
      const error = response.statusText;
      return Promise.reject(error);
    }
    return response.data;
  });
}

function deleteCartByUserIdAndProductId(data: any) {
  let url = API_CONSTANT.DELETE_PRODUCT_CART_BY_USER_ID_ANDS_PRODUCT_ID;
  return httpCommon.post(url, data).then((response: any) => {
    if (response.statusText !== "OK") {
      const error = response.statusText;
      return Promise.reject(error);
    }
    return response.data;
  });
}

function deleteCartProducts() {
  let url = API_CONSTANT.DELETE_CARD_PRODUCTS;
  return httpCommon.post(url).then((response: any) => {
    if (response.statusText !== "OK") {
      const error = response.statusText;
      return Promise.reject(error);
    }
    return response.data;
  });
}

import React from "react";
import arrow from "../../assets/images/arrow1.png";
import UserChildren from "../../components/user-profile/UserChildren";
import AboutUs from "../../components/about-us/AboutUs";

const About = () => {
  return (
    <UserChildren title="About Us">
      <div className="lg:flex gap-3 justify-between items-center lg:mt-16 mt-4">
        <div className="lg:w-[60%] w-full">
          <AboutUs />
        </div>
        <div className="lg:w-[40%] lg:mt-0 mt-12 w-full relative">
        <img className="w-full max-w-[400px]" src={arrow} />
          <p className="text-[12px] max-w-[400px] text-white tracking-[4.8px] text-center absolute top-1/2 left-0 w-full">
            CHEMISTRY THAT UNDERSTANDS YOUR BIOLOGY
          </p>
        </div>
      </div>
    </UserChildren>
  );
};

export default About;

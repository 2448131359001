import httpCommon from "../core/HttpCommonCore";
import { API_CONSTANT } from "../core/Constant";

export const PaymentService = {
  createPayment,
  addNewCard,
  getCustomerByEmail,
  createCustomer,
  getCardDetails,
  deleteExistingCard,
  paymentStatus,
  createPaymentApple
};

function createPayment(data: any) {
  let url = API_CONSTANT.CREATE_PAYMENT;
  return httpCommon.post(url, data).then((response: any) => {
    if (response.statusText !== "OK") {
      const error = response.statusText;
      return Promise.reject(error);
    }
    return response.data;
  });
}

function createPaymentApple(data: any) {
    let url = API_CONSTANT.CREATE_PAYMENT_APPLE;
    return httpCommon.post(url, data).then((response: any) => {
      if (response.statusText !== "OK") {
        const error = response.statusText;
        return Promise.reject(error);
      }
      return response.data;
    });
  }

function addNewCard(data: any) {
  let url = API_CONSTANT.ADD_NEW_CARD;
  return httpCommon.post(url, data).then((response: any) => {
    if (response.statusText !== "OK") {
      const error = response.statusText;
      return Promise.reject(error);
    }
    return response.data;
  });
}

function getCustomerByEmail() {
  let url = API_CONSTANT.GET_CUSTOMER_BY_EMAIL;
  return httpCommon.get(url).then((response: any) => {
    if (response.statusText !== "OK") {
      const error = response.statusText;
      return Promise.reject(error);
    }
    return response.data;
  });
}

function createCustomer() {
  let url = API_CONSTANT.CREATE_CUSTOMER;
  return httpCommon.post(url).then((response: any) => {
    if (response.statusText !== "OK") {
      const error = response.statusText;
      return Promise.reject(error);
    }
    return response.data;
  });
}

function getCardDetails() {
  let url = API_CONSTANT.GET_CARD_DETAILS;
  return httpCommon.get(url).then((response: any) => {
    if (response.statusText !== "OK") {
      const error = response.statusText;
      return Promise.reject(error);
    }
    return response.data;
  });
}

function deleteExistingCard(data: any) {
  let url = API_CONSTANT.DELETE_EXISTING_CARD;
  return httpCommon.post(url, data).then((response: any) => {
    if (response.statusText !== "OK") {
      const error = response.statusText;
      return Promise.reject(error);
    }
    return response.data;
  });
}

function paymentStatus(id: any) {
  let url = API_CONSTANT.PAYMENT_STATUS + id;
  return httpCommon.get(url).then((response: any) => {
    if (response.statusText !== "OK") {
      const error = response.statusText;
      return Promise.reject(error);
    }
    return response.data;
  });
}

import { useEffect, useState } from 'react';
import ExpertComponent from '../../../components/self-train/expert-component/ExpertComponent';
import MeetYourExpert from '../../../components/self-train/meet-your-expert/MeetYourExpert';
import ExpertCard from '../../../components/self-train/experts-card/ExpertsCard';
import Lessons from '../../../components/self-train/lessons';
import { selfTrainService } from '../../../services/self-train.service';
import { CONSTANT } from '../../../core/StaticConstant';
import { toast } from "react-toastify";
import { ArrowSmallUpIcon } from '@heroicons/react/24/solid';

const Expert = () => {
    const [trainerData, setTrainerData] = useState<any[]>([]);
    const [page, setPage] = useState(1);
    const [totalRecords, setTotalRecords] = useState();
    const [isTrainer,setIsTrainer] = useState(true);
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    };

    useEffect(() => {
    if(isTrainer){
        getAllTrainer();
    }
    }, [page]);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const handleScroll = () => {
        if (
            window.innerHeight + document.documentElement.scrollTop >=
            document.documentElement.offsetHeight
        ) {
            setPage((prevPage) => prevPage + 1);
        }
    };
    const getAllTrainer = async () => {
        try {
            if (trainerData.length === totalRecords) {
                return;
            }
            const response = await selfTrainService.getAllTrainer(page);
            if (response.status === CONSTANT.SUCCESS) {
                const newCourseData = response.data.trainersWithTotalClasses;
                setTrainerData((prevItems: any[]) => [...prevItems, ...newCourseData]);
                setTotalRecords(response.totalRecords);
                setIsTrainer(true);
            }else{
                setIsTrainer(false);
            }
        } catch (err: any) {
            setIsTrainer(false);
            console.error("error ", err.response)
            if(err?.response?.data?.error !== CONSTANT.TRAINER_NOT_FOUND) {
                toast.error(err?.response?.data?.error);
            }
        }
    };

    return (
        <>
            <div>
                <ExpertComponent />
                <MeetYourExpert />
                <ExpertCard trainerData={trainerData} />
                <Lessons />
            </div>
            <div className='bg-darkBlack flex justify-end h-[40px] xs:hidden x:hidden s:hidden'>
                <h2
                    className="text-white text-greenFooter   text-[18px] cursor-pointer font-bold"
                    onClick={() => scrollToTop()}
                >
                    Back to Top
                </h2>
                <a>
                    <ArrowSmallUpIcon
                        className="h-[25px] mt-[3px] ml-[4px] mr-[4rem]  cursor-pointer stroke-[2px] text-white"
                        onClick={() => scrollToTop()}
                    />
                </a>
            </div>
        </>
    )
}

export default Expert;
import React, { useEffect, useState } from "react";
import "./Faq.css";
import { bodyScan } from "../../../services/body-scan.service";

const FaqData = (props: any) => {
    const { faqData, isExpand, className, pagName } = props;
    const [expandedIds, setExpandedIds] = useState<any[]>([]);
    const [expand, setExpand] = useState<boolean>(false);
    const [visibleOne, setVisibleOne] = useState<boolean>(false);

    const onExpandAllClick = () => {
        const allIds = faqData.map((ele: any) => ele?._id);
        setExpandedIds(allIds);
        setExpand(true);
    };

    const onCollapseAllClick = () => {
        setExpandedIds([]);
        setExpand(false);
    };

    const onClickArrow = (id: any) => {
        if (expandedIds.includes(id)) {
            setExpandedIds(expandedIds.filter((i) => i !== id));
            setVisibleOne(!visibleOne);
        } else {
            setExpandedIds([...expandedIds, id]);
        }
    };

    let upArrow = (
        <svg
            className="fill-current h-10 w-10 -rotate-270
            transition duration-150 ease-in-out"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
        >
            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
        </svg>
    );

    let downArrow = (
        <svg
            className="fill-current h-10 w-10 -rotate-180
            transition duration-150 ease-in-out"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
        >
            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
        </svg>
    );

    return (
        <div className={`sm:grid ${className || ""} ${pagName == "self-train" ? "sm:grid-cols-6 bg-darkBlack" : 'sm:grid-cols-8'} `} id="FAQS">
            <div className={`${pagName == "self-train" ? 'sm:col-span-1' : 'lg:col-span-2 sm:col-span-1'}`}></div>
            <div
                className={`${isExpand
                    ? "lg:col-span-4 sm:col-span-5"
                    : " xl:px-16 sm:px-8 px-4 xl:py-8 sm:py-6 py-3  lg:col-span-8 sm:col-span-8"
                    } ${pagName == "self-train" ? 'bg-darkBlack' : 'bg-gray85'}`}
            >
                {faqData &&
                    faqData.map((ele: any, index: number) => {
                        return (
                            <div key={index} className="ml-auto">
                                <button
                                    onClick={() => onClickArrow(ele?._id)}
                                    className={`${pagName === "self-train" ? 'text-lightGray' : 'text-capeCod'} font-bold mb-[10px] mt-[10px] inline-flex items-center sm:text-[16px] text-[12px]`}
                                    type="button"
                                >
                                    {expandedIds.includes(ele?._id) ? downArrow : upArrow}{" "}
                                    {pagName === "self-train" ? (ele && ele.faqTitle) : null}
                                    {ele && ele.faqName}

                                </button>
                                <br />
                                {expandedIds.includes(ele?._id) && (
                                    <div
                                        className={`${expandedIds.includes(ele?._id) ? "expand" : "collapse"
                                            } ${pagName === "self-train" ? 'text-minGray text-[14px]' : 'text-capeCod sm:text-[16px] text-[12px]'} text-set ml-10 bg-transperant mb-[5px]  `}
                                    >
                                        <p
                                        >
                                            {pagName === "self-train" ? (ele && ele.faqDescription) : null}
                                            <span dangerouslySetInnerHTML={{ __html: ele.faqLongDescription }} />


                                        </p>


                                    </div>
                                )}
                            </div>
                        );
                    })}
            </div>
            <div className={isExpand ? "sm:col-span-1" : "lg:col-span-1"}></div>
            <div className="col-span-1 expand-hide">
                {isExpand &&
                    (!expand ? (
                        <button
                            className="text-capeCod font-bold bg-transperant border-0 float-left expand"
                            onClick={() => onExpandAllClick()}
                        >
                            Expand all
                        </button>
                    ) : (
                        <button
                            className="text-capeCod font-bold bg-transperant border-0 float-left collaps"
                            onClick={() => onCollapseAllClick()}
                        >
                            Collapse all
                        </button>
                    ))}
            </div>
        </div>
    );
};

export default FaqData;

import React from 'react'
import AppHero from '../../assets/images/app-hero.png'

const AppComponent = () => {
  return (
    <div>
      <div>
        <img src={AppHero} alt="AppHero" />
      </div>
    </div>
  )
}

export default AppComponent;
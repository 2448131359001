import React from 'react'

const MeetYourExpert = () => {
    return (
        <div className='bg-darkBlack lg:px-28 px-6'>
            <h2 className='text-gray85 text-center s:text-[34px] text-[40px] md:text-[48px] font-bold pt-10 pb-3'>Meet Your Experts</h2>
            <p className='text-gray85 text-center md:text-[13px] font-[300]'>Know your experts and their <br /> Qualification. Read about them and get inspired</p>
            <hr className="border-silver sm:mt-16 border-t-[1px] pt-8 hidden sm:block" />
            <h2 className='text-gray85 text-center s:text-[34px] text-[40px] md:text-[48px] pt-24 font-bold'>About GreenLab Experts</h2>
            <h4 className='py-2 text-gray85 text-center'>GreenLab Experts</h4>
            <div className='max-w-[1200px] m-auto'>
            <p className='text-midLightBlack text-start'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ultricies lacus sed turpis tincidunt. Sagittis vitae et leo duis ut diam quam. Ante in nibh mauris cursus mattis molestie a iaculis. Sagittis vitae et leo duis ut diam. Tincidunt ornare massa eget egestas purus viverra accumsan. Sagittis eu volutpat odio facilisis.</p>
            </div>
        </div>
    )
}

export default MeetYourExpert
import React, { useEffect, useState } from "react";
import { ProductService } from "../../services";
import { CONSTANT } from "../../core/StaticConstant";
import WishList from "../../components/wish-list/WishList";
import UserChildren from "../../components/user-profile/UserChildren";
import { useNavigate } from "react-router-dom";
import { getUserData } from "../../core/Utils";
import { Localstorage } from "../../core/AuthService";

const Wishlist = () => {
  const [likeUnlikeData, setLikeUnlike] = useState<any[]>([]);
  const [userId, setUserId] = useState<String>("");
  let navigate = useNavigate();
  const like = async () => {
    try {
      const response = await ProductService.GetlikeUnlikeProductByUser();
      if (response && response.status === CONSTANT.SUCCESS) {
        setLikeUnlike(response.data.product);
        // response.data.forEach((sku: any) => {
        //   if (sku.skuId.productQty === 0) {
        //     sku.skuId.productQty = "";
        //   }
        // });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const likeUnlikeProduct = async (productId: any, skuId: any) => {
    if(userId){
      try {
        let obj = {
            productId: productId, // Use productId instead of skuId
            skuId: skuId
          };  
        const response = await ProductService.likeUnlikeProductByUser(obj);
        if (response && response.status === CONSTANT.SUCCESS) {
          like();
        }
      } catch (error) {
        console.error(error);
      }
    } else {
      Localstorage(CONSTANT.SET,CONSTANT.LAST_VISITED_PAGE, window.location.href);
      navigate("/login")
    }
  };

  useEffect(() => {
    const userData: any = getUserData();
        setUserId(userData?.userId);
    if(userData?.userId){
      like();
    }
  }, []);

  return (
    <UserChildren title="wishlist">
      <div className="lg:flex gap-3">
        <div className="w-full">
          <ul>
            {likeUnlikeData?.length > 0 ? (
              likeUnlikeData?.map((item, index) => (
                <WishList
                  item={item}
                  index={index}
                  likeUnlikeProduct={likeUnlikeProduct}
                />
              ))
            ) : (
              <div className="text-[25px] py-4 font-medium">
                No items saved for later
              </div>
            )}
          </ul>
        </div>
      </div>
    </UserChildren>
  );
};

export default Wishlist;

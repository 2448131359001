import React from 'react'
import screen from '../../../assets/images/screen.png'

const WatchAnywhere = (props: any) => {

  const { courseData } = props
  return (
    <div className='bg-midnightDream x:px-4 s:px-4 xxs:px-4 md:pt-8'>
      <h2 className='text-center text-[40px] md:text-[48px] text-gray85 font-bold'>{courseData.courseWatchTitle ? courseData.courseWatchTitle: "N/A"}</h2>
      <p className='text-center m-auto text-midLightBlack font-[300] max-w-[400px] py-2'>{courseData.courseWatchSubtitle ?courseData.courseWatchSubtitle: "N/A" }</p>
      <div className='flex justify-center py-20 pt-4'>
        <img src={courseData.courseWatchImage ? courseData.courseWatchImage: ""} className='2xl: && xl:w-[1800px] px-20' />
      </div>
      <h2 className='text-center text-[40px] md:text-[48px] text-gray85 font-bold pt-16'>{courseData.courseTitle ? courseData.courseTitle: "N/A"}</h2>
      <p className='m-auto text-midLightBlack font-[300] text-center max-w-[400px] pb-8'>{courseData.courseSubtitle ?courseData.courseSubtitle: "N/A"}</p>
      <div className='max-w-[500px] m-auto bg-white mt-8'>
        <div className='px-8 pt-4 pb-2'>
          <p className='text-center font-bold text-darkBlack text-[13px] tracking-[2.5px]'>{courseData.coursePriceText ? courseData.coursePriceText: "N/A"}</p>
          <h2 className="text-center font-bold text-[25px]">
            {courseData.coursePrice ? `£${courseData.coursePrice.toFixed(2)}` : "N/A"}
          </h2>

        </div>
      </div>
      <p className='text-center text-midLightBlack text-[14px] py-4'>OR</p>
      <div className='max-w-[460px] m-auto bg-darkBlack'>
        <div className='px-8 pt-4 pb-2'>
          <p className='text-center text-nightDream font-bold text-[12px] tracking-[2px]'>{courseData.allCoursePriceText ? courseData.allCoursePriceText:"N/A"}</p>
          <h2 className='text-center text-nightDream font-bold text-[25px]'>{courseData.allCoursePrice ? `£${courseData.allCoursePrice.toFixed(2)}` : "N/A"}</h2>
        </div>
      </div>
      <div className='pt-12 pb-16'>
        <button
          className="hover:bg-contessa whitespace-nowrap bg-coralTree font-[500] text-white text-[14px] text-bold 2xl:h-[54px] sm:h-[46px] h-[40px] opacity-100 2xl:px-[30px] md:px-5 px-4 proceed-to-checkout sm:mt-0 mt-2 flex items-center gap-2 text-gray85 m-auto"
        >
          <span>Add To Cart</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="w-6 h-5 font-bold"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M4.5 19.5l15-15m0 0H8.25m11.25 0v11.25"
            />
          </svg>
        </button>
      </div>
    </div>
  )
}

export default WatchAnywhere
import React, { useEffect, useState, PropsWithChildren } from "react";
import {
    HeartIcon,
    ArrowUpRightIcon,
    StarIcon,
} from "@heroicons/react/24/outline";
import { HeartIcon as HeartSolidIcon } from "@heroicons/react/24/solid";
import { StarIcon as StarSolidIcon } from "@heroicons/react/24/solid";
import { ProductService } from "../../services";
import { CONSTANT } from "../../core/StaticConstant";
import Rating from "@mui/material/Rating";
import Stack from "@mui/material/Stack";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import { encryptData, getUserData } from "../../core/Utils";
import { Localstorage } from "../../core/AuthService";

function StarWrapper({ children }: PropsWithChildren<unknown>) {
    return (
        <div
            style={{
                display: "inline-block",
                clipPath:
                    "polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%)",
                width: "24px",
                height: "24px",
            }}
        >
            {children}
        </div>
    );
}

const ProductCard = (props: any) => {
    const {
        productId,
        productFormat,
        skuId,
        isveg,
        image,
        name,
        productVariant,
        description,
        productTitle,
        ratings,
        mrp,
        onAddToCart,
        css,
    } = props;
    const [likeUnlikeData, setLikeUnlike] = useState<any[]>(props.likeUnlikeData);
    
    let navigate = useNavigate();
    const [userId, setUserId] = useState<String>("");
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 2000);
    }, []);

    useEffect(() => {
        const userData: any = getUserData();
        setUserId(userData?.userId);
        // if (userData?.userId) {
        //     like(userData?.userId);
        // }
    }, []);
    const like = async () => {
        try {
          let response = await ProductService.GetlikeUnlikeProductByUser();
          if (response && response.status === CONSTANT.SUCCESS) {
            setLikeUnlike(response.data.product);
          }
        } catch (err: any) {
          console.error("Error occurred in like:", err);
          toast.error(err?.response?.data?.error);
        }
      };
    
      const likeUnlikeProduct = async (id: any) => {
        if (userId) {
          try {
            let obj = {
              productId: productId, 
              skuId: skuId,
            };  
            
            
            let response = await ProductService.likeUnlikeProductByUser(obj);
            if (response.status === CONSTANT.SUCCESS) {
              setLikeUnlike(response.data.product);
              toast.success(response.message);
              
            }
            await like();
          } catch (err: any) {
            console.error("Error occurred in likeUnlikeProduct:", err);
            toast.error(err?.response?.data?.error);
          }
        } else {
          Localstorage(CONSTANT.SET,CONSTANT.LAST_VISITED_PAGE,window.location.href);
          
          navigate("/login");
        }
      };

    const checkProductLikeOrNot = (productId: any) => {
        return (
            likeUnlikeData &&
            likeUnlikeData.length &&
            likeUnlikeData.some((a: any) => a?.productId?._id === productId)
        );
        
    };

    return (
        <div className={`${css} mx-[5px]`}>
            <div
                className="rounded-[0.5rem] overflow-hidden sm:pb-8 sm:pt-3 sm:px-[14px] p-2 bg-white relative"

            >
                <div className="inline-flex w-full justify-between sm:px-[18px] px-4 pt-2 absolute left-0">
                    {isveg === "Yes" ? <div className="text-align-webkit-center border-[2px] border-green w-[16px] h-[16px]">
                        <div className="w-[60%] h-[60%] bg-green rounded-md mt-[2.9px]"></div>
                    </div> :<div className="text-align-webkit-center border-[2px] border-red-700 w-[16px] h-[16px]">
                        <div className="w-[60%] h-[60%] bg-red-700 rounded-md mt-[2.9px]"></div>
                    </div>}
                    {/* <div className="text-align-webkit-center border-[2px] border-green w-[16px] h-[16px]">
                        <div className="w-[60%] h-[60%] bg-green rounded-md mt-[2.9px]"></div>
                    </div> */}
                    <span>
                        {productId ? (
                            checkProductLikeOrNot(productId) === true ? (
                                <span className="inline-flex w-full justify-end hover:underline items-center">
                                    <HeartSolidIcon
                                        className="h-[17px] text-[red] hover:border-[black]  cursor-pointer"
                                        onClick={() => likeUnlikeProduct(productId)}
                                    />
                                </span>
                            ) : (
                                <span className="inline-flex w-full justify-end items-center">
                                    <HeartIcon
                                        className="h-[17px] text-oxfordBlue hover:border-[black]  cursor-pointer"
                                        onClick={() => likeUnlikeProduct(productId)}
                                    />
                                </span>
                            )
                        ) : (
                            <span className="inline-flex w-full justify-end items-center">
                                <Skeleton
                                    width="100px"
                                    height="16px"
                                    containerClassName="h-full"
                                />
                            </span>
                        )}
                    </span>
                </div>

                {loading ? (
                    <Skeleton
                        className="!leading-[300px] xl:h-full"
                        containerClassName="h-full"
                    />
                ) : (
                    <div className="text-align-webkit-center">
                        <img
                            className="2xl:h-[300px] xl:h-[260px] lg:h-[278px] md:h-[230px] sm:h-[270px] x:!h-[180px] bg-white product-image"
                            src={
                                image ||
                                "https://us.123rf.com/450wm/pavelstasevich/pavelstasevich1811/pavelstasevich181101065/112815953-no-image-available-icon-flat-vector.jpg?ver=6"
                            }
                            alt="Image"
                        />
                    </div>
                )}
                <div className="mt-2">
                    {loading ? (
                        <Skeleton height="35px" containerClassName="h-full" />
                    ) : (
                        <div className="inline-flex">
                            <h1 className="font-bold 2xl:text-[20px] lg:text-[16px] sm:c-text1 text-midnightDream  cursor-pointer" onClick={()=>navigate(
                                `/shop/productdetail/${encryptData(productId)}`
                            )}>
                                {name}{" "}
                                <span className="font-light text-midnightDream 2xl:text-[25px] xl:text-[20px] md:text-[20px]">
                                    |
                                </span>{" "}
                                <span className="text-midnightDream 2xl:text-[14px] xl:text-[11px] lg:text-[10px] md:text-[10px] sm:text-[10px] c-text2">
                                    {productFormat}
                                </span>
                            </h1>
                        </div>
                    )}
                    <div>
                        {loading ? (
                            <Skeleton height="14px" containerClassName="h-full" />
                        ) : (
                            <h6 className="font-bold text-xs x:text-[10px] text-midnightDream">
                                {description}
                            </h6>
                        )}
                        {loading ? (
                            <Skeleton height="14px" containerClassName="h-full" />
                        ) : (
                            <p className="text-midnightDream x:text-[9px] text-xs my-0.5 productTitle overflow-hidden overflow-ellipsis">
                                {productTitle}
                            </p>
                        )}
                        <div className="flex my-0.5">
                            {loading ? (
                                <Skeleton
                                    count={5}
                                    wrapper={StarWrapper}
                                    height="100%"
                                    baseColor="#ebebeb"
                                    highlightColor="#f5f5f5"
                                    duration={0.9}
                                />
                            ) : (
                                <Stack>
                                    <Rating
                                        name="half-rating-read"
                                        className="color"
                                        value={ratings || 0}
                                        precision={0.5}
                                        readOnly
                                    />
                                </Stack>
                            )}
                            {loading ? (
                                <Skeleton
                                    width="37px"
                                    height="24px"
                                    containerClassName="h-full ml-2"
                                />
                            ) : (
                                <p className="ml-2 text-sm font-medium text-gray-500 mt-[3px] dark:text-gray-400">
                                    {ratings}
                                </p>
                            )}
                        </div>
                        <div className="inline-flex w-full justify-between mt-6">
                            {loading ? (
                                <Skeleton
                                    width="70px"
                                    height="30px"
                                    containerClassName="h-full ml-1"
                                />
                            ) : (
                                <h1 className="font-bold product-price 2xl:text-[20px] xl:text-[20px] x:text-[14px] text-[18px] text-midnightDream">
                                    {mrp ? `£${mrp}` : "N/A"}
                                </h1>
                            )}
                            {loading ? (
                                <Skeleton
                                    width="150px"
                                    height="38px"
                                    containerClassName="h-full"
                                />
                            ) : (
                                <button
                                    className="inline-flex items-center pt-[10px] hover:bg-[#616d78] bg-midnightDream font-bold text-white text-sm md:px-4 lg:px-5 px-6 x:px-3 x:text-[10px] x:py-1 py-1.5 pb-[8px] c-px-16 add-to-cart-btn 2xl:text-[14px] xl:text-[13px]"
                                    onClick={() => onAddToCart()}
                                >
                                    Add To Cart{" "}
                                    <ArrowUpRightIcon className="h-5 x:h-3 pt-[3px] pl-[7px] arrow-up-right-icon stroke-[4px] mb-1" />{" "}
                                </button>
                            )}
                        </div>
                        {/* <p className="text-[10px] text-right mt-[5px]">Delivered in days</p> */}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProductCard;

import React from "react";
import "./BannerComponent.css";

const BannerComponent = (props: any) => {
    const {
        banner,
        title,
        spanTitle,
        description,
        titleStyle,
        imageStyle,
        mainDivStyle,
        hrStyle,
        descStyle,
        btn
    } = props;

    return (
        <div className={imageStyle} style={{ backgroundImage: `url(${banner})` }}>
            <div className={mainDivStyle}>
                <h1
                    className={titleStyle}
                    dangerouslySetInnerHTML={{
                        __html: title ? title + (spanTitle ? `<span class="text-white">${spanTitle}</span>` : '') : "",
                    }}
                ></h1>
                <hr className={hrStyle} />
                <p
                    className={descStyle}
                    dangerouslySetInnerHTML={{
                        __html: description || "",
                    }}
                ></p>
                {btn}
            </div>
        </div>
    );
};

export default BannerComponent;

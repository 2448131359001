import React from "react";
import arrow from "../../assets/images/arrow1.png";
import UserChildren from "../../components/user-profile/UserChildren";
import AboutUs from "../../components/about-us/AboutUs";
import Plaid from "./PlaidHistory";

const PlaidTransaction = () => {
  return (
    <UserChildren title="Plaid Transaction">
      <div className="lg:flex gap-3 justify-between items-center mt-4">
        <div className="lg:w-[100%] md:w-full w-[100%]">
          <Plaid />
        </div>
      </div>
    </UserChildren>
  );
};

export default PlaidTransaction;

import React, { useEffect, useRef, useState } from "react";
import {
  ArrowUpRightIcon,
  HeartIcon,
  BoltIcon,
  BeakerIcon,
  FaceSmileIcon,
  MoonIcon,
  EyeIcon,
  ScaleIcon
} from "@heroicons/react/24/outline";

import shopBanner from "../../assets/images/banner2.png";
import CategoryNav from "../../components/sub-nav/SubNav";
import BannerComponent from "../../components/banner-component/BannerComponent";
import BodyScansProduct from "../../components/modal/BodyScansProductModal";

const BodyScansCustomPackage = () => {
  const scrollRef = useRef<HTMLDivElement>(null);
  const [selected, setSelected] = useState<any>("");
  const [open, setOpen] = useState<any>(false);
  const [selectedItems, setSelectedItems] = useState<any>(["all"]);

  const menuList: any = [
    { _id: 1, name: "All Test Packages" },
    { _id: 2, name: "Build Custom Test Packages" }
  ];

  const filterItem: any = [
    { name: "Fitness", icon: <HeartIcon className="rotate-0 w-5 h-5" /> },
    { name: "Mood", icon: <FaceSmileIcon className="rotate-0 w-5 h-5" /> },
    { name: "Sleep", icon: <MoonIcon className="rotate-0 w-5 h-5" /> },
    { name: "Energy", icon: <BoltIcon className="rotate-0 w-5 h-5" /> },
    { name: "Concentration", icon: <EyeIcon className="rotate-0 w-5 h-5" /> },
    { name: "Libido", icon: <HeartIcon className="rotate-0 w-5 h-5" /> },
    { name: "Weight", icon: <ScaleIcon className="rotate-0 w-5 h-5" /> }
  ];

  useEffect(() => {
    if (selectedItems.length > 1 && selectedItems.includes("all")) {
      setSelectedItems(selectedItems.filter((item: any) => item !== "all"));
    }
  }, [selectedItems]);

  const handleSelect = (name: any) => {
    if (name === "all") {
      setSelectedItems(["all"]);
    } else {
      setSelectedItems((selectedItems: any) => {
        if (selectedItems.includes(name)) {
          return selectedItems.filter((item: any) => item !== name);
        } else {
          return selectedItems
            .filter((item: any) => item !== "all")
            .concat(name);
        }
      });
    }
  };

  const handleItemClick = (item: any) => {
    const index = selected.indexOf(item);
    if (index === -1) {
      setSelected([...selected, item]);
    } else {
      setSelected(selected.filter((x: any) => x !== item));
    }
  };

  const handleWheel = (event: any) => {
    const delta = Math.max(
      -1,
      Math.min(1, event?.nativeEvent?.wheelDelta || -event?.nativeEvent?.detail)
    );
    if (scrollRef.current) {
      scrollRef.current.scrollLeft -= delta * 30; // Adjust the scrolling speed here
    }
  };

  useEffect(() => {
    if (open) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }
  }, [open]);

  return (
    <div>
      <CategoryNav pageName="body-scans" subMenuList={menuList} />
      <BannerComponent
        banner={shopBanner}
        title={"Build Your Test Package"}
        btn={
          <button
            onClick={() => setOpen(true)}
            className="flex gap-3 bg-graymetal md:px-8 sm:px-4 px-3 md:py-3 py-2 mt-3 sm:w-auto w-full justify-center hover:bg-[#5c7386]"
          >
            <span className="text-white md:text-[16px] text-[12px]">
              Build Custom Test Packages
            </span>
            <ArrowUpRightIcon className="text-white w-5 h-5" />
          </button>
        }
        description={
          "Choose which tests you’d like to add to your personalised package.<br /><br /> Need help choosing your tests? Get started with your recommended tests based on your goals, lifestyle and medical information"
        }
        titleStyle={
          "text-greenFooter xl:text-[3.5rem] lg:text-[3rem] md:text-[2.5rem] sm:text-[1.875rem] main-text heavy-font-heading leading-none"
        }
        imageStyle={
          "relative w-full 2xl:h-[42rem] xl:h-[36rem] lg:h-[32rem] md:h-[24rem] sm:h-[20rem] xs:h-[19rem] s:h-[19rem] grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-2 md:gap-4 lg:gap-6 2xl:px-40 md:px-11 px-4 py-5 s:py-3 bg-no-repeat xs:bg-right s:bg-right bg-cover"
        }
        mainDivStyle={
          "lg:max-w-[550px] sm:max-w-[350px] lg:pl-32 lg:pt-20 sm:pl-8 sm:pt-4 pt-4 pl-4 pr-4 sm:relative absolute sm:left-auto sm:top-auto top-[100%]"
        }
        hrStyle={
          "mt-5 xl:w-[83%] border-greenFooter border-4 lg:w-[56%] md:w-[65%] sm:w-[68%] banner-hr"
        }
        descStyle={
          "text-greenFooter mt-[10px] w-[80%] xl:text-xs xl:text-[15px] font-semibold sm:text-[10px] banner-desc"
        }
      />
      <BodyScansProduct open={open} setOpen={setOpen} />
      <div className="bg-tuatara 2xl:px-40 md:px-11 px-4 sm:pt-12 pt-56 sm:pb-20 pb-14">
        <div
          ref={scrollRef}
          onWheel={handleWheel}
          className="overflow-auto scrollbar-hide"
        >
          <ul className="flex xl:justify-between sm:gap-3 gap-2 sm:mb-[60px] mb-[40px] lg:max-w-[90%] lg:mx-auto">
            <li>
              <button
                onClick={() => handleSelect("all")}
                className={`${
                  selectedItems.includes("all")
                    ? "text-capeCod bg-greenFooter"
                    : "text-greenFooter"
                } flex sm:px-6 px-4 py-2 sm:gap-2 gap-1 items-center duration-300 sm:text-[16px] text-[14px]`}
              >
                <BeakerIcon className="rotate-0 w-5 h-5" />
                <span>All</span>
              </button>
            </li>
            {filterItem.map((item: any, i: any) => (
              <li key={i}>
                <button
                  onClick={() => handleSelect(item?.name)}
                  className={`${
                    selectedItems.includes(item?.name)
                      ? "text-capeCod bg-greenFooter"
                      : "text-greenFooter"
                  } flex sm:px-6 px-4 py-2 sm:gap-2 gap-1 items-center duration-300 sm:text-[16px] text-[14px]`}
                >
                  {item?.icon}
                  <span>{item?.name}</span>
                </button>
              </li>
            ))}
          </ul>
        </div>
        <div className="md:flex lg:gap-10 gap-6">
          <div className="md:w-[50%]">
            <ul>
              {[1, 2, 3, 4, 5, 6, 7, 8].map((item, index) => (
                <li
                  key={index}
                  className="bg-oxfordBlue lg:pl-8 pl-4 lg:pr-4 pr-3 lg:py-4 py-3 mb-4 md:max-w-[650px]"
                >
                  <div className="flex justify-between mb-3">
                    <h2 className="2xl:text-[32px] sm:text-[22px] text-[20px] font-medium text-gray85">
                      Advance iron profile
                    </h2>
                    <h2 className="2xl:text-[32px] sm:text-[22px] text-[20px] font-medium text-gray85">
                      £10.00
                    </h2>
                  </div>
                  <div className="flex sm:gap-4 gap-3 sm:pr-4 flex-wrap">
                    <div className="flex items-center gap-2 text-gray85">
                      <BoltIcon className="rotate-0 2xl:w-5 2xl:h-5 w-4 h-4" />
                      <span className="2xl:text-[19px] sm:text-[16px] text-[14px]">
                        Energy
                      </span>
                    </div>
                    <div className="flex items-center gap-2 text-gray85">
                      <BoltIcon className="rotate-0 2xl:w-5 2xl:h-5 w-4 h-4" />
                      <span className="2xl:text-[19px] sm:text-[16px] text-[14px]">
                        Sleep
                      </span>
                    </div>
                    <div className="flex items-center gap-2 text-gray85">
                      <BoltIcon className="rotate-0 2xl:w-5 2xl:h-5 w-4 h-4" />
                      <span className="2xl:text-[19px] sm:text-[16px] text-[14px]">
                        Fitness
                      </span>
                    </div>
                  </div>
                  <div className="flex justify-between gap-3 items-end">
                    <p className="2xl:text-[18px] lg:text-[14px] sm:text-[12px] text-[10px] pt-2 pb-2 pl-4 border-l-[5px] border-gray85 text-gray85">
                      Your body uses iron to make haemoglobin - a part of red
                      blood cells that carries oxygen around your body. If you
                      don’t get enough iron you might develop iron deficiency
                      anamia
                    </p>
                    <div>
                      <button
                        onClick={() => setOpen(true)}
                        className="flex items-center text-capeCod lg:py-3 py-1 lg:px-5 px-3 bg-gray85 gap-2 font-bold lg:text-[20px] text-[16px]"
                      >
                        <span>Add</span>
                        <ArrowUpRightIcon className="w-5 h-5" />
                      </button>
                    </div>
                  </div>
                  <button className="text-gray85 ml-5">Learn more</button>
                </li>
              ))}
            </ul>
          </div>
          <div className="md:w-[50%]">
            <div className="bg-silverChalice xl:px-8 px-4 py-4 md:max-w-[650px] ml-auto">
              <h4 className="xl:text-[32px] lg:text-[28px] sm:text-[22px] text-[18px] text-tuatara xl:mb-20 mb-12">
                YOUR PERSONALISED PACKAGE
              </h4>
              <ul>
                <li className="text-tuatara mb-3 flex justify-between xl:text-[20px] lg:text-[18px] text-[14px] px-3">
                  <p>Advance Iron profile</p>
                  <p>£10.00</p>
                </li>
                <li className="text-tuatara mb-3 flex justify-between xl:text-[20px] lg:text-[18px] text-[14px] px-3">
                  <p>Advance Iron profile</p>
                  <p>£10.00</p>
                </li>
                <li className="text-tuatara mb-3 flex justify-between xl:text-[20px] lg:text-[18px] text-[14px] px-3">
                  <p>Advance Iron profile</p>
                  <p>£10.00</p>
                </li>
                <hr className="border-tuatara" />
                <li className="text-tuatara mb-3 flex justify-between xl:text-[20px] lg:text-[18px] text-[14px] px-3 mt-3">
                  <p>Kit + postage + GP report</p>
                  <p>£30.00</p>
                </li>
                <li className="text-tuatara mb-3 flex justify-between xl:text-[28px] lg:text-[24px] text-[20px] px-3 mt-3">
                  <p>Total</p>
                  <p>£60.00</p>
                </li>
              </ul>
              <button className="w-full lg:mt-16 mt-8 px-4 py-3 flex items-center justify-center gap-3 bg-oxfordBlue text-white lg:text-[24px] text-[20px] font-bold">
                <span>Checkout</span>
                <ArrowUpRightIcon className="w-5 h-5" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BodyScansCustomPackage;

import React from "react";

const MarketingDetail = (props: any) => {
  const { marketData } = props;
  return (
    <div
      className="sm:grid lg:grid-cols-3 flex-wrap-reverse s:flex x:flex xs:flex c-md:flex c-sm:flex md:grid-cols-2 gap-10 pt-[50px] sm:mt-[50px] xs:mt-[30px]"
      id="marketingDetail"
    >
      <div className="col-span-2">
        <h1 className="xl:text-[65px] sm:text-[50px] xxs:text-[40px] x:text-[30px] s:text-[30px] font-bold text-oxfordBlue">
          {marketData?.marketingName || ""}
        </h1>
        <p className="xl:text-[19px] sm:text-[12px] xxs:text-[11px] x:text-[8px] s:text-[8px] font-bold xl:tracking-[5px] sm:tracking-[4px] xxs:tracking-[3px] x:tracking-[2px] text-oxfordBlue">
          {marketData?.marketingTitle?.toUpperCase() || ""}
        </p>
        <div className="xl:mt-[5rem] sm:mt-[3rem] xxs:mt-[3rem] x:mt-[2rem] s:mt-[2rem]">
          <h1 className="xl:text-[19px] sm:text-[16px] xxs:text-[16px] x:text-[14px] s:text-[14px] font-bold text-oxfordBlue">
            {marketData?.marketingIntro || ""}
          </h1>
          <p
            className="xl:mt-[30px] sm:mt-[18px] xxs:mt-[18px] x:mt-[18px] s:mt-[18px] xl:text-[17px] sm:text-[14px] xxs:text-[14px] x:text-[12px] s:text-[12px] text-oxfordBlue"
            dangerouslySetInnerHTML={{
              __html: marketData?.marketingDescription || "",
            }}
          ></p>
        </div>
        <div className="xl:mt-[5rem] sm:mt-[3rem] xxs:mt-[3rem] x:mt-[2rem] s:mt-[2rem]">
          <h1 className="text-oxfordBlue sm:text-[16px] xxs:text-[16px] x:text-[14px] s:text-[14px] font-bold">
            How it helps :
          </h1>
          <p
            className="leading-snug text-oxfordBlue xl:text-[17px] sm:text-[14px] xxs:text-[15px] x:text-[13px] s:text-[13px]"
            dangerouslySetInnerHTML={{
              __html: marketData?.marketingHelps || "",
            }}
          ></p>
        </div>
      </div>
      <div className="sm:col-span-2 w-full xs:col-span-2 lg:col-span-1 text-align-webkit-center">
        <img
          className="h-full object-cover"
          src={
            marketData?.marketingImage ||
            "https://us.123rf.com/450wm/pavelstasevich/pavelstasevich1811/pavelstasevich181101065/112815953-no-image-available-icon-flat-vector.jpg?ver=6"
          }
          alt=""
        />
      </div>
    </div>
  );
};

export default MarketingDetail;

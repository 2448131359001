
const SelectPerfectClass = (props: any) => {

    const { courseData } = props

    return (
        <div className='bg-darkBlack md:p-2 md:p-10 lg:p-16 px-4' id="SelectPerfectClass">
            <h2 className='text-center font-bold text-white text-[40px] md:text-[48px] py-6 md:py-12'>{courseData.courseWhoTitleText ? courseData.courseWhoTitleText: "N/A"}</h2>
            <p className='text-center text-mediumGray text-[20px]'>{courseData.courseWhoTitleSubtext ? courseData.courseWhoTitleSubtext: "N/A"}</p>
            <div className='md:grid md:grid-cols-6 xl:gap-16 gap-8 pt-8 md:pb-24 c-sm:pb-16'>
                <div className='col-span-1'></div>
                <div className='col-span-1'>
                    <p className='text-lightGray text-[15px] c-sm:py-2 xxs:py-2 x:py-2 s:py-2 x:px-4 s:px-4 xxs:px-4'>{courseData.courseWhoText1 ? courseData.courseWhoText1: "N/A"}</p>
                </div>
                <div className='col-span-1'>
                    <p className='text-lightGray text-[15px] c-sm:py-2 xxs:py-2 x:py-2 s:py-2 x:px-4 s:px-4 xxs:px-4'>{courseData.courseWhoText2 ? courseData.courseWhoText2: "N/A"}</p>
                </div>
                <div className='col-span-1'>
                    <p className='text-lightGray text-[15px] c-sm:py-2 xxs:py-2 x:py-2 s:py-2 x:px-4 s:px-4 xxs:px-4'>{courseData.courseWhoText3 ? courseData.courseWhoText3: "N/A"}</p>
                </div>
                <div className='col-span-1'>
                    <p className='text-lightGray text-[15px] c-sm:py-2 xxs:py-2 x:py-2 s:py-2 x:px-4 s:px-4 xxs:px-4'>{courseData.courseWhoText4 ? courseData.courseWhoText4: "N/A"}</p>
                </div>
                <div className='col-span-1'></div>
            </div>
            <hr className='midBlue mx-12 x:hidden s:hidden xxs:hidden' />
            <h2 className='text-center  font-bold text-white text-[40px] md:text-[48px] pt-16'>{courseData.courseTitle ? courseData.courseTitle: "N/A"}</h2>
            <p className='text-center text-mediumGray pt-6 text-[20px]'>{courseData.courseWhyTitle ? courseData.courseWhyTitle: "N/A"}</p>
            <div className='md:flex  max-w-[900px] justify-between m-auto pt-8'>
                {courseData.courseText1 && <div className='flex items-center m-auto max-w-[300px] gap-4'>
                    <h1 className='text-midnightDream font-bold text-[140px] leading-[150px]'>1</h1>
                    <p className='text-lightGray text-[13px]'>{courseData.courseText1}</p>
                </div>}
                {courseData.courseText2 && <div className='flex items-center m-auto max-w-[300px] gap-4'>
                    <h1 className='text-midnightDream font-bold text-[140px] leading-[150px]'>2</h1>
                    <p className='text-lightGray text-[13px]'>{courseData.courseText2}</p>
                </div>}
                {courseData.courseText3 && <div className='flex items-center m-auto max-w-[300px] gap-4'>
                    <h1 className='text-midnightDream font-bold text-[140px] leading-[150px]'>3</h1>
                    <p className='text-lightGray text-[13px]'>{courseData.courseText3}</p>
                </div>}
            </div>
        </div>
    )
}

export default SelectPerfectClass
import React, { useEffect, useState } from "react";
import "./SubNav.css";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { CONSTANT } from "../../core/StaticConstant";
import {
    BenefitService,
    CategoryService,
    MarketingIngredientService,
} from "../../services";
import { ArrowLongLeftIcon } from "@heroicons/react/24/solid";
import { PuzzlePieceIcon } from "@heroicons/react/24/outline";
import { setOrder } from "../../redux/reducer/cartReducer";
import { useDispatch, useSelector } from "react-redux";
import { decryptData, encryptData, getUserData } from "../../core/Utils";
import { PaymentService } from "../../services/payment.service";
import { toast } from "react-toastify";
import { DialogOverlay } from "@reach/dialog";
import LoginUser from "../profile-module/LoginUser";
import { Localstorage } from "../../core/AuthService";

const CategoryNav = (props: any) => {
    let params: any = useParams();
    const location = useLocation();
    const { pageName, subMenuList, selectId } = props;

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [categoryList, setCategoryList] = useState<any>([]);
    const [benefits, setBenefits] = useState<any>([]);
    const [marketingIngredientData, setMarketingIngredientData] =
        useState<any[]>();
    const [selectedCategoryIndex, setSelectedCategoryIndex] = useState<any>("1");
    const [selectMarketingIngredientId, setSelectMarketingIngredientId] =
        useState<any>();
    const [selectBenefitsId, setSelectBenefitsId] = useState<any>();
    const [userId, setUserId] = useState<String>("");
    const [showLoginModal, setShowLoginModal] = useState(false);

    const cartProduct = useSelector((state: any) => state.cartProducts);

    useEffect(() => {
        if (window.location.pathname === "/shop") {
            setSelectedCategoryIndex("1");
        }

        if (window.location.pathname === "/self-train") {
            setSelectedCategoryIndex("1");
        } else if (window.location.pathname === "/self-train/view-all-classes") {
            setSelectedCategoryIndex("2");
        }
    }, [window.location.pathname]);

    const pageNameCheck: any = location.pathname.includes("/self-train");
    const onAllProductClick = (id: any) => {
        let obj = {
            filter: id.name,
        };
        setSelectedCategoryIndex(id);
        if (id === 1) {
            navigate(`/shop`);
            setSelectMarketingIngredientId("");
        }
    };

    useEffect(() => {
        const userData: any = getUserData();
        setUserId(userData?.userId);
        if (pageName === "/shop" ||
            pageName === "/shop/cart/order" ||
            pageName === "/shop/cart" ||
            /^\/shop\/ingredient\/[A-Za-z0-9-]+$/.test(pageName) ||
            /^\/shop\/productdetail\/[A-Za-z0-9-]+$/.test(pageName) ||
            /^\/shop\/productCategory\/[A-Za-z0-9-]+$/.test(pageName)
        ) {
            getAllCategoryForTop();
        }
        let obj = {
            fields: ["name", "shortDescription", "longDescription", "ingredientImage"]
        }
        if (
            pageName === "/shop" ||
            pageName === "/shop/cart/order" ||
            pageName === "/shop/cart" ||
            /^\/shop\/ingredient\/[A-Za-z0-9-]+$/.test(pageName) ||
            /^\/shop\/productdetail\/[A-Za-z0-9-]+$/.test(pageName) ||
            /^\/shop\/productCategory\/[A-Za-z0-9-]+$/.test(pageName)
        ) {
            fetchAllMarketingIngredient(obj);
            getAllBenefits(obj);
        }

    }, [pageName]);


    //   location.pathname.match(new RegExp(`^${ROUTING_CONSTANT.USER_HARDWARE_SPECIFIC}/[A-Za-z0-9-]+$`))

    const fetchAllMarketingIngredient = async (obj: any) => {
        try {
            let response =
                await MarketingIngredientService.getAllMarketingIngredients(obj);
            if (response.status === CONSTANT.SUCCESS) {
                setMarketingIngredientData(response.data.ingredients);
            }
        } catch (error) {
            console.error(
                "Error occurred while fetching marketing ingredients:",
                error
            );
        }
    };

    const getAllCategoryForTop = async () => {
        try {
            let response = await CategoryService.getAllCategoryForTopBar();
            if (response.status === CONSTANT.SUCCESS) {
                setCategoryList(response.data.categories);
            }
        } catch (error) {
            console.error(
                "Error occurred while fetching marketing ingredients:",
                error
            );
        }
    };

    const getAllBenefits = async (obj: any) => {
        try {
            let obj = {
                fields: ["benefitName", "benefitIcon", "benefitImage", "longDescription", "shortDescription"]
            }
            let response = await BenefitService.getAllBenefit(obj);
            if (response.status === CONSTANT.SUCCESS) {
                setBenefits(response.data.benefits);
            }
        } catch (error) {
            console.error(
                "Error occurred while fetching marketing ingredients:",
                error
            );
        }
    };

    const onFilterClick = (id: any) => {
        setSelectMarketingIngredientId(id);
        navigate(`/shop/ingredient/${encryptData(id)}`);
    };

    const onBenefitClick = (id: any) => {
        setSelectBenefitsId(id);
    };

    const onBackButton = () => {
        window.history.back();
    };

    const onClickCheckout = () => {
        if (cartProduct.length > 0) {
            if (userId) {
                navigate("/shop/cart/order");
                dispatch(setOrder("addNewAddress"));
                getCustomerByEmail();
            } else {
                Localstorage(CONSTANT.SET, CONSTANT.LAST_VISITED_PAGE, window.location.href);
            }
        }
    };

    const getCustomerByEmail = async () => {
        try {
            let response: any = await PaymentService.getCustomerByEmail();
            if (response.status !== CONSTANT.SUCCESS) {
                createCustomer();
            } else {
                if (response && response.data && response.data.customer && response.data.customer.length > 0 && response.data.customer[0].data && response.data.customer[0].data.id) {
                    Localstorage(CONSTANT.SET, CONSTANT.CUSTOMER_ID, response.data.customer[0].data.id);
                }
            }
        } catch (error: any) {
            if (error?.response?.data?.error === CONSTANT.CUSTOMER_NOT_FOUND) {
                createCustomer();
            }
        }
    };
    const createCustomer = async () => {
        try {
            let response = await PaymentService.createCustomer();
            if (response.status === CONSTANT.SUCCESS) {
                if (response && response.data && response.data.customer && response.data.customer.data && response.data.customer.data.id) {
                    Localstorage(CONSTANT.SET, CONSTANT.CUSTOMER_ID, response.data.customer.data.id);
                }
            }
        } catch (error) {
            toast.error(CONSTANT.FAIL);
        }
    };

    const onCategoryClick = (id: any) => {
        if (location.pathname.includes("/shop")) {
            setSelectedCategoryIndex(id?._id);
            if (id === 0) {
                navigate(`/shop`);
                setSelectMarketingIngredientId("");
            } else {
                navigate(`/shop/productCategory/${encryptData(id.name)}`, { state: id });
            }
        }

        if (
            pageNameCheck &&
            !location.pathname.includes(CONSTANT.YOGA_DETAILS_PATH)
        ) {
            setSelectedCategoryIndex(id?._id);
            navigate(`${CONSTANT.SELF_TRAIN_PATH}/${id.path}`);
        }

        if (location.pathname.includes(CONSTANT.YOGA_DETAILS_PATH)) {
            setSelectedCategoryIndex(id?._id);

            if (id.name === "Feature Classes") {
                navigate(CONSTANT.SELF_TRAIN_PATH);
            }

            if (id.name === "All Classes") {
                navigate(CONSTANT.VIEW_ALL_CLASSES_PATH);
            }

            if (id.name === "Experts") {
                navigate(CONSTANT.EXPERTS_PATH);
            }

            const reviewsSection = document.getElementById(id.name);
            if (reviewsSection) {
                reviewsSection.scrollIntoView({ behavior: "smooth" });
            }
        }

        if (pageName === "/body-scans") {
            setSelectedCategoryIndex(id?._id);
        }
    };

    const filteredCategories =
        categoryList &&
        categoryList.filter((ele: any) => ele.name !== "All Products");

    const handleNavigate = () => {
        navigate("/shop");
    };

    return (
        <>
            <div className="2xl:px-40 md:px-11 px-4 py-2 top-[68px] inline-flex w-full inherit justify-center opacity-100 bg-tuatara shop-topbar sm:sticky  top-0 z-30 text-center">
                <div className="w-full inline-flex xs:flex-wrap s:flex-wrap sm:justify-between justify-center text-white xl:text-[18px] my-auto lg:text-[16px] text-bold items-center xs:text-[14px] s:text-[12px] xs:gap-3 s:gap-3">
                    <div className="flex items-center  xs:max-w-full xs:max-w-6/12 s:max-w-6/12">
                        {pageName.includes("body-scans") ||
                            pageName.includes("self-train") ? (
                            <div className="sm:block flex w-full gap-2 items-center justify-between">
                                {pageName.includes("body-scans") && (
                                    <p className="sm:hidden text-[10px] max-w-[180px]">
                                        Get unlimited access with{" "}
                                        <b>Comprehensive Annual Health Plan</b>
                                    </p>
                                )}
                                <button
                                    className="hover:bg-marigold bg-olive text-white 3xl:text-[18px] sm:text-[14px] text-[12px] text-bold h-[40px] opacity-100 2xl:px-[30px] md:px-5 xs:px-2 s:px-2 px-4 flex items-center w-max"
                                    onClick={() => onBackButton()}
                                >
                                    <span className="md:mr-3 mr-2 whitespace-nowrap">
                                        Get Unlimited Access
                                    </span>
                                    <PuzzlePieceIcon className="h-5 w-5" />
                                </button>
                            </div>
                        ) : (
                            <button
                                className="hover:bg-marigold sm:flex items-center hidden bg-olive text-white 3xl:text-[18px] text-[14px] text-bold h-[40px] opacity-100 2xl:px-[30px] md:px-5 px-4"
                                onClick={() => onBackButton()}
                            >
                                <ArrowLongLeftIcon className="arrow-icon mr-2 font-bold" />
                                <span>Back</span>
                            </button>
                        )}
                    </div>
                    <div className="sm:flex items-center w-full justify-between xs:order-1 s:order-1">
                        <div className={`w-full ${pageName.includes("/shop") ? "text-center" : "text-left"}`}>
                            <div className="inline-block xs:flex s:flex xs:flex-wrap mx-4 s:flex-wrap xs:justify-center s:justify-center">
                                {pageName !== "body-scans" &&
                                    !pageNameCheck &&
                                    pageName !== "self-train-details" && (
                                        <span
                                            className={`3xl:px-[25px] 3xl:text-[16px] text-[14px] s:px-3 pr-4 hover:text-white cursor-pointer ${selectedCategoryIndex === 1
                                                ? "text-white"
                                                : "text-silverChalice"
                                                }`}
                                            onClick={() => onAllProductClick(1)}
                                        >
                                            All Products
                                        </span>
                                    )}
                                {(pageName === "body-scans" || pageNameCheck || pageName === "self-train-details") && subMenuList.map((ele: any, i: any) => {
                                    return (
                                        <span
                                            key={i}
                                            className={`3xl:px-[25px] 3xl:text-[16px] text-[14px] s:px-3 px-4 hover:text-white cursor-pointer ${selectedCategoryIndex === ele?._id || selectId === i
                                                ? "text-white"
                                                : "text-silverChalice"
                                                }`}
                                            onClick={() => {
                                                if (pageName !== "body-scans") {
                                                    onCategoryClick(ele);
                                                }
                                            }}
                                        >
                                            {ele?.name}
                                        </span>
                                    );
                                })}
                            </div>
                            {pageName !== "body-scans" &&
                                !pageNameCheck &&
                                pageName !== "self-train-details" && (
                                    <>
                                        <div className="group showconcern inline-block relative xs:mt-[15px] c-margin-left-4px">
                                            <button className="bg-transperant text-silverChalice 3xl:text-[16px] text-[14px] hover:text-white py-2 sm:px-4 px-[7px] rounded inline-flex items-center">
                                                <span className="mr-1 w-max">Shop by top Concern</span>
                                                <span className="mt-[3px]">
                                                    <svg
                                                        className="fill-current xl:h-5 xl:w-5 h-4 w-4 -rotate-90 group-hover:-rotate-0
                                        transition duration-150 ease-in-out"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        viewBox="0 0 20 20"
                                                    >
                                                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                                    </svg>
                                                </span>
                                            </button>
                                            <div className="absolute hidden text-silverChalice bg-tuatara rounded pt-2 group-hover:grid group-hover:grid-cols-2 group-hover:w-[500px] text-start z-10">
                                                {filteredCategories &&
                                                    filteredCategories.map((ele: any, index: number) => {
                                                        return (
                                                            <div className="" key={index}>
                                                                <span
                                                                    className={` py-1 px-3 cursor-pointer block whitespace-no-wrap text-center text-base${selectedCategoryIndex === ele?._id
                                                                            ? "text-[white]"
                                                                            : ""
                                                                        }`}
                                                                    onClick={() => onCategoryClick(ele)}
                                                                >
                                                                    {ele.name}
                                                                </span>
                                                            </div>
                                                        );
                                                    })}
                                            </div>
                                        </div>

                                        
                                        <div className="group showconcern inline-block relative xs:mt-[15px] c-margin-left-4px">
                                            <button className="bg-transperant text-silverChalice 3xl:text-[16px] text-[14px] hover:text-white py-2 sm:px-4 px-[7px] rounded inline-flex items-center">
                                                <span className="mr-1 w-max">Shop by top Concern</span>
                                                <span className="mt-[3px]">
                                                    <svg
                                                        className="fill-current xl:h-5 xl:w-5 h-4 w-4 -rotate-90 group-hover:-rotate-0
                                        transition duration-150 ease-in-out"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        viewBox="0 0 20 20"
                                                    >
                                                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                                    </svg>
                                                </span>
                                            </button>
                                            <div className="absolute hidden bg-tuatara text-silverChalice rounded pt-2 group-hover:grid group-hover:grid-cols-2 group-hover:w-[500px] text-start z-10">
                                                {benefits &&
                                                    benefits.map((ele: any, index: number) => {
                                                        return (
                                                            <div className="" key={index}>
                                                                <span
                                                                    className={`py-1 px-3 cursor-pointer block whitespace-no-wrap text-center text-base${selectBenefitsId === ele?._id
                                                                            ? "text-[white]"
                                                                            : ""
                                                                        }`}
                                                                    onClick={() => onBenefitClick(ele?._id)}
                                                                >
                                                                    {ele.benefitName}
                                                                </span>
                                                            </div>
                                                        );
                                                    })}
                                            </div>
                                        </div>
                                        <div className="group showconcern inline-block relative xs:mt-[15px] c-margin-left-4px">
                                            <button className="bg-transperant text-silverChalice 3xl:text-[16px] text-[14px] hover:text-white py-2 sm:px-4 px-[7px] rounded inline-flex items-center">
                                                <span className="mr-1 w-max">
                                                    Shop by top ingredients
                                                </span>
                                                <span className="mt-[3px]">
                                                    <svg
                                                        className="fill-current xl:h-5 xl:w-5 h-4 w-4 -rotate-90 group-hover:-rotate-0
                                        transition duration-150 ease-in-out"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        viewBox="0 0 20 20"
                                                    >
                                                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                                    </svg>
                                                </span>
                                            </button>
                                            <div className="absolute hidden bg-tuatara text-silverChalice rounded pt-2 group-hover:grid group-hover:grid-cols-2 group-hover:w-[500px] text-start z-10">
                                                {marketingIngredientData &&
                                                    marketingIngredientData.map(
                                                        (ele: any, index: number) => {
                                                            return (
                                                                <div className="" key={index}>
                                                                    <span
                                                                        className={`py-1 px-3 cursor-pointer block whitespace-no-wrap text-center text-base ${selectMarketingIngredientId === ele?._id
                                                                                ? "text-[white]"
                                                                                : ""
                                                                            }`}
                                                                        onClick={() => onFilterClick(ele?._id)}
                                                                    >
                                                                        {ele.name}
                                                                    </span>
                                                                </div>
                                                            );
                                                        }
                                                    )}
                                            </div>
                                        </div>
                                    </>
                                )}
                        </div>
                    </div>
                    <div className="getAllAccess">
                        {pageName !== "body-scans" && (
                            <button
                                className="hover:bg-contessa whitespace-nowrap bg-coralTree text-white 3xl:text-[18px] xs:text-[12px] s:text-[12px] text-[14px] text-bold h-[40px] opacity-100 2xl:px-[30px] md:px-5 xs:px-2 s:px-2 px-4 proceed-to-checkout flex items-center gap-2 text-gray85 ml-auto"
                                onClick={() => {
                                    if (userId) {
                                        onClickCheckout();
                                    } else {
                                        setShowLoginModal(true);
                                    }
                                    if (pageName === "/shop/cart/order") {
                                        handleNavigate();
                                    }
                                }}
                            >
                                {pageNameCheck ? (
                                    <span>Get All Class Access</span>
                                ) : pageName === "/shop/cart/order" ? (
                                    <span>Continue Shopping</span>
                                ) : (
                                    <span>Proceed to Checkout</span>
                                )}

                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth="1.5"
                                    stroke="currentColor"
                                    className="h-5 w-5 font-bold"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M4.5 19.5l15-15m0 0H8.25m11.25 0v11.25"
                                    />
                                </svg>
                            </button>
                        )}
                    </div>
                </div>
            </div>

            {pageName === "shop/cart" ||
                ("/shop/productdetail/:Id" &&
                    pageName !== "/shop/cart/order" &&
                    !userId &&
                    cartProduct.length > 0 && (
                        <DialogOverlay isOpen={showLoginModal}>
                            <div className="dialog-container">
                                <LoginUser setShowLoginModal={setShowLoginModal} />
                            </div>
                        </DialogOverlay>
                    ))}
        </>
    );
};

export default CategoryNav;

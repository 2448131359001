import "./ExpertCard.css"
import img1 from "../../../assets/images/AdobeStock_1.png"
import Skeleton from "react-loading-skeleton";
import { useEffect, useState } from "react";

const ExpertCard = (props: any) => {

    const{trainerData} = props
    const cardColors = ['bg-dustyRose', 'bg-[#82938C]', 'bg-[#394A59]', 'bg-[#887730]', 'bg-[#9C92A3]', 'bg-[#36403C]'];
    const shadowClasses = [
        'primary-shadow',
        'secondary-shadow',
        'tertiary-shadow',
        'analogous-shadow',
        'warm-shadow',
        'cool-shadow'
      ]; 

      const [isLoading, setIsLoading] = useState(false);

      useEffect(() => {
        setTimeout(() => {
          setIsLoading(true)
        }, 3000)
      }, [])
    
    return (
        <>

            <div className='bg-darkBlack py-8 2xl:px-40 md:px-11 px-4'>

                <div className='flex flex-wrap gap-6 md:w-[90%] sm:max-w-none max-w-[350px] mx-auto'>
            {trainerData && trainerData.map((ele: any, index: number) => {
                return(
                    isLoading ? <div className='xl:w-[calc(33.33%-16px)] sm:w-[calc(50%-12px)] w-full py-20' key={index}>
                        <div className='relative'>

                        <div className={`card ${cardColors[index % cardColors.length]} rounded-[10px]`} key={index}>
                        <div className="flex  ml-7">
                 
                            <img src="https://drive.google.com/uc?export=view&id=1oua1FryE-Ex4qg7ce9GRVnKS0eJkokO3" alt="Trainer" className="w-14 h-14 image" />
                </div>  
                                <h2 className='text-white pl-3'>GreenLab Expert</h2>   
                                <img className='card-img ' src={ele.trainerImage2 ? ele.trainerImage2: ""} />
                            </div>
                            <div className={`card ${shadowClasses[index % shadowClasses.length]} rounded-[10px] absolute w-full h-auto bottom-[0] px-6`} key={index}>
                                <h2 className="text-[60px] font-bold text-white leading-none">
                                    {ele.trainerName ? ele.trainerName:"N/A"}
                                </h2>   
                                <p className='text-white font-[300] text-[13px]'>{ele.trainerIntroTitle ? ele.trainerIntroTitle : "N/A"}</p>
                                <div className='flex justify-between py-4'>
                                    <h2 className='text-white'>  {ele.totalClasses ? `${ele.totalClasses} ${ele.totalClasses === 1 ? "Class" : "Classes"}` : "0 Class"} </h2>
                                    <button className='text-white'>Know more</button>
                                </div>
                            </div>
                        </div>
                    </div> : <div className='bg-white ml-[80px] h-full max-w-[450px] min-h-[385px] rounded-[14px] '>
            <Skeleton
              className="!leading-[9px] h-[300px] "
              containerClassName="h-full"
            /></div>  )
                })}
                 
                </div>

            </div>
        </>
    )
}

export default ExpertCard
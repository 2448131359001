import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { UserService } from "../../services";

const VerifyUser = () => {
    const { id } = useParams()

    useEffect(() => {
        user()
    }, [])

    let obj = {
        verifyForgotPasswordToken: id
    };

    const navigate = useNavigate()
    const user = async () => {
        const verifyUser = await UserService.verifyUserToken(obj);
        if (verifyUser && verifyUser.status === "success") {
            toast.success(verifyUser.message);
            const resetPasswordLink = "/forgotpassword" + "/" + verifyUser.data.userId;
            navigate(resetPasswordLink);
        }

        else {
            toast.error(verifyUser.message)
        }
    }

    return(
        <div>
        </div>
    )
}


export default VerifyUser
import httpCommon from "../core/HttpCommonCore";
import { API_CONSTANT } from "../core/Constant";

export const PlaidService = {
  createLinkToken,
  plaidTransaction,
  getPlaidTransactionByUserId
};

function createLinkToken() {
  let url = API_CONSTANT.CREATE_LINK_TOKEN;
  return httpCommon.get(url).then((response: any) => {
    if (response.statusText !== "OK") {
      const error = response.statusText;
      return Promise.reject(error);
    }
    return response.data;
  });
}

function plaidTransaction(data: any) {
  let url = API_CONSTANT.PLAID_TRANSACTION;
      return httpCommon.post(url, data).then((response: any) => {
      if (response.statusText !== "OK") {
        const error = response.statusText;
        return Promise.reject(error);
      }
      return response.data;
    });
}

function getPlaidTransactionByUserId(data: any) {
    let url = API_CONSTANT.GET_PLAID_TRANSACTION_BY_USER_ID;
    return httpCommon.post(url, data).then((response: any) => {
      if (response.statusText !== "OK") {
        const error = response.statusText;
        return Promise.reject(error);
      }
      return response.data;
    });
}

import React, { useEffect, useState } from "react";
import VideoComponent from "../../../components/self-train/video-component/VideoComponent";
import { ArrowSmallUpIcon } from "@heroicons/react/24/solid";
import { selfTrainService } from "../../../services/self-train.service";
import { CONSTANT } from "../../../core/StaticConstant";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";


const SelfTrains = () => {
    const [masterData, setMasterData] = useState<any[]>([]);
    const [courseData, setCourseData] = useState<any[]>([]);
    const [page, setPage] = useState<number>(1);
    const [isLoading, setIsLoading] = useState(false);
    const [isCourse,setIsCourse] = useState(true);

    const menuList: any = [
        { _id: "1", name: "Feature Classes", path: "" },
        { _id: "2", name: "All Classes", path: "view-all-classes" },
        { _id: "3", name: "Experts", path: "experts" },
    ];

  const navigate = useNavigate();
  const [sortingOrder, setSortingOrder] = useState<any>();

  const onAllClassesClick = (id: any) => {
    navigate("../self-train/view-all-classes");
  };
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };

    useEffect(() => {
        let obj = {
            fields:["masterImage","masterTitle","masterSubtitle","masterAllCoursePrice","masterAllCoursePriceText"]
        }
        Masters(obj);
    }, []);

    const Masters = async (obj:any) => {
        try {
            const response = await selfTrainService.getMasters(obj);
            if (response.status === CONSTANT.SUCCESS) {
                setMasterData(response.data.masters);
            }
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        if(isCourse){
            fetchData();
        }
      }, [ page, sortingOrder]);
    
      useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
      }, []);
    
      const handleScroll = () => {
        if (
          window.innerHeight + document.documentElement.scrollTop >=
          document.documentElement.offsetHeight
        ) {
          setPage((prevPage) => prevPage + 1);
        }
      };
      const fetchData = async () => {
        try {
          let data = {
            pageCount: page,
            limit: 6,
            sortingOrder: sortingOrder,
            fields : ["courseCardTitle", "courseExpertId", "courseHoverVideo", "coursePrice", "courseThumbImage"]
    
        }
          const response = await selfTrainService.getCourses(data);
          setIsLoading(false);
          if (response.status === CONSTANT.SUCCESS) {
            const newCourseData = response.data.courses;
            setCourseData((prevItems: any[]) => [...prevItems, ...newCourseData]);
            setIsCourse(true);
          }else{
            setIsCourse(false);
          }
        } catch (err: any) {
            console.error("error ", err.response )
            setIsCourse(false);
            if(err?.response?.data?.error !== CONSTANT.COURSE_NOT_FOUND) {
                toast.error(err?.response?.data?.error);
            }
            setIsLoading(false);
            setIsCourse(false);
        }
      };

    return (
        <div className="bg-darkBlack">
            <div>
                {masterData &&
                    masterData.map((ele: any, index: number) => {
                        return (
                            <div key={index}>
                                <div
                                    className={
                                        "relative w-full 2xl:h-[42rem] xl:h-[36rem] lg:h-[32rem] md:h-[24rem] sm:h-[20rem] xs:h-[19rem] s:h-[19rem] grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-2 md:gap-4 lg:gap-6 2xl:px-40 md:px-11 px-4 py-5 s:py-3 bg-no-repeat xs:bg-right s:bg-right bg-cover "
                                    }
                                    style={{
                                        backgroundImage: `url(${ele.masterImage
                                            ? ele.masterImage
                                            : "https://drive.google.com/uc?export=view&id=1N9-QUmW9-22RjnjfG9oQ--te3wkgxuyY"
                                            })`,
                                    }}
                                ></div>
                                <div className="bg-darkBlack">
                                    <div>
                                        <h2 className="text-center text-[45px] text-miniwhite mt-[25px] font-bold pb-8">
                                            {ele.masterTitle ? ele.masterTitle : "N/A"}
                                        </h2>
                                        <p className="text-center sm:max-w-[800px] m-auto text-miniwhite pb-4">
                                            {" "}
                                            {ele.masterSubtitle ? ele.masterSubtitle : "N/A"}{" "}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
            </div>
            <br />
            <div className="sm:px-20">
                {courseData && <VideoComponent data={courseData} />}
            </div>
            <br />
            <div className="text-center">
                <button
                    className="font-bold text-center pt-6 pb-16 text-miniwhite"
                    onClick={() => onAllClassesClick("2")}
                >
                    View All classes
                </button>
            </div>
            <div className="flex justify-end 2xl:w-[100%] 2xl:-ml-[80px] bg-darkBlack xs:hidden">
                <h2
                    className="text-greenFooter mb-[10px]  text-[18px] cursor-pointer font-bold"
                    onClick={() => scrollToTop()}
                >
                    Back to Top
                </h2>
                <a>
                    <ArrowSmallUpIcon
                        className="h-[25px] mt-[3px] ml-[4px] mr-[4rem] mb-[13px]  cursor-pointer stroke-[2px] text-white"
                        onClick={() => scrollToTop()}
                    />
                </a>
            </div>
        </div>
    );
};

export default SelfTrains;

import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { setCartProducts } from '../../redux/reducer/cartReducer';
import { CartService } from '../../services';
import { CONSTANT } from '../../core/StaticConstant';
import { toast } from 'react-toastify';
import { getUserData, isUndefineOrNull } from '../../core/Utils';

const Home = () => {
    const cartProductsStore = useSelector((state: any) => state.cartProducts);
    const dispatch = useDispatch()
    const getCartData = async () => {
        await CartService.getCartByUserId().then((data: any) => {
            if (data && data.statusCode === 403) {
                toast.error(data.message);
                dispatch(setCartProducts([]));
                localStorage.removeItem(CONSTANT.USER_INFO);
                localStorage.removeItem("selectedMenuName");
                setTimeout(() => {
                    window.location.href = "/shop";
                }, 1000)
            } else {
                if (data && data.status == CONSTANT.SUCCESS) {
                    const lastVisitedPage = localStorage.getItem("lastVisitedPage");
                    if (lastVisitedPage?.includes("/shop/cart")) {
                        const user: any = getUserData();

                        dispatch(setCartProducts(cartProductsStore));
                    } else {
                        dispatch(setCartProducts(data?.data?.cart?.cart));
                    }
                    ;

                }
            }
        }).catch((error) => {
            console.error('err', error.response);
        });
    };

    useEffect(() => {
        const isAuthenticated: any = getUserData() || "";
        if (isAuthenticated) {
            getCartData();
        }
    },[]);
    return (
        <div>home</div>
    )
}

export default Home;
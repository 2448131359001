import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import { useFormik } from "formik";
import DatePicker from "react-datepicker";
import { ArrowUpTrayIcon, DocumentIcon, EllipsisVerticalIcon } from "@heroicons/react/24/outline";
import { medicalSchema } from "../../schema/Schema";
import { MedicalService } from "../../services/medical.service";
import { CONSTANT } from "../../core/StaticConstant";
import { UserService } from "../../services";
import Loader from "../loader/Loader";
import { toast } from "react-toastify";
import { useUser } from "../Contexts/UserContext";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Modal, Popover, Stack, Tooltip, Typography } from "@mui/material";
import { Localstorage } from "../../core/AuthService";
const FILE_DOWNLOAD_URL: any = process.env.REACT_APP_FILE_DOWNLOAD_URL;

const initialValues = {
    bloodGrp: "",
    dob: "",
    avgWakeUpTime: "06:00 AM",
    weight: "NA",
    height: "NA",
    medicalConditions: "NA",
    disability: "NA",
    clinicalDocuments: []
};

const MedicalDetails = (props: any) => {
    const { updateUserAge } :any = useUser();
    const { isMedicalProfileClicked, setIsMedicalProfileClicked } = props;
    const inputRef = useRef<any>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [showFullDescription, setFullDescription] = useState(false);

    const [edit, setEdit] = useState(false);
    const [resMedicalDetails, setResMedicalDetails] = useState<any>();
    const [open, setOpen] = useState(false);
    const [selectedDocuments, setSelectedDocuments] = useState<any>({});

    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    const handleClickPopover = (event: React.MouseEvent<HTMLButtonElement>, item: any) => {
        setAnchorEl(event.currentTarget);
        setSelectedDocuments(item);
    };

    const showFullDescriptionHandler = () => {
        setFullDescription(!showFullDescription);
    };

    const handleClosePopover = () => {
        setAnchorEl(null);
        if(showFullDescription) {
            showFullDescriptionHandler()
        }
    };

    const openBoolean = Boolean(anchorEl);
    const id = openBoolean ? 'simple-popover' : undefined;

    const handleClickOpen = () => {
      handleClosePopover();
      setOpen(true);
    };
  
    const handleClose = () => {
      setSelectedDocuments({});
      setOpen(false);
    };

    const handleClick = () => {
        inputRef.current.click();
    };

    const {
        values,
        errors,
        touched,
        handleChange,
        setFieldValue,
        handleSubmit,
        setValues
    } = useFormik({
        initialValues,
        validationSchema: medicalSchema,
        onSubmit: async (values, action) => {
            try {
                const id = resMedicalDetails?._id
                var avgWakeUpTime = moment(values?.avgWakeUpTime).format('hh:mm A');
                const data = {
                    bloodGroup: values?.bloodGrp,
                    dob: values?.dob,
                    avgWakeUpTime: avgWakeUpTime,
                    weight: {
                        value: values?.weight
                    },
                    height: {
                        value: values?.height
                    },
                    medicalConditions: {
                        value: values?.medicalConditions
                    },
                    disability: {
                        value: values?.disability
                    },
                    clinicalDocuments: resMedicalDetails?.clinicalDocuments || []
                };
                setIsLoading(true)
                const response = id ? await MedicalService.updateMedicalDetails(id, data) : await MedicalService.createMedicalDetails(data);
                setIsLoading(false)
                if (response?.status === CONSTANT.SUCCESS) {
                    toast.success(response.message);
                    let userAge:string = response.data.medicalDetail.dob;
                    updateUserAge(userAge);
                    const birthdateMoment = moment.utc(userAge, 'YYYY-MM-DDTHH:mm:ss.SSSZ', true);
                    const age: any = moment().utc().diff(birthdateMoment.startOf('day'), 'years');
                    Localstorage(CONSTANT.SET,CONSTANT.USER_AGE,age)
                    setEdit(false);
                } else {
                    toast.success(response.message);
                }
            } catch(err: any) {
                toast.error((err?.response?.data?.error));
                setIsLoading(false);
            }
        }
    });
    
    const uploadClinicalDocuments = async (event: any) => {
        const checkAllFiles = [...event.target.files].filter((file: any) => file.type !== "application/pdf")
        const duplicateFiles = [...event.target.files].filter((file) => resMedicalDetails?.clinicalDocuments?.some((existingFile: any) => existingFile?.originalName === file.name));
        if (checkAllFiles && checkAllFiles.length > 0) {
            toast.error(CONSTANT.ONLY_PDF)
        } else if (duplicateFiles.length > 0) {
            toast.error(CONSTANT.FILE_ALREADY_EXIST);
        } else {
            var formData: any = new FormData();
            for (let i = 0; i < event.target.files.length; i++) {
                formData.append("clinicalDocumentsFiles", event.target.files[i]);
            }
            formData.append('uploadType', 'MANUAL');
            const id = resMedicalDetails?._id;
            try {
                setIsLoading(true)
                const response = id ? await MedicalService.uploadClinicalDocuments(id, formData) : await MedicalService.createMedicalDetailsWithUploadClinicalDocuments(formData);
                setIsLoading(false)
                if (response?.status === CONSTANT.SUCCESS) {
                    setResMedicalDetails(response.data.medicalDetail)
                    toast.success(response.message);
                    setEdit(false);
                } else {
                    toast.error(response.error);
                }
            } catch (error:any) {
                toast.error(error?.response?.data?.message);
                setIsLoading(false)
            }
        }
    }

    const handleClickDownload = async () => {
        const URL: any = FILE_DOWNLOAD_URL + selectedDocuments?.fileName;
        window.open(URL, '_blank', 'noreferrer');
        handleClosePopover();
        setSelectedDocuments({});
    }

    const convertTimeToDate = (timeToAdd: any) => {
        const today = moment();
        const combinedDateTime = moment(today.format('YYYY-MM-DD') + ' ' + timeToAdd || '6:00 am', 'YYYY-MM-DD HH:mm');
        return combinedDateTime.format('YYYY-MM-DD HH:mm:ss')
    }

    const deleteClinicalDocuments = async () => {
        const id = resMedicalDetails?._id;
        let clinicalDocumentsArr: any[] = [];
        let deletedclinicalDocumentsArr: any[] = [];
        resMedicalDetails.clinicalDocuments.forEach((item: any) => {
            if(selectedDocuments?.fileName !== item?.fileName) {
                clinicalDocumentsArr.push(item);
            } else {
                deletedclinicalDocumentsArr.push(item);
            }
        })
        try {
            setIsLoading(true)
            const response = await MedicalService.removeClinicalDocumentById(id, {clinicalDocuments: clinicalDocumentsArr, deletedclinicalDocumentsArr: deletedclinicalDocumentsArr});
            setIsLoading(false)
            if (response?.status === CONSTANT.SUCCESS) {
                toast.success(CONSTANT.DOCUMENTS_DELETE_SUCCESSFULLY);
                setEdit(false);
                setResMedicalDetails({...resMedicalDetails, clinicalDocuments: clinicalDocumentsArr})
                handleClose();
            } else {
                toast.success(response.message || CONSTANT.ERROR_OCCURRED);
            } 
        } catch (error:any) {
            setIsLoading(false)
            toast.error(error?.response?.data?.error || CONSTANT.ERROR_OCCURRED);
        }
    }

    const medicalDetailsById = async () => {
        try {
            let response = await MedicalService.getMedicalDetailsById();
            if (response?.status === CONSTANT.SUCCESS) {
                setResMedicalDetails(response?.data.medicalDetails[0]);
                const medicalValue: any = {
                    bloodGrp: response?.data.medicalDetails[0]?.bloodGroup,
                    dob:(response?.data.medicalDetails[0]?.dob || new Date()),
                    avgWakeUpTime:convertTimeToDate(response?.data.medicalDetails[0]?.avgWakeUpTime),
                    weight: response?.data.medicalDetails[0]?.weight?.value || "NA",
                    height: response?.data.medicalDetails[0]?.height?.value || "NA",
                    medicalConditions:
                        response?.data.medicalDetails[0]?.medicalConditions?.value || "NA",
                    disability: response?.data.medicalDetails[0]?.disability?.value || "NA",
                    clinicalDocuments: response?.data.medicalDetails[0]?.clinicalDocuments?.value || []
                };
                setValues(medicalValue);
            } else {
            }
        } catch (error) {
            try {
                let response2 = await UserService.getUserBasicDetailsById();
                if (response2?.status == CONSTANT.SUCCESS) {
                    const medicalValue: any = {
                        bloodGrp: "",
                        dob: (response2?.data.user?.birthDate || new Date()),
                        avgWakeUpTime: convertTimeToDate(response2?.data.user?.avgWakeUpTime) || new Date(),
                        weight: "NA",
                        height: "NA",
                        medicalConditions: "NA",
                        disability: "NA",
                        clinicalDocuments: [],
                    }
                    setValues(medicalValue);
                } else {
                    const medicalValue: any = {
                        bloodGrp: "",
                        dob: new Date(),
                        avgWakeUpTime: new Date(),
                        weight: "NA",
                        height: "NA",
                        medicalConditions: "NA",
                        disability: "NA",
                        clinicalDocuments: [],
                    }
                    setValues(medicalValue);
                }
            } catch (error) {
                const medicalValue: any = {
                    bloodGrp: "",
                    dob: new Date(),
                    avgWakeUpTime: new Date(),
                    weight: "NA",
                    height: "NA",
                    medicalConditions: "NA",
                    disability: "NA",
                    clinicalDocuments: [],
                }
                setValues(medicalValue);
            }
        }
    };

    useEffect(() => {
        if(isMedicalProfileClicked){
            medicalDetailsById();
        }
    }, [isMedicalProfileClicked]);

    const handleBack = () => {
        setIsMedicalProfileClicked(false);
    };

    return (
        <>
         {isLoading && (
                <div className="absolute top-[50%] z-50 left-[50%] transform translate-x-[-50%] translate-y-[-50%]">
                    <Loader />
                </div>)}
        <div className="lg:w-6/12 w-full bg-graygreen 2xl:p-8 p-4 x:p-3 s:p-3">
            {isMedicalProfileClicked ?
                <div>
                    <div className="flex justify-between gap-4 mb-5">
                        <h4 className="text-white uppercase x:text-[14px] s:text-[14px] font-medium	tracking-wide">
                            Medical Details
                        </h4>
                        <div className="flex items-start gap-4">
                            <button type="button">
                                <ArrowUpTrayIcon className="w-6 h-6 text-white hover:text-white" />
                            </button>
                            <div>
                                {edit ? (
                                    <>
                                        <button
                                            type="button"
                                            onClick={() => handleSubmit()}
                                            className="bg-capeCod text-white ml-2 mr-2 sm:px-6 px-4 py-2 text-[12px] min-w-[120px] x:min-w-[100px] s:min-w-[100px] hover:text-white"
                                        >
                                            Save
                                        </button>
                                        <button
                                            type="button"
                                            onClick={() => setEdit(false)}
                                            className="bg-capeCod text-white ml-2 mr-2 sm:px-6 px-4 py-2 text-[12px] min-w-[120px] x:min-w-[100px] s:min-w-[100px] hover:text-white"
                                        >
                                            Back
                                        </button>
                                    </>
                                ) : (
                                    <>
                                        <button
                                            type="button"
                                            onClick={() => setEdit(true)}
                                            className="bg-capeCod text-white sm:px-6 px-4 py-2 mr-2 text-[12px] min-w-[120px] x:min-w-[100px] s:min-w-[100px] hover:text-white"
                                        >
                                            Edit Details
                                        </button>
                                        <button
                                            type="button"
                                            onClick={handleBack}
                                            className="bg-capeCod text-white sm:px-6 px-4 py-2 text-[12px] min-w-[120px] x:min-w-[100px] s:min-w-[100px] hover:text-white"
                                        >
                                            Back
                                        </button>
                                    </>
                                )}
                                <div className="text-center">
                                    <button className="text-greenFooter text-[12px] uppercase hover:text-white">
                                        Sync With App
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <ul>
                            <li className="flex sm:py-4 py-2 items-center">
                                <div className="w-[35%]">
                                    <h6 className="sm:text-[14px] text-[12px] text-mantle">
                                        Blood Group
                                    </h6>
                                </div>
                                <div className="w-[65%]">
                                    <select
                                        disabled={!edit}
                                        value={values?.bloodGrp}
                                        name="bloodGrp"
                                        onChange={handleChange}
                                        className={`${edit
                                            ? "text-gray85 bg-capeCod inner-shadow"
                                            : "text-mantle bg-transparent"
                                            } focus focus:outline-none w-full h-10 xs:h-9 s:h-9 sm:text-[14px] text-[12px] px-4`}
                                    >
                                        <option value="" disabled>Select Blood Group</option>
                                        <option value="A+">A Positive</option>
                                        <option value="A-">A Negative</option>
                                        <option value="B+">B Positive</option>
                                        <option value="B-">B Negative</option>
                                        <option value="O+">O Positive</option>
                                        <option value="O-">O Negative</option>
                                        <option value="AB+">AB Positive</option>
                                        <option value="AB-">AB Negative</option>
                                    </select>
                                    {errors?.bloodGrp && touched?.bloodGrp && (
                                        <div className="text-[#e53e34]">{errors?.bloodGrp}</div>
                                    )}
                                </div>
                            </li>
                            <li className="flex sm:py-4 py-2 items-center">
                                <div className="w-[35%]">
                                    <h6 className="sm:text-[14px] text-[12px] text-mantle">
                                        Date of Birth
                                    </h6>
                                </div>
                                <div className="w-[65%]">
                                    {/* <input
                                        type="date"
                                        disabled={!edit}
                                        value={values?.dob}
                                        name="dob"
                                        onChange={handleChange}
                                        className={`${edit
                                            ? "text-gray85 bg-capeCod inner-shadow"
                                            : "text-mantle bg-transparent"
                                            } focus focus:outline-none w-full h-10 xs:h-9 s:h-9 sm:text-[14px] text-[12px] px-4`}
                                    /> */}
                                    <DatePicker
                                    disabled={!edit}
                                    maxDate={moment().toDate()}
                                    showYearDropdown
                                    yearDropdownItemNumber={30} 
                                    scrollableYearDropdown
                                    yearItemNumber={30}
                                    selected={ moment(values?.dob).toDate() }
                                    value={moment(values?.dob).format("DD/MM/YYYY")}
                                    onChange={(value: any) => setFieldValue("dob", value)}
                                    className={`${edit
                                        ? "text-gray85 bg-capeCod inner-shadow"
                                        : "text-mantle bg-transparent"
                                        } focus focus:outline-none w-full h-10 xs:h-9 s:h-9 sm:text-[14px] text-[12px] px-4`}
                                    />
                                    {errors?.dob && touched?.dob && (
                                        <div className="text-[#e53e34]">{errors?.dob}</div>
                                    )}
                                </div>
                            </li>
                            <li className="flex sm:py-4 py-2 items-center">
                                <div className="w-[35%]">
                                    <h6 className="sm:text-[14px] text-[12px] text-mantle">
                                        Average wake up time
                                    </h6>
                                </div>
                                <div className="w-[65%]">
                                    <DatePicker
                                        disabled={!edit}
                                        maxDate={moment().toDate()}
                                        showTimeSelect
                                        showTimeSelectOnly
                                        timeIntervals={15}
                                        timeCaption="Time"
                                        dateFormat="h:mm aa"
                                        selected={ moment(values.avgWakeUpTime).toDate() }
                                        value={moment(values?.avgWakeUpTime).format("h:mm a")}
                                        onChange={(value: any) => setFieldValue("avgWakeUpTime", value)}
                                        className={`${edit
                                            ? "text-gray85 bg-capeCod inner-shadow"
                                            : "text-mantle bg-transparent"
                                            } focus focus:outline-none w-full h-10 xs:h-9 s:h-9 sm:text-[14px] text-[12px] px-4`}
                                    />
                                    {errors?.avgWakeUpTime && touched?.avgWakeUpTime && (
                                        <div className="text-[#e53e34]">{errors?.avgWakeUpTime}</div>
                                    )}
                                </div>
                            </li>
                            <li className="flex sm:py-4 py-2 items-center">
                                <div className="w-[35%]">
                                    <h6 className="sm:text-[14px] text-[12px] text-mantle">Weight</h6>
                                </div>
                                <div className="w-[65%]">
                                    <div className="relative">
                                        {/* <p className="sm:text-[14px] text-[12px] text-mantle">78 Kg</p> */}
                                        <div>
                                            <input
                                                type="text"
                                                disabled={!edit}
                                                value={values?.weight}
                                                name="weight"
                                                onChange={handleChange}
                                                className={`${edit
                                                    ? "text-gray85 bg-capeCod inner-shadow"
                                                    : "text-mantle bg-transparent"
                                                    } focus focus:outline-none w-full h-10 xs:h-9 s:h-9 sm:text-[14px] text-[12px] px-4`}
                                            />
                                            {errors?.weight && touched?.weight && (
                                                <div className="text-[#e53e34]">{errors?.weight}</div>
                                            )}
                                        </div>
                                        {!edit && (
                                            <div className="absolute top-[3px] right-0">
                                                <p className="text-[10px] text-mantle">Last Update</p>
                                                <p className="text-[10px] font-bold text-mantle">
                                                    {moment(
                                                        resMedicalDetails?.["weight"]?.["updatedAt"]
                                                    ).format("MMM YYYY")}
                                                </p>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </li>
                            <li className="flex sm:py-4 py-2 items-center">
                                <div className="w-[35%]">
                                    <h6 className="sm:text-[14px] text-[12px] text-mantle">Height</h6>
                                </div>
                                <div className="w-[65%]">
                                    <div className="relative">
                                        <div>
                                            <input
                                                type="text"
                                                disabled={!edit}
                                                value={values?.height}
                                                name="height"
                                                onChange={handleChange}
                                                className={`${edit
                                                    ? "text-gray85 bg-capeCod inner-shadow"
                                                    : "text-mantle bg-transparent"
                                                    } focus focus:outline-none w-full h-10 xs:h-9 s:h-9 sm:text-[14px] text-[12px] px-4`}
                                            />
                                            {errors?.height && touched?.height && (
                                                <div className="text-[#e53e34]">{errors?.height}</div>
                                            )}
                                        </div>
                                        {!edit && (
                                            <div className="absolute top-[3px] right-0">
                                                <p className="text-[10px] text-mantle">Last Update</p>
                                                <p className="text-[10px] font-bold text-mantle">
                                                    {moment(
                                                        resMedicalDetails?.["height"]?.["updatedAt"]
                                                    ).format("MMM YYYY")}
                                                </p>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </li>
                            <li className="flex sm:py-4 py-2 items-center">
                                <div className="w-[35%]">
                                    <h6 className="sm:text-[14px] text-[12px] text-mantle">
                                        Medical Conditions
                                    </h6>
                                </div>
                                <div className="w-[65%]">
                                    <div className="relative">
                                        <div>
                                            <input
                                                type="text"
                                                disabled={!edit}
                                                value={values?.medicalConditions}
                                                name="medicalConditions"
                                                onChange={handleChange}
                                                className={`${edit
                                                    ? "text-gray85 bg-capeCod inner-shadow"
                                                    : "text-mantle bg-transparent"
                                                    } focus focus:outline-none w-full h-10 xs:h-9 s:h-9 sm:text-[14px] text-[12px] px-4`}
                                            />
                                            {errors?.medicalConditions && touched?.medicalConditions && (
                                                <div className="text-[#e53e34]">
                                                    {errors?.medicalConditions}
                                                </div>
                                            )}
                                        </div>
                                        {!edit && (
                                            <div className="absolute top-[3px] right-0">
                                                <p className="text-[10px] text-mantle">Last Update</p>
                                                <p className="text-[10px] font-bold text-mantle">
                                                    {moment(
                                                        resMedicalDetails?.["medicalConditions"]?.[
                                                        "updatedAt"
                                                        ]
                                                    ).format("MMM YYYY")}
                                                </p>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </li>
                            <li className="flex sm:py-4 py-2 items-center">
                                <div className="w-[35%]">
                                    <h6 className="sm:text-[14px] text-[12px] text-mantle">
                                        Disability
                                    </h6>
                                </div>
                                <div className="w-[65%]">
                                    <div className="relative">
                                        <div>
                                            <input
                                                type="text"
                                                disabled={!edit}
                                                value={values?.disability}
                                                name="disability"
                                                onChange={handleChange}
                                                className={`${edit
                                                    ? "text-gray85 bg-capeCod inner-shadow"
                                                    : "text-mantle bg-transparent"
                                                    } focus focus:outline-none w-full h-10 xs:h-9 s:h-9 sm:text-[14px] text-[12px] px-4`}
                                            />
                                            {errors?.disability && touched?.disability && (
                                                <div className="text-[#e53e34]">{errors?.disability}</div>
                                            )}
                                        </div>
                                        {!edit && (
                                            <div className="absolute top-[3px] right-0">
                                                <p className="text-[10px] text-mantle">Last Update</p>
                                                <p className="text-[10px] font-bold text-mantle">
                                                    {moment(
                                                        resMedicalDetails?.["disability"]?.["updatedAt"]
                                                    ).format("MMM YYYY")}
                                                </p>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </li>
                            <li className="flex sm:py-4 py-2 items-center">
                                <div className="w-[35%]">
                                    <h6 className="sm:text-[14px] text-[12px] text-mantle">
                                        Clinical documents
                                    </h6>
                                </div>
                                <div className="w-[65%] pl-4">
                                    {edit ? (
                                        <div className="2xl:flex 2xl:justify-between 2xl:items-center">
                                            <ul className="flex flex-wrap gap-2 mb-2">
                                                {resMedicalDetails && resMedicalDetails.clinicalDocuments && resMedicalDetails.clinicalDocuments.length > 0 ?
                                                    resMedicalDetails.clinicalDocuments.map((item: any, index: any) => 
                                                        <li key={index} className="flex items-center group gap-2">
                                                            
                                                            <IconButton aria-label="delete" className="group-hover:!hidden"><DocumentIcon className="w-5 h-5 text-white" /></IconButton>
                                                            <IconButton aria-describedby={id} onClick={(e) => handleClickPopover(e, item)} className="group-hover:!block !hidden">
                                                                <EllipsisVerticalIcon className="w-5 h-5 text-white cursor-pointer" />
                                                            </IconButton>
                                                            <span className="text-white text-[12px]">
                                                                {item?.originalName?.length > 10 ? item?.originalName?.slice(0, 10) + '...' : item?.originalName}
                                                            </span>
                                                        </li>) : 
                                                        <li>
                                                            <span className="text-white text-[12px]">No Documents available</span>
                                                        </li>}
                                            </ul>
                                            <div>
                                                {/* <button className="text-white text-[10px]">
                                                    REMOVE
                                                </button> */}
                                                <input
                                                    style={{display: 'none'}}
                                                    ref={inputRef}
                                                    type="file"
                                                    name="clinicalDocuments"
                                                    multiple
                                                    onChange={(event: any) => uploadClinicalDocuments(event)}
                                                />
                                                <button
                                                    type="button"
                                                    onClick={() => handleClick()}
                                                    className="bg-capeCod text-white 2xl:px-6 px-5 py-2 text-[12px] ml-4"
                                                >
                                                    Upload
                                                </button>
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="flex justify-between items-center min-h-[34px]">
                                            <p className="sm:text-[14px] text-[12px] text-mantle">
                                                {resMedicalDetails && resMedicalDetails.clinicalDocuments ? resMedicalDetails.clinicalDocuments.length : 0} available
                                            </p>
                                            {/* <button className="text-greenFooter text-[10px] hover:text-white">
                                                DOWNLOAD
                                            </button> */}
                                        </div>
                                    )}
                                </div>
                            </li>
                        </ul>
                    </div>
                </div> : <div className="mb-4 mt-4">
                    <div className="mb-6">
                        <h3 className="text-white text-[18px] x:text-[14px] s:text-[14px] font-medium	tracking-wide">
                            Lifestyle & Habits
                        </h3>
                        <div className="flex justify-between ml-16">
                            <h3 className="text-white text-[18px] x:text-[14px] s:text-[14px] font-medium tracking-wide leading-9">
                                Sleep Patterns
                            </h3>
                            <h3 className="text-white  x:text-[14px] s:text-[14px] font-normal leading-9 tracking-wide flex items-center">
                                <p>COMPLETED</p>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="3" stroke="currentColor" className="w-6 h-4 mb-1 ml-1">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125" />
                                </svg>
                            </h3>
                        </div>
                        <div className="flex justify-between ml-16">
                            <h3 className="text-white text-[18px] x:text-[14px] s:text-[14px] font-medium tracking-wide leading-9">
                                Exercise History
                            </h3>
                            <h3 className="text-white  x:text-[14px] s:text-[14px] font-normal leading-9 tracking-wide flex items-center">
                                COMPLETED
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="3" stroke="currentColor" className="w-6 h-4 mb-1 ml-1">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125" />
                                </svg>
                            </h3>
                        </div>
                        <div className="flex justify-between ml-16">
                            <h3 className="text-white text-[18px] x:text-[14px] s:text-[14px] font-medium tracking-wide leading-9">
                                Lifestyle Choices
                            </h3>
                        </div>
                    </div>
                    <div className="mb-6">
                        <h3 className="text-white text-[18px] x:text-[14px] s:text-[14px] font-medium	tracking-wide">
                            Wellness & Mental Health
                        </h3>
                        <div className="flex justify-between ml-16">
                            <h3 className="text-white text-[18px] x:text-[14px] s:text-[14px] font-medium tracking-wide leading-9">
                                Mental Health
                            </h3>
                        </div>
                        <div className="flex justify-between ml-16">
                            <h3 className="text-white text-[18px] x:text-[14px] s:text-[14px] font-medium tracking-wide leading-9">
                                Physiological Health
                            </h3>
                        </div>
                    </div>
                    <div className="mb-6">
                        <h3 className="text-white text-[18px] x:text-[14px] s:text-[14px] font-medium	tracking-wide">
                            Nutrition & Supplements
                        </h3>
                        <div className="flex justify-between ml-16">
                            <h3 className="text-white text-[18px] x:text-[14px] s:text-[14px] font-medium tracking-wide leading-9">
                                Dietary Preferences
                            </h3>
                        </div>
                        <div className="flex justify-between ml-16">
                            <h3 className="text-white text-[18px] x:text-[14px] s:text-[14px] font-medium tracking-wide leading-9">
                                Supplements & Vitamins
                            </h3>
                        </div>
                    </div>
                    <div className="mb-6">
                        <h3 className="text-white text-[18px] x:text-[14px] s:text-[14px] font-medium	tracking-wide">
                            Health Records
                        </h3>
                        <div className="flex justify-between ml-16">
                            <h3 className="text-white text-[18px] x:text-[14px] s:text-[14px] font-medium tracking-wide leading-9">
                                Diagnostic Reports
                            </h3>
                        </div>
                        <div className="flex justify-between ml-16">
                            <h3 className="text-white text-[18px] x:text-[14px] s:text-[14px] font-medium tracking-wide leading-9">
                                Clinical Records & Other Documents
                            </h3>
                        </div>
                    </div>
                    <div className="mb-6">
                        <h3 className="text-white text-[18px] x:text-[14px] s:text-[14px] font-medium	tracking-wide">
                            Delete All Data
                        </h3>
                    </div>
                </div>}



                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        Are you sure?
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Do you really want to delete these clinical documents? This process cannot be undone.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Disagree</Button>
                        <Button onClick={() => deleteClinicalDocuments()} autoFocus>
                            Agree
                        </Button>
                    </DialogActions>
                </Dialog>
                <Modal
                    open={openBoolean}
                    onClose={handleClosePopover}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box 
                        className="absolute	top-2/4 left-2/4 -translate-y-1/2 -translate-x-2/4 w-[calc(100%-24px)] max-w-[500px] bg-white max-h-[calc(100vh-24px)] overflow-y-auto border-2 border-black border-solid p-4"
                        sx={{ boxShadow: 24 }}
                    >
                        <Box>
                            <Typography variant="h5" className="pb-4">
                                <b>{selectedDocuments.originalName}</b>
                            </Typography>
                            <Typography  variant="subtitle2" className="pb-1">
                                <b>PDF Content :</b>
                            </Typography>
                            <Typography className="pb-4">
                                {showFullDescription ? selectedDocuments?.fileContent : selectedDocuments?.fileContent?.slice(0, 200)}
                                {selectedDocuments?.fileContent?.length > 200 &&
                                <button className="text-[#4469BF]" onClick={showFullDescriptionHandler}>
                                    &nbsp; Read {showFullDescription ? "Less" : "More"}
                                </button>}
                            </Typography>
                            <Stack direction="row" spacing={2}>
                                <button 
                                    onClick={() => handleClickDownload()} 
                                    className="hover:bg-marigold bg-olive text-white 3xl:text-[18px] text-[14px] text-bold h-[40px] opacity-100 2xl:px-[30px] md:px-5 px-4"
                                >
                                    Download
                                </button>
                                <button 
                                    onClick={() => handleClickOpen()} 
                                    className="hover:bg-contessa whitespace-nowrap bg-coralTree text-white 3xl:text-[18px] xs:text-[12px] s:text-[12px] text-[14px] text-bold h-[40px] opacity-100 2xl:px-[30px] md:px-5 xs:px-2 s:px-2 px-4 proceed-to-checkout sm:mt-0"
                                >
                                    Delete
                                </button>
                                <button 
                                    onClick={() => handleClosePopover()} 
                                    className="inline-flex items-center pt-[10px] hover:bg-[#616d78] bg-midnightDream text-white text-sm md:px-4 lg:px-5 px-6 x:px-3 x:text-[10px] x:py-1 py-1.5 pb-[8px] c-px-16 3xl:text-[18px] text-[14px] text-bold"
                                >
                                    Cancel
                                </button>
                            </Stack>
                        </Box>
                    </Box>
                </Modal>
            </div>
        </>

    );
};

export default MedicalDetails;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setOrder } from "../../../redux/reducer/cartReducer";
import { CONSTANT } from "../../../core/StaticConstant";
import { OrderService } from "../../../services/order.services";
import { getUserData } from "../../../core/Utils";

const OrderPending = () => {
  const dispatch = useDispatch();

  const orderId = useSelector((state: any) => state.orderId);
  
  const [userEmail, setUserEmail] = useState<String>("");
  const [orderNumber, setOrderNumber] = useState<string>();
  const getOrderById = async () => {
    try {
      const response = await OrderService.getOrderNumberById(orderId);
      if (response.status === CONSTANT.SUCCESS) {
        setOrderNumber(response?.data?.order?.orderNumber);        
      }
    } catch (error) {
      console.error(error);
    }
  };

  const onClickPayment = (id: any) => {
    dispatch(setOrder(id));
  };

  useEffect(() => {
    const userData: any = getUserData();
    setUserEmail(userData?.email);
    getOrderById();
  });

  return (
    <div className="pl-16 mt-8">
      <h2 className="text-darkyellowbrown font-bold md:text-[25px] xl:text-[35px]">
        Payment pending!
      </h2>
      <p className="text-midnightDream font-bold text-[25px] md:text-[50px] xl:text-[70px]">
        Awaiting payment confirmation from bank
      </p>
      <p className="text-lightblack text-[16px] font-bold mt-16">
        Order ID: {orderNumber}
      </p>
      <p className="text-miniblack font-[400] text-[16px]">
        You will receive order confirmation email shortly on{" "}
        <span className="text-midnightDream font-bold">{userEmail}</span>
      </p>
      <div className="mt-10">
        <button
          className="text-[15px] px-10 py-3 font-bold bg-midnightDream text-white tracking-wide"
          onClick={() => onClickPayment("paymentMethod")}
        >
          Change Payment Mode
        </button>
      </div>
    </div>
  );
};

export default OrderPending;

import React from "react";

const ProductInformation = (props: any) => {
  const { productData, selectedSkuItemData } = props;
  return (
    <div className="sm:py-[50px] sm:p-8 mt-8" id="productInformation">
      {/* <div className='col-span-2 flex justify-between'>
                <div className='text-center border-[1px] border-black'>
                    <div className='my-20 rotate'>
                        <p>100% Recyclable</p>
                    </div>
                    <div className='my-24 rotate'>
                        <p>100% Natural Product</p>
                    </div>
                    <div className='my-16 rotate'>
                        <p>Zero added Sugars</p>
                    </div>
                    <div className='mt-20 rotate border-top'>
                        <h2 className='text-[30px] font-bold'>Contain Caffeine</h2>
                    </div>
                </div>
                <div className='ml-4'>
                    <div className='table-border'>
                        <h2 className='text-[30px] font-bold text-oxfordBlue'>NUTRITIONAL INFROMATION</h2>
                        <p className='text-[25px] text-oxfordBlue'>(APPROXIMATE VALUES)</p>
                    </div>
                    <div className='table-border top-border'>
                        <p className='text-[16px] text-oxfordBlue'>Serving Size: {selectedSkuItemData.productServingSize}</p>
                        <p className='text-[16px] text-oxfordBlue'>Serving per Container - {selectedSkuItemData.productServingJar}</p>
                    </div>
                    <div className='table-border top-border'>
                        <div className='flex justify-between px-4'>
                            <div>
                                <h2 className='text-[30px] font-bold'>{productData.productContentName1}</h2>
                                <p className='text-[16px]'>{productData.productContentText1}</p>
                            </div>
                            <div>
                                <h2 className='text-[24px] font-bold text-oxfordBlue'>{productData.productContentValue1}</h2>
                                <p className='text-[16px] text-oxfordBlue'>{productData.productContentValueText1}</p>
                            </div>
                            <div>
                                <h2>**</h2>
                                <p>*%RDA</p>
                            </div>
                        </div>
                    </div>
                    <div className='table-border top-border'>
                        <p className='text-[16px] text-oxfordBlue text-start px-4'>{productData.productNutritionInfo}</p>
                    </div>
                    <div className='table-border top-border'>
                        <p className='text-start px-4 text-oxfordBlue'>**% RDA values not established</p>
                    </div>
                    <div className='sm:mt-[3rem] xxs:mt-[3rem] x:mt-[2rem]'>
                        <p className='sm:mt-[18px] xxs:mt-[18px] x:mt-[18px] xl:text-[17px] sm:text-[14px] xxs:text-[14px] x:text-[12px] text-oxfordBlue'>
                            <span className='text-oxfordBlue text-[18px] font-bold'> Incredients: </span> {productData && productData.ingredientIds && productData.ingredientIds.name}
                        </p>
                        <p className='xl:mt-[30px] sm:mt-[18px] xxs:mt-[18px] x:mt-[18px] xl:text-[17px] sm:text-[14px] xxs:text-[14px] x:text-[12px] text-oxfordBlue'>
                            <span className='text-oxfordBlue text-[18px] font-bold'> Recommended Usage: </span> {selectedSkuItemData.productUsage}
                        </p>
                        <p className='xl:mt-[30px] sm:mt-[18px] xxs:mt-[18px] x:mt-[18px] xl:text-[17px] sm:text-[14px] xxs:text-[14px] x:text-[12px] text-oxfordBlue'>
                            <span className='text-oxfordBlue text-[18px] font-bold'> Advice: </span> {selectedSkuItemData.productAdvice}
                        </p>
                    </div>
                </div>
            </div>
            <div className='bg-white text-lg font-bold text-center sm:col-span-2 xs:col-span-2 lg:col-span-1 text-align-webkit-center'>
                <img src={productData.productNutritionImage} className="h-[34rem] p-[3rem]" alt='' />
            </div>             */}

      <div className="flex flex-wrap justify-center gap-4">
        <div className="w-full sm:w-[40rem] md:w-full flex-shrink-0 xl:w-[30rem] lg:w-[25rem]">
          <img
            src={
              productData.productNutritionImage ||
              "https://us.123rf.com/450wm/pavelstasevich/pavelstasevich1811/pavelstasevich181101065/112815953-no-image-available-icon-flat-vector.jpg?ver=6"
            }
            className="w-full object-cover"
            alt=""
          />
          <div className="m-auto max-w-[38rem] mt-6">
            <h2 className="text-20px text-oxfordBlue font-bold">
              Product Usage
            </h2>
            <p className="text-oxfordBlue text-sm">
              {selectedSkuItemData?.productUsage}
            </p>
            <h2 className="text-20px text-oxfordBlue font-bold mt-4">
              Product Advice
            </h2>
            <p className="text-oxfordBlue text-sm">
              {selectedSkuItemData?.productAdvice}
            </p>
            <h2 className="text-20px text-oxfordBlue font-bold mt-4">
              Product Ingredients
            </h2>
            <p className="text-oxfordBlue text-sm">
              {selectedSkuItemData?.productIngredients}
            </p>
          </div>
        </div>

        <div className=" w-full flex-shrink-0 md:w-[38rem] xl:w-[32rem] lg:w-[25rem] sm:w-[40rem]">
          <img
            src={
              productData.productInfoImage ||
              "https://us.123rf.com/450wm/pavelstasevich/pavelstasevich1811/pavelstasevich181101065/112815953-no-image-available-icon-flat-vector.jpg?ver=6"
            }
            className="h-auto"
            alt=""
          />
        </div>
      </div>
    </div>
  );
};

export default ProductInformation;

import React, { useEffect, useState } from 'react';
import Filter from '../../../components/filter/Filter';
import VideoComponent from '../../../components/self-train/video-component/VideoComponent';
import { selfTrainService } from '../../../services/self-train.service';
import { CONSTANT } from '../../../core/StaticConstant';
import { toast } from "react-toastify";
import Loader from '../../../components/loader/Loader';
import { ArrowSmallUpIcon } from '@heroicons/react/24/solid';
const ViewAllClasses = () => {
  const menuList = [
    { _id: "1", name: "Feature Classes", path: "" },
    { _id: "2", name: "All Classes", path: "view-all-classes" },
    { _id: "3", name: "Experts", path: "experts" }
  ];
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };
  const [sortingType, setSortingType] = useState("");
  const [courseData, setCourseData] = useState<any[]>([]);
  const [page, setPage] = useState(1);
  const [sortingOrder, setSortingOrder] = useState("");
  const [sortingField, setSortingField] = useState("");
  const [filter, setFilter] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState();
  const [isCourse,setIsCourse] = useState(true);

  const handleSortingSelected = (type: any) => {
    setSortingType(type);
  };

  useEffect(() => {
    if(isCourse){
        fetchData();
    }
  }, [sortingType, page, sortingOrder, sortingField, filter]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleScroll = () => {
    if (
      window.innerHeight + document.documentElement.scrollTop >=
      document.documentElement.offsetHeight
    ) {
      setPage((prevPage) => prevPage + 1);
    }
  };
  const fetchData = async () => {
    try {
      if (courseData.length === totalRecords) {
        return;
      }
      let data = {
        pageCount: page,
        limit: 6,
        sortingOrder: sortingOrder,
        sortingField: sortingField,
        filter: filter,
        fields : ["courseCardTitle", "courseExpertId", "courseHoverVideo", "coursePrice", "courseThumbImage"]

    }
      const response = await selfTrainService.getCourses(data);
      setIsLoading(false);
      if (response.status === CONSTANT.SUCCESS) {
        const newCourseData = response.data.courses;
        setCourseData((prevItems: any[]) => [...prevItems, ...newCourseData]);
        setTotalRecords(response.totalRecords);
        setIsCourse(true);
      }else{
        setIsCourse(false);
      }
    } catch (err: any) {
        console.error("error ", err.response )
        setIsCourse(false);
        if(err?.response?.data?.error !== CONSTANT.COURSE_NOT_FOUND) {
            toast.error(err?.response?.data?.error);
        }
        setIsLoading(false);
        setIsCourse(false);
    }
  };
  

  const sortingData = (type: string) => {
    const sortingTypes: { [key: string]: { order: string; field: string } } = {
      "A-Z": { order: "asc", field: "courseCardTitle" },
      "Z-A": { order: "desc", field: "courseCardTitle" },
      Newest: { order: "desc", field: "updatedAt" },
      Oldest: { order: "asc", field: "createdAt" },
      Longest: { order: "desc", field: "longest" },
      Shortest: { order: "asc", field: "shortest" },
      "Price: Highest": { order: "desc", field: "coursePrice" },
      "Price: Lowest": { order: "asc", field: "coursePrice" },
      Popular: { order: "desc", field: "popularCount" },
      "Best Seller": { order: "desc", field: "sellsCount" },
      "Best Reviews": { order: "desc", field: "reviewCount" },
    };

    if (sortingTypes.hasOwnProperty(type)) {
      const { order, field } = sortingTypes[type];
      setPage(1)
      setSortingType(type);
      setSortingOrder(order);
      setSortingField(field);
      setCourseData([]);
    }
  };



  const filterData = (category: string) => {
    const categoryFilters: { [key: string]: string } = {
      Fitness: "Fitness",
      Health: "Health",
      Performance: "Performance",
      Wellness: "Wellness",
      Beauty: "Beauty",
      Relationship: "Relationship",
    };

    setCourseData([]);

    if (categoryFilters.hasOwnProperty(category)) {
      setFilter(categoryFilters[category]);
    }
  };


  return (
    <>
      <div className='bg-darkBlack'>
        <Filter pageName="self-train" bgColor="bg-darkBlack" onSortingSelected={handleSortingSelected} sortingData={sortingData} filteredData={filterData} />
    
        <div className='sm:px-20 px-4'>
          <VideoComponent data={courseData} /><br/><br/><br/>
               <div className='flex justify-end 2xl:w-[100%] 2xl:-ml-[30px] bg-darkBlack xs:hidden'>
        <h2
          className="text-greenFooter mb-[10px]  text-[18px] cursor-pointer font-bold"
          onClick={() => scrollToTop()}
        >
          Back to Top
        </h2>
        <a>
          <ArrowSmallUpIcon
            className="h-[25px] mt-[3px] ml-[4px] mr-[4rem] mb-[13px]  cursor-pointer stroke-[2px] text-white"
            onClick={() => scrollToTop()}
          />
        </a>
      </div>
        </div>
        {isLoading && (
        <div className="absolute top-[50%] z-50 left-[50%]  transform translate-x-[-50%] translate-y-[-50%]">
          <Loader />
        </div>
      )}
      </div>
 
    </>
  );
};

export default ViewAllClasses;

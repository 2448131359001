import arrow from "../../assets/images/arrow1.png";
import UserChildren from "../../components/user-profile/UserChildren";
import SignOutSec from "../../components/sign-out/SignOut";

const SignOut = () => {
  return (
    <UserChildren title="Sign Out">
      <div className="lg:flex gap-3 justify-between items-center lg:mt-16 mt-4">
        <div className="lg:w-[60%] w-full">
          <SignOutSec />
        </div>
        <div className="lg:w-[40%] lg:mt-0 mt-12 w-full relative">
          <img className="w-full max-w-[400px]" src={arrow} alt="arrow" />
          <p className="text-[12px] max-w-[400px] text-white tracking-[4.8px] text-center absolute top-1/2 left-0 w-full">
            SEE YOU SOON!
          </p>
        </div>
      </div>
    </UserChildren>
  );
};

export default SignOut;

import React, { useState } from "react";
import UserChildren from "../../components/user-profile/UserChildren";
import ProfileDetails from "../../components/profile-details/ProfileDetails";
import MedicalDetails from "../../components/medical-details/MedicalDetails";

const MyProfile = (props:any) => {
    const [isMedicalProfileClicked, setIsMedicalProfileClicked] = useState(false);
    
  return (
    <UserChildren title="My Profile">
      <div className="lg:flex gap-3">
      <ProfileDetails
          isMedicalProfileClicked={isMedicalProfileClicked}
          setIsMedicalProfileClicked={setIsMedicalProfileClicked}
        />
        <MedicalDetails isMedicalProfileClicked={isMedicalProfileClicked} setIsMedicalProfileClicked={setIsMedicalProfileClicked} />

      </div>
    </UserChildren>
  );
};

export default MyProfile;

import React, { useState } from "react";
import "./SignUp.css";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import img from "../../assets/images/signup-background.png";
import mobileImg from "../../assets/images/mobile-signup.png";
import Logo from "../../assets/images/white-greenlab-logo.png";
import {
    ChevronRightIcon,
    EyeIcon,
    EyeSlashIcon,
} from "@heroicons/react/24/outline";
import { UserService } from "../../services";
import { signUpSchema } from "./SignUpSchemas";
import { CONSTANT } from "../../core/StaticConstant";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import OTPInput from "react-otp-input";
import Loader from "../loader/Loader";

const initialValues = {
    email: "",
    firstName: "",
    lastName: "",
    password: "",
    confirm_password: "",
    birthDate: "",
    gender: "",
    userType: CONSTANT.ROLES.USER,
    mode:CONSTANT.MODE.NORMAL
};

const Signup = () => {
    const navigate = useNavigate();

    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isOtpScreen, setIsOtpScreen] = useState(false);
    const [userId, setuserId] = useState('');
    const [otp, setOtp] = useState('');
    const [email, setEmail] = useState('');

    const maskEmail = (email: any) => {
        const atIndex = email.indexOf('@');
        const username = email.slice(0, atIndex);
        const domain = email.slice(atIndex);

        const maskedUsername =
            username.charAt(0) +
            '*'.repeat(Math.max(0, username.length - 4)) +
            username.slice(-3);

        return maskedUsername + domain;
    };
    const maskedEmail = maskEmail(email);

    const { values, errors, touched, handleChange, handleSubmit, setFieldValue } = useFormik({
        initialValues,
        validationSchema: signUpSchema,
        onSubmit: (values, action) => {
            try {
                setIsLoading(true);
                let data: any = UserService.signUpUser(values).then((data: any) => {
                    setIsLoading(false);
                    if (data && data.status === CONSTANT.SUCCESS) {
                        toast.success(data.message);
                        setuserId(data?.data?.user?._id)
                        setEmail(data?.data?.user?.email)
                        setIsOtpScreen(true);
                        action.resetForm();
                        // navigate("/login");
                    } else {
                        toast.error(data.message);
                    }
                }).catch((err: any) => {
                    toast.error(err?.response?.data?.error);
                    setIsLoading(false);
                });
            } catch (error: any) {
                setIsOtpScreen(false);
                setIsLoading(false);
                toast.error(error?.response?.data?.error);
            }
        },
    });

    const handleTogglePassword = () => {
        setShowPassword(!showPassword);
    };

    const handleToggleConfirmPassword = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    const handleChangeOTP = (otpValue: any) => {
        setOtp(otpValue);
    };

    const handleOtpChange = async () => {
        try {
            setIsLoading(true);
            let obj = {
                otp: otp,
                userId: userId
            }
            let data: any = await UserService.verifyUserOtp(obj).then((data: any) => {
                setIsLoading(false);
                if (data && data.status === CONSTANT.SUCCESS) {
                    toast.success(data.message);
                    navigate("/login");
                } else {
                    toast.error(data.message);
                }
            }).catch((error: any) => {
                setIsLoading(false);
                toast.error(error?.response?.data?.error);
            });
        } catch (error: any) {
            setIsLoading(false);
            toast.error(error?.response?.data?.error);
        }
    }

    return (
        <div className="bg-midnightDream min-h-screen">
            {isLoading && (
                <div className="absolute top-[50%] z-50 left-[50%]  transform translate-x-[-50%] translate-y-[-50%]">
                    <Loader />
                </div>
            )}
            <div className="sm:flex mt-0 sm:justify-center pt-16 xs:pt-10 s:pt-10 lg:min-w-[200px] lg:max-w-[200px] max-w-[150px] mx-auto mb-3">
                <img src={Logo} alt="GREEN LAB" />
            </div>
            <div className="sm:flex mt-0 sm:justify-center pb-8 xs:pb-[20px] s:pb-[20px]">
                <p className="text-greenFooter h-full max-h-[1200px] xs:text-[9px] s:text-[9px] xs:tracking-[3.6px] s:tracking-[2.1px] text-[14px] tracking-[4.8px] text-center">
                    CHEMISTRY THAT UNDERSTANDS YOUR BIOLOGY
                </p>
            </div>
            <div className="grid grid-cols-6 xs:p-[10px] s:p-[10px] p-4 gap-4">
                <div className="xl:col-span-1"></div>
                <div className="xl:col-span-4 col-span-6">
                    <div className="grid xs:block s:block bg-graymetal 2xl:w-[full]  grid-cols-3 gap-4">
                        <div className="col-span-1">
                            <img
                                className="w-full h-[100%] xs:hidden s:hidden object-cover p-[6px]"
                                src={img}
                                alt="Sunset in the mountains"
                            />
                            <img
                                className="w-full h-full xs:block s:block hidden object-fit p-[6px]"
                                src={mobileImg}
                                alt="Sunset in the mountains"
                            />
                        </div>
                        {!isOtpScreen ? <div className="col-span-2 s:p-[6px] xs:p-[6px] c-sm:p-4 c-md:p-8 p-16 align">
                            <form>
                                <h4 className="text-greenFooter text-2xl xs:text-[20px] s:text-[20px] 2xl:pt-[30px] 2xl:pb-[21px] font-bold text-3xl">
                                    S I G N U P
                                </h4>
                                <hr className="border-silver border-t-[1px] px-8 xl:w-[98%] border-bottom" />
                                <div className="flex gap-10 w-[100%]">
                                    <div className="w-[44%]">
                                        <p className="text-greenFooter 2xl:-mt-1 2xl:w-[50%] xs:text-[9px] s:text-[9px] xs:tracking-[3.6px] s:tracking-[2.1px] font-[12] 2xl:pt-[31px] tracking-[4.0px] text-[14px]">
                                            FIRST NAME
                                        </p>
                                        <input
                                            name="firstName"
                                            value={values.firstName}
                                            onChange={handleChange}
                                            className="mt-2.5 bg-capeCod w-[98%] 2xl:w-[110%] xl:w-[100%] lg:w-[110%] text-[white] focus focus:outline-none md:px-8 xs:px-4 s:px-4 inner-shadow-signup h-10 xs:h-9 s:h-9"

                                        />
                                    </div>
                                    <div className="w-[44%]">

                                        <p className="text-greenFooter  2xl:-mt-1 2xl:w-[50%] xs:text-[9px] s:text-[9px] xs:tracking-[3.6px] s:tracking-[2.1px] font-[12] 2xl:pt-[31px] tracking-[4.0px] text-[14px]">
                                            LAST NAME
                                        </p>
                                        <input
                                            name="lastName"
                                            value={values.lastName}
                                            onChange={handleChange}
                                            className="bg-capeCod w-[98%] 2xl:w-[110%] xl:w-[100%] lg:w-[110%] mt-2.5 text-[white] focus focus:outline-none md:px-8 xs:px-4 s:px-4 inner-shadow-signup h-10 xs:h-9 s:h-9"

                                        /></div>

                                </div>
                                <p className="text-greenFooter  mt-2.5  2xl:-mt-1 xs:text-[9px] s:text-[9px] xs:tracking-[3.6px] s:tracking-[2.1px] font-[12] 2xl:pt-[31px] tracking-[4.0px] text-[14px]">
                                    EMAIL
                                </p>
                                <input
                                    name="email"
                                    value={values.email}
                                    onChange={handleChange}
                                    className="bg-capeCod w-[98%] mt-2.5 text-[white] focus focus:outline-none md:px-8 xs:px-4 s:px-4 inner-shadow-signup h-10 xs:h-9 s:h-9"
                                />
                                {errors.email && touched.email ? (
                                    <p className="text-greenFooter xs:text-[9px] s:text-[9px] xs:tracking-[3.6px] s:tracking-[2.1px] font-[12] tracking-[4.0px] text-[14px] text-rose-800">
                                        {errors.email}
                                    </p>
                                ) : null}


                                <div className="flex gap-10 w-[100%]">
                                    <div className="w-[44%]">
                                        <p className="text-greenFooter  mt-2.5 2xl:-mt-1 xs:text-[9px] s:text-[9px] xs:tracking-[3.6px] s:tracking-[2.1px] font-[12] 2xl:pt-[31px] tracking-[4.0px] text-[14px]">
                                            GENDER
                                        </p>
                                        <select
                                            onChange={handleChange}
                                            value={values.gender}
                                            id="gender"
                                            className="bg-capeCod w-[98%] 2xl:w-[110%] xl:w-[100%] mt-2.5 text-[white] focus focus:outline-none md:px-8 xs:px-4 s:px-4 inner-shadow-signup h-10 xs:h-9 s:h-9"

                                        >
                                            <option selected>
                                                Select Gender
                                            </option>
                                            <option value="Male">
                                                Male
                                            </option>
                                            <option value="Female">
                                                Female
                                            </option>
                                            <option value="Other">
                                                Other
                                            </option>

                                        </select>

                                    </div>
                                    <div className="w-[44%]">

                                        <p className="text-greenFooter  mt-2.5 2xl:-mt-1 2xl:w-[50%] xs:text-[9px] s:text-[9px] xs:tracking-[3.6px] s:tracking-[2.1px] font-[12] 2xl:pt-[31px] tracking-[4.0px] text-[14px]">
                                            BIRTH DATE
                                        </p>

                                        <DatePicker
                                            className="ant-picker-input bg-capeCod w-[98%] 2xl:w-[110%] xl:w-[100%] mt-2.5 text-[white] focus focus:outline-none p-10 md:px-8 xs:px-4 s:px-4 inner-shadow-signup h-10 xs:h-9 s:h-9"
                                            isClearable
                                            placeholderText="Select Birth Date"
                                            showYearDropdown
                                            yearDropdownItemNumber={30}
                                            scrollableYearDropdown
                                            yearItemNumber={30}
                                            maxDate={moment().toDate()}
                                            selected={values.birthDate ? moment(values.birthDate, "YYYY-MM-DD").toDate() : null}
                                            onChange={(date: any) => {
                                                setFieldValue("birthDate", date);
                                            }}

                                        />

                                    </div>

                                </div>


                                <p className=" mt-2.5 text-greenFooter 2xl:-mt-1 xs:text-[9px] s:text-[9px] xs:tracking-[3.6px] s:tracking-[2.1px] font-[12] 2xl:pt-[31px] tracking-[4.0px] text-[14px]">
                                    PASSWORD
                                </p>
                                <div className="relative">
                                    <input
                                        className="bg-capeCod w-[98%] mt-2.5 text-[white] focus focus:outline-none md:px-8 xs:px-4 s:px-4 inner-shadow-signup  h-10 xs:h-9 s:h-9"
                                        type={showPassword ? "text" : "password"}
                                        name="password"
                                        onChange={handleChange}
                                        value={values.password}
                                    />
                                    {showPassword ? (
                                        <EyeIcon
                                            className="absolute cursor-pointer text-[white] top-[22px] right-[24px] w-[17px]"
                                            onClick={() => handleTogglePassword()}
                                        />
                                    ) : (
                                        <EyeSlashIcon
                                            className="absolute cursor-pointer text-[white] top-[22px] right-[24px] w-[17px]"
                                            onClick={() => handleTogglePassword()}
                                        />
                                    )}
                                </div>
                                {errors.password && touched.password ? (
                                    <p className="text-greenFooter xs:text-[9px] s:text-[9px] xs:tracking-[3.6px] s:tracking-[2.1px] font-[12] tracking-[4.0px] text-[14px] text-rose-800">
                                        {errors.password}
                                    </p>
                                ) : null}
                                <p className=" mt-2.5 2xl:-mt-1 text-greenFooter xs:text-[9px] s:text-[9px] xs:tracking-[3.6px] s:tracking-[2.1px] font-[12] 2xl:pt-[31px] tracking-[4.0px] text-[14px]">
                                    CONFIRM PASSWORD
                                </p>
                                <div className="relative">
                                    <input
                                        className="bg-capeCod w-[98%] mt-2.5 text-[white] focus focus:outline-none md:px-8 xs:px-4 s:px-4 inner-shadow-signup  h-10 xs:h-9 s:h-9"
                                        type={showConfirmPassword ? "text" : "password"}
                                        name="confirm_password"
                                        onChange={handleChange}
                                        value={values.confirm_password}
                                    />
                                    {showConfirmPassword ? (
                                        <EyeIcon
                                            className="absolute cursor-pointer text-[white] top-[22px] right-[24px] w-[17px]"
                                            onClick={() => handleToggleConfirmPassword()}
                                        />
                                    ) : (
                                        <EyeSlashIcon
                                            className="absolute cursor-pointer text-[white] top-[22px] right-[24px] w-[17px]"
                                            onClick={() => handleToggleConfirmPassword()}
                                        />
                                    )}
                                </div>
                                {errors.confirm_password && touched.confirm_password ? (
                                    <p className="text-greenFooter xs:text-[9px] s:text-[9px] xs:tracking-[3.6px] s:tracking-[2.1px] font-[12] tracking-[4.0px] text-[14px] text-rose-800">
                                        {errors.confirm_password}
                                    </p>
                                ) : null}
                                <div className="grid w-[98%] xs:justify-center s:justify-center gap-4">
                                    <button
                                        type="button"
                                        onClick={() => handleSubmit()}
                                        className="text-greenFooter xs:pt-[20px] s:pt-[20px] pt-8 font-bold hover:cursor-pointer inline-flex text-[25px]"
                                    >
                                        <span>Sign Up</span>
                                        <span>
                                            <ChevronRightIcon className="h-6 w-8 	mt-2" />
                                        </span>
                                    </button>
                                </div>
                            </form>
                        </div> : <></>}

                        {isOtpScreen ?
                            <div className="col-span-2 s:p-[6px] xs:p-[6px] c-sm:p-2 c-md:p-8 px-16 pb-4 pt-10 align">
                                <div className="flex flex-col justify-center items-center p-3">
                                    <h4 className="text-greenFooter text-2xl xs:text-[20px] s:text-[20px] 2xl:pt-[50px] 2xl:pb-[21px] font-bold">
                                        VERIFY YOUR ACCOUNT
                                    </h4>
                                    <hr className="border-silver border-t-[2px] w-60" />
                                    <p className="text-center p-2 m-3 text-greenFooter">
                                        We emailed you the six digit code to <span className="text-sky-300">{maskedEmail}</span> <br />
                                        Enter the code below to confirm your email address.
                                    </p>
                                    <div className="block text-center ml-0 w-80">
                                        <OTPInput
                                            value={otp}
                                            onChange={handleChangeOTP}
                                            numInputs={6}
                                            shouldAutoFocus={true}
                                            inputStyle={{
                                                border: "1px solid transparent",
                                                borderRadius: "8px",
                                                width: "45px",
                                                height: "54px",
                                                fontSize: "12px",
                                                color: "#000",
                                                marginLeft: "5px",
                                                fontWeight: "400",
                                                caretColor: "blue",
                                                marginTop: "1rem"

                                            }}
                                            renderInput={(props) => <input {...props} />}
                                        />
                                    </div>
                                    <button
                                        className="mt-20 font-bold hover:cursor-pointer w-56 md:w-80 sm:w-56 x:w-56 xs:w-56 inline-flex text-[23px] bg-midnightDream text-white p-2 rounded hover:bg-zinc-700 focus:outline-none flex justify-center"
                                        onClick={handleOtpChange}
                                    >
                                        <span className="text-base">Verify Email</span>
                                    </button>
                                    <button
                                        className="mt-1 font-bold hover:cursor-pointer w-56 md:w-80 sm:w-56 x:w-56 xs:w-56 inline-flex text-[23px] text-white p-2 rounded hover:bg-neutral-800 focus:outline-none flex justify-center mb-3"
                                        onClick={() => setIsOtpScreen(false)}
                                    >
                                        <span className="text-base">Cancel</span>
                                    </button>
                                </div>
                            </div>
                            :
                            <></>
                        }
                    </div>
                    {/* <div className="grid lg:grid-cols-3 display pt-4 bg-midnightDream">
                        <div className="lg:col-span-1"></div>
                        <div className="lg:col-span-1">
                            <button
                                onClick={() => navigate("/login")}
                                className="text-greenFooter xs:text-[9px] s:text-[9px] xs:tracking-[3.6px] s:tracking-[2.1px] text-[12] hover:cursor-pointer tracking-[4.0px] text-[14px] text-left"
                            >
                                ALREADY HAVE AN ACCOUNT?
                            </button>
                        </div>
                        <div className="lg:col-span-1 text-right">
                            <button
                                onClick={() => navigate("/login")}
                                className="text-end xs:text-[9px] s:text-[9px] xs:tracking-[3.6px] s:tracking-[2.1px] text-greenFooter font-[700] hover:cursor-pointer tracking-[4.0px] text-[14px]"
                            >
                                GO TO LOGIN PAGE
                            </button>
                        </div>
                    </div> */}

                    <div className="grid xs:grid-cols-4 s:grid-cols-4 c-md:grid-cols-4 c-sm:grid-cols-4 pt-4 bg-midnightDream">
                        <div className="xs:col-span-2 s:col-span-2 c-md:col-span-2 c-sm:col-span-2">
                            <button
                                onClick={() => navigate("/login")}
                                className="text-greenFooter xs:text-[9px] s:text-[9px] xs:tracking-[3.6px] s:tracking-[2.1px] text-[12] hover:cursor-pointer tracking-[4.0px] text-[14px] text-left"
                            >
                                ALREADY HAVE AN ACCOUNT?
                            </button>
                        </div>
                        <div className="xs:col-span-2 s:col-span-2 c-md:col-span-2 c-sm:col-span-2 text-right">
                            <button
                                onClick={() => navigate("/login")}
                                className="text-end xs:text-[9px] s:text-[9px] xs:tracking-[3.6px] s:tracking-[2.1px] text-greenFooter font-[700] hover:cursor-pointer tracking-[4.0px] text-[14px]"
                            >
                                GO TO LOGIN PAGE
                            </button>
                        </div>
                    </div>
                </div>
                <div className="xl:col-span-1"></div>
            </div>
        </div>
    );
};

export default Signup;

import React, { createContext, useState } from 'react';

interface UserContextType {
  username: string;
  updateUsername: (newUsername: string) => void;
  userAge:String;
  updateUserAge: (newUsername: string) => void;
}

const UserContext = createContext<UserContextType | undefined>(undefined);

interface UserProviderProps {
  children: React.ReactNode;
}

export const UserProvider: React.FC<UserProviderProps> = ({ children }) => {
  const [username, setUsername] = useState('');
  const [userAge, setUserAge] = useState('');

  const updateUsername = (newUsername: string) => {
    setUsername(newUsername);
  };
  const updateUserAge = (newUserAge: string) => {
    setUserAge(newUserAge);
  };

  return (
    <UserContext.Provider value={{ username, updateUsername, updateUserAge, userAge}}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = (): UserContextType => {
  const context = React.useContext(UserContext);
  if (!context) {
    throw new Error('useUser must be used within a UserProvider');
  }
  return context;
};

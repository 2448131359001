import httpCommon from "../core/HttpCommonCore";
import { API_CONSTANT } from "../core/Constant";

export const BenefitService = {
  getAllBenefit,
};

function getAllBenefit(data:any) {
  let url = API_CONSTANT.GET_ALL_BENEFIT;
  return httpCommon.post(url,data).then((response: any) => {
    if (response.statusText !== "OK") {
      const error = response.statusText;
      return Promise.reject(error);
    }
    return response.data;
  });
}

import React, { useEffect, useRef, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { useStripe, useElements, CardElement, PaymentRequestButtonElement } from "@stripe/react-stripe-js";
import { useDispatch, useSelector } from "react-redux";
import { getUserData } from "../../../core/Utils";
import {
    orderId,
    setCartProducts,
    setDeliveryCharge,
    setOrder,
    setProductTrack,
} from "../../../redux/reducer/cartReducer";
import { PaymentService } from "../../../services/payment.service";
import { CheckIcon, TrashIcon } from "@heroicons/react/24/solid";
import { CartService } from "../../../services";
import { toast } from "react-toastify";
import { CONSTANT } from "../../../core/StaticConstant";
import { OrderService } from "../../../services/order.services";
import { showConfirmDialog } from "../../../core/Constant";
import { useParams } from "react-router-dom";
import { Localstorage } from "../../../core/AuthService";

const CARD_ELEMENT_OPTIONS = {
    hidePostalCode: true,
    style: {
        base: {
            lineHeight: "35px",
            color: "#212529",
            backgroundColor: "",
            fontSize: "1.1rem",
            "::placeholder": {
                color: "#aab7c4",
            },
            padding: "10px",
            border: "1px solid red",
            borderRadius: "4px",
        },
        invalid: {
            color: "#fa755a",
            iconColor: "#fa755a",
        },
    },
};

const PaymentMethod = (props: any) => {
    const {
        totalPrice,
        onLoaderChange,
        isParentLoading,
        checkCvv,
        isCvvCheck,
        orderIdData,
        handleCardElementChange,
        isCardElementValid

    } = props;
    

    const deliveryAddress = useSelector(
        (state: any) => state.selectDeliveryAddress
    );

const totalPrices = useSelector((state: any) => state.setPromoCode);

    const stripe: any = useStripe();
    const elements: any = useElements();
    const dispatch = useDispatch();
    const [userDetails, setUserDetails] = useState<any>();
    const [paymentMethodId, setPaymentMethodId] = useState<any>();
    const [cardData, setCardData] = useState<any>();
    const [selectCard, setSelectCard] = useState<any>();
    const [paymentRequest, setPaymentRequest] = useState(null);
    const products = useSelector((state: any) => state);
    const getCustomerId = Localstorage(CONSTANT.GET,CONSTANT.CUSTOMER_ID);
    const [saveCardChecked, setSaveCardChecked] = useState(false);


    useEffect(() => {
        const userData: any = getUserData();
        setUserDetails(userData);
        getAllCard();
    }, []);

    const createPaymentObject = (
        getCustomerId: any,
        paymentMethodId: any,
        totalPrices: any,
        deliveryAddress: any
    ) => {
        return {
            customerId: getCustomerId,
            cardToken: paymentMethodId,
            paymentMethodId: paymentMethodId || selectCard,
            amount: totalPrices || parseFloat(orderIdData.amount),
            description: "Test Payment",
            currency: "GBP",
            shipping: {
                name: deliveryAddress?.fullName,
                address: {
                    line1: deliveryAddress?.address1,
                    postal_code: deliveryAddress?.postcode,
                    city: deliveryAddress?.admin_district,
                    country: "GB",
                },
            },
        };
    };

    const store = useSelector((state: any) => state);

    const orderProcess = store.order;
    const [userId, setUserId] = useState<String>("");

    useEffect(() => {
        const userData: any = getUserData();
        setUserId(userData?.userId);
    }, [store.amount]);

    const createOrder = async (paymentStatus: any, paymentHistoryId: any) => {
        props.onLoaderChange(true);
        try {
            const userData: any = getUserData();
            let obj = {
                cartProducts: store?.cartProducts,
                addressId: store?.selectDeliveryAddress?._id,
                amount: totalPrices,
                orderStatus: paymentStatus,
                deliveryCharge: store?.deliveryCharge.amount,
                expectedDate: store.setExpectedDate,
                paymentId: paymentHistoryId
            };

            const response = await OrderService.createOrder(obj);
            if (response.status === CONSTANT.SUCCESS) {
                dispatch(orderId(response?.data?.order?._id));
            }
        } catch (error) {
            console.error(error);
            onLoaderChange(false);
        }
        props.onLoaderChange(false);
    };

    const addNewCard = async (paymentMethodId: any) => {
        onLoaderChange(true);
        if (!stripe || !elements) {
            return;
        }
        try {
            const cardElement: any = elements.getElement(CardElement);
            if (!cardElement) {
                return;
            }
            const token: any = await stripe.createToken(cardElement);
            const tokenId = token.token.id || "";

            const { error, paymentMethod } = await stripe.createPaymentMethod({
                type: "card",
                card: cardElement,
            });
            if (error) {
                return;
            }
            paymentMethodId = paymentMethod?.id;
            setPaymentMethodId(paymentMethodId);
            let obj = {
                customerId: getCustomerId,
                cardToken: tokenId,
                paymentMethodId: paymentMethodId,
            };

            await PaymentService.addNewCard(obj)
                .then((res) => {
                    onLoaderChange(true);
                    if (res.status === CONSTANT.SUCCESS) {
                        toast.success(res.message);
                        getAllCard();
                        cardElement.clear();
                    } else {
                        toast.error(res.message);
                    }
                })
                .catch((err) => {
                    console.error(err);
                    onLoaderChange(false);
                });
        } catch (error) {
            console.error("Error creating payment method:", error);
            onLoaderChange(false);
            toast.error(CONSTANT.CARD_ERROR);
        }
    };

    const { ids } = useParams();

    

    const updateOrderByID = async (paymentStatus: any, paymentHistoryId: any) => {
        let obj = {
            userId: orderIdData.userId,
            cartProducts: orderIdData.cartProducts,
            addressId: orderIdData.addressId,
            amount: totalPrices || parseFloat(orderIdData.amount),
            orderStatus: paymentStatus,
            deliveryCharge: orderIdData.deliveryCharge,
            expectedDate: orderIdData.expectedDate,
            paymentId: paymentHistoryId
        };
        try {
            orderIdData.order.orderStatus === CONSTANT.PAYMENT_ISSUE &&
                (await OrderService.updateOrderById(ids, obj));
        } catch (error) {
            console.error(error);
        }
    };




    const makePayment = async () => {
        onLoaderChange(true);
        checkCvv(false);

        if (!stripe || !elements) {
            return;
        }
        try {
            const cardElement = elements.getElement(CardElement);
            if (!cardElement) {
                return;
            }
            if (selectCard) {
                const obj = createPaymentObject(
                    getCustomerId,
                    paymentMethodId,
                    totalPrices,
                    deliveryAddress
                );
                const res = await PaymentService.createPayment(obj);
                onLoaderChange(false);

                if (res.status === CONSTANT.SUCCESS) {
                    toast.success(res.message);

                    {
                        ids
                            ? updateOrderByID(CONSTANT.PENDING, res.data.paymentHistoryId)
                            : createOrder(CONSTANT.PENDING, res.data.paymentHistoryId);
                    }

                    let navigateUrl = res.data.redirectUrl;
                    const windowFeatures =
                        "width=500,height=500,top=50%,left=50%,location=no,menubar=no";
                    if(navigateUrl) {
                        window.open(navigateUrl, "Payment Popup", windowFeatures);
                    }
                    let paymentHistoryId = res.data.paymentHistoryId;

                    paymentStatus(paymentHistoryId);

                    setTimeout(() => {
                        dispatch(setOrder("order"));
                        dispatch(setProductTrack("1"));
                    }, 5000);
                } else {
                    toast.error(res.message);
                    dispatch(setOrder("order"));
                    dispatch(setProductTrack("2"));

                    {
                        ids
                            ? updateOrderByID(CONSTANT.PAYMENT_ISSUE, "")
                            : createOrder(CONSTANT.PAYMENT_ISSUE, "");

                    }

                }
            } else {
                const { error, paymentMethod } = await stripe.createPaymentMethod({
                    type: "card",
                    card: cardElement,
                });

                if (error ) {
                    toast.error(
                        "Error creating payment method. Please check your card details."
                    );
                    onLoaderChange(false);
                    return;
                }

                const paymentMethodId = paymentMethod?.id;
                setPaymentMethodId(paymentMethodId);

                const obj = createPaymentObject(
                    getCustomerId,
                    paymentMethodId,
                    totalPrices,
                    deliveryAddress
                );
                const res = await PaymentService.createPayment(obj);
                onLoaderChange(false);

                if (res.status === CONSTANT.SUCCESS) {
                    toast.success(res.message);
                    let navigateUrl = res.data.redirectUrl;
                    const windowFeatures =
                        "width=500,height=500,top=50%,left=50%,location=no,menubar=no";
                    if(navigateUrl) {
                        window.open(navigateUrl, "Payment Popup", windowFeatures);
                    }
                    let paymentHistoryId = res.data.paymentHistoryId;

                    paymentStatus(paymentHistoryId);
                    {
                        ids
                            ? updateOrderByID(CONSTANT.PENDING, paymentHistoryId)
                            : createOrder(CONSTANT.PENDING, paymentHistoryId);
                    }

                    setTimeout(() => {
                        dispatch(setOrder("order"));
                        dispatch(setProductTrack("1"));
                    }, 5000);
                } else {
                    toast.error(res.message);
                    dispatch(setOrder("order"));
                    dispatch(setProductTrack("2"));


                    {
                        ids
                            ? updateOrderByID(CONSTANT.PAYMENT_ISSUE, "")
                            : createOrder(CONSTANT.PAYMENT_ISSUE, "");
                    }
                    deleteCartProducts();

                }

                if (saveCardChecked) {
                    await addNewCard(paymentMethodId);
                    onLoaderChange(false);
                }
            }
        } catch (error:any) {
            onLoaderChange(false);
            toast.error(error?.response?.data?.error || CONSTANT.CARD_ERROR)
        }
    };

    const getAllCard = async () => {
        onLoaderChange(true);
        await PaymentService.getCardDetails()
            .then((res: any) => {
                onLoaderChange(false);
                setCardData(res?.data?.cards);
            })
            .catch((error: any) => {
                onLoaderChange(false);
            });
    };

    function handleInput(event: any) {
        const input = event.target;
        const inputValue = input.value?.trim();
        const numericValue = inputValue?.replace(/\D/g, "");
        const restrictedValue = numericValue?.slice(0, 3);
        input.value = restrictedValue;
        const isValidCVV = CONSTANT.CHECK_CVV?.test(restrictedValue);
        checkCvv(isValidCVV);
    }

    const enterCvv = (id: any) => {
        if (selectCard === id) {
            setSelectCard(null);
            checkCvv(false);
        } else {
            checkCvv(false);
            setSelectCard(id);
        }
    };


    const deleteCartProducts = async () => {
        try {
            onLoaderChange(true);
            await CartService.deleteCartProducts().then(() => {
                onLoaderChange(false);
                dispatch(setCartProducts([]));
                dispatch(setDeliveryCharge({}));
            });
        } catch (error) {
            console.error(error);
        }
    };

    const deleteExistingCard = async (id: any) => {
        onLoaderChange(true);

        showConfirmDialog(CONSTANT.DELETE_CONFIRMATION, async (confirmed: any) => {
            if (confirmed) {
                let obj = {
                    paymentMethodId: id,
                };
                await PaymentService.deleteExistingCard(obj)
                    .then((res) => {
                        onLoaderChange(false);
                        if (res.status === CONSTANT.SUCCESS) {
                            getAllCard();
                        }
                    })
                    .catch((err) => {
                        onLoaderChange(false);
                    });
            }
            onLoaderChange(false);
        });
    };

    const paymentStatus = async (id: any) => {
        onLoaderChange(true);
        await PaymentService.paymentStatus(id)
            .then((res) => {
                onLoaderChange(false);
                if (res.status === CONSTANT.SUCCESS) {
                    deleteCartProducts();
                }
            })
            .catch((err) => {
                onLoaderChange(false);

            });
    };




    const handleGpayment = async (paymentMethodId: any) => {

        const obj = {
            customerId: getCustomerId,
            cardToken: paymentMethodId,
            paymentMethodId: paymentMethodId.paymentMethod.id,
            amount: totalPrices || parseFloat(orderIdData.order.amount),
            description: "Test Payment",
            currency: "GBP",
            shipping: {
                name: deliveryAddress?.fullName,
                address: {
                    line1: deliveryAddress?.address1,
                    postal_code: deliveryAddress?.postcode,
                    city: deliveryAddress?.admin_district,
                    country: "GB",
                }
            }
        }

        const res = await PaymentService.createPayment(obj);

        if (res.status === CONSTANT.SUCCESS) {
            {
                ids
                    ? updateOrderByID(CONSTANT.PENDING, res.data.paymentHistoryId)
                    : createOrder(CONSTANT.PENDING, res.data.paymentHistoryId);
            }

            let paymentHistoryId = res.data.paymentHistoryId;
            paymentStatus(paymentHistoryId);


            setTimeout(() => {
                dispatch(setOrder("order"));
                dispatch(setProductTrack("1"));
                toast.success(res.message);
            }, 5000);
        } else {

            {
                ids
                    ? updateOrderByID(CONSTANT.PAYMENT_ISSUE, "")
                    : createOrder(CONSTANT.PAYMENT_ISSUE, "");

            }
            deleteCartProducts();

        }

        if (res.data && res.data.client_secret) {
            const { paymentIntent, error }: any = await stripe.confirmCardPayment(res.data.client_secret, {
                payment_method: paymentMethodId.paymentMethod.id
            });

            if (error) {
                paymentMethodId.complete(CONSTANT.FAIL)
            }

            paymentMethodId.complete(CONSTANT.SUCCESS)


            if (paymentIntent.paymentIntent.status == CONSTANT.REQUIRES_ACTION) {
                await stripe.confirmCardPayment(paymentIntent.paymentIntent.client_secret)

            }
        } else {
        }

    }

    useEffect(() => {

        if (stripe) {

            const pr = stripe.paymentRequest({
                country: 'GB',
                currency: 'gbp',
                total: {
                    label: 'Total Price',
                    amount: totalPrices * 100,
                },
                
                requestPayerName: true,
                requestPayerEmail: true,
                
            });


            const payment = async () => {
                await pr.canMakePayment().then((result: any, event: any) => {
                    if (result) {

                        pr.on('paymentmethod', handleGpayment);
                        setPaymentRequest(pr);

                    }
                }).catch((e: any) => {
                    setPaymentRequest(null);
                })
            }
            payment()

        }

    }, [stripe]);

    if (!stripe) {
        return <div>Loading...</div>;
    }




    return (
        <div className="relative">
            {cardData &&
                cardData.map((elements: any, index: number) => {
                    return (
                        <div className="grid grid-cols-8" key={index}>
                            <div className="col-span-7">
                                <div
                                    className={`grid grid-cols-8 text-black bg-lessgray md:px-4 px-2 mt-4 py-4 cursor-pointer ${selectCard === elements.id ? "bg-nero text-white" : ""
                                        }`}
                                    onClick={() => enterCvv(elements.id)}
                                >
                                    <div className="col-span-3">
                                        <h2 className="md:font-[700] font-[500] text-[12px] md:text-[15px] tracking-normal">
                                            **** **** **** {elements?.card?.last4}
                                        </h2>
                                        <p className="md:font-[700] font-[500] text-[12px] md:text-[18px] tracking-normal">
                                            {elements.card.brand}
                                        </p>
                                    </div>
                                    <div className="col-span-4 mx-auto">
                                        {selectCard === elements.id ? (
                                            <div className="lg:flex gap-4 lg:gap-[2px]">
                                                <p className=" font-[400] lg:w-full text-[13px] tracking-normal">
                                                    Enter CVV (?) :
                                                </p>
                                                <div onClick={(e) => e.stopPropagation()}>
                                                    <div>
                                                        <input
                                                            className="text-[black] w-[40%] lg:w-[60%] focus focus:outline-none text-center"
                                                            maxLength={3}
                                                            type="text"
                                                            onInput={handleInput}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                    <div className="col-span-1">
                                        <div className="">
                                            {selectCard === elements.metadata.payment_method_id ? (
                                                <CheckIcon className="w-[35px] text-end  mb-1 text-[white]" />
                                            ) : (
                                                ""
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-span-1 m-auto">
                                <TrashIcon
                                    className="w-[40px] text-greenFooter cursor-pointer"
                                    onClick={() => deleteExistingCard(elements.id)}
                                />
                            </div>
                        </div>
                    );
                })}
            <div className="mt-4 text-[18px] font-bold">
                <label htmlFor="">Card Details</label>
                <div className="border bg-gray-200 border-gray-200 rounded p-2 mt-4">
                    <CardElement
                        id="card-element"
                        options={CARD_ELEMENT_OPTIONS}
                        onChange={handleCardElementChange}
                    />
                </div>
            </div>
            <input
                type="checkbox"
                id="checkboxChecked"
                className={`text-[15px] px-10 py-3 mt-4 text-white font-bold ${!isCardElementValid || isParentLoading
                    ? "bg-greenFooter"
                    : "bg-midnightDream text-white"
                    } tracking-wide`}
                disabled={!isCardElementValid || isParentLoading}
                checked={saveCardChecked}
                onChange={() => setSaveCardChecked(!saveCardChecked)}
            />

            <label className="ms-3 cursor-pointer" htmlFor="checkboxChecked">
                Save card for faster checkout
            </label>
            <div>
                {paymentRequest &&
                    <PaymentRequestButtonElement options={{ paymentRequest }} />
                }
            </div>
            <div className="text-end mt-12">
                <button
                    className={`text-[15px] px-10 py-3 font-bold ${!(isCardElementValid || isCvvCheck)
                        ? "bg-greenFooter text-white"
                        : "bg-midnightDream text-white"
                        } tracking-wide`}
                    onClick={() => {
                        makePayment();
                    }}
                    disabled={!(isCardElementValid || isCvvCheck)}
                >
                    Make Payment : £{ids && ids ? orderIdData.amount : totalPrices ? totalPrices?.toFixed(2) : "N/A"}
                </button>
            </div>
        </div>
    );
};

export default PaymentMethod;

import httpCommon from "../core/HttpCommonCore";
import { API_CONSTANT } from "../core/Constant";

export const AddressService = {
  getAllAddressByUserId,
  registerAddress,
  getSpecificAddressByUserId,
  updateSpecificAddressByUserId,
  getDeliveryOptions,
};

function registerAddress(data: any) {
  let url = API_CONSTANT.ADD_ADDRESS;
  return httpCommon.post(url, data).then((response: any) => {
    if (response.statusText !== "OK") {
      const error = response.statusText;
      return Promise.reject(error);
    }
    return response.data;
  });
}

function getAllAddressByUserId() {
  let url = API_CONSTANT.GET_ALL_ADDRESS_BY_USERId;
  return httpCommon.post(url).then((response: any) => {
    if (response.statusText !== "OK") {
      const error = response.statusText;
      return Promise.reject(error);
    }
    return response.data;
  });
}

function getSpecificAddressByUserId(data: any) {
  let url = API_CONSTANT.GET_SPECIFIC_ADDRESS_BY_USER_ID;
  return httpCommon.post(url, data).then((response: any) => {
    if (response.statusText !== "OK") {
      const error = response.statusText;
      return Promise.reject(error);
    }
    return response.data;
  });
}

function updateSpecificAddressByUserId(data: any) {
  let url = API_CONSTANT.UPDATE_SPECIFIC_ADDRESS_BY_USER_ID;
  return httpCommon.post(url, data).then((response: any) => {
    if (response.statusText !== "OK") {
      const error = response.statusText;
      return Promise.reject(error);
    }
    return response.data;
  });
}

function getDeliveryOptions() {
  let url = API_CONSTANT.GET_DELIVERY_OPTIONS;
  return httpCommon.get(url).then((response: any) => {
    if (response.statusText !== "OK") {
      const error = response.statusText;
      return Promise.reject(error);
    }
    return response.data;
  });
}

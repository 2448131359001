import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    orderId,
    setCartProducts,
    setOrder,
    setProductTrack,
    setPromoCode,
} from "../../../redux/reducer/cartReducer";
import { OrderService } from "../../../services/order.services";
import { CONSTANT } from "../../../core/StaticConstant";
import { getUserData } from "../../../core/Utils";
import moment from "moment";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { Localstorage } from "../../../core/AuthService";

const OrderSummary = (props: any) => {
    const {
        cartData,
        totalPrice,
        deliveryCharge,
        isParentLoading,
        isCvvCheck,
        makePayment,
        getCustomerByEmail,
        orderIdData,
        isCardElementValid
    } = props;
    const cartProductsStore = useSelector((state: any) => state.cartProducts);

    const totalPrices = useSelector((state: any) => state.setPromoCode);

    const store = useSelector((state: any) => state);
    const orderProcess = store.order;
    const [isPromoCodeApplied, setisPromoCodeApplied] = useState<any>(false);
    
    const [userId, setUserId] = useState<String>("");
    const [price, setPrice] = useState<number | undefined>(undefined);

    const [openOrders, setOpenOrders] = useState<any>(false);
    const [showAlert, setShowAlert] = useState(false);
    const [promoCodeData, setPromoCodeData] = useState<any>();

    const futureDate = moment().add(5, "days").format("MMM DD , YYYY");

    const dispatch = useDispatch();
    useEffect(() => {
        const userData: any = getUserData();
        setUserId(userData?.userId);

    }, [store.amount]);

    const createOrder = async () => {
        props.onLoaderChange(true);
        try {
            let obj = {
                cartProducts: store?.cartProducts,
                addressId: store?.selectDeliveryAddress?._id,
                amount: totalPrice,
            };

            const response = await OrderService.createOrder(obj);
            if (response.status === CONSTANT.SUCCESS) {
                dispatch(orderId(response?.data?.order?._id));                
            }
        } catch (error) {
            console.error(error);
            props.onLoaderChange(false);
        }
    };

    const onClickAddressSelect = (id: any) => {
        window.scrollTo(0, 0);
        getCustomerByEmail();
        if (id === "order") {
            dispatch(setOrder(id));
            dispatch(setProductTrack("1"));
            createOrder();
            dispatch(setCartProducts([]));
        } else {
            dispatch(setOrder(id));
        }
    };


    const onClickArrow: any = () => {
        setOpenOrders(!openOrders)
        setValues({ promoCode: '' });
        setShowAlert(false);
    };

    const deliveryObj:any = Localstorage(CONSTANT.GET,CONSTANT.DELIVERY_CHARGE);

    const DeliveryCharge = JSON.parse(deliveryObj);

    const initialValues = {
        promoCode: ""
    }
    // useEffect(() => {
    //     let totalDeliveryCharge = 0;
    //     if (deliveryCharge) {
    //         totalDeliveryCharge = deliveryCharge.amount;
    //     }
    //     setTotalDeliveryCharge(totalDeliveryCharge);
    // }, [deliveryCharge, cartProductsStore]);

    const { values, errors, touched, handleChange, handleSubmit, setValues } =
        useFormik({
            initialValues,
            onSubmit: async () => {
                try {
                    let data = await OrderService.getAllPromoCode(values.promoCode);
                    setPromoCodeData(data.data)
                    setValues((prevValues) => ({
                        ...prevValues,
                        promoCode: data.promoCode,
                    }));
                    if (data.status === CONSTANT.SUCCESS) {
                        toast.success(data.message);
                        setShowAlert(false);
                        setisPromoCodeApplied(true);
                        
                    }
                    else {
                        setShowAlert(true);
                        toast.error(data.message);

                    }

                } catch (err:any) {
                    setShowAlert(true);
                    setPromoCodeData(err.response.data.error)
                        

                }


            }
        });

    // useEffect(() => {
    //     if (promoCodeData && promoCodeData.promoCodeDetails && promoCodeData.promoCodeDetails.discountValue && promoCodeData.promoCodeDetails.discountType) {
    //         if (promoCodeData.promoCodeDetails && promoCodeData.promoCodeDetails.discountType === "percentage") {
    //             const discountPercentage = promoCodeData.promoCodeDetails.discountValue / 100;
    //             const discountedAmount = totalPrice * discountPercentage;

    //             const calculatedPrice = totalPrice - discountedAmount;
    //             setPrice(calculatedPrice);
    //         }
    //         else {
    //             const calculatedPrice = totalPrice - promoCodeData.promoCodeDetails.discountValue;
    //             setPrice(calculatedPrice);
    //         }
    //     } else {
    //         setPrice(totalPrice);
    //     }
    // }, [promoCodeData, totalPrice]);

    // dispatch(setPromoCode(price))


    const totalProductPrice = cartProductsStore.reduce(
        (total: any, product: any) => total + product.totalAmount,
        0
    );

    const totalAmount = totalProductPrice + deliveryCharge.amount;


    if (promoCodeData && promoCodeData.promoCodeDetails) {
        if (promoCodeData.promoCodeDetails.discountType === CONSTANT.PERCENTAGE) {
            const discountPercentage = promoCodeData.promoCodeDetails.discountValue / 100;

            const discountedAmount = totalAmount * discountPercentage;
            const calculatedPrice = totalAmount - discountedAmount;

            dispatch(setPromoCode(calculatedPrice?.toFixed(2)));
        } else {
            const calculatedPrice = totalAmount - promoCodeData.promoCodeDetails.discountValue;
            dispatch(setPromoCode(calculatedPrice?.toFixed(2)));
        }
    } else {
        dispatch(setPromoCode(totalAmount?.toFixed(2)));
    }

    return (
        <div className="bg-nero  2xl:w-[90%] -ml-auto py-7">

            <p className="text-silverChalice pb-4 px-6">
                Choose an address to continue checking out. You will still have a chance
                to review and edit your order before it is final
            </p>
            <hr className="py-4 !text-darkshadegray" />
            <div className="px-6">
                <h2 className="text-silverChalice font-bold text-[25px]">
                    Order Summary
                </h2>
                <div className="flex justify-between py-4">
                    <div>
                        <h2 className="text-silverChalice font-bold">
                            Items ({orderIdData &&
                                orderIdData?.orderStatus === CONSTANT.PAYMENT_ISSUE
                                ? Object.keys(orderIdData.cartProducts).length
                                : cartProductsStore.length})
                        </h2>
                    </div>
                    <div>
                        <h2 className="text-silverChalice font-bold">Quantity</h2>
                    </div>
                    <div>
                        <p className="text-silverChalice">Price</p>
                    </div>
                </div>
                {cartProductsStore &&
                    cartProductsStore.map((ele: any, index: number) => {
                        return (
                            <div className="flex justify-between pt-2" key={index}>
                                <div className="w-[50%]">
                                    <h2 className="text-silverChalice">{ele?.productName}</h2>
                                </div>
                                <div className="w-[5%] text-center">
                                    <h2 className="text-silverChalice">{ele?.quantity}</h2>
                                </div>
                                <div className="w-[45%] text-end">
                                    <p className="text-silverChalice"> £ {ele && ele?.totalAmount ? ele?.totalAmount?.toFixed(2) : "N/A"}</p>
                                </div>
                            </div>
                        );
                    })}
                {orderIdData &&
                    orderIdData.cartProducts.map((product: any, index: number) => (
                        <div className="flex justify-between pt-2" key={index}>
                            <div className="w-[50%]">
                                <h2 className="text-silverChalice">{product?.productName}</h2>
                            </div>
                            <div className="w-[5%] text-center">
                                <h2 className="text-silverChalice">{product?.quantity}</h2>
                            </div>
                            <div className="w-[45%] text-end">
                                <p className="text-silverChalice">£ {product && product.totalAmount ? product.totalAmount?.toFixed(2) : "N/A"}</p>
                            </div>
                        </div>
                    ))}

                <div className="flex justify-between py-4">
                    <div>
                        <h2 className="text-silverChalice">{deliveryCharge?.title}</h2>
                    </div>
                    <div>
                        <p className="text-silverChalice"> {deliveryCharge && deliveryCharge?.amount ? `£ ${deliveryCharge?.amount?.toFixed(2)}` : ""}</p>
                    </div>
                </div>


                {promoCodeData && promoCodeData?.promoCodeDetails ?
                <div className="flex justify-between pb-2">
                    <div>
                        <h2 className="text-silverChalice">Discount Amount</h2>
                    </div>
                    <div>
                        <p className="text-silverChalice"> {promoCodeData?.promoCodeDetails && promoCodeData?.promoCodeDetails?.discountType === CONSTANT.PERCENTAGE ? (
                                    `${promoCodeData.promoCodeDetails && promoCodeData.promoCodeDetails.discountValue?.toFixed(2)}%`
                                ) : (
                                    `£${promoCodeData.promoCodeDetails && promoCodeData.promoCodeDetails.discountValue?.toFixed(2)}`
                                )}</p>
                    </div>
                </div>:""}

                <hr className="" />
                <div className="flex justify-between pt-4">
                    <div>
                        <h2 className="text-silverChalice font-bold pb-6">
                            Total ({orderIdData &&
                                orderIdData?.orderStatus === CONSTANT.PAYMENT_ISSUE
                                ? Object.keys(orderIdData.cartProducts).length
                                : cartProductsStore.length})
                        </h2>
                    </div>
                    <div>
                        <p className="text-silverChalice font-bold pb-6">
                            £
                            {orderIdData &&
                                orderIdData?.orderStatus === CONSTANT.PAYMENT_ISSUE
                                ? orderIdData?.amount
                                : totalPrices ? totalPrices?.toFixed(2) : "N/A"}
                        </p>
                    </div>
                </div>

                <div>
                    <p className="text-silverChalice pb-4 cursor-pointer" onClick={onClickArrow}>
                        Have a promo code?
                    </p>

                    {openOrders &&

                        <div className="pb-2">

                            <form onSubmit={(e) => handleSubmit(e)}>

                                <div className="sm:flex justify-between gap-4">
                                    <input
                                        className="!bg-gray85 text-darkshadegreen w-full 2xl:w-[12rem] font-bold mt-1 focus focus:outline-none md:px-5 xs:px-4 s:px-4 inner-shadow-signup  h-10 xs:h-9 s:h-9"
                                        name="promoCode"
                                        placeholder="Enter promo code"
                                        onChange={handleChange}
                                        value={values?.promoCode}
                                        disabled={isPromoCodeApplied} // Disable the input field if promo code is applied
                                    />


                                    <button
                                        className={`text-[15px] px-4 xs:px-7 py-2  xs:mt-[4px] sm:mt-[3.5px] font-bold  text-white  whitespace-nowrap ${!values.promoCode ? "bg-gray-300" : "bg-midnightDream"}`}
                                        type="submit"
                                        disabled={!values?.promoCode}
                                    >
                                        Apply
                                    </button>
                                </div>
                            </form>

                            {showAlert && (
                                <div
                                    className=" inline-flex w-[350px] -ml-[30px] items-center rounded-lg text-[#e32323] text-[13px] px-6 py-5 "
                                    role="alert"
                                >
                                    <span className="mr-2 ml-1.5">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 24 24"
                                            fill="currentColor"
                                            className="h-5 w-5"
                                        >
                                            <path
                                                fill-rule="evenodd"
                                                d="M9.401 3.003c1.155-2 4.043-2 5.197 0l7.355 12.748c1.154 2-.29 4.5-2.599 4.5H4.645c-2.309 0-3.752-2.5-2.598-4.5L9.4 3.003zM12 8.25a.75.75 0 01.75.75v3.75a.75.75 0 01-1.5 0V9a.75.75 0 01.75-.75zm0 8.25a.75.75 0 100-1.5.75.75 0 000 1.5z"
                                                clip-rule="evenodd"
                                            />
                                        </svg>
                                    </span>
                                    <p className="form-error text-[red]">
                                        {promoCodeData}

                                    </p>
                                </div>
                            )}

                        </div>
                    }
                </div>

                <p className="text-silverChalice pt-2">
                    Get it delivered by {futureDate}
                </p>


            </div>
        </div>
    );
};

export default OrderSummary;
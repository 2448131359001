import { Localstorage } from '../../core/AuthService';
import { CONSTANT } from '../../core/StaticConstant';
import { getUserData } from '../../core/Utils';

const BotAvatar = ({ setSelectedBotName }: any) => {
    return (
        <div className="w-10 h-10 rounded-full flex justify-center items-center text-lg mr-1 bg-[#9b7d00de] text-white min-h-40 min-w-40">
            {setSelectedBotName?.substring(0, 1)}
        </div>
    )
}

export default BotAvatar


export const UserAvatar = () => {
    const user: any = getUserData() || "";
    const firstName = user?.firstName || ""; 
    const lastName = user?.lastName || "";   
    const UserName = Localstorage(CONSTANT.GET, CONSTANT.USER_NAME);

    const initials = `${firstName?.substring(0, 1)?.toUpperCase() || ''}${lastName?.substring(0, 1)?.toUpperCase() || ''}`;

    return (
        <div className="w-10 h-10 rounded-full flex justify-center items-center text-lg bg-[#d8d8d8] ml-1 bg-gray-300 text-black">
           {initials || UserName?.substring(0, 1)?.toUpperCase()}
        </div>
    )
}


    
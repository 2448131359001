import React, { useEffect, useState } from "react";
import "./Navbar.css";
import { Fragment } from "react";
import arrow from "../../assets/images/arrow1.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { XMarkIcon, ArrowLeftIcon } from "@heroicons/react/24/outline";
import {
    ShoppingCartIcon,
    BellIcon,
    ChevronDownIcon,
    MagnifyingGlassIcon,
    ChevronUpIcon
} from "@heroicons/react/24/solid";
import { CONSTANT } from "../../core/StaticConstant";
import { useDispatch, useSelector } from "react-redux";
import Logo from "../../assets/images/greenlablogo.png";
import { encryptData, getUserData } from "../../core/Utils";
import { ProductService } from "../../services";
import { BeatLoader } from "react-spinners";
import { Localstorage } from "../../core/AuthService";
import { useUser } from "../Contexts/UserContext";
import { setCartProducts } from "../../redux/reducer/cartReducer";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import AlertDialog from "../../custom_components/LogoutDialog";

function classNames(...classes: any) {
    return classes.filter(Boolean).join(" ");
}

const Navbar = (props: any) => {
    const { isGreen } = props;
    const { username }: any = useUser();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const cartProductsStore = useSelector((state: any) => state?.cartProducts);
    let totalProductCounts = 0;

    if (Array.isArray(cartProductsStore)) {
        for (const product of cartProductsStore) {
            totalProductCounts += product?.quantity;
        }
    }

    const [selectedMenuName, setSelectedMenuName] = useState<any>("HOME");
    const [userData, setUserData] = useState<any>("");
    const [searchToggle, setSearchToggle] = useState<any>(false);
    const [totalProduct, setTotalProducts] = useState<any>();
    const [productData, setProductData] = useState<any>("");
    const [searchingData, setSearchingData] = useState<any>()
    const [searchingKeywords, setSearchingKeywords] = useState<any>()
    const [status, setStatus] = useState<any>()
    const [isLoading, setIsLoading] = useState(false)
    const [isOpen, setIsOpen] = useState(false);
    const navigation = [
        { name: "HOME", path: "/" },
        { name: "BODY-SCANS", path: "/body-scans" },
        { name: "SELF-TRAIN", path: "/self-train" },
        { name: "SHOP", path: "/shop" },
        { name: "APP", path: "/app" },
    ];
    const location = useLocation();
    const currentPath = location.pathname;
    const isAuthenticated: any = getUserData();
    const userProfile = [
        { name: "My Profile", path: "/user/my-profile" },
        { name: "Account settings", path: "/user/account-settings" },
        { name: "Plaid Transaction", path: "/user/plaid" },
        { name: "Orders & Tracking", path: "/user/order-tracking" },
        { name: "Wishlist", path: "/user/wishlist" },
        { name: "Expert classes", path: "/user/expert-classes" },
        { name: "Appointments", path: "/user/appointments" },
        { name: "Contact us", path: "/user/contact" },
        { name: "About us", path: "/user/about" },
        ...(isAuthenticated
            ? [{ name: "Sign Out", path: "/user/sign-out" }]
            : [{ name: "Sign In", path: "/login" }]
        ),
    ];

    const matchingMenu: any = navigation.find((item) => item.path === currentPath);
    useEffect(() => {
        if (matchingMenu) {
            setSelectedMenuName(matchingMenu?.name);
        } else {
            setSelectedMenuName('');
        }
    }, [location.pathname]);

    const onItemClick = (name: any) => {
        setSelectedMenuName(name);
        Localstorage(CONSTANT.SET, CONSTANT.SELECTED_MENU, name);
    };

    const UserName = Localstorage(CONSTANT.GET, CONSTANT.USER_NAME);

    useEffect(() => {
        const userData: any = getUserData();
        setUserData(userData);
        navigation.forEach(({ name, path }) => {
            if (currentPath.includes(path)) {
                setSelectedMenuName(name);
                Localstorage(CONSTANT.SET, CONSTANT.SELECTED_MENU, name);

            }
            else {
                setSelectedMenuName(matchingMenu?.name);
            }
        });



        // if(userData?.userId){
        //   let obj = {
        //     userId: userData?.userId
        //   }
        //   getCartData(obj)
        // }
        // let totalQuantity = cartProductsStore.reduce((total: number, product: any) => {
        //         return total + product.quantity;
        //       }, 0);
        //       console.log("totalQuantity",totalQuantity)
        // setTotalProducts(totalQuantity);
    }, []);

    const getCartData = async (id: any) => {
        // let response = await CartService.getCartByUserId(id).then((data: any) => {
        //   if (data && data.status === CONSTANT.SUCCESS) {
        //     let totalQuantity = data.data.cart.reduce((total: number, product: any) => {
        //       return total + product.quantity;
        //     }, 0);
        //     setTotalProducts(totalQuantity);
        //   }
        // });
    };

    useEffect(() => {
        if (searchToggle) {
            document.body.classList.add("overflow-hidden");
        } else {
            document.body.classList.remove("overflow-hidden");
        }
    }, [searchToggle]);

    const onSignOut = () => {
        dispatch(setCartProducts([]));
        Localstorage(CONSTANT.REMOVE, CONSTANT.USER_INFO);
        Localstorage(CONSTANT.REMOVE, CONSTANT.SELECTED_MENU);
        Localstorage(CONSTANT.REMOVE, CONSTANT.USER_NAME);
        Localstorage(CONSTANT.REMOVE, CONSTANT.USER_AGE)
        Localstorage(CONSTANT.REMOVE, CONSTANT.DELIVERY_CHARGE);
        Localstorage(CONSTANT.REMOVE, CONSTANT.SELECTED_CARD_INDEX);
        Localstorage(CONSTANT.REMOVE, CONSTANT.CUSTOMER_ID);
        window.location.href = "/shop";
    };

    const onCartClick = () => {
        navigate("/shop/cart");
        setSelectedMenuName('');
    };

    useEffect(() => {
        const timer = setTimeout(() => {
            searchingProduct();
        }, 1000);
        return () => {
            clearTimeout(timer);
        };
    }, [productData])


    const searchingProduct = async () => {
        let obj = {
            search: productData && typeof productData === 'string' ? productData.trim() : productData
        }
        try {
            if (
                typeof productData === 'string' ? productData.trim() : productData !== "" &&
                    (!Array.isArray(productData) || productData.length > 0)
            ) {

                setIsLoading(true)
                const data = await ProductService.getSearchingProduct(obj);
                setTimeout(() => {

                    setIsLoading(false)
                }, 1000)
                setStatus(data.status);
                setSearchingData(data.data.products);
                setSearchingKeywords(data.data.suggestedKeywords);
            }
            else {
                setProductData("")
                setSearchingData([])
                setSearchingKeywords([])
            }
        } catch (error) {
            console.error("Error fetching data:", error);
            setIsLoading(false)
        }
    };

    const onNavigate = (id: any) => {
        window.location.href = `/shop/productdetail/${encryptData(id)}`
        setTimeout(() => {
            setProductData('')
        }, 1000)
    };

    const redirectPage = (name: any) => {
        window.location.href = `/searchresult/${name}`
        setTimeout(() => {
            setProductData('')
        }, 1000)
    }


    return (
        <>
            <Disclosure
                as="nav"
                className={`${isGreen ? "bg-capeCod" : "bg-nero"
                    } sm:sticky top-0 z-[999]`}
            >
                {({ open }) => (
                    <>
                        <div className="mx-auto">
                            <div className="relative flex h-[68px] items-center justify-between 2xl:px-40 md:px-12 c-sm:pl-12 xs:pl-12 s:pl-12 c-sm:pr-4 xs:pr-4 s:pr-4">
                                {/* pages dropdown */}
                                <div className="flex items-center">
                                    <Menu
                                        as="div"
                                        className="absolute left-0 ml-3 c-hidden1 hidden md:block"
                                    >
                                        <div>
                                            <Menu.Button className="flex bg-gray-800 bg-transparent text-sm focus:outline-none">
                                                <div className="lines">
                                                    <p className="bar-line-1"></p>
                                                    <p className="bar-line-2"></p>
                                                </div>
                                            </Menu.Button>
                                        </div>
                                        <Transition
                                            as={Fragment}
                                            enter="transition ease-out duration-100"
                                            enterFrom="opacity-0 scale-95 h-[120px] fixed w-[247px]"
                                            enterTo="opacity-100 scale-100 h-[120px] fixed w-[247px]"
                                            leave="transition ease-in duration-75"
                                            leaveFrom="opacity-100 scale-100 h-[120px] fixed w-[247px]"
                                            leaveTo="opacity-0 scale-95 h-[120px] fixed w-[247px]"
                                        >
                                            <Menu.Items className={`absolute left-[-9px] mt-7 h-[120px] w-[247px] origin-top-right divide-y divide-gray-100 rounded-md border-silverChalice border-2 ${isGreen ? "bg-capeCod" : "bg-nero"
                                                } shadow-lg ring-1 ring-black/5 focus:outline-none`}>
                                                <div className="px-1">
                                                    {isAuthenticated ?
                                                        <>
                                                            <Menu.Item>
                                                                {({ active }) => (
                                                                    <a
                                                                        className={classNames(
                                                                            active ? "bg-gray-100 cursor-pointer rounded-md" : "text-white",
                                                                            "block px-4  my-2 py-2 text-sm text-gray-700"
                                                                        )}
                                                                        onClick={() => { navigate("/user/my-profile") }}
                                                                    >
                                                                        <AccountBoxIcon
                                                                            className="mr-4 h-5 w-5"
                                                                            aria-hidden="true"
                                                                        />
                                                                        Profile
                                                                    </a>
                                                                )}
                                                            </Menu.Item>
                                                            <hr />
                                                            <Menu.Item>
                                                                {({ active }) => (
                                                                    <a
                                                                        onClick={(e: any) => {
                                                                            e.preventDefault();
                                                                        }}
                                                                        className={classNames(
                                                                            active ? "bg-gray-100 cursor-pointer rounded-md my-2" : "text-white",
                                                                            "block px-4  my-2 py-2 text-sm text-gray-700"
                                                                        )}
                                                                    >
                                                                        <ArrowBackIcon
                                                                            className="mr-4 h-5 w-5"
                                                                            aria-hidden="true"
                                                                        />
                                                                        <AlertDialog isBgGreen={isGreen} />
                                                                    </a>
                                                                )}
                                                            </Menu.Item>
                                                        </> : <Menu.Item>
                                                            {({ active }) => (
                                                                <a
                                                                    className={classNames(
                                                                        active ? "bg-gray-100 cursor-pointer rounded-md my-2" : "text-white",
                                                                        "block px-4  my-2 py-2 text-sm text-gray-700"
                                                                    )}
                                                                    onClick={() => navigate('/login')}
                                                                >
                                                                    <ArrowForwardIcon
                                                                        className="mr-4 h-5 w-5"
                                                                        aria-hidden="true"
                                                                    />
                                                                    Login
                                                                </a>
                                                            )}
                                                        </Menu.Item>
                                                    }
                                                </div>
                                            </Menu.Items>
                                        </Transition>
                                    </Menu>
                                    <div className="absolute inset-y-0 left-0 flex items-center md:hidden xs:mb-[10px]">
                                        {/* Mobile menu button*/}
                                        <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset">
                                            <span className="sr-only">Open main menu</span>
                                            {open ? (
                                                <XMarkIcon
                                                    className="block h-6 w-6"
                                                    aria-hidden="true"
                                                />
                                            ) : (
                                                <div className="lines" aria-hidden="true">
                                                    <p className="bar-line-1"></p>
                                                    <p className="bar-line-2"></p>
                                                </div>
                                            )}
                                        </Disclosure.Button>
                                    </div>
                                    <div className="xs:mb-[5px] lg:min-w-[200px] lg:max-w-[200px] max-w-[130px]">
                                        <a
                                            className="text-marigold text-xs 2xl:text-xl xl:text-xl lg:text-base md:text-xs sm:text-[18px] xs:text-[18px]"
                                            href="/"
                                        >
                                            <img src={Logo} alt="greenlab" />
                                        </a>
                                    </div>
                                </div>
                                <div className="flex items-center lg:w-full justify-between 2xl:pl-48 xl:pl-36 lg:pl-28 lg:gap-0 gap-3">
                                    {navigation.map((item: any, index: number) => (
                                        <Link
                                            key={index}
                                            to={item.path}
                                            className={classNames(
                                                selectedMenuName == item.name
                                                    ? "text-white underline underline-offset-8"
                                                    : "text-silverChalice hover:text-white",
                                                "xl:px-3 xl:py-2 rounded-md lg:text-sm text-[12px] font-medium lg:px-2 lg:py-1 md:px-1 md:block hidden heavy-font-heading"
                                            )}
                                            aria-current={
                                                selectedMenuName == item.name ? "page" : undefined
                                            }
                                            onClick={() => onItemClick(item.name)}
                                        >
                                            {item.name}
                                        </Link>
                                    ))}
                                    <MagnifyingGlassIcon
                                        className="h-8 w-6 cursor-pointer text-silverChalice hover:text-white"
                                        aria-hidden="true"
                                        onClick={() => {
                                            window.scrollTo(0, 0);
                                            setSearchToggle(true);
                                        }}
                                    />
                                    {/* Profile dropdown */}
                                    {userData && (
                                        <Menu
                                            as="div"
                                            className="relative sm:hidden md:block c-hidden1 xs:hidden s:hidden"
                                        >
                                            <div>
                                                <Menu.Button onClick={() => {
                                                    setIsOpen((prevIsOpen) => !prevIsOpen);
                                                }} className="flex items-center bg-gray-800 bg-transparent text-sm focus:outline-none focus:ring-2">
                                                    <span className="sr-only">Open user menu</span>
                                                    <img
                                                        className="lg:h-8 h-6 lg:w-8 w-6 rounded-full"
                                                        src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                                                        alt=""
                                                    />
                                                    <a className="text-sm focus:outline-none text-gray-300 hover:text-white lg:px-3 px-2 py-1 font-medium">
                                                        {username ? username : UserName ? UserName : userData?.firstName}
                                                    </a>
                                                    {isOpen ? <ChevronDownIcon
                                                        className="h-6 w-4 text-white"
                                                        aria-hidden="true"
                                                    /> : <ChevronUpIcon
                                                        className="h-6 w-4  text-white"
                                                        aria-hidden="true"
                                                    />}
                                                </Menu.Button>
                                            </div>
                                            <Transition
                                                as={Fragment}
                                                enter="transition ease-out duration-100"
                                                enterFrom="transform opacity-0 scale-95"
                                                // enterTo="transform opacity-100 scale-100"
                                                leave="transition ease-in duration-75"
                                                leaveFrom="transform opacity-100 scale-100"
                                                leaveTo="transform opacity-0 scale-95"
                                            >
                                                <div className="urvsh absolute ml-48">
                                                    <Menu.Items className="absolute border-silverChalice border right-0 mt-2 w-48 origin-top-right bg-capeCod py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                        {userProfile.map((item: any, ind: number) => (
                                                            <Menu.Item key={ind}>
                                                                {({ active }) => (
                                                                    <button
                                                                        className={classNames(
                                                                            active
                                                                                ? "text-white hover:bg-[#424B47]"
                                                                                : "",
                                                                            "block px-4 py-2 text-sm text-silverChalice w-full text-left"
                                                                        )}
                                                                        onClick={() => {
                                                                            navigate(item.path);
                                                                            setSelectedMenuName('');
                                                                        }}
                                                                    >
                                                                        {item.name}
                                                                    </button>
                                                                )}
                                                            </Menu.Item>
                                                        ))}
                                                    </Menu.Items>
                                                </div>
                                            </Transition>
                                        </Menu>
                                    )}
                                    <button
                                        type="button"
                                        className="rounded-full text-gray-400 hover:text-white focus:outline-none sm:hidden md:block c-hidden1 xs:hidden"
                                    >
                                        <span className="sr-only">View notifications</span>
                                        <BellIcon className="h-6 w-6" aria-hidden="true" />
                                    </button>
                                    <button
                                        type="button"
                                        className="rounded-full text-gray-400 focus:outline-none"
                                    >
                                        <span className="sr-only">View cart</span>
                                        {/* <ShoppingCartIcon className="h-6 w-6" aria-hidden="true"  /> */}
                                        <div className="relative">
                                            <ShoppingCartIcon
                                                className={`h-6 w-6 hover:text-white ${currentPath === "/shop/cart" ? "text-white" : ""
                                                    }`}
                                                aria-hidden="true"
                                                onClick={() => onCartClick()}
                                            />
                                            <span
                                                className="absolute bg-[#b79528] top-[-6px] right-[-5px] inline-flex items-center justify-center px-[5px] py-[2px] text-xs font-bold leading-none text-white rounded-full"
                                                onClick={() => onCartClick()}
                                            >
                                                {totalProductCounts || "0"}
                                            </span>
                                        </div>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <Disclosure.Panel
                            className={`${open
                                ? "left-0 w-full opacity-100"
                                : "left-full w-0 overflow-hidden opacity-0"
                                } xs:block sm:block bg-[#36403C] absolute top-0 z-[999] duration-500`}
                        >
                            <div className="px-2 pt-2 pb-3 min-h-screen">
                                <div className="flex gap-4 items-center px-3 pb-6 pt-8">
                                    <Disclosure.Button>
                                        <ArrowLeftIcon className="w-6 h-6 text-[#A8A8A8]" />
                                    </Disclosure.Button>
                                    <h4 className="text-marigold text-xs sm:text-[18px] xs:text-[18px]">
                                        GREEN LAB
                                    </h4>
                                </div>
                                {[
                                    ...navigation,
                                    { name: "My Profile", path: "/user/my-profile" },
                                    ...(isAuthenticated
                                        ? [{ name: "Sign Out", path: "/user/sign-out" }]
                                        : [{ name: "Sign In", path: "/login" }]
                                    ),
                                ].map((item) => (
                                    <Disclosure.Button
                                        key={item.name}
                                        className={classNames(
                                            selectedMenuName === item.name
                                                ? "text-white underline-offset-8"
                                                : "text-gray-300 hover:text-white",
                                            "block px-3 py-2 rounded-md text-[12px] font-medium w-full text-start mb-3"
                                        )}
                                        aria-current={
                                            selectedMenuName == item.name ? "page" : undefined
                                        }
                                        onClick={() => {
                                            onItemClick(item.name);
                                            navigate(item.path);
                                        }}
                                    >
                                        {item.name}
                                    </Disclosure.Button>
                                ))}
                            </div>
                        </Disclosure.Panel>
                    </>
                )
                }
            </Disclosure >
            <div
                className={`bg-[#36403C] absolute top-0 z-[999] duration-500 max-h-screen ${searchToggle
                    ? "left-0 w-full opacity-100 overflow-auto"
                    : "left-full w-0 overflow-hidden opacity-0 "
                    }`}
            >
                <div className="px-5 pt-5 flex flex-col justify-between min-h-screen">
                    <div>
                        <div className="flex justify-between">
                            <button
                                onClick={() => { setSearchToggle(false); setProductData("") }}
                                className="text-white text-[22px]"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth={1.5}
                                    stroke="currentColor"
                                    className="w-6 h-6"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        d="M6 18L18 6M6 6l12 12"
                                    />
                                </svg>
                            </button>
                            <div className="sm:w-2/6 w-9/12 relative">
                                <button className="absolute left-2 top-2 ">
                                    <svg
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth="1.5"
                                        viewBox="0 0 24 24"
                                        className="w-6 h-6 text-[#A8A8A8]"
                                        xmlns="http://www.w3.org/2000/svg"
                                        aria-hidden="true"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                                        ></path>
                                    </svg>
                                </button>
                                <input
                                    className="outline-none w-full py-2 pl-12 pr-4"
                                    type="search"
                                    placeholder="Search in Greenlab"
                                    value={productData}
                                    onChange={(event: any) => setProductData(event.target.value)}
                                />
                            </div>
                            <span></span>

                        </div>



                        {productData && (searchingData || searchingKeywords) ? (
                            status !== CONSTANT.FAIL ? (
                                <div className="w-auto max-w-[800px] bg-greenFooter sm:p-5 p-2 2xl:ml-[30%] xl: && lg:ml-[21%] sm:ml-[9%] c-sm:ml-[14%] xs:ml-[7px] mt-[4px]">
                                    {(productData && isLoading) ? (
                                        <>
                                            <div className="text-center">
                                                <BeatLoader />
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            {searchingData && searchingData.map((ele: any) => {
                                                return (
                                                    <>
                                                        <div className="flex sm:gap-5 gap-2">
                                                            <div>
                                                                <img
                                                                    className="w-[80px] h-[100px] cursor-pointer s:w-[145px]"
                                                                    src={ele && ele.skuIds && ele.skuIds[0] && ele.skuIds[0].productImage[0]}
                                                                    alt=""
                                                                    onClick={() => onNavigate(ele?._id)}
                                                                />
                                                            </div>
                                                            <div className="bg-white w-auto max-w-[200px] p-2 h-auto cursor-pointer" onClick={() => onNavigate(ele?._id)}>
                                                                <ul>
                                                                    <li className="sm:text-lg font-bold">
                                                                        {ele.productName}
                                                                    </li>
                                                                    <li className="sm:text-sm text-xs font-bold">
                                                                        {ele.productType}
                                                                    </li>

                                                                    <li className="sm:text-sm text-xs">
                                                                        {ele.productTitle}
                                                                    </li>
                                                                </ul>

                                                            </div>

                                                            <div className="sm:text-sm text-xs ml-auto top-10 relative" >
                                                                {ele.productFormat}
                                                            </div>
                                                        </div>
                                                        <div className="mt-4 text-black font-bold">
                                                            <hr className="h-0.5 my-5 border-0 bg-black" />
                                                        </div>
                                                    </>

                                                )
                                            })}

                                            {searchingKeywords && searchingKeywords.map((keyword: any) => (
                                                <div key={keyword.name} className="flex items-center sm:gap-6 gap-3 py-2">
                                                    <div className="bg-white  p-2 cursor-pointer">
                                                        <svg
                                                            fill="none"
                                                            stroke="currentColor"
                                                            strokeWidth="1.5"
                                                            viewBox="0 0 24 24"
                                                            className="w-6 h-6 text-[#A8A8A8]"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            aria-hidden="true"
                                                        >
                                                            <path
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                                d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                                                            ></path>
                                                        </svg>
                                                    </div>
                                                    <div className="relative top-1 cursor-pointer sm:text-lg text-sm" onClick={() => redirectPage(keyword.name)}>
                                                        {keyword.name}
                                                    </div>
                                                </div>
                                            ))}
                                        </>
                                    )}
                                </div>
                            ) : (
                                <div className="w-auto max-w-[800px] bg-greenFooter p-5 2xl:ml-[30%] xl: && lg:ml-[21%] sm:ml-[9%] text-center font-bold c-sm:ml-[14%] xs:ml-[7px] mt-[4px]">
                                    No data found
                                </div>
                            )
                        ) : null}


                        <div className="pt-28 text-center text-[#A8A8A8]">
                            <p className="text-[18px] mb-3">Search for</p>
                            <ul className="flex justify-center text-[14px] sm:gap-12 gap-4">
                                <li>PRODUCTS</li>
                                <li>CLASSES</li>
                                <li>EXPERTS</li>
                                <li>TEST</li>
                            </ul>
                        </div>
                        <div className="pt-28 text-center text-white">
                            <p className="text-[18px] mb-2 text-xs 2xl:text-xl xl:text-xl lg:text-base md:text-xs sm:text-[18px] xs:text-[18px]">
                                GREEN LAB
                            </p>
                            <p className="tracking-[4px] text-[10px]">
                                CHEMISTRY THAT UNDERSTANDS YOUR BIOLOGY
                            </p>
                        </div>
                    </div>
                    <div className="mt-[14px] pb-[16px]">
                        <img
                            src={arrow}
                            className="mx-auto mb-[-25px] sm:max-w-[400px] max-w-[200px] w-full"
                        />
                        <p className="text-center text-white text-[14px]">
                            Copyright 2022. All rights reserved
                        </p>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Navbar;

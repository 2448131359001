import React, { useEffect, useState } from "react";
import { ChevronDownIcon } from "@heroicons/react/24/outline";

import CategoryNav from "../../components/sub-nav/SubNav";
import HowItWorks from "../../components/body-scans/how-it-works/HowItWorks";
import ProductCardDetail from "../../components/product/product-card-detail/ProductCardDetails";
import FaqData from "../../components/product/faq";

const BodyScansPackage = () => {
  const [skuId, setSkuId] = useState<String>("");
  const [skuData, setSkuData] = useState<any[]>([]);
  const [productData, setProductData] = useState<any>({
    _id: "6451fae9e6fb18bcdab15157",
    productName: "Druffle Bag",
    productTitle: "Offers pain relief & healthy joints",
    productType: "Flat Front",
    isVeg: "yes",
    productImage: [
      "https://images.unsplash.com/photo-1538805060514-97d9cc17730c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Nnx8Z3ltJTIwY2xvdGhpbmd8ZW58MHx8MHx8&w=1000&q=80",
    ],
    dosageAssistance: {
      _id: "644659526b5ba980bf34ccb9",
      dosageAssistanceName: "Solid Dosage",
      dosageAssistancePrice: "250",
      dosageAssistanceLongDescription:
        "<div><p>+ Expert review of your supplement combinations</p><p>+ Personalised dosage reviews</p></div>",
    },
    productAbout:
      "- An ideal pick for a formal wardrobe - This pair of slim tapered trousers are designed with a solid surface - A breezy edit for a weekend",
    productCategory: "6446591d6b5ba980bf34ccad",
    productSubCategory: "6451f617e6fb18bcdab1506a",
    productFormat: "Male",
    marketingId: {
      _id: "6451fa6ee6fb18bcdab1513f",
      marketingName: "Druffle Bag",
      marketingImage:
        "https://media.istockphoto.com/id/1346787202/photo/sporty-woman-in-a-white-sweatshirt-walking-in-city.jpg?s=612x612&w=0&k=20&c=zDebkQ7Qv72az-B7MzRH13ZEgpbYavW9TGmMnuMRfg8=",
      marketingTitle: "Increase Stamina & endurance",
      marketingIntro:
        "Product - Caffeine Tablets | Formula - Tablets | Helps in - Pre Workout + Alertness",
      marketingDescription:
        "<p> An ideal pick for a formal wardrobe, this pair of slim tapered trousers are designed with a solid surface, insert and welt pockets.</p><p> Now you can start your day with these premium caffeine pills that have zero added sugar, the same benefits as coffee and minus the burden of those extra calories. Support your mental & physical performance needs without compromising on your dietary goals. Ward off daytime sleepiness, glide through your daily workout, prep for your competitive exam and keep the hustle on with Green Labs energy booster caffeine tablets.</p>",
      marketingHelps:
        "<div><p>- Boosts mental and physical performance</p><p> - Improved focus and alertness</p><p> - Faster Reaction Time</p><p> - Reduced fatigue and increased endurance</p><p> - Elevated mood</p>",
    },
    faqIds: [
      {
        _id: "64465aa16b5ba980bf34ccd7",
        faqName: "Coffee vs. caffeine tablets: What to choose?",
        faqLongDescription:
          "Long Some placeholder content for the collapse component. This panel is hidden by default but revealed when the user activates the relevant trigger.Long ",
      },
      {
        _id: "6451f85ee6fb18bcdab15098",
        faqName: "Does Citrulline Malate have any side effects?",
        faqLongDescription:
          "Long Some placeholder content for the collapse component. This panel is hidden by default but revealed when the user activates the relevant trigger.Long ",
      },
      {
        _id: "6451f888e6fb18bcdab1509b",
        faqName: "Which is better: arginine or citrulline?",
        faqLongDescription:
          "Long Some placeholder content for the collapse component. This panel is hidden by default but revealed when the user activates the relevant trigger.Long ",
      },
      {
        _id: "6451f8a4e6fb18bcdab1509e",
        faqName: "Should I choose citrulline or creatine?",
        faqLongDescription:
          "Long Some placeholder content for the collapse component. This panel is hidden by default but revealed when the user activates the relevant trigger.Long ",
      },
    ],
    benefitIds: [
      {
        _id: "64465aeb6b5ba980bf34cceb",
        benefitName: "Weight",
        benefitImage:
          "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcREn_ZmfDsO_0j0-g4BwotVGbaIXy5SGTU3EA&usqp=CAU",
        benefitLongDescription:
          "An ideal pick for a formal wardrobe, this pair of slim tapered trousers are designed with a solid surface, insert and welt pockets.",
      },
      {
        _id: "6451f927e6fb18bcdab150e7",
        benefitName: "Energy",
        benefitImage:
          "https://icon2.cleanpng.com/20180418/xfq/kisspng-computer-icons-electricity-power-symbol-electrical-5ad7bc1a438c31.0339173015240878342767.jpg",
        benefitLongDescription:
          "An ideal pick for a formal wardrobe, this pair of slim tapered trousers are designed with a solid surface, insert and welt pockets.",
      },
    ],
    keywordIds: ["64465b206b5ba980bf34cd04"],
    skuIds: [
      {
        _id: "6451f705e6fb18bcdab15076",
        productVariant: "Male",
        productWeight: "45mg/0.8oz",
        productQty: 15,
        productImage: [
          "https://rukminim1.flixcart.com/image/416/416/xif0q/sport-bag/o/l/a/unisex-polyester-sports-gym-duffle-bag-for-gym-pack-of-1-original-imagnkzggkczh8uy.jpeg?q=70",
          "https://rukminim1.flixcart.com/image/416/416/kmax8y80/sport-bag/r/d/p/standard-camouflage-pattern-multi-purpose-gym-bag-duffle-bag-kit-original-imagf8m7s8mu2g3q.jpeg?q=70",
          "https://reviewed-com-res.cloudinary.com/image/fetch/s--LrPNOWnh--/b_white,c_limit,cs_srgb,f_auto,fl_progressive.strip_profile,g_center,q_auto,w_972/https://reviewed-production.s3.amazonaws.com/1581540726000/herschel2.jpg",
          "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRKtxFxWq2SXKvTPXf7R3pnl6SSLlBKI5yVBT_g802HXbRdxswpsBKwWqZ6dfcnlzNgJCU&usqp=CAU",
        ],
        productServingSize: "2 veg. capsule 0.33g",
        productServingJar: 50,
        productUsage:
          "Take one capsule as pre-workout supplement or as suggested by healthcare professional.",
        productAdvice:
          "Not a exceed recommended daily usage. if you are pregnent or lactating or prone to any food allergy or under any medical condition. Consult your hralthcare professional before using the product. Keep out of reach of childern",
        productSubscribeAmount: 75,
        productSingleAmount: 550,
        isDefault: "No",
      },
      {
        _id: "6451f803e6fb18bcdab15089",
        productVariant: "Female",
        productWeight: "55mg/0.8oz",
        productQty: 22,
        productImage: [
          "https://assets.myntassets.com/dpr_1.5,q_60,w_400,c_limit,fl_progressive/assets/images/13512466/2023/1/25/dfdc6374-c668-4e50-a364-d7757cdf1cc21674639423330GearUnisexBlackGreyColourblockedCross-TrainingDuffelBag1.jpg",
          "https://media.istockphoto.com/id/453934121/photo/female-athlete-with-gym-bag-looking-away-smiling.jpg?s=612x612&w=0&k=20&c=vQH7ibHPRhxDi4DBHcU0eEMcKptho2Gd1Rs63RcaAwY=",
          "https://media.istockphoto.com/id/1346787202/photo/sporty-woman-in-a-white-sweatshirt-walking-in-city.jpg?s=612x612&w=0&k=20&c=zDebkQ7Qv72az-B7MzRH13ZEgpbYavW9TGmMnuMRfg8=",
          "https://media.istockphoto.com/id/1159579719/photo/girl-taking-clothes-out-of-gym-bag.jpg?s=612x612&w=0&k=20&c=t1ycA52GL0NoLzErMKEjZbRlUOmrMvM0mNiGLOF1PL4=",
        ],
        productServingSize: "2 veg. capsule 0.33g",
        productServingJar: 50,
        productUsage:
          "Take one capsule as pre-workout supplement or as suggested by healthcare professional.",
        productAdvice:
          "Not a exceed recommended daily usage. if you are pregnent or lactating or prone to any food allergy or under any medical condition. Consult your hralthcare professional before using the product. Keep out of reach of childern",
        productSubscribeAmount: 65,
        productSingleAmount: 850,
        isDefault: "Yes",
      },
    ],
    ingredientIds: [
      {
        _id: "6451f9bce6fb18bcdab15106",
        name: "Vitamin C",
      },
    ],
    productInfoImage:
      "https://media.istockphoto.com/id/1159579719/photo/girl-taking-clothes-out-of-gym-bag.jpg?s=612x612&w=0&k=20&c=t1ycA52GL0NoLzErMKEjZbRlUOmrMvM0mNiGLOF1PL4=",
    productContentName1: "Tapered Formal Trousers",
    productContentText1: "Flat Front",
    productContentValue1: "350mg",
    productContentValueText1: "per serve",
    productNutritionInfo:
      "https://imgnew.outlookindia.com/uploadimage/library/free_files/jpg/Screen_2023_01_12_043007.jpg",
    productNutritionImage:
      "https://media.gq.com/photos/62d6d4414710d36708119125/master/w_2000,h_1333,c_limit/protein13-projym.jpg",
    productViewCount: 42,
    isDeleted: false,
    createdAt: "2023-05-03T06:10:49.757Z",
    updatedAt: "2023-05-10T06:47:06.008Z",
    productHowItWorkIds: [
      {
        _id: "645a170760c66f53f4e50883",
        productHow:
          "- Morning or afternoon is the best time to consume these pills, as taking later in the evening or night can hamper sleep. - One to two tablets as per requirement or as directed by your healthcare professional",
        productHelps:
          "Radiance is a very powerful natural stimulant. It absorbs fast in the body and works by stimulating the central nervous system, heart, muscles and the centers that control blood pressure. Moderate use of caffeine is considered to be helpful but overconsumption can have adverse effects. The carefully curated formula of Green Labs Caffeine Tablets ensures that you consume just the right amount. A lot of studies and research have proven that caffeine enhances athletic performance. It is known to improve focus, concentration and facilitate better coordination. Increased endurance in high intensity exercises is another benefit that can be derived through caffeine. Improvement in short-term memory can also be witnessed when desirable amounts of caffeine are ingested and hence it can prove to be extremely useful for people preparing for competitive exams. Caffeine is also known to alleviate migraines and headaches mainly because it increases our pain endurance. Regular consumption of caffeine also improves metabolism & appetite. This helps in weight management as the fat gets burned faster with increased energy production. Caffeine invigorates the central nervous system and brain which help us in cognitive functions in an optimum way. It enhances mood, making you feel more relaxed and cheerful.",
        productHowImage:
          "https://imgnew.outlookindia.com/uploadimage/library/free_files/jpg/Screen_2023_01_12_043007.jpg",
      },
    ],
  });
  const [reviewAverage, setReviewAverage] = useState<number>();
  const [isSkeleton, setIsSkeleton] = useState<boolean>(false);
  const [productSkuImg, setProductSkuImg] = useState<any[]>([
    "https://assets.myntassets.com/dpr_1.5,q_60,w_400,c_limit,fl_progressive/assets/images/13512466/2023/1/25/dfdc6374-c668-4e50-a364-d7757cdf1cc21674639423330GearUnisexBlackGreyColourblockedCross-TrainingDuffelBag1.jpg",
    "https://media.istockphoto.com/id/453934121/photo/female-athlete-with-gym-bag-looking-away-smiling.jpg?s=612x612&w=0&k=20&c=vQH7ibHPRhxDi4DBHcU0eEMcKptho2Gd1Rs63RcaAwY=",
    "https://media.istockphoto.com/id/1346787202/photo/sporty-woman-in-a-white-sweatshirt-walking-in-city.jpg?s=612x612&w=0&k=20&c=zDebkQ7Qv72az-B7MzRH13ZEgpbYavW9TGmMnuMRfg8=",
    "https://media.istockphoto.com/id/1159579719/photo/girl-taking-clothes-out-of-gym-bag.jpg?s=612x612&w=0&k=20&c=t1ycA52GL0NoLzErMKEjZbRlUOmrMvM0mNiGLOF1PL4=",
  ]);
  const [selectedSkuItemData, setSelectedSkuItemData] = useState<any>({
    _id: "6451f803e6fb18bcdab15089",
    productVariant: "Female",
    productWeight: "55mg/0.8oz",
    productQty: 22,
    productImage: [
      "https://assets.myntassets.com/dpr_1.5,q_60,w_400,c_limit,fl_progressive/assets/images/13512466/2023/1/25/dfdc6374-c668-4e50-a364-d7757cdf1cc21674639423330GearUnisexBlackGreyColourblockedCross-TrainingDuffelBag1.jpg",
      "https://media.istockphoto.com/id/453934121/photo/female-athlete-with-gym-bag-looking-away-smiling.jpg?s=612x612&w=0&k=20&c=vQH7ibHPRhxDi4DBHcU0eEMcKptho2Gd1Rs63RcaAwY=",
      "https://media.istockphoto.com/id/1346787202/photo/sporty-woman-in-a-white-sweatshirt-walking-in-city.jpg?s=612x612&w=0&k=20&c=zDebkQ7Qv72az-B7MzRH13ZEgpbYavW9TGmMnuMRfg8=",
      "https://media.istockphoto.com/id/1159579719/photo/girl-taking-clothes-out-of-gym-bag.jpg?s=612x612&w=0&k=20&c=t1ycA52GL0NoLzErMKEjZbRlUOmrMvM0mNiGLOF1PL4=",
    ],
    productServingSize: "2 veg. capsule 0.33g",
    productServingJar: 50,
    productUsage:
      "Take one capsule as pre-workout supplement or as suggested by healthcare professional.",
    productAdvice:
      "Not a exceed recommended daily usage. if you are pregnent or lactating or prone to any food allergy or under any medical condition. Consult your hralthcare professional before using the product. Keep out of reach of childern",
    productSubscribeAmount: 65,
    productSingleAmount: 850,
    isDefault: "Yes",
  });
  const [dosageAssistanceData, setDosageAssistanceData] = useState<any>({});
  const menuList: any = [
    { _id: 1, name: "All Test Packages" },
    { _id: 2, name: "Build Custom Test Packages" },
  ];

  useEffect(() => {
    setTimeout(() => {
      setIsSkeleton(true);
    }, 3000);
  }, []);

  const scrollDownNextElement = (id: string) => {
    const reviewsSection: any = document.getElementById(id);
    reviewsSection.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div>
      <CategoryNav pageName="body-scans" subMenuList={menuList} />
      <div className={`${isSkeleton ? "bg-capeCod" : ""}`}>
        <ProductCardDetail
          isBodyScans={true}
          className="2xl:px-40 md:px-11 px-4 md:py-20 py-10"
          isSkeleton={isSkeleton}
          productData={productData}
          skuId={skuId}
          selectedSkuItemData={selectedSkuItemData}
          setSelectedSkuItemData={setSelectedSkuItemData}
          productSkuImg={productSkuImg}
          setProductSkuImg={setProductSkuImg}
          skuData={skuData}
          dosageAssistanceData={dosageAssistanceData}
          reviewAverage={reviewAverage}
        />
        {isSkeleton && (
          <div className="2xl:pt-[2rem] pt-[0rem] sm:pb-0 pb-8">
            <div className="text-align-webkit-center">
              <button
                className="text-silver xs:text-[14px] text-align-webkit-center font-light text-xl"
                onClick={() => scrollDownNextElement("knowMore")}
              >
                Know more about your product{" "}
                <p className="text-align-webkit-center">
                  <ChevronDownIcon className="sm:w-[11%] w-[7%] text-silver stroke-[3]" />
                </p>
              </button>
            </div>
          </div>
        )}
      </div>
      {isSkeleton && (
        <>
          <div
            className="bg-silverChalice 2xl:px-40 md:px-11 px-4 sm:pt-12 pt-8 sm:pb-20 pb-14"
            id="knowMore"
          >
            <h2 className="text-center sm:text-[32px] text-[16px] text-capeCod mb-[30px] font-medium">
              What’s covered in this blood test?
            </h2>
            <ul className="flex gap-4 flex-wrap">
              {[1, 2, 3, 4, 5, 6, 7, 8].map((item, index) => (
                <li
                  key={index}
                  className="lg:w-[calc(25%-12px)] md:w-[calc(33.33%-12px)] sm:w-[calc(50%-12px)] w-full bg-capeCod xl:px-5 px-4 xl:pt-5 pt-4 pb-12 relative"
                >
                  <h4 className="text-gray85 mb-3">
                    CHOLESTEROL (LIPID PROFILE)
                  </h4>
                  <p className="text-gray85 text-[12px]">
                    Lipids, including cholesterol, are essential for the
                    production of hormones and vitamin D. But high cholesterol
                    levels are linked to an increased risk of heart disease and
                    stroke.
                  </p>
                  <div className="opacity-20 absolute right-[20px] bottom-[6px] inline-block rotate-45 h-[76px] w-[40px] border-gray85 border-b-[14px] border-r-[14px]"></div>
                </li>
              ))}
            </ul>
          </div>
          <div className="bg-capeCod 2xl:px-40 md:px-11 px-4 sm:pt-12 pt-8 sm:pb-20 pb-14">
            <h2 className="text-center sm:text-[32px] text-[16px] text-white mb-[30px] font-medium">
              Collection method
            </h2>
            <ul className="flex gap-4 flex-wrap justify-center">
              <li className="flex gap-3 lg:w-[calc(25%-12px)] w-[calc(50%-12px)] sm:p-3 p-[5px] bg-silverChalice">
                <div className="bg-capeCod sm:w-[53px] w-[25px]"></div>
                <div>
                  <h4 className="mb-4 text-capeCod sm:text-[20px] text-[16px] font-medium">
                    Do it yourself
                  </h4>
                  <p className="text-capeCod sm:text-[14px] text-[10px]">
                    - Finger-prick test at home
                  </p>
                  <p className="text-capeCod sm:text-[14px] text-[10px]">
                    - No extra charge
                  </p>
                </div>
              </li>
              <li className="flex gap-3 lg:w-[calc(25%-12px)] w-[calc(50%-12px)] sm:p-3 p-[5px] bg-silverChalice">
                <div className="sm:w-[53px] w-[25px]"></div>
                <div>
                  <h4 className="mb-4 text-capeCod sm:text-[20px] text-[16px] font-medium">
                    Have a nurse do it
                  </h4>
                  <p className="text-capeCod sm:text-[14px] text-[10px]">
                    - Venous test at pharmacy
                  </p>
                  <p className="text-capeCod sm:text-[14px] text-[10px]">
                    - Extra £40 charge — pay on the day
                  </p>
                  <p className="text-capeCod sm:text-[14px] text-[10px]">
                    - Appointments in 1-2 weeks
                  </p>
                </div>
              </li>
            </ul>
          </div>
          <HowItWorks />
          <div className="bg-capeCod sm:pt-16 pt-[20px] sm:pb-36 pb-[30px]">
            <h2 className="2xl:px-40 md:px-11 px-4 text-center sm:text-[32px] text-[16px] text-white mb-[30px] font-medium">Want to know more?</h2>
            <FaqData
              isExpand={false}
              className="sm:px-0 px-4"
              faqData={[
                {
                  _id: "64465aa16b5ba980bf34ccd7",
                  faqName: "How does you subscription work?",
                  faqLongDescription:
                    "Your blood test arrives every 3 months. You can change your subscription and how often you’d like a test on your account dashboard. We’ll remind you that payment is due 3 days before your kit arrives. And you can pause, change, or cancel at any time.",
                },
                {
                  _id: "64465aa16b5ba980bf34ccd8",
                  faqName: "Data protection",
                  faqLongDescription:
                    "Your blood test arrives every 3 months. You can change your subscription and how often you’d like a test on your account dashboard. We’ll remind you that payment is due 3 days before your kit arrives. And you can pause, change, or cancel at any time.",
                },
                {
                  _id: "64465aa16b5ba980bf34ccd9",
                  faqName: "What’s included in your home testing kit?",
                  faqLongDescription:
                    "Your blood test arrives every 3 months. You can change your subscription and how often you’d like a test on your account dashboard. We’ll remind you that payment is due 3 days before your kit arrives. And you can pause, change, or cancel at any time.",
                },
                {
                  _id: "64465aa16b5ba980bf34ccd1",
                  faqName: "How to collect your sample at home",
                  faqLongDescription:
                    "Your blood test arrives every 3 months. You can change your subscription and how often you’d like a test on your account dashboard. We’ll remind you that payment is due 3 days before your kit arrives. And you can pause, change, or cancel at any time.",
                },
                {
                  _id: "64465aa16b5ba980bf34ccd2",
                  faqName: "Will a GP write your report?",
                  faqLongDescription:
                    "Your blood test arrives every 3 months. You can change your subscription and how often you’d like a test on your account dashboard. We’ll remind you that payment is due 3 days before your kit arrives. And you can pause, change, or cancel at any time.",
                },
                {
                  _id: "64465aa16b5ba980bf34ccd3",
                  faqName: "How long does it take to get your results?",
                  faqLongDescription:
                    "Your blood test arrives every 3 months. You can change your subscription and how often you’d like a test on your account dashboard. We’ll remind you that payment is due 3 days before your kit arrives. And you can pause, change, or cancel at any time.",
                },
                {
                  _id: "64465aa16b5ba980bf34ccd4",
                  faqName: "How do you know when your results are ready?",
                  faqLongDescription:
                    "Your blood test arrives every 3 months. You can change your subscription and how often you’d like a test on your account dashboard. We’ll remind you that payment is due 3 days before your kit arrives. And you can pause, change, or cancel at any time.",
                },
              ]}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default BodyScansPackage;

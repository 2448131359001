const GuideYoga = (props:any) => {
  const {courseData} = props;
  return (
    <div className='bg-darkBlack pt-8 px-4'>
        <h2 className='text-center text-[40px] md:text-[48px] xs:text-[36px] mb-3 font-bold text-white'>{courseData.courseTitle ? courseData.courseTitle : "N/A"}</h2>
        <p className='text-center text-[17px] text-silverChalice pb-4'>{courseData.courseSubtitle ? courseData.courseSubtitle: "N/A"} </p>
        <p className='text-center text-[13px]  text-mediumGray'>{courseData.courseExpertId && courseData.courseExpertId.trainerName ? `WITH ${courseData.courseExpertId.trainerName.toUpperCase(2)}` : "N/A"}</p>
        <div className='flex max-w-[600px] justify-between m-auto pt-6 pb-12 gap-4'>
            <div className='max-w-[250PX] w-6/12'>
                <img className='m-auto max-h-[75px]' src={courseData.courseBenefit1Icon ? courseData.courseBenefit1Icon: "N/A"} alt='' />
                <p className='text-center tracking-[5px] xs:tracking-[2px] s:tracking-[2px] x:text-[13px] font-bold text-white pt-8 pb-4'>{courseData.courseBenefit1Name ? courseData.courseBenefit1Name: "N/A"}</p>
                <p className='text-center text-silverChalice xxs:text-[14px] x:text-[12px]'>{courseData.courseBenefit1Description ? courseData.courseBenefit1Description: "N/A"} <br /></p>
            </div>
            <div className='max-w-[250PX] w-6/12'>
                <img className='m-auto max-h-[75px]' src={courseData.courseBenefit2Icon ? courseData.courseBenefit2Icon: "N/A"} alt='' />
                <p className='text-center tracking-[5px] xs:tracking-[2px] s:tracking-[2px] x:text-[13px] font-bold text-white pt-8 pb-4'>{courseData.courseBenefit2Name ? courseData.courseBenefit2Name : "N/A"}</p>
                <p className='text-center text-silverChalice xxs:text-[14px] x:text-[12px]'>{courseData.courseBenefit2Description ? courseData.courseBenefit2Description: "N/A"}</p>
            </div>
        </div>
    </div>
  )
}

export default GuideYoga
import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { Localstorage } from '../core/AuthService';
import { CONSTANT } from '../core/StaticConstant';
import { setCartProducts } from '../redux/reducer/cartReducer';
import { useDispatch } from 'react-redux';

const AlertDialog = ({isBgGreen}:any) => {
    const dispatch = useDispatch();
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const onSignOut = () => {
        dispatch(setCartProducts([]));
        Localstorage(CONSTANT.REMOVE, CONSTANT.USER_INFO);
        Localstorage(CONSTANT.REMOVE, CONSTANT.SELECTED_MENU);
        Localstorage(CONSTANT.REMOVE, CONSTANT.USER_NAME);
        Localstorage(CONSTANT.REMOVE, CONSTANT.USER_AGE)
        Localstorage(CONSTANT.REMOVE, CONSTANT.DELIVERY_CHARGE);
        Localstorage(CONSTANT.REMOVE, CONSTANT.SELECTED_CARD_INDEX);
        Localstorage(CONSTANT.REMOVE, CONSTANT.CUSTOMER_ID);
        window.location.href = "/shop";
    };

    return (
        <React.Fragment>
            <button onClick={handleClickOpen}>
                Logout
            </button>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title" className={`${isBgGreen ? "bg-capeCod" : "bg-nero"} text-gray-500 w-[500px] s:w-[250px] x:w-[340px] xs:w-[470px] xxs:w-[425px] text-center overflow-hidden`}>
                    <div className="text-center">
                        <HelpOutlineIcon
                            aria-hidden="true"
                            style={{ fontSize: "45px", color: "white" }}
                        />
                    </div>
                    {"Are you sure you want to logout?"}
                </DialogTitle>
                <DialogContent className={`${isBgGreen ? "bg-capeCod" : "bg-nero"} w-[500px] s:w-[250px] x:w-[340px] xs:w-[470px] xxs:w-[425px]`}>
                    <DialogContentText id="alert-dialog-description overflow-hidden">
                        <p className='text-gray-500 text-center'>
                            This will end your current session..
                        </p>
                    </DialogContentText>
                </DialogContent>
                <div className={`${isBgGreen ? "bg-capeCod" : "bg-nero"} text-center pb-4`}>
                    <button
                        data-modal-hide="popup-modal"
                        type="button"
                        className="text-white border-white bg-transparent border font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2"
                        onClick={() => onSignOut()}
                    >
                        Yes, Logout
                    </button>
                    <button
                        data-modal-hide="popup-modal"
                        type="button"
                        className="text-black bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5"
                        onClick={() => handleClose()}
                    >
                        Cancel
                    </button>
                </div>
            </Dialog>
        </React.Fragment>
    );
}

export default AlertDialog;
import UserChildren from "../../components/user-profile/UserChildren";
import Order from "../../components/order-tracking/OrderTracking";

const OrderTracking = () => {
  return (
    <UserChildren title="Order & Tracking">
      <div className="lg:flex gap-3">
        <Order />
      </div>
    </UserChildren>
  );
};

export default OrderTracking;

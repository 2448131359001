import React, { useEffect, useState } from 'react';
import { PlaidService } from '../../services/plaid.service';
import Loader from "../loader/Loader";
import { usePlaidLink } from "react-plaid-link";
import { Pagination } from 'antd';

function PlaidHistory({ publicToken, initialTransactions, getPlaidTransactionByUserId, selectedTimeRange, handleChangePage, currentPage, perPage, totalRecords }: any) {
    const [transactions, setTransactions] = useState<any[]>(initialTransactions);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setTransactions(initialTransactions);
    }, [initialTransactions]);

    useEffect(() => {
        async function fetchData() {
            try {
                setIsLoading(true);
                const requestBody = {
                    public_token: publicToken,
                };
                const response = await PlaidService.plaidTransaction(requestBody);
                let obj: any = {
                    limit: perPage,
                    lastDays: selectedTimeRange,
                    pageCount: currentPage,
                };
                getPlaidTransactionByUserId(obj);
                setIsLoading(false);
                setTransactions([...response?.data?.transactions]);
            } catch (error) {
                console.error('Error fetching data:', error);
                setIsLoading(false);
            }
        };
        if (publicToken) {
            fetchData();
        };
    }, [publicToken]);

    return (
        <>
            {isLoading && (
                <div className="absolute top-[50%] z-50 left-[50%] transform translate-x-[-50%] translate-y-[-50%]">
                    <Loader />
                </div>
            )}
            <>
                <ul>
                    <div className="overflow-x-auto overflow-y-hidden w-full">
                        <table className="table-fixed border-black border w-[-webkit-fill-available]">
                            <thead>
                                <tr className="bg-black">
                                    <th className="px-4 py-2 text-silverChalice heavy-font-heading font-medium s:text-xs">Account Id</th>
                                    <th className="px-4 py-2 text-silverChalice heavy-font-heading font-medium s:text-xs">Transaction Date</th>
                                    <th className="px-4 py-2 text-silverChalice heavy-font-heading font-medium s:text-xs">Total Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                                {transactions && transactions?.length > 0 ? (
                                    transactions?.map((transaction: any) => (
                                        <tr key={transaction?.plaidTransaction?.transaction_id}>
                                            <td className="border border-black px-4 py-2 text-silverChalice heavy-font-heading font-medium text-center">{transaction?.plaidTransaction?.account_id ? transaction?.plaidTransaction?.account_id : "N/A"}</td>
                                            <td className="border border-black px-4 py-2 text-silverChalice heavy-font-heading font-medium text-center">{transaction?.plaidTransaction?.authorized_date ? transaction?.plaidTransaction?.authorized_date : "N/A"}</td>
                                            <td className="border border-black px-4 py-2 text-silverChalice heavy-font-heading font-medium text-center">
                                                {transaction?.plaidTransaction?.amount && !isNaN(parseFloat(transaction?.plaidTransaction?.amount))
                                                    ? `£${parseFloat(transaction?.plaidTransaction?.amount)?.toFixed(2)}`
                                                    : "N/A"}
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan={3} className="border px-4 py-2 text-center">No transactions found.</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                    {transactions?.length !== undefined && (
                        <div className="flex justify-end text-white mt-3">
                            <div className="ml-auto">
                                <Pagination
                                    className="pagination"
                                    current={currentPage}
                                    pageSize={perPage}
                                    total={totalRecords}
                                    onChange={handleChangePage}
                                    showSizeChanger={false}
                                />
                            </div>
                        </div>
                    )}
                </ul>
            </>
        </>
    )
}

function Plaid() {
    const [linkToken, setLinkToken] = useState();
    const [publicToken, setPublicToken] = useState<any>();
    const [isLoading, setIsLoading] = useState(false);
    const [initialTransactions, setInitialTransactions] = useState<any[]>([]);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [perPage, setPerPage] = React.useState<number>(10);
    const [totalRecords, setTotalRecords] = useState(0);
    const [selectedTimeRange, setSelectedTimeRange] = useState<number>(10);

    useEffect(() => {
        async function fetch() {
            try {
                setIsLoading(true);
                const response = await PlaidService.createLinkToken();
                setIsLoading(false);
                setLinkToken(response?.data?.link_token);
            } catch {
                setIsLoading(false);
            }
        }
        fetch();
        let obj: any = {
            limit: perPage,
            pageCount: currentPage,
            lastDays: selectedTimeRange
        };
        getPlaidTransactionByUserId(obj);
    }, []);

    const { open, ready } = usePlaidLink({
        token: linkToken || null,
        onSuccess: (public_token, metadata) => {
            setPublicToken(public_token);
        },
    });

    const getPlaidTransactionByUserId = async (obj: any) => {
        try {
            setIsLoading(true);
            const response = await PlaidService.getPlaidTransactionByUserId(obj);
            setIsLoading(false);
            setInitialTransactions(response?.data?.transaction);
            setTotalRecords(response.data.totalRecords);
        } catch (error) {
            console.error('Error fetching initial transactions:', error);
            setIsLoading(false);
        }
    }

    const handleChangePage = (newPage: any) => {
        let obj = {
            limit: perPage,
            pageCount: newPage,
            lastDays: selectedTimeRange
        };
        getPlaidTransactionByUserId(obj);
        setCurrentPage(parseInt(newPage));
    };

    const handleChangeRowsPerPage = async (event: any) => {
        let obj = {
            limit: parseInt(event),
            pageCount: 1,
            lastDays: selectedTimeRange
        };
        await setCurrentPage(1);
        await setPerPage(parseInt(event));
        await getPlaidTransactionByUserId(obj);
    };

    return (
        <>
            {isLoading && (
                <div className="absolute top-[50%] z-50 left-[50%] transform translate-x-[-50%] translate-y-[-50%]">
                    <Loader />
                </div>
            )}
            <div className='flex justify-between items-center'>
                <button onClick={() => open()} disabled={!ready} className='bg-black text-white p-2 s:text-xs'>
                    {initialTransactions?.length > 0 && initialTransactions !== undefined ? "Reload" : "Connect a bank account"}
                </button>
                <div className="p-2 m-2">
                    <select
                        onChange={(e) => {
                            const selectedValue = parseInt(e.target.value);
                            setSelectedTimeRange(selectedValue);
                            const obj = {
                                limit: perPage,
                                pageCount: currentPage,
                                lastDays: selectedValue
                            };
                            getPlaidTransactionByUserId(obj);
                        }}
                        value={selectedTimeRange}
                        id="timeRange"
                        className="bg-black text-[white] focus focus:outline-none md:px-8 xs:px-4 s:px-4 h-10 xs:h-9 s:h-8 s:text-xs"
                    >
                        <option value={10}>Last 10 Days</option>
                        <option value={30}>Last 30 Days</option>
                    </select>
                </div>
            </div>
            <PlaidHistory
                publicToken={publicToken}
                initialTransactions={initialTransactions}
                getPlaidTransactionByUserId={getPlaidTransactionByUserId}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                currentPage={currentPage}
                perPage={perPage}
                totalRecords={totalRecords}
                selectedTimeRange={selectedTimeRange}
            />
        </>
    )
}

export default Plaid;
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import ProductCard from '../product-card/ProductCard';
import BannerComponent from '../banner-component/BannerComponent';
import Filter from '../filter/Filter';
import { ProductService } from '../../services';
import { CONSTANT } from '../../core/StaticConstant';
import imgNoData from '../../assets/images/nodatafound.png'
import Loader from '../loader/Loader';
import { toast } from 'react-toastify';
import { decryptData, encryptData, getUserData } from '../../core/Utils';
import { Localstorage } from '../../core/AuthService';


const ProductCategory = (props: any) => {

    let params: any = useParams();
    let productId = decryptData(params?.Id);
    const location = useLocation()
    const [categoryId, setCategoryId] = useState("");
    const [categoryData, setCategoryData] = useState([]);
    const [likeUnlikeData, setLikeUnlike] = useState<any[]>([]);
    const [showLoading, setShowLoading] = useState(true);
    const [userId, setUserId] = useState<any>("");
    
    const [categoryWiseDataFound, setCategoryWiseDataFound] = useState(false)

    useEffect(() => {
        let obj = {
            filter: productId,
            limit: "",
            pageCount: "",
            // sortingOrder: "",
            // sortingField: ""
        }
        const init = async () => {
            if (location && location.state && location.state.productData && location.state.productData.length > 0) {
                let categoryId = location.state.productData[0] && location.state.productData[0].productCategory && location.state.productData[0].productCategory._id || "";
                setCategoryId(categoryId)
            }
        }
        const userData: any = getUserData();
        const userId: any = userData?.userId;
        init();
        if (userId) {
            like();
        }
        getProductByCategory(obj);
    }, [productId])
    const like = async () => {
        try {
            let response = await ProductService.GetlikeUnlikeProductByUser();
            if (response && response.status === CONSTANT.SUCCESS) {
                setLikeUnlike(response.data.product);
            }
        } catch (err: any) {
            console.error("Error occurred in like:", err);
            toast.error(err?.response?.data?.error);
        }
    };

    const likeUnlikeProduct = async (id: any, skuId: any) => {
        if (userId) {
            try {
                let obj = {
                    productId: productId, // Use productId instead of skuId
                    skuId: skuId._id,
                };
                let response = await ProductService.likeUnlikeProductByUser(obj);
                if (response.status === CONSTANT.SUCCESS) {
                    setLikeUnlike(response.data.product);

                    toast.success(response.message);

                }
                await like();
            } catch (err: any) {
                console.error("Error occurred in likeUnlikeProduct:", err);
                toast.error(err?.response?.data?.error);
            }
        } else {
            Localstorage(CONSTANT.SET,CONSTANT.LAST_VISITED_PAGE,window.location.href);
            navigate("/login");
        }
    };

    const getProductByCategory = async (obj: any) => {
        try {
            let response: any = await ProductService.getProductByCategory(obj);
            setTimeout(() => {
                if (response && response.status == CONSTANT.SUCCESS) {
                    setShowLoading(false);
                    setCategoryWiseDataFound(true);
                    let array = response.data.products.map((a: any) => {
                        let mrp = "";
                        if (a.skuIds && a.skuIds.length > 0) {
                            const filteredSkuIds = a.skuIds.filter((sku: any) => sku.isDefault === "Yes");
                            if (filteredSkuIds.length > 0) {
                                mrp = filteredSkuIds[0].productSingleAmount;
                            }
                        }
                        return { ...a, mrp };
                    });
                    setCategoryData(array)
                } else {
                    setCategoryWiseDataFound(false);
                }
            }, 2000)
        } catch (error) {
            console.error('Error occurred while fetching products by category:', error);
            setShowLoading(false);
        }
    }

    const navigate = useNavigate();

    const onAddToCart = (id: any) => {
        navigate(`/shop/productdetail/${encryptData(id)}`);
    };
    return (
        <>
            <>
                {/* Banner start */}
                <BannerComponent
                    banner={location?.state?.categoryImg || location?.state?.data?.categoryImg}
                    title={location?.state?.shortDescription || productId}
                    description={location?.state?.longDescription || location?.state?.data?.longDescription}
                    mainDivStyle={"pt-20 s:pt-1 pl-44 2xl:w-[50%] xl:w-[60%] md:w-[100%] md:pl-[4rem] lg:pt-[20rem] md:pt-[10rem] sm:pt-[4rem] xxs:pt-[4rem] xxs:px-[2rem] xs:px-[1rem] s:px-[0rem] sm:pl-[6rem] sm:w-[84%] sm:text-center xs:text-center s:text-center xs:pt-[2rem] md:text-start c-h-24rem"}
                    titleStyle={"xl:text-6xl font-bold text-white lg:text-[3rem] md:text-4xl sm:text-3xl main-text"}
                    imageStyle={'w-full h-[40rem] lg:h-[40rem] md:h-[28rem] sm:h-[25rem] xxs:h-[18rem] xs:h-[14rem] s:h-[14rem] grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-2 md:gap-4 lg:gap-6 p-5 bg-no-repeatbg-no-repeat bg-cover'}
                    hrStyle={'mt-5 xs:mt-[0] xl:w-[83%] border-white xl:border-4 lg:w-[56%] md:w-[54%] sm:border-[3px] xs:border-[2px] sm:w-full xs:w-full'}
                    descStyle={'text-white mt-[10px] xxs:mt-[5px] w-[100%] xl:text-xs xl:text-[15px] xl:w-[100%] font-semibold lg:w-[62%] sm:text-[11px] sm:mt-[10px] xs:w-full xs:text-[7px] s:text-[7px] xxs:text-[9px] xxs:w-full md:w-[60%] sm:w-full sm:text-center xs:text-center md:text-start'}
                />
                {/* Banner end */}
                <Filter bgColor={'bg-whiteSmoke'} getProductByCategory={getProductByCategory} />
                {
                    showLoading === true &&
                    <div className="absolute top-[50%] z-50 left-[50%] transform translate-x-[-50%] translate-y-[-50%]">
                        <Loader />
                    </div>
                }
                <>
                    {
                        categoryWiseDataFound && showLoading === false &&
                        <>
                            {
                                categoryData && categoryData.length > 0 ?
                                    <>
                                        <div className="m-auto grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 xs:grid-cols-2 bg-whiteSmoke py-[3rem] sm:px-[2.5rem] px-3">
                                            {categoryData && categoryData.map((ele: any, i: any) => {
                                                return (
                                                    <React.Fragment key={i}>
                                                        <ProductCard
                                                            key={i}
                                                            productId={ele && ele._id || ""}
                                                            image={ele && ele.skuIds && ele.skuIds[0] && ele.skuIds[0].productImage[0]}
                                                            name={ele.productName}
                                                            description={ele && ele.productSubCategory && ele.productSubCategory.subCategoryName}
                                                            productTitle={ele.productTitle}
                                                            productVariant={ele && ele.skuIds[0] && ele.skuIds[0].productVariant}
                                                            ratings={ele && ele.averageReview}
                                                            mrp={ele && ele.mrp || null}
                                                            isveg={ele && ele.isVeg || null}
                                                            deliverDays={ele.benefitIds}
                                                            skuId={ele && ele.skuIds && ele.skuIds[0] && ele.skuIds[0]._id}
                                                            onAddToCart={() => onAddToCart(ele._id)}
                                                            likeUnlikeData={likeUnlikeData}
                                                            css={"lg:mb-[5rem] sm:mb-[1rem] xs:mb-[1rem] s:mb-[1rem]"}
                                                        />
                                                    </React.Fragment>
                                                );
                                            })}
                                        </div>
                                    </> :
                                    <div className="flex justify-center items-center h-[62vh]">
                                        <img className="max-w-[20%] h-auto" src={imgNoData} alt="No Data" />
                                    </div>
                            }
                        </>
                    }
                </>
                <>
                    {
                        categoryWiseDataFound === false && showLoading === false &&
                        <div>No Product Found</div>
                    }
                </>
            </>
        </>
    )
}

export default ProductCategory;
import React from 'react'
import BotAvatar, { UserAvatar } from './BoatAvatar'

const MessageParser = (props: any) => {
    const { showMessage, messages, setSelectedBotName } = props;
    return (
        <div>
            {showMessage &&
                messages?.map((msg: any, index: any) => (
                    <div key={index}>
                        {msg.type === 'dateHeader' && (
                            <div className='flex justify-center items-center'>
                                <div className='text-center text-sm bg-slate-300 p-1 rounded-lg s:text-[10px]'>
                                    {msg.date}
                                </div>
                            </div>
                        )}
                        {msg.type === 'user' && (
                            <div className='flex justify-end '>
                                <div className='bg-gray-200 p-1 rounded-md max-w-[80%] text-sm'>{msg.message}
                                    <div className='text-[10px] text-right'>
                                        {msg.time}
                                    </div></div>
                                <UserAvatar />
                            </div>
                        )}
                        {msg.type === 'bot' && (
                            <div className='flex justify-start '>
                                <BotAvatar setSelectedBotName={setSelectedBotName} />
                                <div className='bg-[#9b7d00de] text-white p-1 rounded max-w-[80%] text-sm'>{msg.message}
                                    <div className='text-[10px] text-right'>
                                        {msg.time}
                                    </div></div>
                            </div>
                        )}
                        {msg?.messages?.map((item: any) =>
                            <>
                                {item?.type === 'user' && (
                                    <div className='flex justify-end m-2'>
                                        <div className='bg-gray-200 p-1 rounded-md max-w-[80%] text-sm'>
                                            {item?.message}
                                            <div className='text-[10px] text-right'>
                                                {item.time}
                                            </div>
                                        </div>
                                        <UserAvatar />
                                    </div>
                                )}
                                {item?.type === 'bot' && (
                                    <div className='flex justify-start m-2'>
                                        <BotAvatar setSelectedBotName={setSelectedBotName} />
                                        <div className='bg-[#9b7d00de] text-white p-1 rounded max-w-[80%] text-sm'>
                                            {item?.message}
                                            <div className='text-[10px] text-right'>
                                                {item?.time}
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                ))}
        </div>
    )
}

export default MessageParser
import React from "react";
import { Route, Routes, Navigate, useLocation } from "react-router-dom";
import SelfTrains from "../pages/self-trains/self-train/SelfTrains";
import Home from "../pages/home/Home";
import Cart from "../pages/cart/Cart";
import About from "../pages/about/About";
import AppComponent from "../pages/app/App";
import Contact from "../pages/contact/Contact";
import ProductDetail from "../pages/shop/product-detail/ProductDetail";
import PrivacyPolicy from "../pages/privacy-policy/PrivacyPolicy";
import ProductCategory from "../components/product-category/ProductCategory";
import ProtectedRoute from "./PrivateRoute";
import Login from "../components/profile-module/Login";
import Footer from "../components/footer/Footer";
import Navbar from "../components/navbar/Navbar";
import { getCurrentUser, SelectedMenu } from "../core/AuthService";
import CategoryNav from "../components/sub-nav/SubNav";
import SignUp from "../components/signup-profile/SignUp";
import CartOrder from "../pages/cart-order/CartOrder";
import MyProfile from "../pages/user-profile/MyProfile";
import AccountSettings from "../pages/user-profile/AccountSettings";
import OrderTracking from "../pages/user-profile/OrderTracking";
import Wishlist from "../pages/user-profile/Wishlist";
import ExpertClasses from "../pages/user-profile/ExpertClasses";
import Appointments from "../pages/user-profile/Appointments";
import SignOut from "../pages/sign-out/SignOut";
import Product from "../pages/shop/all-product/AllProduct";
import BodyScans from "../pages/body-scans/BodyScans";
import BodyScansPackage from "../pages/body-scans/BodyScansPackage";
import BodyScansCustomPackage from "../pages/body-scans/BodyScansCustomPackage";
import ViewAllClasses from "../pages/self-trains/view-all-classes/ViewAllClasses";
import YogaDetails from "../pages/self-trains/yoga-details/YogaDetails";
import Expert from "../pages/self-trains/experts/Experts";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import SearchResult from "../components/navbar/SearchResult";
import VerifyUser from "../components/profile-module/VerifyUser";
import ForgotPassword from "../components/profile-module/ForgotPassword";
import PlaidTransaction from "../components/plaid/PlaidTransaction";
const stripePublickey: any = process.env.REACT_APP_STRIPE;
const stripePromise: any = loadStripe(stripePublickey);

const ROUTES = [
    {
        path: "/signup",
        component: <SignUp />,
        isPrivate: false,
    },
    {
        path: "*",
        component: <Home />,
        isPrivate: false,
    },
    {
        path: "/self-train",
        component: <SelfTrains />,
        isPrivate: false,
    },
    {
        path: "/self-train/view-all-classes",
        component: <ViewAllClasses />,
        isPrivate: false,
    },
    {
        path: "/self-train/yoga-details/:id",
        component: <YogaDetails />,
        isPrivate: false,
    },
    {
        path: "/self-train/experts",
        component: <Expert />,
        isPrivate: false,
    },
    {
        path: "/body-scans",
        component: <BodyScans />,
        isPrivate: false,
    },
    {
        path: "/body-scans/package",
        component: <BodyScansPackage />,
        isPrivate: false,
    },
    {
        path: "/body-scans/custom-package",
        component: <BodyScansCustomPackage />,
        isPrivate: false,
    },
    {
        path: "/shop",
        component: <Product />,
        isPrivate: false,
    },
    {
        path: "/shop/ingredient/:Id",
        component: <Product />,
        isPrivate: false,
    },
    {
        path: "/app",
        component: <AppComponent />,
        isPrivate: false,
    },
    {
        path: "/shop/productdetail/:Id",
        component: <ProductDetail />,
        isPrivate: false,
    },
    {
        path: "/shop/productCategory/:Id",
        component: <ProductCategory />,
        isPrivate: false,
    },
    {
        path: "/shop/cart",
        component: <Cart />,
        isPrivate: false,
    },
    {
        path: "/shop/cart/order",
        component: (
            <Elements stripe={stripePromise}>
                <CartOrder />
            </Elements>
        ),
        isPrivate: true,
    },
    {
        path: "/shop/cart/order/:ids",
        component: (
            <Elements stripe={stripePromise}>
                <CartOrder />
            </Elements>
        ),
        isPrivate: true,
    },
    {
        path: "/user/my-profile",
        component: <MyProfile />,
        isPrivate: true,
    },
    {
        path: "/user/account-settings",
        component: <AccountSettings />,
        isPrivate: true,
    },
    {
        path: "/user/order-tracking",
        component: <OrderTracking />,
        isPrivate: true,
    },
    {
        path: "/user/wishlist",
        component: <Wishlist />,
        isPrivate: true,
    },
    {
        path: "/user/expert-classes",
        component: <ExpertClasses />,
        isPrivate: true,
    },
    {
        path: "/user/appointments",
        component: <Appointments />,
        isPrivate: true,
    },
    {
        path: "/user/about",
        component: <About />,
        isPrivate: true,
    },
    {
        path: "/user/plaid",
        component: <PlaidTransaction />,
        isPrivate: true,
    },
    {
        path: "/privacy-policy",
        component: <PrivacyPolicy />,
        isPrivate: true,
    },
    {
        path: "/user/contact",
        component: <Contact />,
        isPrivate: true,
    },

    {
        path: "/verifyuser/:id",
        component: <VerifyUser />,
        isPrivate: false,
    },
    {
        path: "/searchresult/:id",
        component: <SearchResult />,
        isPrivate: false,
    },

    {
        path: "/forgotpassword/:id",
        component: <ForgotPassword />,
        isPrivate: false,
    },
    {
        path: "/user/sign-out",
        component: <SignOut />,
        isPrivate: true,
    },
];

const subMenuList: any = [
    { _id: "1", name: "Feature Classes", path: "" },
    { _id: "2", name: "All Classes", path: "view-all-classes" },
    { _id: "3", name: "Experts", path: "experts" },
];

const Routing = () => {
    const location = useLocation();
    const isUser = window.location.pathname.includes("/user/");
    const isNav = window.location.pathname.includes("/login");
    const isSignUp = window.location.pathname.includes("/signup");
    const isForgot = window.location.pathname.includes("/forgotpassword/");


    return (
        <>
            {!isSignUp && !isNav && !isForgot && <Navbar isGreen={isUser} />}
            {SelectedMenu() && location.pathname !== "/self-train/yoga-details" && (
                <CategoryNav pageName={location.pathname} subMenuList={subMenuList} />
            )}
            <Routes>
                <Route
                    path="/login"
                    element={
                        (!getCurrentUser() && <Login />) || <Navigate to="*" replace />
                    }
                />
                {ROUTES.map((item, ind) =>
                    item.isPrivate ? (
                        <Route
                            key={ind}
                            path={item.path}
                            element={<ProtectedRoute>{item.component}</ProtectedRoute>}
                        />
                    ) : (
                        <Route key={ind} path={item.path} element={item.component} />
                    )
                )}
            </Routes>
            {!isUser && !isSignUp && !isNav && !isForgot && <Footer />}
        </>
    );
};

export default Routing;

import React, { Fragment, useEffect, useState } from "react";
import "./Cart.css";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { ArrowUpRightIcon } from "@heroicons/react/24/outline";
import { CartService, ProductService } from "../../services";
import { CONSTANT } from "../../core/StaticConstant";
import { useNavigate } from "react-router-dom";
import { DialogOverlay } from "@reach/dialog";
import "@reach/dialog/styles.css";
import ProductCart from "../../components/product-cart/ProductCart";
import {
    setCartProducts,
    setExpectedDate,
} from "../../redux/reducer/cartReducer";
import { MinusIcon, PlusIcon } from "@heroicons/react/24/solid";
import { setOrder } from "../../redux/reducer/cartReducer";
import { encryptData, getUserData, isUndefineOrNull } from "../../core/Utils";
import Login from "../../components/profile-module/Login";
import LoginUser from "../../components/profile-module/LoginUser";
import { toast } from "react-toastify";
import { Localstorage } from "../../core/AuthService";

const Cart = (props: any) => {
    const futureDate = moment().add(5, "days").format("MMM DD YYYY");

    interface CartData {
        cart: {
            // Define the structure of the 'cart' property here
            productId: string;
            productName: string;
            actualAmount: number;
            // ... other properties
        }[];
        // ... other properties if any
    }

    // const { setTotalCart } = props;
    const navigate = useNavigate();
    const storageData = useSelector((state: any) => state);
    //   const cartProductsStore = storageData.cartProducts;
    const dispatch = useDispatch();
    const [userId, setUserId] = useState<String>("");
    const [showLoginModal, setShowLoginModal] = useState(false);
    const [totalPrice, setTotalPrice] = useState<any>(0);
    const [likeUnlikeData, setLikeUnlike] = useState<any[]>([]);
    const [recommendedData, setRecommendedData] = useState<any[]>([]);
    const [removeCartProductId, setRemoveCartProductId] = useState<string>();
    const [cartTotal, setCartTotal] = useState(0);
    const [open, setOpen] = useState<boolean>(false);
    const cartProductsStore = useSelector((state: any) => state.cartProducts);

    const [cartData, setCartData] = useState<any[]>(cartProductsStore);
    const cart = useSelector((state: any) => state.cart);

    useEffect(() => {
        setCartData(cartProductsStore);
    }, [cartProductsStore]);

    let cartAllData = cart;

    const handleOpen = (id: any) => {
        setOpen(true);
        setRemoveCartProductId(id);
    };

    let totalProductCounts = 0;

    //   for (const product of cartProductsStore) {
    //     totalProductCounts += product?.quantity;
    //   }

    if (Array.isArray(cartProductsStore)) {
        for (const product of cartProductsStore) {
            totalProductCounts += product?.quantity;
        }
    }

    const handleClose = () => {
        setOpen(false);
    };

    const getCartData = async () => {
        await CartService.getCartByUserId()
            .then((data: any) => {
                if (data && data.statusCode === 403) {
                    toast.error(data.message);
                    dispatch(setCartProducts([]));
                    Localstorage(CONSTANT.REMOVE, CONSTANT.USER_INFO);
                    Localstorage(CONSTANT.REMOVE, CONSTANT.SELECTED_MENU);
                    setTimeout(() => {
                        window.location.href = "/shop";
                    }, 1000);
                } else {
                    if (data && data.status == CONSTANT.SUCCESS) {
                        setCartData(data?.data?.cart?.cart);

                        let totalAmount = data?.data?.cart?.cart?.reduce(
                            (total: number, product: any) => {
                                return total + product?.totalAmount;
                            },
                            0
                        );
                        const lastVisitedPage = Localstorage(CONSTANT.GET, CONSTANT.LAST_VISITED_PAGE);
                        if (lastVisitedPage?.includes("/shop/cart")) {
                            const user: any = getUserData();

                            var newUserId = userId;
                            if (isUndefineOrNull(userId) && user) {
                                setUserId(user["userId"]);
                                newUserId = user["userId"];
                            }

                            let obj = {
                                cart: cartProductsStore,
                            };
                            createCart(obj);
                            dispatch(setCartProducts(cartProductsStore));
                        } else {
                            dispatch(setCartProducts(data?.data?.cart?.cart));
                            dispatch(setExpectedDate(futureDate));
                        }
                        setTotalPrice(totalAmount);
                    }
                }
            })
            .catch((err: any) => {
                if (err?.response?.data?.error !== CONSTANT.DATA_NOT_FOUND) {
                    toast.error(err?.response?.data?.error);
                }
            });
    };

    useEffect(() => {
        const userData: any = getUserData();

        setUserId(userData?.userId);
        const fetchData = async () => {
            try {
                like();
            } catch (error) {
                console.error(error);
            }
        };
        if (userData?.userId) {
            fetchData();

            getCartData();
        }

        // let totalAmount = cartData.reduce((total: number, product: any) => {
        //   return total + product?.totalAmount;
        // }, 0);
        // setTotalPrice(totalAmount);

        // Assuming cartData is an object with a 'cart' property

        const totalAmount = cartData.reduce((total: number, product: any) => {
            return total + (product?.totalAmount || 0);
        }, 0);


        setTotalPrice(totalAmount);

        getAllrecommendedProduct();

        // eslint-disable-next-line
        // }, [totalPrice]);
    }, []);

    const onRemoveProduct = async () => {
        try {
            const updatedCartProducts = cartProductsStore.filter(
                (product: any) => product.productId !== removeCartProductId
            );
            dispatch(setCartProducts(updatedCartProducts));
            handleClose();
            if (removeCartProductId) {
                let obj = {
                    productId: removeCartProductId,
                };
                removeProduct(obj);
            }
            const totalAmount = updatedCartProducts.reduce(
                (total: number, product: any) => total + product.totalAmount,
                0
            );
            setTotalPrice(totalAmount);
        } catch (error) {
            console.error({ error });

        }
    };

    const createCart = async (data: any) => {
        let response = await CartService.createCart(data);
    };

    const incNum = (element: any, index: number) => {
        if (element?.quantity < element?.skuId?.productQty) {
            const newArray = cartData.map((elem: any, ind: number) => {
                if (ind === index) {
                    const newQuantity = elem?.quantity + 1;

                    let totalAmount = newQuantity * elem?.actualAmount;
                    if (element?.dosageAssistancePrice) {
                        totalAmount += element?.dosageAssistancePrice;
                    }
                    return {
                        ...elem,
                        quantity: elem.quantity + 1,
                        totalAmount: totalAmount,
                    };
                } else {
                    return elem;
                }
            });
            const updatedCartProductsStore = newArray.map((item: any) => {
                if (item.productId === element.productId) {
                    return {
                        ...item,
                        quantity: newArray[index].quantity,
                        totalAmount: newArray[index].totalAmount,
                    };
                } else {
                    return item;
                }
            });
            if (userId) {
                let obj = {
                    cart: updatedCartProductsStore,
                };
                createCart(obj);
            }
            if (!userId) {
                setCartData(updatedCartProductsStore);
            }
            dispatch(setCartProducts(updatedCartProductsStore));
            let quantity = 0;
            for (let i = 0; i < updatedCartProductsStore.length; i++) {
                quantity += updatedCartProductsStore[i].quantity;
            }
            setCartTotal(quantity);
            let mrp = updatedCartProductsStore.reduce(
                (acc: any, ele: any) => acc + ele.totalAmount,
                0
            );
            setTotalPrice(mrp);
        }
    };

    const decNum = (element: any, index: number) => {
        if (1 < element.quantity) {
            const newArray = cartData.map((elem: any, ind: number) => {
                if (ind === index) {
                    const newQuantity = elem?.quantity - 1;
                    let totalAmount = newQuantity * elem?.actualAmount;
                    if (element?.dosageAssistancePrice) {
                        totalAmount += element.dosageAssistancePrice;
                    }
                    return {
                        ...elem,
                        quantity: elem.quantity - 1,
                        totalAmount: totalAmount,
                    };
                } else {
                    return elem;
                }
            });
            const updatedCartProductsStore = newArray.map((item: any) => {
                if (item.productId === element.productId) {
                    return {
                        ...item,
                        quantity: newArray[index].quantity,
                        totalAmount: newArray[index].totalAmount,
                    };
                } else {
                    return item;
                }
            });
            if (userId) {
                let obj = {
                    cart: updatedCartProductsStore,
                };
                createCart(obj);
            }
            if (!userId) {
                setCartData(updatedCartProductsStore);
            }
            dispatch(setCartProducts(updatedCartProductsStore));
            let quantity = 0;
            for (let i = 0; i < updatedCartProductsStore.length; i++) {
                quantity += updatedCartProductsStore[i].quantity;
            }
            setCartTotal(quantity);
            let mrp = updatedCartProductsStore.reduce(
                (acc: any, ele: any) => acc + ele.totalAmount,
                0
            );
            setTotalPrice(mrp);
        }
    };

    const like = async () => {
        try {
            let response = await ProductService.GetlikeUnlikeProductByUser();
            if (response && response.status === CONSTANT.SUCCESS) {
                setLikeUnlike(response.data.product);
            }
        } catch (err: any) {
            console.error("Error occurred in like:", err);
            toast.error(err?.response?.data?.error);
        }
    }
    const likeUnlikeProduct = async (productId: any, skuId: any) => {
        if (userId) {
            try {
                const obj = {
                    productId: productId,
                    skuId: skuId._id,
                };
                const response = await ProductService.likeUnlikeProductByUser(obj);
                if (response && response.status === CONSTANT.SUCCESS) {
                    setLikeUnlike(response.data.product);
                    like();
                    toast.success(response.message);
                }
            } catch (error) {
                console.error(error);
            }
        } else {
            Localstorage(CONSTANT.SET, CONSTANT.LAST_VISITED_PAGE, window.location.href);
            navigate("/login");
        }
    };

    const removeProduct = async (data: any) => {
        try {
            let response = await CartService.deleteCartByUserIdAndProductId(data);
            if (response && response.status === CONSTANT.SUCCESS) {
                getCartData();
            }
        } catch (error) {
            console.error("Error occurred in removeProduct:", error);
        }
    };

    const handleRemove = (index: number) => {
        const updatedRecommendedData = [...recommendedData];
        updatedRecommendedData?.splice(index, 1);
        setRecommendedData(updatedRecommendedData);
    };
    const getAllrecommendedProduct = async () => {
        try {
            const obj = {
                fields: ["productName", "skuIds"]
            };
            const response = await ProductService.getAllRecommendedProduct(obj);
            if (response && response.status === CONSTANT.SUCCESS) {
                const filterData = response.data.products;
                filterData.forEach((element: any, index: number) => {
                    const clickedStatus = Localstorage(
                        CONSTANT.GET,
                        `product_${element._id}_clicked`
                    );
                    if (clickedStatus === "true") {
                        element["isAddingToCart"] = true;
                    }
                    const skuData = element.skuIds.filter(
                        (sku: any) => sku.isDefault === "Yes"
                    );
                    filterData[index]["skuIds"] = skuData;
                });
                setRecommendedData(filterData);
            }
        } catch (error) {
            console.error(error);
        }
    };
    const isProductInCart = (productId: any) => {
        return cartAllData?.some(
            (item: any) => item.productId && item.productId._id === productId
        );
    };
    const isProductLiked = (productId: any) => {
        return likeUnlikeData?.some(
            (item) => item.product && item.product._id === productId
        );
    };

    const onClickCheckout = () => {
        if (userId) {
            navigate("/shop/cart/order");
            dispatch(setOrder("addNewAddress"));
        } else {
            Localstorage(CONSTANT.SET, CONSTANT.LAST_VISITED_PAGE, window.location.href);
        }
    };

    const totalProduct = cartData.reduce((total, item) => total + item.quantity, 0);

    return (
        <>
            <div className="bg-fullwhite lg:px-12 xl:px-36 px-4 pb-20 pt-8">
                <div className="grid grid-cols-2 mb-4">
                    <div className="col-span-1">
                        <h2 className="text-capeCod font-bold xl:text-[40px] text-[22px] lg:text-[30px] md:text-[25px]">
                            My Cart
                        </h2>
                    </div>
                    <div className="col-span-1 my-auto">
                        <h2 className="text-capeCod font-bold text-[20px] text-end">
                            Price
                        </h2>
                    </div>
                </div>
                {cartData && cartData?.length > 0 ? (
                    <>
                        {cartData &&
                            cartData?.map((ele: any, index: number) => {
                                return (
                                    <div key={ele?.productId} className="max-h-[320px]">
                                        <div className="mt-[2px]">
                                            <hr className="border-silver mt-[6px] border-t-[1px]" />
                                            <div className="grid xl:grid-cols-2 md:grid-cols-4 sm:grid-cols-6 grid-cols-7">
                                                <div className="xl:col-span-1 md:col-span-3 sm:col-span-5 col-span-6">
                                                    <div className="grid grid-cols-3">
                                                        <div className="col-span-1 bg-white">
                                                            <img
                                                                className="w-[100%] h-[200px] object-contain"
                                                                src={ele?.skuId?.productImage[0]}
                                                                alt=""
                                                            />
                                                        </div>
                                                        <div className="col-span-2 my-auto pl-8">
                                                            <h2 className="text-black font-bold xl:text-[25px] md:text-[18px] text-[13px]">
                                                                <span
                                                                    className="cursor-pointer"
                                                                    onClick={() =>
                                                                        navigate(
                                                                            `/shop/productdetail/${encryptData(ele?.productId)}`
                                                                        )
                                                                    }
                                                                >
                                                                    {ele?.productName}{" "}
                                                                </span>{" "}
                                                                |{" "}
                                                                <span
                                                                    className="cursor-pointer"
                                                                    onClick={() =>
                                                                        navigate(
                                                                            `/shop/productdetail/${encryptData(ele?.productId)}`
                                                                        )
                                                                    }
                                                                >
                                                                    {ele?.skuId && ele?.skuId?.productVariant}
                                                                </span>
                                                            </h2>
                                                            <p className="text-green font-[500] xl:text-[15px] md:[14px] text-[10px] hidden">Hurry up,Only {" "}
                                                                {ele && ele?.skuId && ele?.skuId?.productQty}{" "}
                                                                Available
                                                            </p>
                                                            <p className="text-black xl:text-[15px] md:text-[14px] text-[10px] font-[700]">
                                                                Estimated delivery: {futureDate}
                                                            </p>
                                                            <p className="text-black xl:text-[15px] md:text-[14px] text-[10px] font-[700]">
                                                                Price: {ele && ele?.actualAmount}£
                                                            </p>
                                                            <div className="text-lg font-bold pt-[10px]">
                                                                <div>
                                                                    <p className="text-[14px] text-black">
                                                                        Quantity
                                                                    </p>
                                                                    <div className="inline-flex">
                                                                        <button
                                                                            className={`border w-[28px] h-[24px] border-oxfordBlue h-[25px] font-thin bg-black ${ele?.quantity === 1
                                                                                ? "bg-greenFooter border-none cursor-default"
                                                                                : "cursor-pointer"
                                                                                }`}
                                                                            onClick={() => decNum(ele, index)}
                                                                        >
                                                                            <MinusIcon className="w-5 h-5 mx-auto text-white" />
                                                                        </button>
                                                                        <input
                                                                            className="text-oxfordBlue w-[55px] h-[25px] mx-[2px] text-align-webkit-center border-black border-[2px] focus:outline-0"
                                                                            disabled
                                                                            type="num"
                                                                            value={ele?.quantity}
                                                                        />
                                                                        <button
                                                                            className={`border w-[28px] h-[24px] border-oxfordBlue h-[25px] font-thin bg-black ${ele?.skuId?.productQty === ele?.quantity
                                                                                ? "bg-greenFooter border-none cursor-default"
                                                                                : "cursor-pointer"
                                                                                }`}
                                                                            onClick={() => incNum(ele, index)}
                                                                        >
                                                                            <PlusIcon className="w-5 h-5 mx-auto text-white" />
                                                                        </button>
                                                                        <span className="border-r content pl-[12px]"></span>
                                                                        <span
                                                                            className="text-[12px] pl-[12px] content cursor-pointer text-capeCod"
                                                                            onClick={() => {
                                                                                handleOpen(ele?.productId);
                                                                            }}
                                                                        >
                                                                            Remove
                                                                        </span>
                                                                        <span className="border-r content pl-[12px]"></span>
                                                                        {isProductInCart(
                                                                            ele?.productId && ele?.productId._id
                                                                        ) &&
                                                                            isProductLiked(
                                                                                ele?.productId && ele?.productId._id
                                                                            ) ? (
                                                                            <span
                                                                                className="text-[12px] pl-[12px] content cursor-pointer text-capeCod"
                                                                                onClick={() =>
                                                                                    likeUnlikeProduct(
                                                                                        ele?.productId,
                                                                                        ele?.skuId
                                                                                    )
                                                                                }
                                                                            >
                                                                                Remove From Wishlist
                                                                            </span>
                                                                        ) : (
                                                                            <span
                                                                                className="text-[12px] pl-[12px] content cursor-pointer text-capeCod"
                                                                                onClick={() =>
                                                                                    likeUnlikeProduct(
                                                                                        ele?.productId,
                                                                                        ele?.skuId
                                                                                    )
                                                                                }
                                                                            >
                                                                                Move to Wishlist
                                                                            </span>
                                                                        )}
                                                                    </div>
                                                                    <div className="max-w-[640px] sm:hidden">
                                                                        <span
                                                                            className="text-[12px] cursor-pointer text-capeCod"
                                                                            onClick={() => {
                                                                                handleOpen(ele?.productId?._id);
                                                                            }}
                                                                        >
                                                                            Remove
                                                                        </span>
                                                                        <span className="border-r pl-[12px]"></span>
                                                                        <span
                                                                            className="text-[12px] cursor-pointer pl-[12px] text-capeCod"
                                                                            onClick={() =>
                                                                                likeUnlikeProduct(
                                                                                    ele?.productId,
                                                                                    ele?.skuId
                                                                                )
                                                                            }
                                                                        >
                                                                            Move to Wishlist
                                                                        </span>
                                                                        <span
                                                                            className="text-[12px] cursor-pointer pl-[12px] text-capeCod"
                                                                            onClick={() =>
                                                                                likeUnlikeProduct(
                                                                                    ele?.productId && ele?.productId?._id,
                                                                                    ele?.skuId && ele?.skuId?._id
                                                                                )
                                                                            }
                                                                        >
                                                                            Remove From Wishlist
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="xl;col-span-1 md:col-span-1 sm:mt-12 mt-[2.5rem] xl:mt-8 2xl:mt-4 sm:col-span-1 col-span-1">
                                                    <h2 className="text-black font-bold xl:text-[25px] lg:[18px] md:text-[15px] text-[13px] text-end">
                                                        £{" "}
                                                        {ele && ele?.totalAmount
                                                            ? ele?.totalAmount?.toFixed(2)
                                                            : "N/A"}
                                                    </h2>
                                                </div>
                                            </div>
                                            <hr className="border-silver border-t-[1px]" />
                                        </div>
                                    </div>
                                );
                            })}
                    </>
                ) : (
                    <>
                        <div className="text-[25px] py-4 font-medium">
                            Your Cart is Empty
                        </div>
                    </>
                )}
                <hr className="border-silver border-t-[1px]" />
                <div className="sm:grid sm:grid-cols-2">
                    <div className="sm:col-span-1"></div>
                    <div className="sm:col-span-1 my-auto text-end pb-4">
                        <h2 className="text-black font-bold text-[25px] text-end">
                            <span className="text-black font-[400] leading-[1px] text-[15px] 2xl:text-[18px] xl:text-[18px] md:text-[15px] sm:text-[16px]">
                                Total ({totalProductCounts} {totalProduct === 1 ? "item" : "items"}):
                            </span>

                            <span className="text-[15px] 2xl:text-[20px] xl:text-[18px] md:text-[18px] sm:text-[16px]">
                                £ {totalPrice ? totalPrice?.toFixed(2) : "N/A"}
                            </span>
                        </h2>
                        {cartData && cartData.length > 0 ? (
                            <button
                                className="inline-flex p-[6px] mt-4 2xl:text-[20px] xl:text-[15px] md:text-[18px] sm:text-[16px] x:text-[14px] text-miniwhite bg-capeCod font-bold justify-center items-center px-8"
                                onClick={
                                    userId
                                        ? () => onClickCheckout()
                                        : () => setShowLoginModal(true)
                                }
                            >
                                {" "}
                                Proceed to Checkout{" "}
                                <ArrowUpRightIcon className="h-[23px] pt-[4px] pl-[7px] stroke-[3]" />
                            </button>
                        ) : (
                            <button className="inline-flex p-[6px] mt-4 2xl:text-[20px] xl:text-[15px] md:text-[18px] sm:text-[16px] x:text-[14px] text-silver bg-greenFooter font-bold justify-center items-center px-8">
                                {" "}
                                Proceed to Checkout{" "}
                                <ArrowUpRightIcon className="h-[23px] pt-[4px] pl-[7px] stroke-[3]" />
                            </button>
                        )}
                    </div>
                </div>
            </div>
            <div className="bg-greenFooter lg:px-12 xl:px-36 px-4 pt-8">
                <div className="grid grid-cols-2">
                    <div className="col-span-1">
                        <h2 className="text-capeCod font-bold xl:text-[40px] text-[22px] lg:text-[30px] md:text-[25px]">
                            My Wishlist
                        </h2>
                    </div>
                    <div className="col-span-1 my-auto">
                        <h2 className="text-capeCod font-bold text-[20px] text-end">
                            Price
                        </h2>
                    </div>
                </div>
                {likeUnlikeData && likeUnlikeData.length > 0 ? (
                    <>
                        {likeUnlikeData &&
                            likeUnlikeData.map((ele: any, index: number) => {
                                return (
                                    <ProductCart
                                        key={index}
                                        ele={ele}
                                        likeUnlikeProduct={likeUnlikeProduct}
                                    />
                                );
                            })}
                    </>
                ) : (
                    <div className="text-[25px] py-4 font-medium">
                        No items saved for later
                    </div>
                )}
            </div>
            <div className="bg-greenFooter lg:px-12 xl:px-36 px-4 pt-20 pb-4">
                <div className="grid grid-cols-4">
                    <div className="col-span-3">
                        <h2 className="text-capeCod font-bold xl:text-[40px] text-[22px] lg:text-[30px] md:text-[25px]">
                            Recommended Products based on your cart
                        </h2>
                    </div>
                    <div className="col-span-1 my-auto">
                        <h2 className="text-capeCod font-bold text-[20px] text-end">
                            Price
                        </h2>
                    </div>
                </div>
                {recommendedData &&
                    recommendedData.map((ele: any, index: number) => {
                        return (
                            <div key={index}>
                                <div className="mt-4">
                                    <hr className="border-silver mt-4 border-t-[1px]" />
                                    <div className="grid xl:grid-cols-2 md:grid-cols-4 sm:grid-cols-6 grid-cols-7">
                                        <div className="xl:col-span-1 md:col-span-3 sm:col-span-5 col-span-6">
                                            <div className="grid grid-cols-3">
                                                <div className="col-span-1 bg-white">
                                                    <img
                                                        className="w-[100%] h-[200px] object-contain"
                                                        src={
                                                            ele &&
                                                            ele.skuIds &&
                                                            ele.skuIds[0] &&
                                                            ele.skuIds[0].productImage[0]
                                                        }
                                                        alt=""
                                                    />
                                                </div>
                                                <div className="col-span-2 my-auto pl-8">
                                                    <h2 className="text-black font-bold xl:text-[25px] md:text-[18px] text-[13px] cursor-pointer" onClick={() =>
                                                        navigate(
                                                            `/shop/productdetail/${encryptData(ele?._id)}`
                                                        )
                                                    }>
                                                        {ele && ele.productName} | <span>30 Tablets</span>
                                                    </h2>
                                                    <p className="text-green font-[500] xl:text-[15px] md:[14px] text-[10px]">
                                                        {/* Hurry,{" "}
                                                        {ele.skuIds &&
                                                            ele.skuIds[0] &&
                                                            ele.skuIds[0].productQty}{" "}
                                                        Only Available */}
                                                        {ele.skuIds[0]?.productQty > 0 ? (
                                                            <p className="text-[15px] font-bold text-darkgreen text-[500] sm:ml-1  mr-1 sm:mr-8 mr-[1rem]">Hurry up,Only {ele.skuIds[0]?.productQty}

                                                                <span className="ml-[8px]">Available</span>
                                                            </p>
                                                        ) : (
                                                            <h2 className="text-[15px] x:text-[10px] s:text-[10px] font-bold text-red-500 w-full mr-8 x:mr-[1rem] s:mr-[1rem] inline-block">
                                                                Out of stock
                                                            </h2>
                                                        )}
                                                    </p>
                                                    <p className="text-black xl:text-[15px] md:text-[14px] text-[10px] font-[700]">
                                                        Estimated delivery: {futureDate}
                                                    </p>
                                                    <div className="text-lg font-bold pt-[20px]">
                                                        <div>
                                                            <div className="inline-flex">
                                                                <span
                                                                    className="text-[12px] content cursor-pointer text-capeCod"
                                                                    onClick={() => handleRemove(index)}
                                                                >
                                                                    Remove
                                                                </span>
                                                                {ele?.skuIds[0]?.productQty > 0 ? (
                                                                    <>
                                                                        <span className="border-r content pl-[12px]"></span>
                                                                        <span
                                                                            className="text-[12px] cursor-pointer pl-[12px] content text-capeCod"
                                                                            onClick={() =>
                                                                                navigate(
                                                                                    `/shop/productdetail/${ele && encryptData(ele?._id)}`
                                                                                )
                                                                            }
                                                                        >
                                                                            Add To Cart
                                                                        </span>
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        <span className="border-r content pl-[12px]"></span>
                                                                        <span
                                                                            className="text-[12px] pl-[12px] content cursor-pointer text-silver"
                                                                        >
                                                                            Add To Cart
                                                                        </span>
                                                                    </>
                                                                )}
                                                            </div>
                                                            {/* <div className="max-w-[640px] sm:hidden">
                                                                <span className="text-[12px] cursor-pointer text-capeCod">
                                                                    Remove
                                                                </span>
                                                                <span className="border-r pl-[12px]"></span>
                                                                {ele.skuIds[0]?.productQty ? (
                                                                    <>
                                                                        <span
                                                                            className="text-[12px] pl-[12px] cursor-pointer text-capeCod"
                                                                            onClick={() =>
                                                                                likeUnlikeProduct(
                                                                                    ele && ele._id,
                                                                                    ele.skuIds && ele.skuIds[0]
                                                                                )
                                                                            }
                                                                        >
                                                                            Move to Wishlist
                                                                        </span>
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        <span
                                                                            className="text-[12px] pl-[12px] cursor-pointer text-capeCod"
                                                                            onClick={() =>
                                                                                navigate(
                                                                                    `/shop/productdetail/${ele && encryptData(ele._id)
                                                                                    }`
                                                                                )
                                                                            }
                                                                        >
                                                                            Add To Cart
                                                                        </span>
                                                                    </>
                                                                )}
                                                            </div> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="xl;col-span-1 md:col-span-1 sm:mt-12 mt-[2.5rem] xl:mt-8 2xl:mt-4 sm:col-span-1 col-span-1">
                                            <h2 className="text-black font-bold xl:text-[25px] lg:[18px] md:text-[15px] text-[13px] text-end">
                                                £
                                                {ele.skuIds &&
                                                    ele.skuIds[0] &&
                                                    ele.skuIds[0].productSingleAmount?.toFixed(2)}
                                            </h2>
                                        </div>
                                    </div>
                                    <hr className="border-silver border-t-[1px]" />
                                </div>
                            </div>
                        );
                    })}
            </div>
            <div>
                {open && (
                    <DialogOverlay>
                        <div className="flex items-center justify-center h-screen">
                            <div className="relative w-full m-auto max-w-md max-h-full">
                                <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                                    <button
                                        type="button"
                                        className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
                                        data-modal-hide="popup-modal"
                                        onClick={() => handleClose()}
                                    >
                                        <svg
                                            aria-hidden="true"
                                            className="w-5 h-5"
                                            fill="currentColor"
                                            viewBox="0 0 20 20"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                fillRule="evenodd"
                                                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                                clipRule="evenodd"
                                            />
                                        </svg>
                                        <span className="sr-only">Close modal</span>
                                    </button>
                                    <div className="p-6 text-center">
                                        <svg
                                            aria-hidden="true"
                                            className="mx-auto mb-4 text-gray-400 w-14 h-14 dark:text-gray-200"
                                            fill="none"
                                            stroke="currentColor"
                                            viewBox="0 0 24 24"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth={2}
                                                d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                                            />
                                        </svg>
                                        <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                                            Are you sure you want to remove this product?
                                        </h3>
                                        <button
                                            data-modal-hide="popup-modal"
                                            type="button"
                                            className="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2"
                                            onClick={() => onRemoveProduct()}
                                        >
                                            Yes, I'm sure
                                        </button>
                                        <button
                                            data-modal-hide="popup-modal"
                                            type="button"
                                            className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
                                            onClick={() => handleClose()}
                                        >
                                            No, cancel
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </DialogOverlay>
                )}
            </div>

            <div>
                <DialogOverlay isOpen={showLoginModal}>
                    <div className="dialog-container">
                        <LoginUser setShowLoginModal={setShowLoginModal} />
                    </div>
                </DialogOverlay>
            </div>
        </>
    );
};

export default Cart;


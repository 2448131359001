import { API_CONSTANT } from "../core/Constant";
import httpCommon from "../core/HttpCommonCore";
import HttpMultipartCommonCore from "../core/HttpMultipartCommonCore";

export const MedicalService = {
  getMedicalDetailsById,
  updateMedicalDetails,
  createMedicalDetails,
  uploadClinicalDocuments,
  createMedicalDetailsWithUploadClinicalDocuments,
  downloadClinicalDocuments,
  removeClinicalDocumentById
};

function getMedicalDetailsById() {
  let url = API_CONSTANT.GET_MEDICAL_DETAILS;
  return httpCommon.post(url).then((response: any) => {
    if (response.statusText !== "OK") {
      const error = response.statusText;
      return Promise.reject(error);
    }
    return response.data;
  });
}

function updateMedicalDetails(id: any, data: any) {
  let url = API_CONSTANT.UPDATE_MEDICAL_DETAILS + id;
  return httpCommon.post(url, data).then((response: any) => {
    if (response.statusText !== "OK") {
      const error = response.statusText;
      return Promise.reject(error);
    }
    return response.data;
  });
}

function removeClinicalDocumentById(id: any, data: any) {
  let url = API_CONSTANT.REMOVE_CLINICAL_DOCUMENTS + id;
  return httpCommon.post(url, data).then((response: any) => {
    if (response.statusText !== "OK") {
      const error = response.statusText;
      return Promise.reject(error);
    }
    return response.data;
  });
}

function createMedicalDetails(data: any) {
  let url = API_CONSTANT.CREATE_MEDICAL_DETAILS;
  return httpCommon.post(url, data).then((response: any) => {
    if (response.statusText !== "OK") {
      const error = response.statusText;
      return Promise.reject(error);
    }
    return response.data;
  });
}

function uploadClinicalDocuments(id: any, data: any) {
  let url = API_CONSTANT.UPLOAD_CLINICAL_DOCUMENTS + id;
  return HttpMultipartCommonCore.post(url, data).then((response: any) => {
    if (response.statusText !== "OK") {
      const error = response.statusText;
      return Promise.reject(error);
    }
    return response.data;
  });
}

function createMedicalDetailsWithUploadClinicalDocuments(data: any) {
  let url = API_CONSTANT.CREATE_MEDICAL_DETAILS_WITH_UPLOAD_CLINICAL_DOCUMENTS;
  return HttpMultipartCommonCore.post(url, data).then((response: any) => {
    if (response.statusText !== "OK") {
      const error = response.statusText;
      return Promise.reject(error);
    }
    return response.data;
  });
}

function downloadClinicalDocuments(data: any) {
  let url = API_CONSTANT.DOWNLOAD_CLINICAL_DOCUMENTS;
  return httpCommon.post(url, data).then((response: any) => {
    if (response.statusText !== "OK") {
      const error = response.statusText;
      return Promise.reject(error);
    }
    return response.data;
  });
}
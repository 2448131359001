import React, { useEffect, useState, useCallback, useRef } from 'react';
import Options from './Option';
import { ConversationService } from '../../services/conversation.service';
import { CONSTANT } from '../../core/StaticConstant';
import { toast } from 'react-toastify';
import { ChatBubbleLeftRightIcon, XMarkIcon, ChevronDoubleDownIcon } from '@heroicons/react/24/outline';
import Loader from '../loader/Loader';
import { ChevronLeftIcon } from "@heroicons/react/24/outline";
import moment from 'moment';
import * as Yup from "yup";
import { useFormik } from 'formik';
import MessageParser from './MessageParser';

interface Message {
    message: string;
    type: 'user' | 'bot' | 'dateHeader';
    botId?: string;
    createdAt: string;
    date?: string;
    time?: string;
}

const ChatBoat = () => {
    const [chatbotVisible, setChatbotVisible] = useState(false);
    const [botData, setBotData] = useState([]);
    const [selectedBot, setSelectedBot] = useState(null);
    const [messages, setMessages] = useState<Message[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [apiRequestInProgress, setApiRequestInProgress] = useState(false);
    const [selectedBotName, setSelectedBotName] = useState('');
    const [inputEnabled, setInputEnabled] = useState(false);
    const [showMessages, setShowMessages] = useState(false);
    const [showSupport, setShowSupport] = useState(true);
    const [page, setPage] = useState(1);
    const [ShowPrevMessage, setShowPrevMessage] = useState(true);
    const [noConversation, setnoConversation] = useState('');
    const [shouldScrollToTop, setShouldScrollToTop] = useState(false);

    useEffect(() => {
        if (chatbotVisible) {
            let obj = {
                fields: ["botName"]
            }
            getAllBots(obj);
        }
    }, [chatbotVisible]);

    const messagesContainerRef: any = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (messagesContainerRef.current) {
            messagesContainerRef.current.scrollTop = messagesContainerRef.current.scrollHeight;
        }
    }, [messages]);

    const getAllBots = async (obj: any) => {
        try {

            let data = await ConversationService.getAllBots(obj);

            if (data && data.status === CONSTANT.SUCCESS) {
                const responseData = data?.data?.bots;
                setBotData(responseData);

                if (responseData.length > 0) {
                    setSelectedBot(responseData[0]);
                    setInputEnabled(false);
                }
            } else {
                toast.error(data.message);
            }
        } catch (error: any) {
            toast.error(error?.response?.data?.message);
        }
    };

    const handleBotSelection = async (selectedBot: any) => {
        setSelectedBot(selectedBot);
        setShowSupport(false);
        try {
            setIsLoading(true);
            const data = {
                botId: selectedBot._id,
                pageCount: 1,
                limit: 5,
            };
            const response = await ConversationService.getChatbotLastConversation(data);
            if (response && response.status === CONSTANT.SUCCESS) {
                const conversations = response.data?.conversations;
                if (conversations && conversations.length > 0) {
                    let newArray: any = [];
                    let currentDate = '';

                    conversations.forEach((item: any) => {
                        const messageDate = moment(item.createdAt);
                        const formattedDate = messageDate.format('LL');
                        const formattedTime = messageDate.format('hh:mm A');

                        if (formattedDate !== currentDate) {
                            newArray.push({
                                type: 'dateHeader',
                                date: formattedDate,
                                messages: [],
                            });
                            currentDate = formattedDate;
                        }

                        const currentDay = newArray.find((obj: any) => obj.date === currentDate);
                        currentDay?.messages.push({
                            message: item.botResponse,
                            type: 'bot',
                            createdAt: item.createdAt,
                            time: formattedTime,
                        });
                        currentDay?.messages.push({
                            message: item.userMessage,
                            type: 'user',
                            createdAt: item.createdAt,
                            time: formattedTime,
                        });

                    });
                    newArray.reverse();
                    setMessages([...newArray]);
                    newArray.forEach((item: any) => item.messages.reverse());
                    setInputEnabled(true);
                    setShowMessages(true);
                    setTimeout(() => {
                        setIsLoading(false);
                    }, 1000);
                    setShowPrevMessage(true);
                } else {
                    setnoConversation(response.message);
                    setInputEnabled(true);
                    setShowMessages(true);
                    setShowPrevMessage(false);
                    setTimeout(() => {
                        setIsLoading(false);
                    }, 1000);
                }
                setBotData((prevBotData: any) =>
                    prevBotData.map((bot: any) => ({ ...bot, disabled: true }))
                );
            } else {
                setnoConversation(response.message);
                setInputEnabled(true);
                setShowMessages(true);
                setShowPrevMessage(false);
                setTimeout(() => {
                    setIsLoading(false);
                }, 1000);
            }
        } catch (error) {
            setMessages([]);
            setInputEnabled(true);
            setShowMessages(true);
            setShowPrevMessage(false);
            setTimeout(() => {
                setIsLoading(false);
            }, 1000);
            console.error('Error in handleBotSelection:', error);
        }
    };
    const initialValues = {
        message: "",
        botId: ""
    }

    const validationSchema = Yup.object({
        message: Yup.string().min(5, 'Message should have at least 5 characters').required('Message is required'),
    });

    const { values, handleChange, handleSubmit } =
        useFormik({
            initialValues,
            validationSchema,
            onSubmit: async (values, action) => {
                if (!isLoading) {
                    if (apiRequestInProgress) return;
                    try {
                        setApiRequestInProgress(true);
                        setIsLoading(true);
                        const BotId: any = selectedBot;
                        const response = await ConversationService.getConversationResponse({
                            message: values.message,
                            botId: BotId._id,
                        });
                        action.resetForm();

                        const Time = response.data?.conversation?.createdAt;

                        const formattedTime = moment(new Date(Time)).format('hh:mm A');

                        const userMessage = {
                            message: values.message,
                            type: 'user',
                            botId: BotId._id,
                            createdAt: Time,
                            time: formattedTime
                        };
                        setMessages((prevMessages: any) => [...prevMessages, userMessage]);

                        const botResponse = response.data?.conversation?.botResponse;

                        if (botResponse) {
                            const botMessage = {
                                message: botResponse,
                                type: 'bot',
                                createdAt: Time,
                                time: formattedTime
                            };
                            setMessages((prevMessages: any) => [...prevMessages, botMessage]);
                        }
                        setIsLoading(false);
                        setnoConversation('');
                        setShowPrevMessage(true);
                    } catch (error) {
                        console.error('Error in GetConversationResponse:', error);
                        setIsLoading(false);
                    } finally {
                        setApiRequestInProgress(false);
                    }
                }
            }
        });

    const handleSendClick = (e: any) => {
        handleSubmit(e);
    };


    const toggleChatbot = () => {
        setInputEnabled(false);
        setChatbotVisible((prevState) => !prevState);
        setShowMessages(false);
        setShowSupport(true);
        setShowPrevMessage(false);
        setnoConversation('');
    };

    const toggleShowMessages = () => {
        setShowMessages((prev) => !prev);
        setShowSupport(false);
        setInputEnabled(false);
        setnoConversation('');
        setMessages([]);
        setShowPrevMessage(false);
    };

    const loadPreviousMessages = async () => {
        try {
            setIsLoading(true);
            const data = {
                botId: selectedBot ? (selectedBot as { _id?: string })._id ?? null : null,
                pageCount: page,
                limit: 50,
            };

            const response = await ConversationService.getChatbotLastConversation(data);
            if (response && response.status === CONSTANT.SUCCESS) {
                const conversations = response.data?.conversations;
                if (conversations && conversations.length > 0) {
                    let newArray: any = [];
                    let currentDate = '';

                    conversations.forEach((item: any) => {
                        const messageDate = moment(item.createdAt);
                        const formattedDate = messageDate.format('LL');
                        const formattedTime = messageDate.format('hh:mm A');

                        if (formattedDate !== currentDate) {
                            newArray.push({
                                type: 'dateHeader',
                                date: formattedDate,
                                messages: [],
                            });
                            currentDate = formattedDate;
                        }

                        const currentDay = newArray.find((obj: any) => obj.date === currentDate);
                        currentDay?.messages.push({
                            message: item.botResponse,
                            type: 'bot',
                            createdAt: item.createdAt,
                            time: formattedTime,
                        });
                        currentDay?.messages.push({
                            message: item.userMessage,
                            type: 'user',
                            createdAt: item.createdAt,
                            time: formattedTime,
                        });
                    });
                    newArray.reverse();
                    setShouldScrollToTop(true);
                    newArray.forEach((item: any) => item.messages.reverse());
                    setMessages([...newArray]);
                    setPage((prevPage) => prevPage + 1);
                    setTimeout(() => {
                        setIsLoading(false);
                    }, 1000);
                } else {
                    setnoConversation(response.message);
                    setShowPrevMessage(false);
                    setTimeout(() => {
                        setIsLoading(false);
                    }, 1000);
                }
            }
        } catch (error) {
            console.error('Error loading previous messages:', error);
            setTimeout(() => {
                setIsLoading(false);
            }, 1000);
        }
    };

    useEffect(() => {
        if (shouldScrollToTop && messagesContainerRef.current) {
            messagesContainerRef.current.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
            setShouldScrollToTop(false);
        }
    }, [messages, shouldScrollToTop]);

    return (
        <div className='max-w-[600px] fixed right-5 md:right-10 sm:right-10 xs:right-5 s:right-4 bottom-5 z-[99999] chatbot'>
            {isLoading && <Loader />}
            <div className='relative'>
                {chatbotVisible && (
                    <div className="bg-white rounded sm:w-[365px] w-[365px] s:w-[290px] h-[500px] md:w-[365px] max-h-[calc(100vh-102px)] s:h-[450px] relative xs:left-[10px] s:left-[10px] left-3">
                        <div className="">
                            <div className='flex items-center bg-[#efefef] rounded-tl-[5px] gap-2 rounded-tr-[5px] text-lg text-[#514f4f] font-bold font-family-[Arial] text-[.65rem] sm:text-[16px] md:text-[16px] xs:text-[16px] s:text-[12px] px-3 py-5 h-[50px] s:py-3'>
                                {showMessages && (
                                    <div
                                        onClick={() => {
                                            toggleShowMessages();
                                            setShowSupport((prevShowSupport) => !prevShowSupport);
                                        }}
                                        className='cursor-pointer'
                                    >
                                        <ChevronLeftIcon className="h-6 text-capeCod" />
                                    </div>
                                )}
                                {showSupport ? 'Support' : selectedBotName}
                            </div>
                        </div>
                        <div className='overflow-y-scroll s:h-[350px] h-[calc(100%-94px)]' ref={messagesContainerRef}>
                            {!showMessages && (
                                <><p className='text-center p-2 s:text-[14px]'>Please Select Below Botname...</p>
                                    <Options
                                        botNames={botData}
                                        onSelectBot={handleBotSelection}
                                        setSelectedBotName={setSelectedBotName}
                                        setPage={setPage} /></>
                            )}
                            <div className="flex flex-col gap-2 overflow-y-auto justify-end m-1">
                                {showMessages && ShowPrevMessage ? <div className='flex justify-center items-center m-2 bg-inherit'>
                                    <button onClick={loadPreviousMessages} className='bg-[#e2e1e1de] rounded-full border-none border-b-[5px] text-black w-38 mt-1 px-4 py-1 hover:text-white transition ease-in-out delay-150 s:text-[12px] sm:text-[16px] md:text-[16px] text-[12px] xs:text-[14px] hover:-translate-y-1 hover:scale-110 hover:bg-[#9b7d00de] duration-300'>
                                        Prev Messages
                                    </button>
                                    <></>
                                </div> : noConversation &&
                                <div className="flex justify-center items-center bg-inherit">
                                    <p className="text-center bg-slate-300 py-1 px-2 rounded-lg mt-2 xs:text-[0.875rem] s:text-[0.875rem]">{noConversation}</p>
                                </div>}
                                <MessageParser showMessage={showMessages} messages={messages} setSelectedBotName={selectedBotName}/>
                            </div>

                        </div>
                        <form onSubmit={(e) => handleSubmit(e)}>
                            <div className='flex w-full absolute bottom-0 bg-[#efefef]'>
                                <input
                                    id="message"
                                    name="message"
                                    value={values.message}
                                    onChange={handleChange}
                                    placeholder="Type your message..."
                                    disabled={!inputEnabled}
                                    minLength={2}
                                    className='border-b-1 border-l-5 border-none text-sm py-3 px-5 flex-1'
                                />
                                <button onClick={handleSendClick} type='button' disabled={!inputEnabled} className='bg-[#9b7d00de] border-none border-b-[5px] text-white w-20 flex justify-center items-center'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="white"
                                        viewBox="0 0 512 512" className="bi bi-telegram"><path d="M476 3.2L12.5 270.6c-18.1 10.4-15.8 35.6 2.2 43.2L121 358.4l287.3-253.2c5.5-4.9 13.3 2.6 8.6 8.3L176 407v80.5c0 23.6 28.5 32.9 42.5 15.8L282 426l124.6 52.2c14.2 6 30.4-2.9 33-18.2l72-432C515 7.8 493.3-6.8 476 3.2z"></path></svg>
                                </button>
                            </div>
                        </form>
                    </div>
                )}
            </div>

            <div className='mt-16 text-end'>
                <div
                    onClick={toggleChatbot}
                    className="cursor-pointer p-3 inline-block bg-[#9b7d00de] rounded-tl-[6px] rounded-[22px] w-[56px] h-[56px] fixed right-3 md:right-8 sm:right-8 xs:right-3 s:right-2 bottom-5 z-50"
                >
                    {chatbotVisible ? (
                        <XMarkIcon className="w-full h-full text-white" />
                    ) : (
                        <ChatBubbleLeftRightIcon className="w-full h-full text-white" />
                    )}
                </div>
            </div>
        </div>

    );
};

export default ChatBoat;
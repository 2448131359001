import { Localstorage, getCurrentGuest, getCurrentUser } from "../core/AuthService";
import { useDispatch } from "react-redux";
import { setCartProducts } from "../redux/reducer/cartReducer";
import { CONSTANT } from "../core/StaticConstant";

const ProtectedRoute = (props: any) => {
  const { children } = props;
  const dispatch = useDispatch();
  if (!(getCurrentUser() || getCurrentGuest())) {
    dispatch(setCartProducts([]));
    Localstorage(CONSTANT.REMOVE,CONSTANT.USER_INFO);
    Localstorage(CONSTANT.REMOVE,CONSTANT.SELECTED_MENU);
    window.location.href = "/shop";

    // return <Navigate to="/shop" replace />;
    return null;
  } else {
    return children;
  }
};
export default ProtectedRoute;

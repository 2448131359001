import * as Yup from "yup";
import { CONSTANT } from "../../../../core/StaticConstant";

export const AddressSchema = Yup.object({
  fullName: Yup.string().required(CONSTANT.FULL_NAME),
  contactNo: Yup.string()
  .required(CONSTANT.CONTACT_NO)
  .matches(/^[0-9]+$/, "Must be only digits")
  .min(10, "Must be exactly 10 digits")
  .max(10, "Must be exactly 10 digits"),
  address1: Yup.string().required(CONSTANT.ADDRESS),
  postcode: Yup.string().required(CONSTANT.POST_CODE),
});

import * as jwt_decode from "jwt-decode";
import CryptoJS from "crypto-js";
import { CONSTANT } from "./StaticConstant";
import { Localstorage } from "./AuthService";
const secretCryptoJS : any = process.env.REACT_APP_CRYPTO_JS_SECRET_KEY;

export const isUndefineOrNull = (value: any) => {
  if (typeof value == "undefined" || value == null || value == "") {
    return true;
  } else {
    return false;
  }
};

export const getUserData = () => {
  const userInfo = Localstorage(CONSTANT.GET,CONSTANT.USER_INFO);
  let userDetails;

  if (userInfo) {
    userDetails = jwt_decode.default(userInfo);
  } else {
    userDetails = null;
  }

  return userDetails;
};

export const isObjEmpty = (obj: any) => {
  return typeof obj == "undefined" || obj === null || !Object.keys(obj).length;
};
export const isFloat = (n: any) => {
  return Number(n) === n && n % 1 !== 0;
};

export const isValidEmail = (email: string) => {
  return String(email)
    ?.toLowerCase()
    ?.match(
        /^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
    );
};

export const isValidPassword = (password: string) => {
  return String(password)?.match(
    /^(?=.*[A-Z])(?=.*[!@#$&*])(?=.*[0-9])(?=.*[a-z]).{8,}$/
  );
};

export const getCurrentUserData = (session: any) => {
  const val = Localstorage(CONSTANT.GET,session);
  const data = JSON.parse(`${val}`);
  return data;
};

export const getCurrentGuestData = (session: any) => {
  const val = Localstorage(CONSTANT.GET,session);
  const data = JSON.parse(`${val}`);
  return data;
};

export const isArrayEmpty = (arr: any) => {
  return arr == null || typeof arr === "undefined" || arr.length <= 0;
};

export const getPlanDetails = (plan: string) => {
  let months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  let date = new Date();
  switch (plan) {
    case "annual":
      return [
        "One year at <b>$29</b>",
        months[date.getMonth()] +
          ", " +
          date.getFullYear() +
          " - " +
          months[date.getMonth()] +
          ", " +
          (date.getFullYear() + 1),
      ];
      break;
    case "three":
      return [
        "Three years at <b>$49</b>",
        months[date.getMonth()] +
          ", " +
          date.getFullYear() +
          " - " +
          months[date.getMonth()] +
          ", " +
          (date.getFullYear() + 3),
      ];
      break;
    case "unpaid":
      return ["Free plan", "Lifetime"];
  }
};

const aesKey = CryptoJS.enc.Utf8.parse(secretCryptoJS);
const aesIv = CryptoJS.enc.Utf8.parse('0123456789abcdef');
const aesOptions = {
    iv: aesIv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
};

export const encryptData = (text: any) => {
  return CryptoJS.AES.encrypt(text || "", aesKey, aesOptions).ciphertext.toString();
}

export const decryptData = (text: any) => {
  const encoded : any = {ciphertext: CryptoJS.enc.Hex.parse(text|| "")};
  return CryptoJS.enc.Utf8.stringify(CryptoJS.AES.decrypt(encoded, aesKey, aesOptions));
}
import React from "react";
import {
  ChatBubbleLeftEllipsisIcon,
  EnvelopeIcon,
  PhoneIcon
} from "@heroicons/react/24/outline";

const ContactUs = () => {
  return (
    <>
      <div className="sm:py-[50px] py-[30px] sm:px-4">
        <div className="sm:text-[18px] text-[16px] font-bold text-silverChalice items-center flex gap-3 mb-3">
          <EnvelopeIcon className="w-6 h-6" />{" "}
          <span className="block">Email us</span>
        </div>
        <a
          href="mailto: support@greenlab.com"
          className="text-silverChalice text-xl	font-normal text-[#D9D9D9]"
        >
          support@greenlab.com
        </a>
      </div>
      <hr className="sm:max-w-[320px] w-full border-[#4e6059]" />
      <div className="sm:py-[50px] py-[30px] sm:px-4">
        <div className="sm:text-[18px] text-[16px] font-bold text-silverChalice items-center flex gap-3 mb-3">
          <PhoneIcon className="w-6 h-6" />{" "}
          <span className="block">Call us</span>
        </div>
        <a
          href="tel:1800-666-666"
          className="text-xl	font-normal text-[#D9D9D9]"
        >
          1800-666-666
        </a>
      </div>
      <hr className="sm:max-w-[320px] w-full border-[#4e6059]" />
      <div className="sm:py-[50px] py-[30px] sm:px-4">
        <div className="sm:text-[18px] text-[16px] font-bold text-silverChalice items-center flex gap-3 mb-3">
          <ChatBubbleLeftEllipsisIcon className="w-6 h-6" />{" "}
          <span className="block">Chat with us</span>
        </div>
        <p className="text-xl font-normal text-[#D9D9D9]">Start</p>
      </div>
    </>
  );
};

export default ContactUs;

import React from "react";

const ProductBenefit = (props: any) => {
  const { benefitsData } = props;
  return (
    <div className="mt-16  sm:px-6 md:px-8">
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 max-w-7xl mx-auto justify-center">
        {benefitsData &&
          benefitsData.map((ele: any, index: number) => {
            return (
              <div
                className="col-span-1 card"
                key={index}
              >
                <div className="flex gap-6">
                  <div className="w-[30%] max-w-[120px]">
                    <img
                      className="w-full object-cover"
                      src={ele?.benefitImage}
                      alt={ele?.benefitName}
                    />
                  </div>
                  <div className="w-[70%]">
                    <h2 className="text-midnightDream text-1xl md:text-2xl mt-2 font-bold">
                      {ele?.benefitName.toUpperCase()}
                    </h2>
                    <p className="text-midnightDream text-sm md:text-base font-normal max-w-[1200px]">
                      {ele?.longDescription}
                    </p>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default ProductBenefit;

import React from "react";
import { StarIcon as StarSolidIcon } from "@heroicons/react/24/solid";
import { StarIcon } from "@heroicons/react/24/outline";
import Rating from "@mui/material/Rating";
import Stack from "@mui/material/Stack";
import "./Reviews.css";

const Review = (props: any) => {
  const {
    review,
    reviewAverage,
    reviewsToShow,
    count,
    getCustomerReviewByProductId,
  } = props;

  return (
    <div
      className="sm:grid grid-cols-8 gap-10 bg-whiteSmoke pb-[5rem] sm:px-0 px-4"
      id="review"
    >
      <div className="col-span-1 xl:col-span-2"></div>
      <div
        className="col-span-6 xl:col-span-4 sm:mt-[9rem] mt-[5rem]"
        id="reviews-section"
      >
        <h1 className="inline-flex text-nero items-center lg:text-[30px] sm:text-[18px] text-[16px] s:text-[14px] font-bold sm:mb-[3rem] mb-[2rem]">
          <span className="mr-4">Customer Reviews:</span>
          <Stack>
            <Rating
              name="half-rating-read"
              className="color"
              value={reviewAverage || 0}
              precision={0.5}
              readOnly
            />
          </Stack>
          <p className="lg:text-[20px] sm:text-[16px] text-[14px] s:text-[12px] inline-flex sm:ml-[1.5rem] ml-[0.5rem] items-center">
            {reviewAverage}
          </p>
          <p className="lg:text-[18px] text-[14px] s:text-[12px] margin sm:ml-[3rem] ml-[1rem]">
            {count} reviews
          </p>
        </h1>
        {review &&
          review.map((ele: any, index: number) => {
            return (
              <div className="mt-[1rem]" key={index}>
                <h1 className="inline-flex text-nero lg:text-[22px] sm:text-[18px] text-[14px] font-bold">
                  {(ele && ele.userId && ele.userId.firstName) || ""}{" "}
                  <div className="lg:text-[20px] text-[16px] inline-flex ml-[10px] items-center">
                    <Stack>
                      <Rating
                        name="half-rating-read"
                        className="color"
                        value={ele.review || 0}
                        precision={0.5}
                        readOnly
                      />
                    </Stack>
                    <span className="ml-4">{ele && ele.review ? ele.review.toFixed(2) : "" }</span>
                  </div>
                </h1>
                <p className="lg:text-[18px] sm:text-[16px] text-[14px] mt-[1rem] text-nero font-bold">
                  {ele.reviewTitle}
                </p>
                <p className="sm:text-[16px] text-[12px] text-nero">
                  {ele?.reviewDescription}
                </p>
              </div>
            );
          })}
        {reviewsToShow < count && (
          <div
            className="mt-[1rem] text-nero sm:text-[18px] text-[14px] font-medium cursor-pointer"
            onClick={() => getCustomerReviewByProductId()}
          >
            Read more
          </div>
        )}
      </div>
      <div className="col-span-1 xl:col-span-2"></div>
    </div>
  );
};

export default Review;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { OrderService } from "../../../services/order.services";
import { CONSTANT } from "../../../core/StaticConstant";
import { getUserData } from "../../../core/Utils";
import { Localstorage } from "../../../core/AuthService";

const OrderSuccess = () => {
    const orderId = useSelector((state: any) => state.orderId);

    const dispatch = useDispatch();
    const [userEmail, setUserEmail] = useState<String>("");
    const [OrderMessage, setOrderMessage] = useState<String>("Your order Processing...");
    const [orderNumber, setOrderNumber] = useState<string>();
    const [orderID, setOrderID] = useState<string>("");


    const navigate = useNavigate();

    const getOrderById = async () => {
        if (orderId) {
            try {
                const response = await OrderService.getOrderNumberById(orderId);

                if (response.status === CONSTANT.SUCCESS) {
                    setOrderNumber(response?.data?.order?.orderNumber);
                }
            } catch (error) {
                console.error(error);
            }
        }
    };


    useEffect(() => {
        const userData: any = getUserData();
        setUserEmail(userData?.email);
        getOrderById();
        const shouldRedirect = Localstorage(CONSTANT.GET,CONSTANT.REDIRECT_FROM_ORDER_SUCCESS);
        setTimeout(() => {
            setOrderMessage("Order Placed Successfully!")
        }, 3000)
        if (shouldRedirect) {
            Localstorage("remove",CONSTANT.REDIRECT_FROM_ORDER_SUCCESS);
            navigate("/shop");
        }

        const handleBeforeUnload = () => {
            Localstorage(CONSTANT.SET,CONSTANT.REDIRECT_FROM_ORDER_SUCCESS,CONSTANT.TRUE);
        };

        window.addEventListener("beforeunload", handleBeforeUnload);

        return () => {
            window.removeEventListener("beforeunload", handleBeforeUnload);
        };
    }, []);

    return (
        <div className="pl-16 mt-8">
            <h2 className={`${OrderMessage === 'Your order Processing...' ? "text-olive" : "text-darkgreen"} font-bold md:text-[25px] xl:text-[35px]`}>
                {OrderMessage}
            </h2>
            <p className="text-midnightDream font-bold text-[25px] md:text-[50px] xl:text-[70px]">
                Thankyou for choosing <span>GREENLAB</span>
            </p>
            <p className="text-lightblack text-[16px] font-bold mt-16">
                Order Number: {orderNumber}
            </p>
            <p className="text-miniblack font-[400] text-[16px]">
                You will receive order confirmation email shortly on{" "}
                <span className="text-midnightDream font-bold">{userEmail}</span>
            </p>
        </div>
    );
};

export default OrderSuccess;

import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { CONSTANT } from "./StaticConstant";

export const Localstorage = (methodType: string,key:string, data?: string) => {
    if (methodType === CONSTANT.SET) {
        localStorage.setItem(key, data!);
    } else if (methodType === CONSTANT.GET) {
        return localStorage.getItem(key);
    } else if (methodType === CONSTANT.REMOVE) {
        localStorage.removeItem(key);
    }
};

export const authHeader = () => {
    const userInfo: any = Localstorage(CONSTANT.GET, CONSTANT.USER_INFO);
  const user: any = JSON.parse(userInfo);
  if (user && user?.token) {
    return { Authorization: "Bearer " + user };
  } else {
    return {};
  }
};

export const getCurrentUser = () => {
  const userInfo: any = Localstorage(CONSTANT.GET,CONSTANT.USER_INFO);
  const userData: any = JSON.parse(userInfo);
  if (userData) {
    const decodedJwt = parseJwt(userData);
    if (decodedJwt?.exp * 1000 < Date.now()) {
      return false;
    }
  }
  return userData;
};

export const getCurrentGuest = () => {
  const guestInfo: any = Localstorage(CONSTANT.GET,CONSTANT.USER);
  if (guestInfo) {
    const decodedJwt = parseJwt(guestInfo);
    if (decodedJwt?.exp * 1000 < Date.now()) {
      return false;
    }
  }
  return guestInfo;
};

export const parseJwt = (token: string) => {
  try {
    return JSON.parse(atob(token.split(".")[1]));
  } catch (e) {
    return null;
  }
};

export const isLoggedIn = () => {
    const userInfo: any = Localstorage(CONSTANT.GET,CONSTANT.USER_INFO);
  const guestInfo: any = Localstorage(CONSTANT.GET,CONSTANT.USER);
  if (userInfo || guestInfo) {
    return true;
  }
  return false;
};

export const SelectedMenu = () => {
  const [isShopSelected, setIsShopSelected] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (
      location.pathname.includes("/shop") ||
      location.pathname.includes("/self-train")
    ) {
      setIsShopSelected(true);
    } else {
      setIsShopSelected(false);
    }
  }, [location]);

  return isShopSelected;
};

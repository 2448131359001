import React, { useState } from "react";
import img from "../../assets/images/login-background.png";
import mobileImg from "../../assets/images/mobile-login.png";
import Logo from "../../assets/images/white-greenlab-logo.png";
import "./Login.css";
import { ChevronRightIcon } from "@heroicons/react/24/outline";
import { UserService } from "../../services";
import { CONSTANT } from "../../core/StaticConstant";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { logInSchema } from "./LoginSchema";
import { useNavigate } from "react-router-dom";
import { storeUserData } from "../../redux/reducer/cartReducer";
import { useDispatch } from "react-redux";
import { Localstorage } from "../../core/AuthService";

const initialValues = {
  email: "",
  password: "",
  userType: CONSTANT.ROLES.USER,
  userId: "",
  otp: "", 
  newPassword: "", 
  confirmPassword: "",
  token: "", 
};

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [forgotPassword, setForgotPassword] = useState(false);
  const [verificationSuccess, setVerificationSuccess] = useState(false);
  const [forgetUserId, setForgetUserId] = useState("");
  const [dataToken, setDataToken] = useState("");
  const [showNewPassword, setShowNewPassword] = useState(false);

  const { values, errors, touched, handleChange, handleSubmit,setFieldValue } = useFormik({
    initialValues,
    validationSchema: logInSchema,
    onSubmit: async (values, action) => {
        try {
            setIsLoading(true);
            let obj = {
                email: values.email,
                password:values.password,
                userType: CONSTANT.ROLES.USER,
                mode:CONSTANT.MODE.NORMAL,
            }
            let data = await UserService.loginUser(obj);
            setIsLoading(false);
            if (data && data.status === CONSTANT.SUCCESS) {
                dispatch(storeUserData(data.data)); 
                action.resetForm();
                Localstorage(CONSTANT.SET,CONSTANT.USER_INFO,JSON.stringify(data.data.token));
                toast.success(data.message);
                const lastVisitedPage = Localstorage(CONSTANT.GET,CONSTANT.LAST_VISITED_PAGE);
                if (lastVisitedPage) {
                    Localstorage(CONSTANT.REMOVE,CONSTANT.LAST_VISITED_PAGE);
                    window.location.href = lastVisitedPage;
                } else { 
                    window.location.href = "/";
                }
            } else {
                toast.error(data.message);
            }
        } catch (err: any) {
            console.error("error ", err.response )
            toast.error((err?.response?.data?.error));
            setIsLoading(false);
        }
    },
});

  const forgotpassword = async () => {
    try {
      setIsLoading(true);
      let obj={
        email:values.email
      }
      const response = await UserService.forgotPassword(obj);
      setIsLoading(false);
      if (response && response.status === CONSTANT.SUCCESS) {
        toast.success(response.message);

        setForgetUserId(response.data.userId);
        setVerificationSuccess(true);
      } else {
        toast.error(response.message);
      }
    } catch (err:any) {
      toast.error(err?.response?.data?.error)
      setIsLoading(false);
    }
  };

  const handleOtpChange = (event: any) => {
    setFieldValue("otp", event.target.value);
  };

  const handlePasswordChange = (event: any) => {
    setFieldValue("newPassword", event.target.value);
  };

  const handleConfirmPasswordChange = (event: any) => {
    setFieldValue("confirmPassword", event.target.value);
  };

  const verifyOtp = async () => {
    try {
      setIsLoading(true);

      const otpData = {
        otp: values.otp,
        userId: forgetUserId,
      };
      const response = await UserService.verifyUserToken(otpData);

      setIsLoading(false);

      if (response && response.status === CONSTANT.SUCCESS) {
        toast.success(response.message);
        setDataToken(response.data.token);
        setVerificationSuccess(true);
        setShowNewPassword(true);
      } else {
        toast.error(response.message);
      }
    } catch (err:any) {
      console.error('errorOtp',err.response);
      toast.error(err?.response?.data?.error);
      setIsLoading(false);
    }
  };

  const resetpassword = async () => {
    try {
      setIsLoading(true);

      const resetData = {
        // userId: forgetUserId,
        password: values.newPassword,
        confirmPassword: values.confirmPassword,
        token: dataToken,
      };

      const response = await UserService.resetPasswordWithToken(resetData);

      setIsLoading(false);

      if (response && response.status === CONSTANT.SUCCESS) {
        toast.success(response.message);

        setVerificationSuccess(false);
        setForgotPassword(false);
        setForgetUserId("");
        setDataToken("");
        setFieldValue("newPassword", ""); 
        setFieldValue("confirmPassword", ""); 
      } else {
        toast.error(response.message);
      }
    } catch (err:any) {
      console.error(err);
      toast.error(err?.response?.data?.error);
      setIsLoading(false);
    }
  };

  return (
    <div className="bg-capeCod min-h-screen">
      <div className="sm:flex mt-0 sm:justify-center pt-16 xs:pt-10 s:pt-10 lg:min-w-[200px] lg:max-w-[200px] max-w-[150px] mx-auto mb-3">
        <img src={Logo} alt="GREEN LAB" />
      </div>
      <div className="sm:flex mt-0 sm:justify-center pb-8 xs:pb-[20px] s:pb-[20px]">
        <p className="text-greenFooter xs:text-[9px] s:text-[9px] xs:tracking-[3.6px] s:tracking-[2.1px] text-[14px] tracking-[4.8px] text-center">
          CHEMISTRY THAT UNDERSTANDS YOUR BIOLOGY
        </p>
      </div>
      <div className="grid grid-cols-6 xs:p-[10px] s:p-[10px] p-4 gap-4">
        <div className="xl:col-span-1"></div>
        <div className="xl:col-span-4 col-span-6">
          <div className="grid xs:block s:block background bg-graygreen 2xl:w-[full] 2xl:h-[685px] grid-cols-3 gap-4">
            <div className="col-span-1">
              <img
                className="w-full h-full xs:hidden s:hidden object-cover p-[6px]"
                src={img}
                alt="Sunset in the mountains"
              />
              <img
                className="w-full h-full xs:block s:block hidden object-fit p-[6px]"
                src={mobileImg}
                alt="Sunset in the mountains"
              />
            </div>
            <div className="col-span-2 s:p-[6px] xs:p-[6px] c-sm:p-4 c-md:p-8 p-16 align">
              {!forgotPassword && !verificationSuccess && (
                <>
                  <h4 className="text-greenFooter xs:text-[20px] s:text-[20px] 2xl:pt-[50px] 2xl:pb-[21px] font-bold text-3xl">
                    LOGIN
                  </h4>
                  <hr className="border-silver border-t-[1px] px-8 xl:w-[98%] border-bottom" />

                  <p className="text-greenFooter xs:text-[9px] s:text-[9px] xs:tracking-[3.6px] s:tracking-[2.1px] font-[12] 2xl:pt-[31px] tracking-[4.0px] text-[14px]">
                    EMAIL
                  </p>
                  <input
                    className="bg-capeCod w-[98%] mt-2.5 text-[white] focus focus:outline-none md:px-8 xs:px-4 s:px-4 inner-shadow h-10 xs:h-9 s:h-9"
                    value={values.email}
                    name="email"
                    onChange={handleChange}
                  />
                  {errors.email && touched.email ? (
                    <p className="form-error text-[red]">{errors.email}</p>
                  ) : null}

                  <p className="text-greenFooter xs:text-[9px] s:text-[9px] xs:tracking-[3.6px] s:tracking-[2.1px] mt-2 font-[12] 2xl:pt-[21px] tracking-[4.0px] text-[14px]">
                    PASSWORD
                  </p>
                  <input
                    className="bg-capeCod w-[98%] mt-2.5 text-[white] md:px-8 xs:px-4 s:px-4 focus:outline-none inner-shadow h-10 xs:h-9 s:h-9"
                    type="password"
                    value={values.password}
                    name="password"
                    onChange={handleChange}
                  />
                  {errors.password && touched.password ? (
                    <p className="form-error text-[red]">{errors.password}</p>
                  ) : null}

                  <div className="grid w-[98%] grid-cols-2 gap-4 md:pt-[31px]">
                    <div className="xl:col-span-1 col-span-1">
                      <div className="mb-[0.125rem] flex items-center gap-1 min-h-[1.5rem]">
                        <input
                          className="bg-silver"
                          type="checkbox"
                          value=""
                          id="checkboxChecked"
                          defaultChecked
                        />
                        <label
                          className="text-greenFooter xs:text-[9px] s:text-[9px] xs:tracking-[3.6px] s:tracking-[2.1px] text-[14px] pl-[0.15rem] hover:cursor-pointer tracking-[4.0px]"
                          htmlFor="checkboxChecked"
                        >
                          REMEMBER ME
                        </label>
                      </div>
                    </div>
                    <div className="xl:col-span-1 col-span-1 flex items-center justify-end">
                      <p className="text-greenFooter xs:text-[9px] s:text-[9px] xs:tracking-[3.6px] s:tracking-[2.1px] text-end hover:cursor-pointer tracking-[4.0px] text-[14px]">
                        <span onClick={() => setForgotPassword(true)}>
                          FORGOT PASSWORD
                        </span>
                      </p>
                    </div>
                  </div>

                  <button
                    className={`s:hidden pt-8 font-bold hover:cursor-pointer inline-flex text-[25px] ${
                      isLoading ? "text-gray-500" : "text-greenFooter"
                    }`}
                    onClick={() => handleSubmit()}
                    disabled={isLoading}
                  >
                    <span>Login</span>
                    <span>
                      <ChevronRightIcon className="h-6 w-8 mt-2" />
                    </span>
                  </button>
                </>
              )}

              {forgotPassword && !verificationSuccess && (
                <>
                  <p className="text-greenFooter xs:text-[20px] s:text-[20px] 2xl:pt-[50px] 2xl:pb-[21px] font-bold text-3xl">
                    FORGOT PASSWORD
                  </p>
                  <hr className="border-silver border-t-[1px] px-8 xl:w-[98%] border-bottom" />

                  <p className="text-greenFooter xs:text-[9px] s:text-[9px] xs:tracking-[3.6px] s:tracking-[2.1px] font-[12] 2xl:pt-[31px] tracking-[4.0px] text-[14px]">
                    EMAIL
                  </p>
                  <input
                    className="bg-capeCod w-[98%] mt-2.5 text-[white] focus focus:outline-none md:px-8 xs:px-4 s:px-4 inner-shadow h-10 xs:h-9 s:h-9"
                    value={values.email}
                    name="email"
                    onChange={handleChange}
                  />
                  {errors.email && touched.email ? (
                    <p className="form-error text-[red]">{errors.email}</p>
                  ) : null}

                  <button
                    className={`s:hidden xs:hidden pt-8 font-bold hover:cursor-pointer inline-flex text-[23px] ${
                      isLoading ? "text-gray-500" : "text-greenFooter"
                    }`}
                    onClick={forgotpassword}
                    disabled={isLoading}
                  >
                    <span>GET OTP</span>
                    <span>
                      <ChevronRightIcon className="h-6 w-8 mt-1" />
                    </span>
                  </button>
                  <div className="xl:col-span-1 col-span-1 flex items-center justify-end">
                    <p className="text-greenFooter xs:text-[9px] s:text-[9px] xs:tracking-[3.6px] s:tracking-[2.1px] text-end hover:cursor-pointer tracking-[4.0px] text-[14px]">
                      <span onClick={() => setForgotPassword(false)}>
                        GO TO LOGIN PAGE
                      </span>
                    </p>
                  </div>
                </>
              )}

              {verificationSuccess && !showNewPassword && (
                <>
                  <h4 className="text-greenFooter xs:text-[20px] s:text-[20px] 2xl:pt-[50px] 2xl:pb-[21px] font-bold text-3xl">
                    ENTER OTP
                  </h4>
                  <p className="text-greenFooter xs:text-[9px] s:text-[9px] xs:tracking-[3.6px] s:tracking-[2.1px] font-[12] 2xl:pt-[31px] tracking-[4.0px] text-[14px]">
                    ENTER OTP
                  </p>
                  <input
                    className="bg-capeCod w-[98%] mt-2.5 text-[white] focus focus:outline-none md:px-8 xs:px-4 s:px-4 inner-shadow h-10 xs:h-9 s:h-9"
                    value={values.otp}
                    name="otp"
                    onChange={handleOtpChange}
                  />
                  <button
                    className={`s:hidden xs:hidden pt-8 font-bold hover:cursor-pointer inline-flex text-[23px] ${
                      isLoading ? "text-gray-500" : "text-greenFooter"
                    }`}
                    onClick={verifyOtp}
                    disabled={isLoading}
                  >
                    <span>Submit OTP</span>
                    <span>
                      <ChevronRightIcon className="h-6 w-8 mt-2" />
                    </span>
                  </button>
                  <div className="xl:col-span-1 col-span-1 flex items-center justify-end">
                    <p className="text-greenFooter xs:text-[9px] s:text-[9px] xs:tracking-[3.6px] s:tracking-[2.1px] text-end hover:cursor-pointer tracking-[4.0px] text-[14px]">
                      <span onClick={() => setVerificationSuccess(false)}>
                        BACK
                      </span>
                    </p>
                  </div>
                </>
              )}

              {verificationSuccess && showNewPassword && (
                <>
                  <h4 className="text-greenFooter xs:text-[20px] s:text-[20px] 2xl:pt-[50px] 2xl:pb-[21px] font-bold text-3xl">
                    RESET PASSWORD
                  </h4>
                  <p className="text-greenFooter xs:text-[9px] s:text-[9px] xs:tracking-[3.6px] s:tracking-[2.1px] font-[12] 2xl:pt-[31px] tracking-[4.0px] text-[14px]">
                    NEW PASSWORD
                  </p>
                  <input
                    className="bg-capeCod w-[98%] mt-2.5 text-[white] focus focus:outline-none md:px-8 xs:px-4 s:px-4 inner-shadow h-10 xs:h-9 s:h-9"
                    type="password"
                    value={values.newPassword}
                    name="newPassword"
                    onChange={handlePasswordChange}
                  />

                  <p className="text-greenFooter xs:text-[9px] s:text-[9px] xs:tracking-[3.6px] s:tracking-[2.1px] font-[12] 2xl:pt-[21px] tracking-[4.0px] text-[14px]">
                    CONFIRM PASSWORD
                  </p>
                  <input
                    className="bg-capeCod w-[98%] mt-2.5 text-[white] focus focus:outline-none md:px-8 xs:px-4 s:px-4 inner-shadow h-10 xs:h-9 s:h-9"
                    type="password"
                    value={values.confirmPassword}
                    name="confirmPassword"
                    onChange={handleConfirmPasswordChange}
                  />

                  <button
                    className={`s:hidden xs:hidden pt-8 font-bold hover:cursor-pointer inline-flex text-[23px] ${
                      isLoading ? "text-gray-500" : "text-greenFooter"
                    }`}
                    onClick={resetpassword}
                    disabled={isLoading}
                  >
                    <span>RESET PASSWORD</span>
                    <span>
                      <ChevronRightIcon className="h-6 w-8 mt-2" />
                    </span>
                  </button>
                  <div className="xl:col-span-1 col-span-1 flex items-center justify-end">
                    <p className="text-greenFooter xs:text-[9px] s:text-[9px] xs:tracking-[3.6px] s:tracking-[2.1px] text-end hover:cursor-pointer tracking-[4.0px] text-[14px]">
                      <span onClick={() => setShowNewPassword(false)}>
                        BACK
                      </span>
                    </p>
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="grid lg:grid-cols-3 display pt-4 bg-capeCod">
            <div className="lg:col-span-1"></div>
            <div className="lg:col-span-1">
              <button
                onClick={() => navigate("/signup")}
                className="text-greenFooter xs:text-[9px] s:text-[9px] xs:tracking-[3.6px] s:tracking-[2.1px] text-[12] hover:cursor-pointer tracking-[4.0px] text-[14px] text-left"
              >
                DON'T HAVE AN ACCOUNT?
              </button>
            </div>
            <div className="lg:col-span-1 text-right">
              <button
                onClick={() => navigate("/signup")}
                className="text-end xs:text-[9px] s:text-[9px] xs:tracking-[3.6px] s:tracking-[2.1px] text-greenFooter font-[700] hover:cursor-pointer tracking-[4.0px] text-[14px]"
              >
                REGISTER NEW ACCOUNT
              </button>
            </div>
          </div>
          {/*<div className="grid xs:grid-cols-4 s:grid-cols-4 c-md:grid-cols-4 c-sm:grid-cols-4 lg:hidden pt-4 bg-capeCod">
            <div className="xs:col-span-2 s:col-span-2 c-md:col-span-2 c-sm:col-span-2">
              <button
                onClick={() => navigate("/signup")}
                className="text-greenFooter xs:text-[9px] s:text-[9px] xs:tracking-[3.6px] s:tracking-[2.1px] text-[12] hover:cursor-pointer tracking-[4.0px] text-[14px] text-left"
              >
                DON'T HAVE AN ACCOUNT?
              </button>
            </div>
            <div className="xs:col-span-2 s:col-span-2 c-md:col-span-2 c-sm:col-span-2 text-right">
              <button
                onClick={() => navigate("/signup")}
                className="text-end xs:text-[9px] s:text-[9px] xs:tracking-[3.6px] s:tracking-[2.1px] text-greenFooter font-[700] hover:cursor-pointer tracking-[4.0px] text-[14px]"
              >
                REGISTER NEW ACCOUNT
              </button>
            </div>
          </div> */}
        </div>
        <div className="xl:col-span-1"></div>
      </div>
    </div>
  );
};

export default Login;

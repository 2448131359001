import React from "react";
import { CONSTANT } from "../../core/StaticConstant";
import {
  ArrowUpTrayIcon,
  ClockIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/24/outline";
import { useDispatch, useSelector } from "react-redux";
import { setCartProducts } from "../../redux/reducer/cartReducer";
import { Localstorage } from "../../core/AuthService";

const SignOut = () => {
  const dispatch = useDispatch();

  const onSignOut = () => {
    dispatch(setCartProducts([]));
    Localstorage(CONSTANT.REMOVE,CONSTANT.USER_INFO);
    Localstorage(CONSTANT.REMOVE,CONSTANT.SELECTED_MENU);
    Localstorage(CONSTANT.REMOVE,CONSTANT.USER_NAME);
    Localstorage(CONSTANT.REMOVE,CONSTANT.USER_AGE)
    Localstorage(CONSTANT.REMOVE,CONSTANT.DELIVERY_CHARGE);
    Localstorage(CONSTANT.REMOVE,CONSTANT.SELECTED_CARD_INDEX);
    Localstorage(CONSTANT.REMOVE, CONSTANT.CUSTOMER_ID);
    window.location.href = "/shop";
  };

  return (
    <>
      <div className="sm:pb-[68px] sm:pt-0 pb-[40px] pt-[40px]">
        <button
          className="text-[18px] font-bold text-gray85 items-center flex gap-3 sm:px-4 hover:text-white"
          onClick={() => onSignOut()}
        >
          <ArrowUpTrayIcon className="-rotate-90 w-6 h-6" />{" "}
          <span className="block">Confirm Sign Out</span>
        </button>
      </div>
      <hr className="sm:max-w-[320px] w-full border-[#4e6059]" />
      <div className="sm:pt-[68px] pt-[40px]">
        <div className="py-[10px]">
          <button className="text-silverChalice flex gap-3 sm:px-4">
            <ClockIcon className="w-6 h-6" />{" "}
            <span className="block">Deactivate my account temporarily</span>
          </button>
        </div>
        <div className="py-[10px]">
          <button className="text-silverChalice flex gap-3 sm:px-4">
            <ExclamationTriangleIcon className="w-6 h-6" />{" "}
            <span className="block">Delete my account permanently</span>
          </button>
        </div>
      </div>
    </>
  );
};

export default SignOut;

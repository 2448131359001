import { Rating, Stack } from "@mui/material";
import ReactPlayer from "react-player";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import SelectPerfectClass from "../select-perfect-class/SelectPerfectClass";
const VideoAbout = (props: any) => {
  const { courseData } = props;

  const scrollDownNextElment = (id: string) => {
    const reviewsSection: any = document.getElementById(id);
    reviewsSection.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className="bg-midnightDream 2xl:px-40 xl:px-11 lg:px-11 md:px-11 xs:px-4 s:px-4 sm:px-10 sm:pt-10 md:px-16 md:pt-16 pt-6 px-6">
      <div className="lg:grid lg:grid-cols-8">
        <div className="col-span-4">
          {/* <ReactPlayer
            className=''
            width="100%"
            height="100%"
            url={courseData.courseHoverVideo}
            playing={true}
            loop={true}
          /> */}
          <img className='w-full h-full 2xl:w-[700px] 2xl:h-[92%] ' src={courseData.courseIntroVideo ? courseData.courseIntroVideo : ""} />
        </div>
        <div className="col-span-4 lg:pl-10 ">
          <p className="text-white font-bold 2xl:leading-[60px] text-[30px] 2xl:text-[50px]">
            {courseData.courseLongTitle ? courseData.courseLongTitle: "N/A"}  
          </p>
          <p className="text-midLightBlack text-[15px] xxs:pt-4 lg:pt-3">
            {courseData.courseShortTitle ? courseData.courseShortTitle:"N/A"}
          </p>
          <div className="pt-6 pt-4 pb-3">
            <Stack>
              <Rating
                name="half-rating-read"
                value={4.5}
                precision={0.5}
                readOnly
                sx={{
                  "& .MuiRating-icon": {
                    color: "#d9d9d9",
                  },
                }}
              />
            </Stack>
          </div>
          <div>


            <div>
              {courseData.courseBenefits && courseData.courseBenefits.map((benefit: any, index: any) => (
                <p key={index} className="text-midLightBlack font-[300] text-[16px]">
                  - {benefit}
                </p>
              ))}
            </div>



          </div>
          <hr className="border-silver sm:mt-16 border-t-[1px]" />
          <div className="flex justify-between items-end my-8 sm:my-16">
            <h2 className="text-white font-bold text-[18px]">
              {courseData?.courseBenefit1Name ? courseData.courseBenefit1Name: "N/A"}
            </h2>
            <div>
              <p className="text-white flex items-center gap-1 text-[12px]">
                Total <span className="text-[25px]">{courseData.coursePrice ? `£${courseData.coursePrice.toFixed(2)}` : "N/A"}</span>
                
                
              </p>
              <button className="whitespace-nowrap bg-white  3xl:text-[18px] text-[14px] text-bold 2xl:h-[54px] sm:h-[46px] h-[40px] opacity-100 2xl:px-[30px] md:px-5 px-4 proceed-to-checkout sm:mt-0 mt-2 flex items-center gap-2 text-gray85">
                <span className="font-bold text-[20px] text-darkshadegreen">
                  Enroll Now
                </span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="w-6 h-5 font-bold text-darkshadegreen"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M4.5 19.5l15-15m0 0H8.25m11.25 0v11.25"
                  />
                </svg>
              </button>
            </div>
          </div>
          <hr className="border-silver border-t-[1px]" />
        </div>
      </div>
      <div className="text-align-webkit-center pt-12">
        <button className="text-[#5D5D5D] xs:text-[14px] text-align-webkit-center text-grayishBlue font-light text-[16px]"
          onClick={() => scrollDownNextElment("SelectPerfectClass")}>
          Know more about your product{" "}
          <p className="text-align-webkit-center">
            <ChevronDownIcon className="w-[11%] text-silverChalice stroke-[4]" />
          </p>
        </button>
      </div>
    </div>
  );
};

export default VideoAbout;

import { CheckIcon } from "@heroicons/react/24/outline";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AddressService } from "../../../../services/address.service";
import { CONSTANT } from "../../../../core/StaticConstant";
import { toast } from "react-toastify";
import Loader from "../../../loader/Loader";
import checkMarkIcon from "../../../../assets/images/check-mark-icon.svg";
import {
    addressLength,
    selectEditAddressId,
    selectedAddressId,
    setOrder,
    setSelectDeliveryAddress,
} from "../../../../redux/reducer/cartReducer";
import { getUserData, isUndefineOrNull } from "../../../../core/Utils";
import { CartService } from "../../../../services";
import { Localstorage } from "../../../../core/AuthService";

const Address = (props: any) => {
    const { onLoaderChange, isParentLoading, getCustomerByEmail } = props;

    const [address, setAddress] = useState<any[]>();
    const [userId, setUserId] = useState<String>("");
    const deliveryAddressId = useSelector(
        (state: any) => state.selectDeliveryAddress._id
    );
    const dispatch = useDispatch();

    // useEffect(() => {
    // createCart()
    // }, [])

    const cartProduct = useSelector((state: any) => state.cartProducts);

    const createCart = async () => {
        try {
            const getUserId: any = getUserData();
            let obj = {
                cart: cartProduct,
            };
            await CartService.createCart(obj);
        }
        catch (err: any) {
            toast.error((err?.response?.data?.error));
        }
    }

    useEffect(() => {
        const userData: any = getUserData();
        setUserId(userData?.userId);
        getAllAddressByUser(userData?.userId);
    }, []);

    const getAllAddressByUser = async (id: any) => {
        onLoaderChange(true);

        const guest: any = getUserData();

        var newUserId = userId;
        if (isUndefineOrNull(userId) && guest) {
            setUserId(guest["userId"]);
            newUserId = guest["userId"];
        }

        try {
            const response = await AddressService.getAllAddressByUserId();
            setTimeout(() => {
                onLoaderChange(false);
            }, 1000);
            
            if (response.status === CONSTANT.SUCCESS) {
                setAddress(response?.data?.addresses?.userAddress);
                dispatch(addressLength(response?.data?.addresses.userAddress.length));
                let selectAddress = response.data.addresses.userAddress.filter(
                    (ele: any) => ele.isDefault === true
                );
                (selectAddress && selectAddress.length > 0) && dispatch(selectedAddressId(selectAddress[0]?._id));
                    
                //     let selectAddress: any = [];
                //     if (response?.data?.addresses?.userAddress) {
                //     console.log("User Addresses:", response.data.addresses.userAddress);
                //     setAddress(response.data.addresses.userAddress);
                //     dispatch(addressLength(response.data.addresses.userAddress.length));
                //     const selectAddress = (ele: any) => {
                //         dispatch(setSelectDeliveryAddress(ele));
                //         dispatch(selectedAddressId(ele._id)); 
                //     };
                // } else {
                //     console.error("userAddress is undefined or null in the response.");
                // }
            } else {
                console.error("userAddress is undefined or null in the response.")
            }
        } catch (error) {
            console.error(error);
            setTimeout(() => {
                onLoaderChange(false);
            }, 1000);
        }
    };

    const selectAddress = (ele: any) => {
        dispatch(setSelectDeliveryAddress(ele));
        dispatch(selectedAddressId(ele._id));
    };

    const onClickAddressSelect = (id: any) => {
        const customerId = Localstorage(CONSTANT.GET, CONSTANT.CUSTOMER_ID);
        if(!customerId) {
            getCustomerByEmail();
        }
        dispatch(setOrder(id));
        dispatch(selectedAddressId(deliveryAddressId));
    };

    const onEditClick = (id: any, addressId: any) => {
        dispatch(setOrder(id));
        dispatch(selectEditAddressId(addressId));
        onLoaderChange(true)
    };

    return (
        <>
            {isParentLoading && (
                <div className="absolute top-[50%] z-50 left-[50%]  transform translate-x-[-50%] translate-y-[-50%]">
                    <Loader />
                </div>
            )}
            <div>
                {address &&
                    address.map((ele: any, index: number) => {
                        return (
                            <div
                                className={`flex justify-between px-8 mt-4 py-4 ${deliveryAddressId === ele?._id ? " bg-nero" : "bg-lessgray"
                                    }`}
                                key={index}
                                onClick={() => selectAddress(ele)}
                            >
                                <div className="max-w-[300px]">
                                    <h2
                                        className={`font-[700] text-[15px] tracking-normal ${deliveryAddressId === ele?._id
                                            ? "text-[white]"
                                            : "text-[black]"
                                            }`}
                                    >
                                        {ele?.fullName}
                                    </h2>
                                    <p
                                        className={`text-[15px] tracking-normal ${deliveryAddressId === ele?._id
                                            ? "text-[white]"
                                            : "text-[black]"
                                            }`}
                                    >
                                        {ele?.address1 ? `${ele?.address1}` : ""}{" "}
                                        {ele?.landMark ? `, ${ele?.landMark}` : ""}{" "}
                                        {ele?.admin_district ? `, ${ele?.admin_district}` : ""}{" "}
                                        {ele?.postcode ? `, ${ele?.postcode}` : ""}{" "}
                                        {ele?.region ? `, ${ele?.region}` : ""}
                                    </p>
                                    <p
                                        className={`font-[700] text-[15px] tracking-normal mt-4 ${deliveryAddressId === ele?._id
                                            ? "text-[white]"
                                            : "text-[black]"
                                            }`}
                                    >
                                        Contact: +91-{ele?.contactNo}
                                    </p>
                                    <div className="flex gap-4 mt-4">
                                        <h2
                                            className={`font-[700] text-[12px] cursor-pointer tracking-normal ${deliveryAddressId === ele?._id
                                                ? "text-[white]"
                                                : "text-[black]"
                                                }`}
                                            onClick={() => onEditClick("addAddress", ele?._id)}
                                        >
                                            Edit
                                        </h2>
                                        <p className="border"></p>
                                        <p
                                            className={`font-[700] text-[12px] tracking-normal ${deliveryAddressId === ele?._id
                                                ? "text-[white]"
                                                : "text-[black]"
                                                }`}
                                        >
                                            Add Instructions
                                        </p>
                                    </div>
                                </div>
                                <div className="my-auto">
                                    <img
                                        src={checkMarkIcon}
                                        alt="checkMarkIcon"
                                        className={`w-[50px] text-[white] ${deliveryAddressId === ele?._id ? "visible" : "invisible"
                                            }`}
                                    />
                                </div>
                            </div>
                        );
                    })}
                <div className="text-end mt-6">
                    {address && address.length > 0 ? (
                        <button
                            className="text-[15px] px-10 py-3 font-bold bg-midnightDream text-white tracking-wide"
                            onClick={() => onClickAddressSelect("deliveryOption")}
                        >
                            Use Selected Address
                        </button>
                    ) : (
                        <button className="text-[15px] px-10 py-3 font-bold bg-greenFooter text-white tracking-wide">
                            Use Selected Address
                        </button>
                    )}
                </div>
            </div>
        </>
    );
};

export default Address;

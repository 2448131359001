import React, { useEffect, useState } from 'react'
import VideoBanner from '../../../components/self-train/video-banner/VideoBanner'
import CategoryNav from '../../../components/sub-nav/SubNav'
import GuideYoga from '../../../components/self-train/guide-yoga/GuideYoga';
import VideoAbout from '../../../components/self-train/video-about/VideoAbout';
import SelectPerfectClass from '../../../components/self-train/select-perfect-class/SelectPerfectClass';
import FaqData from '../../../components/product/faq';
import WatchAnywhere from '../../../components/self-train/watch-anywhere/WatchAnywhere';
import Testimonials from '../../../components/self-train/testimonials/Testimonials';
import MeetTheExpert from '../../../components/self-train/meet-the-expert/MeetTheExpert';
import SessionContent from '../../../components/self-train/session-content/SessionContent';
import MeetExpert from '../../../components/self-train/meet-expert/MeetExpert';
import { ArrowSmallUpIcon } from '@heroicons/react/24/solid';
import { selfTrainService } from '../../../services/self-train.service';
import { toast } from "react-toastify";
import { CONSTANT } from '../../../core/StaticConstant';
import { useParams } from 'react-router-dom';

const YogaDetails = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };

  const menuList: any = [
    { _id: 1, name: "Who is this class for?" },
    { _id: 2, name: "Session Content" },
    { _id: 3, name: "About the expert" },
    { _id: 4, name: "What's included" },
    { _id: 5, name: "FAQS" }
  ];

  const [courseData, setCourseData] = useState<any>();
  const [faqData, setFaqData] = useState<any>();

  let { id } = useParams();
  useEffect(() => {
    getCourseById();
    let obj = {
        fields:["faqTitle","faqDescription","courses"]
    }
    getAllFaqs(obj);
  }, []);

  const getCourseById = async () => {

    try {
      const response = await selfTrainService.getCoursesById(id);
         
      if (response.status === CONSTANT.SUCCESS) {
        setCourseData(response.data.course);
      }
    } catch (err: any) {
        console.error("error ", err.response )
        toast.error((err?.response?.data?.error));
    }
  };

  const getAllFaqs = async (obj:any) => {

    try {
      const response = await selfTrainService.getFaqs(obj);

      if (response.status === CONSTANT.SUCCESS) {

        setFaqData(response.data.courseFaqs);
      }
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <div>
      {courseData && <VideoBanner courseData={courseData}    />}
      {courseData && <GuideYoga courseData={courseData} />}
      {courseData && <VideoAbout courseData={courseData} />}
      {courseData && <SelectPerfectClass courseData={courseData} />}
      {courseData&&<SessionContent chapterData={courseData} />}
      {courseData && <MeetTheExpert courseData={courseData} />}
      <Testimonials />
      <MeetExpert />
      {courseData && <WatchAnywhere courseData={courseData} />}
      {faqData && <FaqData pagName="self-train" faqData={faqData} />}
      <div className='flex justify-end bg-darkBlack xs:hidden'>
        <h2
          className="text-white text-greenFooter mb-[10px]  text-[18px] cursor-pointer font-bold"
          onClick={() => scrollToTop()}
        >
          Back to Top
        </h2>
        <a>
          <ArrowSmallUpIcon
            className="h-[25px] mt-[3px] ml-[4px] mr-[4rem] mb-[13px]  cursor-pointer stroke-[2px] text-white"
            onClick={() => scrollToTop()}
          />
        </a>
      </div>
    </div>
  )
}

export default YogaDetails
import UserChildren from "../../components/user-profile/UserChildren";

const Appointments = () => {
  return (
    <UserChildren title="Appointments">
      <div className="lg:flex gap-3"></div>
    </UserChildren>
  );
};

export default Appointments;

import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Address from "../../components/cart-order/address/address-details/AddressDetails";
import AddAddress from "../../components/cart-order/address/add-address/AddAddress";
import PaymentMethod from "../../components/cart-order/payment-method/PaymentMethod";
import Loader from "../../components/loader/Loader";
import {
    orderId,
    selectEditAddressId,
    setCartProducts,
    setDeliveryCharge,
    setOrder,
    setProductTrack,
} from "../../redux/reducer/cartReducer";
import OrderSummary from "../../components/cart-order/order-summary/OrderSummary";
import OrderPending from "../../components/cart-order/order-status/OrderPending";
import OrderTrack from "../../components/cart-order/order-status/order-track/OrderTrack";
import OrderSuccess from "../../components/cart-order/order-status/OrderSuccess";
import DeliveryOptions from "../../components/cart-order/delivery-options/DeliveryOptions";
import { getUserData } from "../../core/Utils";
import { CartService } from "../../services";
import { CONSTANT } from "../../core/StaticConstant";
import {
    CardElement,
    Elements,
    useElements,
    useStripe,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { AddressService } from "../../services/address.service";
import { PaymentService } from "../../services/payment.service";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { OrderService } from "../../services/order.services";
import { Localstorage } from "../../core/AuthService";
import ApplePayment from "../../components/cart-order/payment-method/ApplePayment";
import Plaid from "../../components/plaid/PlaidHistory";

const stripePublickey: any = process.env.REACT_APP_STRIPE;

const CartOrder = () => {
    const orderProcess = useSelector((state: any) => state);
    const cartProduct = useSelector((state: any) => state.cartProducts);

    const dispatch = useDispatch();
    let selectId = orderProcess.order;
    const [cartData, setCartData] = useState<any[]>([]);
    const [totalPrice, setTotalPrice] = useState<number>(0);

    const [deliveryOptionData, setDeliveryOptionData] = useState<any>();
    const [isCvvCheck, setIsCvvCheck] = useState(false);
    const [orderIdData, setOrderIdData] = useState<any>();

    let orderTrack = orderProcess.track;
    const deliveryChargeAdd = orderProcess.deliveryCharge;

    const [isParentLoading, setIsParentLoading] = useState(false);

    const onLoaderChange = (flag: any) => {
        setIsParentLoading(flag);
    };

    const checkCvv = (flag: any) => {
        setIsCvvCheck(flag);
    };


    const onClickAddressSelect = (id: any) => {
        window.scrollTo(0, 0);
        if (id === "2") {
            dispatch(setOrder("addAddress"));
            dispatch(selectEditAddressId(""));
        } else {
            dispatch(setOrder(id));
        }
    };
    useEffect(() => {
        dispatch(setOrder("addNewAddress"));
        const userData: any = getUserData();
        dispatch(setProductTrack("1"));

        getDeliveryOptions();
        getCartData();
        createCart();
    }, []);

    const createCart = async () => {
        try {
            const getUserId: any = getUserData();
            let obj = {
                cart: cartProduct,
            };
            await CartService.createCart(obj);
        }
        catch (err: any) {
            toast.error((err?.response?.data?.error));
        }
    }
    // ==========================================================
    useEffect(() => {
        const userData: any = getUserData();

        // getCartData(obj);
    }, [deliveryChargeAdd.amount]);

    const stripePromise: any = loadStripe(stripePublickey);

    const { ids } = useParams();

    // useEffect(() => {
    //     orderDetails();
    // }, []);

    const orderDetails = async () => {
        try {
            const response = await OrderService.getOrderById(ids);

            if (response && response.status === CONSTANT.SUCCESS) {
                setOrderIdData(response.data.order);
            }
        } catch (err: any) {
            toast.error((err?.response?.data?.error));
        }
    };

    const getDeliveryOptions = async () => {
        try {
            const response: any = await AddressService.getDeliveryOptions();
            if (response && response.status === CONSTANT.SUCCESS) {
                setDeliveryOptionData(response.data.deliveryOptions);
                let deliveryObj = {
                    amount: response?.data.deliveryOptions[0].amount,
                    title: response?.data.deliveryOptions[0].title,
                };
                dispatch(setDeliveryCharge(deliveryObj));
            }
        } catch (error) { }
    };
    const cartProductsStore = useSelector((state: any) => state.cartProducts);

    const getCartData = async () => {
        await CartService.getCartByUserId().then((data: any) => {
            if (data && data.statusCode === 403) {
                toast.error(data.message);
                dispatch(setCartProducts([]));
                Localstorage(CONSTANT.REMOVE, CONSTANT.USER_INFO);
                Localstorage(CONSTANT.REMOVE, CONSTANT.SELECTED_MENU);
                setTimeout(() => {
                    window.location.href = "/shop";
                }, 1000)
            } else {
                if (data && data.status == CONSTANT.SUCCESS) {
                    const lastVisitedPage = Localstorage(CONSTANT.GET, CONSTANT.LAST_VISITED_PAGE);
                    if (lastVisitedPage?.includes("/shop/cart")) {
                        const user: any = getUserData();
                        setCartData(data?.data?.cart?.cart);
                        dispatch(setCartProducts(cartProductsStore));
                    } else {
                        setCartData(data?.data?.cart?.cart);
                        dispatch(setCartProducts(data?.data?.cart?.cart));
                    }
                    let totalAmount = data?.data?.cart?.cart?.reduce(
                        (acc: any, ele: any) =>
                            acc + ele.totalAmount + deliveryChargeAdd?.amount,
                        0
                    );
                    setTotalPrice(totalAmount);
                }
            }
        }).catch((err: any) => {
            if (err?.response?.data?.error !== CONSTANT.DATA_NOT_FOUND) {
                toast.error(err?.response?.data?.error);
            }
            setIsParentLoading(false);
        });
    };

    const deliveryAddress = useSelector(
        (state: any) => state.selectDeliveryAddress
    );
    const stripe = useStripe();
    const elements = useElements();
    const [userDetails, setUserDetails] = useState<any>();
    const [paymentMethodId, setPaymentMethodId] = useState<any>();
    const [cardData, setCardData] = useState<any>();
    const [selectCard, setSelectCard] = useState<any>();
    const [isCardElementValid, setCardElementValidity] = useState(false);
    const products = useSelector((state: any) => state);
    const getCustomerId = Localstorage(CONSTANT.GET, CONSTANT.CUSTOMER_ID);
    const [saveCardChecked, setSaveCardChecked] = useState(false);



    useEffect(() => {
        const userData: any = getUserData();
        setUserDetails(userData);
    }, []);




    useEffect(() => {
        setIsParentLoading(true);
        if (isParentLoading === true) {
            setTimeout(() => {
                setIsParentLoading(false);
            }, 2000)
        }
    }, []);
    const createPaymentObject = (
        getCustomerId: any,
        paymentMethodId: any,
        totalPrice: any,
        deliveryAddress: any
    ) => {
        return {
            customerId: getCustomerId,
            cardToken: paymentMethodId,
            paymentMethodId: paymentMethodId || selectCard,
            amount: totalPrice || parseFloat(orderIdData.order.amount),
            description: "Test Payment",
            currency: "GBP",
            shipping: {
                name: deliveryAddress?.fullName,
                address: {
                    line1: deliveryAddress?.address1,
                    postal_code: deliveryAddress?.postcode,
                    city: deliveryAddress?.admin_district,
                    country: "GB",
                },
            },
        };
    };

    const store = useSelector((state: any) => state);

    // const orderProcess = store.order;
    const [userId, setUserId] = useState<String>("");

    useEffect(() => {
        const userData: any = getUserData();
        setUserId(userData?.userId);
    }, [store.amount]);

    const createOrder = async (paymentStatus: any, paymentHistoryId: any) => {
        onLoaderChange(true);
        try {
            let obj = {
                cartProducts: store?.cartProducts,
                addressId: store?.selectDeliveryAddress?._id,
                amount: totalPrice,
                orderStatus: paymentStatus,
                deliveryCharge: store?.deliveryCharge.amount,
                expectedDate: store.setExpectedDate,
                paymentId: paymentHistoryId
            };

            const response = await OrderService.createOrder(obj);
            if (response.status === CONSTANT.SUCCESS) {
                dispatch(orderId(response?.data?.order?._id));
            }
        } catch (error) {
            console.error(error);
            onLoaderChange(false);
        }
        onLoaderChange(false);
    };

    const addNewCard = async (paymentMethodId: any) => {
        onLoaderChange(true);
        if (!stripe || !elements) {
            return;
        }
        try {
            const cardElement: any = elements.getElement(CardElement);
            if (!cardElement) {
                return;
            }
            const token: any = await stripe.createToken(cardElement);
            const tokenId = token.token.id || "";

            const { error, paymentMethod } = await stripe.createPaymentMethod({
                type: "card",
                card: cardElement,
            });
            if (error) {
                return;
            }
            paymentMethodId = paymentMethod?.id;
            setPaymentMethodId(paymentMethodId);
            let obj = {
                customerId: getCustomerId,
                cardToken: tokenId,
                paymentMethodId: paymentMethodId,
            };

            await PaymentService.addNewCard(obj)
                .then((res) => {
                    onLoaderChange(true);
                    if (res.status === CONSTANT.SUCCESS) {
                        toast.success(res.message);
                        getAllCard();
                        cardElement.clear();
                    } else {
                        toast.error(res.message);
                    }
                })
                .catch((err) => {
                    console.error(err);
                    onLoaderChange(false);
                });
        } catch (error) {
            console.error("Error creating payment method:", error);
            onLoaderChange(false);
            toast.error(CONSTANT.CARD_ERROR);
        }
    };


    const updateOrderByID = async (paymentStatus: any, paymentHistoryId: any) => {
        let obj = {
            userId: orderIdData.order.userId,
            cartProducts: orderIdData.order.cartProducts,
            addressId: orderIdData.order.addressId,
            amount: totalPrice || parseFloat(orderIdData.order.amount),
            orderStatus: paymentStatus,
            deliveryCharge: orderIdData.order.deliveryCharge,
            expectedDate: orderIdData.order.expectedDate,
            paymentId: paymentHistoryId
        };
        try {
            orderIdData.order.orderStatus === CONSTANT.PAYMENT_ISSUE &&
                (await OrderService.updateOrderById(ids, obj));
        } catch (error) {
            console.error(error);
        }
    };

    const makePayment = async () => {
        window.scrollTo(0, 0);
        onLoaderChange(true);
        checkCvv(false);

        if (!stripe || !elements) {
            return;
        }
        try {
            const cardElement = elements.getElement(CardElement);
            if (!cardElement) {
                return;
            }
            if (selectCard) {
                const obj = createPaymentObject(
                    getCustomerId,
                    paymentMethodId,
                    totalPrice,
                    deliveryAddress
                );
                const res = await PaymentService.createPayment(obj);
                onLoaderChange(false);

                if (res.status === CONSTANT.SUCCESS) {
                    {
                        ids
                            ? updateOrderByID(CONSTANT.PENDING, orderIdData.order.paymentHistoryId)
                            : createOrder(CONSTANT.PENDING, res.data.paymentHistoryId);
                    }

                    let navigateUrl = res.data.redirectUrl;
                    const windowFeatures =
                        "width=500,height=500,top=50%,left=50%,location=no,menubar=no";
                    if (navigateUrl) {
                        window.open(navigateUrl, "Payment Popup", windowFeatures);
                    }
                    let paymentHistoryId = res.data.paymentHistoryId;

                    paymentStatus(paymentHistoryId);

                    setTimeout(() => {
                        dispatch(setOrder("order"));
                        dispatch(setProductTrack("1"));
                        toast.success(res.message);
                    }, 5000);
                } else {
                    toast.error(res.message);

                    {
                        ids
                            ? updateOrderByID(CONSTANT.PAYMENT_ISSUE, orderIdData.order.paymentHistoryId)
                            : createOrder(CONSTANT.PAYMENT_ISSUE, res.data.paymentHistoryId);
                    }

                    dispatch(setOrder("order"));
                    dispatch(setProductTrack("2"));
                }
            } else {
                const { error, paymentMethod } = await stripe.createPaymentMethod({
                    type: "card",
                    card: cardElement,
                });

                if (error) {
                    toast.error(
                        "Error creating payment method. Please check your card details."
                    );
                    onLoaderChange(false);
                    return;
                }

                const paymentMethodId = paymentMethod?.id;
                setPaymentMethodId(paymentMethodId);

                const obj = createPaymentObject(
                    getCustomerId,
                    paymentMethodId,
                    totalPrice,
                    deliveryAddress
                );
                const res = await PaymentService.createPayment(obj);
                onLoaderChange(false);

                if (res.status === CONSTANT.SUCCESS) {
                    toast.success(res.message);
                    let navigateUrl = res.data.redirectUrl;
                    const windowFeatures =
                        "width=500,height=500,top=50%,left=50%,location=no,menubar=no";
                    if (navigateUrl) {
                        window.open(navigateUrl, "Payment Popup", windowFeatures);
                    }
                    let paymentHistoryId = res.data.paymentHistoryId;

                    paymentStatus(paymentHistoryId);
                    {
                        ids
                            ? updateOrderByID(CONSTANT.PENDING, orderIdData.order.paymentHistoryId)
                            : createOrder(CONSTANT.PENDING, paymentHistoryId);
                    }

                    setTimeout(() => {
                        dispatch(setOrder("order"));
                        dispatch(setProductTrack("1"));
                    }, 5000);
                } else {
                    toast.error(res.message);
                    {
                        ids
                            ? updateOrderByID(CONSTANT.PAYMENT_ISSUE, orderIdData.order.paymentHistoryId)
                            : createOrder(CONSTANT.PAYMENT_ISSUE, res.data.paymentHistoryId);
                    }
                    dispatch(setOrder("order"));
                    dispatch(setProductTrack("2"));
                }

                if (saveCardChecked) {
                    await addNewCard(paymentMethodId);
                    onLoaderChange(false);
                }
            }
        } catch (error: any) {
            onLoaderChange(false);
        }
    };

    const handlePayment = async () => {
        try {
            const obj = createPaymentObject(
                getCustomerId,
                paymentMethodId,
                totalPrice,
                deliveryAddress
            );
            const res = await PaymentService.createPaymentApple(obj);
            if (res.status === CONSTANT.SUCCESS) {
                toast.success(res.message);
                let navigateUrl = res.data.redirectUrl;
                const windowFeatures =
                    "width=500,height=500,top=50%,left=50%,location=no,menubar=no";
                if (navigateUrl) {
                    window.open(navigateUrl, "Payment Popup", windowFeatures);
                }
                let paymentHistoryId = res.data.paymentHistoryId;

                paymentStatus(paymentHistoryId);
                {
                    ids
                        ? updateOrderByID(CONSTANT.PENDING, orderIdData.order.paymentHistoryId)
                        : createOrder(CONSTANT.PENDING, paymentHistoryId);
                }

                setTimeout(() => {
                    dispatch(setOrder("order"));
                    dispatch(setProductTrack("1"));
                }, 5000);
            } else {
                toast.error(res.message);
                {
                    ids
                        ? updateOrderByID(CONSTANT.PAYMENT_ISSUE, orderIdData.order.paymentHistoryId)
                        : createOrder(CONSTANT.PAYMENT_ISSUE, res.data.paymentHistoryId);
                }
                dispatch(setOrder("order"));
                dispatch(setProductTrack("2"));
            }
        } catch (err) {
            console.log('Error occurred in:', err);
        }
    };

    const getAllCard = async () => {
        onLoaderChange(true);
        await PaymentService.getCardDetails()
            .then((res: any) => {
                onLoaderChange(false);
                setCardData(res?.cards);
            })
            .catch((error: any) => {
                onLoaderChange(false);
            });
    };



    const handleCardElementChange = (event: any) => {
        setCardElementValidity(event.complete);
    };

    const deleteCartProducts = async () => {
        onLoaderChange(true);
        await CartService.deleteCartProducts().then(() => {
            onLoaderChange(false);
            dispatch(setCartProducts([]));
            dispatch(setDeliveryCharge({}));
        });
    };

    const paymentStatus = async (id: any) => {
        onLoaderChange(true);
        await PaymentService.paymentStatus(id)
            .then((res) => {
                onLoaderChange(false);
                if (res.status === CONSTANT.SUCCESS) {
                    deleteCartProducts();
                }
            })
            .catch((err) => {
                onLoaderChange(false);

                // console.log(err);
            });
    };

    const getCustomerByEmail = async () => {
        try {
            let response = await PaymentService.getCustomerByEmail();
            if (response.status !== CONSTANT.SUCCESS) {
                createCustomer();
            } else {
                if(response && response.data && response.data.customer && response.data.customer.length > 0 && response.data.customer[0].data && response.data.customer[0].data.id) {
                    Localstorage(CONSTANT.SET,CONSTANT.CUSTOMER_ID, response.data.customer[0].data.id);
                }
            }
        } catch (error:any) {
            if(error?.response?.data?.error === CONSTANT.CUSTOMER_NOT_FOUND) {
                createCustomer();
            }
        }
    };
    const createCustomer = async () => {
        try {
            let response = await PaymentService.createCustomer();
            if (response.status === CONSTANT.SUCCESS) {
                if(response && response.data && response.data.customer && response.data.customer.data && response.data.customer.data.id) {
                    Localstorage(CONSTANT.SET,CONSTANT.CUSTOMER_ID, response.data.customer.data.id);
                }
            }
        } catch (error) {
            toast.error(CONSTANT.FAIL);
        }
    };
    const navigate = useNavigate();

    useEffect(() => {
        const shouldRedirect = Localstorage(CONSTANT.GET,CONSTANT.REDIRECT_FROM_ORDER_SUCCESS);
        const redirect = Localstorage(CONSTANT.GET,CONSTANT.REDIRECT_FROM_ORDER_PENDING);

        if (shouldRedirect) {
            Localstorage(CONSTANT.REMOVE,CONSTANT.REDIRECT_FROM_ORDER_SUCCESS); 
            navigate("/shop"); // Redirect to /shop
        }

        if (redirect) {
            Localstorage(CONSTANT.GET,CONSTANT.REDIRECT_FROM_ORDER_PENDING);
            navigate("/shop");
        }
        if (cartData.length === 0) {
            const handleBeforeUnload = () => {
                Localstorage(CONSTANT.SET,CONSTANT.REDIRECT_FROM_ORDER_SUCCESS,CONSTANT.TRUE);
            }

            const handleUnload = () => {
                Localstorage(CONSTANT.SET,CONSTANT.REDIRECT_FROM_ORDER_PENDING,CONSTANT.TRUE);

            };

            window.addEventListener("beforeunload", handleUnload);
            window.addEventListener("beforeunload", handleBeforeUnload);

            window.removeEventListener("beforeunload", handleBeforeUnload);
            window.removeEventListener("beforeunload", handleUnload);
        }

        return () => { };
    }, []);

    return (
        <div className="py-4 px-12 sm:px-24 2xl:ml-[7rem] ">
            {isParentLoading && (
                <div className="absolute top-[50%] z-50 left-[50%]  transform translate-x-[-50%] translate-y-[-50%]">
                    <Loader />
                </div>
            )}
            {orderIdData || cartData.length > 0 || cartProduct.length > 0 ? (
                <div className="lg:flex m-auto  max-w-[1050px]  ">
                    <div className="sm:pr-2 pt-16 ml-auto main-section -mt-[3.8rem]">
                        <div className=" gap-4  justify-between">
                            <div className="pr-[1rem]">
                                <button
                                    className={`text-[18px] -ml-[8.5rem] 2xl:-ml-[4rem] w-[30rem] xl:-ml-[4rem] lg:-ml-[4rem] md:-ml-[4rem] sm:-ml-[4rem] cursor pointer sm:text-[30px] font-bold ${selectId === "order"
                                        ? "text-disable pointer-events-none"
                                        : ""
                                        }`}
                                    onClick={() => onClickAddressSelect("addNewAddress")}
                                >
                                    1. Select Delivery Address
                                </button>
                            </div>
                            <div>
                                <h2
                                    className="text-[13px] cursor-pointer sm:text-[20px] font-medium mt-[8px]"
                                    onClick={() => onClickAddressSelect("addAddress")}
                                >
                                    {selectId === "addNewAddress" ? "+ Add New Address" : ""}
                                </h2>
                            </div>
                        </div>
                        {selectId === "addAddress" && (
                            <div>
                                <AddAddress
                                    onLoaderChange={(e: any) => onLoaderChange(e)}
                                    isParentLoading={isParentLoading}
                                    getCustomerByEmail={getCustomerByEmail}
                                />
                            </div>
                        )}
                        {selectId === "addNewAddress" && (
                            <div>
                                <Address
                                    onLoaderChange={(e: any) => onLoaderChange(e)}
                                    isParentLoading={isParentLoading}
                                    getCustomerByEmail={getCustomerByEmail}
                                />
                            </div>
                        )}
                        <hr
                            className={`border-silver mt-6 border-t-[1px] ${selectId === "paymentMethod" ? "hidden" : ""
                                }`}
                        />
                        <div>
                            <div className="mt-4">
                                <button
                                    className={`text-[18px] sm:text-[30px] font-bold ${selectId === "order"
                                        ? "text-disable pointer-events-none"
                                        : ""
                                        }`}
                                    onClick={() => onClickAddressSelect("deliveryOption")}
                                >
                                    2. Select Delivery Options
                                </button>
                            </div>
                            {selectId === "deliveryOption" && (
                                <>
                                    <DeliveryOptions
                                        deliveryOptionData={deliveryOptionData}
                                        onLoaderChange={(e: any) => onLoaderChange(e)}
                                        isParentLoading={isParentLoading}
                                    />
                                </>
                            )}
                        </div>
                        <hr
                            className={`border-silver mt-6 border-t-[1px] ${selectId === "paymentMethod" ? "hidden" : ""
                                }`}
                        />
                        <div>
                            <div className="mt-4">
                                <button
                                    className={`text-[18px] sm:text-[30px] font-bold ${selectId === "order"
                                        ? "text-disable pointer-events-none"
                                        : ""
                                        }`}
                                    onClick={() => onClickAddressSelect("paymentMethod")}
                                >
                                    3. Payment Method
                                </button>
                            </div>
                            {selectId === "paymentMethod" && (
                                <Elements stripe={stripePromise}>
                                    <PaymentMethod
                                        totalPrice={totalPrice}
                                        onLoaderChange={(e: any) => onLoaderChange(e)}
                                        isCvvCheck={isCvvCheck}
                                        checkCvv={(e: any) => checkCvv(e)}
                                        orderIdData={orderIdData}
                                        handleCardElementChange={handleCardElementChange}
                                        isCardElementValid={isCardElementValid}
                                    />
                                    {/* <ApplePayComponent totalPrices={totalPrice} getCustomerId={getCustomerId} deliveryAddress={deliveryAddress} orderIdData={orderIdData} /> */}
                                </Elements>
                            )}
                            <Elements stripe={stripePromise}>
                                <ApplePayment totalPrice={totalPrice} handlePayment={handlePayment}/>
                            </Elements>
                        </div>
                        <hr
                            className={`border-silver mt-6 border-t-[1px] ${selectId === "paymentMethod" ? "hidden" : ""
                                }`}
                        />
                        <div>
                            <div className="mt-4">
                                <button className="text-[18px] sm:text-[30px] font-bold">
                                    4. Order Status
                                </button>
                            </div>
                            <div className="lg:grid lg:grid-cols-8">
                                <div
                                    className={`${selectId === "order" ? "lg:col-span-8" : "lg:col-span-8"
                                        }`}
                                >
                                    <hr className={`border-silver mt-6 border-t-[1px] ${""}`} />
                                </div>
                            </div>
                            {selectId === "order" && orderTrack === "1" && (
                                <>
                                    <OrderSuccess />
                                    <OrderTrack />
                                </>
                            )}
                            {selectId === "order" && orderTrack === "2" && (
                                <>
                                    <OrderPending />
                                </>
                            )}
                        </div>
                    </div>
                    {(selectId === "addNewAddress" ||
                        selectId === "addAddress" ||
                        selectId === "deliveryOption" ||
                        selectId === "paymentMethod") && (
                            <div className="w-full 2xl:pl-3 sticky lg:w-[25rem] mr-auto">
                                <OrderSummary
                                    makePayment={makePayment}
                                    onLoaderChange={(e: any) => onLoaderChange(e)}
                                    isCvvCheck={isCvvCheck}
                                    isParentLoading={isParentLoading}
                                    cartData={cartData}
                                    deliveryCharge={deliveryChargeAdd}
                                    totalPrice={totalPrice}
                                    getCustomerByEmail={getCustomerByEmail}
                                    orderIdData={orderIdData}
                                    isCardElementValid={isCardElementValid}
                                />
                            </div>
                        )}
                </div>
            ) : (
                <div className="text-[25px] flex justify-center mt-[100px] py-4 font-medium h-[150px]">
                    Your cart is empty. Please add items before proceeding to checkout
                </div>
            )}
        </div>
    );
};

export default CartOrder;

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setProductTrack } from "../../../../redux/reducer/cartReducer";

const OrderTrack = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const orderProcess = useSelector((state: any) => state);

  const onClickTrack = (id: any) => {
    dispatch(setProductTrack(id));
  };
  const onClick = () => {
    window.location.href = "/user/order-tracking";
  };

  return (
    <div className=" pl-16">
      <div className="flex gap-4 pt-4">
        <button
          className="text-nero font-bold"
          onClick={() => onClickTrack("2")}
        >
          <span className="cursor-pointer">Print receipt</span>
        </button>
        <div className="border-r border-nero"></div>
        <button
          className="text-nero font-bold"
          onClick={() => navigate("/user/order-tracking")}
        >
          <span className="cursor-pointer">Order Details</span>
        </button>
        <div className="border-r border-nero"></div>
        <button className="font-bold text-nero">
          <span className="cursor-pointer" onClick={() => onClick()}>
            Track your order
          </span>
        </button>
      </div>
      <div className="mt-10">
        <button
          className="text-[15px] px-10 py-3 font-bold bg-midnightDream text-white tracking-wide"
          onClick={() => navigate("/shop")}
        >
          Explore More Products
        </button>
      </div>
    </div>
  );
};

export default OrderTrack;

import httpCommon from "../core/HttpCommonCore";
import { API_CONSTANT } from "../core/Constant";
import { AxiosError } from "axios";

export const ConversationService = {
    getConversationResponse,
    getChatbotLastConversation,
    getAllBots
};

function getConversationResponse(data: any) {
  return httpCommon
    .post(API_CONSTANT.GET_CONVERSATION_RESPONSE, data)
    .then((response: any) => {
      if (response.statusText !== "OK") {
        const error = response.statusText;
        return Promise.reject(error);
      }
      return response.data;
    })
    .catch((error: AxiosError) => {
      return Promise.reject(error);
    });
}

function getChatbotLastConversation(data: any) {
    return httpCommon.post(API_CONSTANT.GET_LAST_CONVERSATION_RESPONSE, data).then((response: any) => {
        if (response.statusText !== 'OK') {
            const error = response.statusText;
            return Promise.reject(error);
        }
        return response.data;
    })
}

function getAllBots(data:any) {
    let url = API_CONSTANT.GET_ALL_BOTS;
    return httpCommon.post(url,data).then((response: any) => {
        if (response.statusText !== 'OK') {
            const error = response.statusText;
            return Promise.reject(error);
        }
        return response.data;
    })
}

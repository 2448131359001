import httpCommon from '../core/HttpCommonCore';
import { API_CONSTANT } from '../core/Constant';

export const ProductService = {
    getAllCategories,
    getProductById,
    getProductByCategoryWise,
    getProductByCategory,
    getAllRecommendedProduct,
    likeUnlikeProductByUser,
    GetlikeUnlikeProductByUser,
    increaseProductViewCount,
    getSearchingProduct
};

function getProductById(id:any) {
    let url = API_CONSTANT.GET_PRODUCT_BY_ID + id 
    return httpCommon.get(url).then((response:any) => {
        if (response.statusText !== "OK") {
            const error = response.statusText;
            return Promise.reject(error);
        }
        return response.data
    })
}

function getAllCategories() {
    let url = API_CONSTANT.GET_ALL_PRODUCT
    return httpCommon.get(url,{}).then((response:any) => {
        if (response.statusText !== "OK") {
            const error = response.statusText;
            return Promise.reject(error);
        }
        return response.data
    })
}

function getAllRecommendedProduct(data: any) {
    let url = API_CONSTANT.GET_ALL_RECOMMENDED_PRODUCT
    return httpCommon.post(url,data).then((response:any) => {
        if (response.statusText !== "OK") {
            const error = response.statusText;
            return Promise.reject(error);
        }
        return response.data
    })
}

function getProductByCategoryWise(data: any) {
    let url = API_CONSTANT.GET_PRODUCT_BY_CATEGORY_WISE
    return httpCommon.post(url,data).then((response:any) => {
        if (response.statusText !== "OK") {
            const error = response.statusText;
            return Promise.reject(error);
        }
        return response.data
    })
}

function getProductByCategory(data:any) {
    let url = API_CONSTANT.GET_PRODUCT_BY_CATEGORY
    return httpCommon.post(url,data).then((response:any) => {
        if (response.statusText !== "OK") {
            const error = response.statusText;
            return Promise.reject(error);
        }
        return response.data
    })
}

function likeUnlikeProductByUser(data:any) {
    let url =API_CONSTANT.LIKE_UNLIKE_PRODUCT_BY_USER_ID
    return httpCommon.post(url,data).then((response:any) => {
       if(response.statusText !== "OK") {
        const error = response.statusText;
        return Promise.reject(error);
       }
       return response.data
    })
}

function GetlikeUnlikeProductByUser() {
    let url =API_CONSTANT.GET_LIKE_UNLIKE_PRODUCT_BY_USER_ID
    return httpCommon.post(url).then((response:any) => {
       if(response.statusText !== "OK") {
        const error = response.statusText;
        return Promise.reject(error);
       }
       return response.data
    })
}

function increaseProductViewCount(id: any) {
    let url =API_CONSTANT.INCREASE_PRODUCT_VIEW_COUNTER + id
    return httpCommon.get(url,{}).then((response:any) => {
       if(response.statusText !== "OK") {
        const error = response.statusText;
        return Promise.reject(error);
       }
       return response.data
    })
}

function getSearchingProduct(data:any) {
    let url =API_CONSTANT.GET_SEARCHING_PRODUCT
    return httpCommon.post(url,data).then((response:any) => {
       if(response.statusText !== "OK") {
        const error = response.statusText;
        return Promise.reject(error);
       }
       return response.data
    })
}
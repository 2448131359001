// import React, { useEffect, useState } from 'react';
// import { PaymentRequestOptions, loadStripe } from '@stripe/stripe-js';
// import { PaymentRequestButtonElement, useStripe } from '@stripe/react-stripe-js';
// import { PaymentService } from '../../../services/payment.service';
// import { CONSTANT } from '../../../core/StaticConstant';
// import { OrderService } from '../../../services/order.services';
// import { useParams } from 'react-router-dom';
// import { useDispatch, useSelector } from 'react-redux';
// import { orderId, setCartProducts, setDeliveryCharge, setOrder, setProductTrack, } from '../../../redux/reducer/cartReducer';
// import { CartService } from '../../../services';
// import { toast } from 'react-toastify';
// import Loader from '../../loader/Loader';

// interface CustomPaymentRequestOptions extends PaymentRequestOptions {
//     paymentMethodTypes: string[];
// }

// const ApplePayComponent = ({ totalPrices, getCustomerId, deliveryAddress, orderIdData }: any) => {
//     const [paymentRequest, setPaymentRequest] = useState<any>(null);
//     const [loading, setIsLoading] = useState<any>(false);
//     const stripe = useStripe();
//     const { ids } = useParams();
//     const dispatch = useDispatch();
//     const store = useSelector((state: any) => state);

//     const updateOrderByID = async (paymentStatus: any, paymentHistoryId: any) => {
//         let obj = {
//             userId: orderIdData.userId,
//             cartProducts: orderIdData.cartProducts,
//             addressId: orderIdData.addressId,
//             amount: totalPrices || parseFloat(orderIdData.amount),
//             orderStatus: paymentStatus,
//             deliveryCharge: orderIdData.deliveryCharge,
//             expectedDate: orderIdData.expectedDate,
//             paymentId: paymentHistoryId
//         };
//         try {
//             orderIdData.order.orderStatus === CONSTANT.PAYMENT_ISSUE &&
//                 (await OrderService.updateOrderById(ids, obj));
//         } catch (error) {
//             console.error(error);
//         }
//     };

//     const createOrder = async (paymentStatus: any, paymentHistoryId: any) => {
//         setIsLoading(true);
//         try {
//             let obj = {
//                 cartProducts: store?.cartProducts,
//                 addressId: store?.selectDeliveryAddress?._id,
//                 amount: totalPrices,
//                 orderStatus: paymentStatus,
//                 deliveryCharge: store?.deliveryCharge.amount,
//                 expectedDate: store.setExpectedDate,
//                 paymentId: paymentHistoryId
//             };

//             const response = await OrderService.createOrder(obj);
//             setIsLoading(false);
//             if (response.status === CONSTANT.SUCCESS) {
//                 dispatch(orderId(response?.data?.order?._id));
//             }
//         } catch (error) {
//             console.error(error);
//             setIsLoading(false);
//         }
//         setIsLoading(false);
//     };

//     const paymentStatus = async (id: any) => {
//         setIsLoading(true);
//         await PaymentService.paymentStatus(id)
//             .then((response) => {
//                 setIsLoading(false);
//                 if (response.status === CONSTANT.SUCCESS) {
//                     deleteCartProducts();
//                 }
//             })
//             .catch((err) => {
//                 setIsLoading(false);

//             });
//     };

//     const deleteCartProducts = async () => {
//         try {
//             setIsLoading(true);
//             await CartService.deleteCartProducts().then(() => {
//                 setIsLoading(false);
//                 dispatch(setCartProducts([]));
//                 dispatch(setDeliveryCharge({}));
//             });
//         } catch (error) {
//             console.error(error);
//         }
//     };

//     useEffect(() => {
//         const initializeStripe = async () => {
//             try {
//                 if (!stripe) {
//                     console.error('Failed to load Stripe');
//                     return;
//                 }

//                 const paymentRequest = stripe.paymentRequest({
//                     country: 'US',
//                     currency: 'usd',
//                     total: {
//                         label: 'Total Price',
//                         amount: totalPrices * 100
//                     },
//                     requestPayerName: true,
//                     requestPayerEmail: true,
//                     paymentMethodTypes: ['card', 'applePay']
//                 } as CustomPaymentRequestOptions);

//                 const canMakePayment = await paymentRequest.canMakePayment();
//                 console.log('canMakePayment',canMakePayment);
//                 if (!canMakePayment) {
//                     console.error('Apple Pay is not available');
//                     return;
//                 }

//                 paymentRequest.on('paymentmethod', async (event: any) => {
//                     try {
//                         if (event.paymentMethod.type === 'card' && event.paymentMethod.card.brand === 'apple') {
//                             const obj = {
//                                 customerId: getCustomerId,
//                                 cardToken: event.paymentMethod.id,
//                                 amount: totalPrices || parseFloat(orderIdData.order.amount),
//                                 description: "Test Payment",
//                                 currency: "usd",
//                                 shipping: {
//                                     name: deliveryAddress?.fullName,
//                                     address: {
//                                         line1: deliveryAddress?.address1,
//                                         postal_code: deliveryAddress?.postcode,
//                                         city: deliveryAddress?.admin_district,
//                                         country: "US",
//                                     }
//                                 }
//                             };

//                             const response = await PaymentService.createPaymentApple(obj);
//                             const { error, paymentIntent } = await stripe.confirmCardPayment(
//                                 response.data.client_secret, {
//                                 payment_method: event.paymentMethod.id,
//                             }, {
//                                 handleActions: false,
//                             });

//                             if (error) {
//                                 console.error('Error confirming card payment:', error.message);
//                                 event.complete('fail');
//                                 return;
//                             }
//                             event.complete('success');
//                             if (paymentIntent.status === CONSTANT.REQUIRES_ACTION) {
//                                 stripe.confirmCardPayment(response.data.client_secret);
//                             }

//                             if (response.status === CONSTANT.SUCCESS) {
//                                 toast.success(response.message);
//                                 let navigateUrl = response.data.redirectUrl;
//                                 const windowFeatures =
//                                     "width=500,height=500,top=50%,left=50%,location=no,menubar=no";
//                                 if (navigateUrl) {
//                                     window.open(navigateUrl, "Payment Popup", windowFeatures);
//                                 }
//                                 let paymentHistoryId = response.data.paymentHistoryId;
//                                 paymentStatus(paymentHistoryId);
//                                 ids
//                                     ? updateOrderByID(CONSTANT.PENDING, orderIdData.order.paymentHistoryId)
//                                     : createOrder(CONSTANT.PENDING, paymentHistoryId);

//                                 setTimeout(() => {
//                                     dispatch(setOrder("order"));
//                                     dispatch(setProductTrack("1"));
//                                 }, 5000);
//                             } else {
//                                 toast.error(response.message);
//                                 dispatch(setOrder("order"));
//                                 dispatch(setProductTrack("2"));
//                                 ids
//                                     ? updateOrderByID(CONSTANT.PAYMENT_ISSUE, "")
//                                     : createOrder(CONSTANT.PAYMENT_ISSUE, "");
//                                 deleteCartProducts();
//                             }
//                         } else {
//                             return;
//                         }
//                     } catch (error) {
//                         console.error('Error processing payment:', error);
//                         event.complete('fail');
//                     }
//                 });
//                 setPaymentRequest(paymentRequest);
//             } catch (error) {
//                 console.error('Error initializing Stripe:', error);
//                 setPaymentRequest(null);
//             }
//         };

//         initializeStripe();

//         return () => {
//             // Clean up if needed
//         };
//     }, [stripe]);


//     return (
//         <>
//             {loading && (
//                 <div className="absolute top-[50%] z-50 left-[50%]  transform translate-x-[-50%] translate-y-[-50%]">
//                     <Loader />
//                 </div>
//             )}
//             {paymentRequest &&
//                 <div id="apple-pay-button">
//                     <PaymentRequestButtonElement options={{ paymentRequest }} />
//                 </div>
//             }
//         </>
//     );
// };

// export default ApplePayComponent;


import React, { useEffect, useState } from 'react';
import { useStripe, useElements, PaymentRequestButtonElement, CardElement } from '@stripe/react-stripe-js';
import { PaymentRequestOptions } from '@stripe/stripe-js';

interface CustomPaymentRequestOptions extends PaymentRequestOptions {
    paymentMethodTypes: string[];
}

const ApplePayment = ({ totalPrice, handlePayment }: any) => {
    const stripe = useStripe();
    const elements = useElements();
    const [paymentError, setPaymentError] = useState<string | null>(null);
    // console.log('paymentError',paymentError);
    const [canMakePayment, setCanMakePayment] = useState<boolean | null>(null);
    // console.log('canMakePayment',canMakePayment);

    useEffect(() => {
        const initializeApplePay = async () => {
            if (!stripe || !elements) return;

            const paymentRequest = stripe.paymentRequest({
                country: 'US',
                currency: 'usd',
                total: {
                    label: 'Total Price',
                    amount: totalPrice * 100,
                },
                requestPayerName: true,
                requestPayerEmail: true,
                paymentMethodTypes: ['card', 'applePay']
            }as CustomPaymentRequestOptions);

            const result: any = await paymentRequest.canMakePayment();
            if (!result) {
                console.error('Apple Pay is not available');
            }
            setCanMakePayment(result);
        };
        initializeApplePay();
    }, [stripe, elements, totalPrice]);

    const handlePaymentMethod = async (event: any) => {
        try {
            const paymentMethod = event.paymentMethod;
            handlePayment(paymentMethod);
        } catch (error: any) {
            setPaymentError(error.message);
        }
    };

    const handleApplePayPayment = async () => {
        if (!stripe || !elements) return;

        try {
            const prButton: any = elements.getElement(PaymentRequestButtonElement);
            if (!prButton) {
                throw new Error('PaymentRequestButtonElement not found.');
            }

            prButton.on('paymentmethod', handlePaymentMethod);
            await prButton.mount();
        } catch (error: any) {
            setPaymentError(error.message);
        }
    };

    return (
        <div className='flex justify-center items-center'>
            <div>
            {canMakePayment && (
                <button id="payment-request-button" onClick={handleApplePayPayment} className='bg-black text-white w-40 p-2'>
                    Pay with Apple Pay
                </button>
            )}
            {/* {!canMakePayment && (
                <div>Apple Pay is not available on this device/browser.</div>
            )} */}
            {paymentError && <div>{paymentError}</div>}
            </div>
        </div>
    );    
};


export default ApplePayment;




// import { useEffect, useState } from 'react';
// import { loadStripe } from '@stripe/stripe-js';
// const stripePublickey: any = process.env.REACT_APP_STRIPE;

// const ApplePayment = ({ totalPrice, handlePayment }: any) => {
//     const [stripe, setStripe] = useState<any>(null);
//     const [elements, setElements] = useState<any>(null);
//     const [canMakePayment, setCanMakePayment] = useState(null);
//     console.log('canMakePayment',canMakePayment);
//     const [paymentError, setPaymentError] = useState(null);
//     console.log('paymentError',paymentError);

//     useEffect(() => {
//         const initializeStripe = async () => {
//             const stripePromise = loadStripe(stripePublickey);
//             const stripeInstance: any = await stripePromise;
//             setStripe(stripeInstance);
//             setElements(stripeInstance.elements());
//         };
//         initializeStripe();
//     }, []);

//     useEffect(() => {
//         const initializeApplePay = async () => {
//             if (!stripe || !elements) return;
        
//             const paymentRequest = stripe.paymentRequest({
//                 country: 'US',
//                 currency: 'usd',
//                 total: {
//                     label: 'Total Price',
//                     amount: totalPrice * 100,
//                 },
//                 requestPayerName: true,
//                 requestPayerEmail: true,
//                 paymentMethodTypes: ['card'],
//             });
        
//             const result = await paymentRequest.canMakePayment();
//             if (!result) {
//                 console.error('Apple Pay is not available');
//                 setCanMakePayment(result);
//             } else {
//                 setCanMakePayment(result);
//             }
//         };        
//         initializeApplePay();
//     }, [stripe, elements, totalPrice]);

//     const handlePaymentSubmit = async () => {
//         if (!stripe || !elements) return;
    
//         try {
//             const prButton = elements.create('paymentRequestButton', {
//                 paymentRequest: stripe.paymentRequest({
//                     country: 'US',
//                     currency: 'usd',
//                     total: {
//                         label: 'Total Price',
//                         amount: totalPrice * 100,
//                     },
//                     requestPayerName: true,
//                     requestPayerEmail: true,
//                     paymentMethodTypes: ['card'],
//                 }),
//             });
    
//             // Check if Apple Pay is available
//             const result = await stripe.paymentRequestWithApplePay.canMakePayment();
//             if (!result) {
//                 throw new Error('Apple Pay is not available');
//             }
    
//             prButton.on('paymentmethod', async (event: any) => {
//                 const paymentMethod = event.paymentMethod;
//                 handlePayment(paymentMethod);
//             });
    
//             await prButton.mount('#payment-request-button');
//         } catch (error: any) {
//             setPaymentError(error.message);
//         }
//     };
    

//     return (
//         <div>
//             {canMakePayment && (
//                 <button id="payment-request-button" onClick={handlePaymentSubmit} className='bg-black text-white w-36 p-2'>
//                     Pay with Apple Pay
//                 </button>
//             )}
//             {!canMakePayment && (
//                 <div>Apple Pay is not available on this device/browser.</div>
//             )}
//             {paymentError && <div>{paymentError}</div>}
//         </div>
//     );
// };

// export default ApplePayment;





import httpCommon from '../core/HttpCommonCore';
import { API_CONSTANT } from '../core/Constant';

export const ReviewService = {
    addEditReview,
    getReviewById,
    deleteReviewById,
    getAllReviews,
    getCustomerReviewByProductId,
    getAverageReviewByProductId
};

function addEditReview(data:any, id:any) {
    let url = (id) ?  API_CONSTANT.UPDATE_REVIEW_BY_ID + id : API_CONSTANT.CREATE_REVIEW
    return httpCommon.post(url, data).then((response:any) => {
        if (response.statusText !== "OK") {
            const error = response.statusText;
            return Promise.reject(error);
        }
        return response.data
    })
}

function getReviewById(id:any) {
    let url = API_CONSTANT.GET_REVIEW_BY_ID + id 
    return httpCommon.get(url).then((response:any) => {
        if (response.statusText !== "OK") {
            const error = response.statusText;
            return Promise.reject(error);
        }
        return response.data
    })
}

function deleteReviewById(id:any) {
    let url = API_CONSTANT.DELETE_REVIEW_BY_ID + id
    return httpCommon.post(url).then((response:any) => {
        if (response.statusText !== "OK") {
            const error = response.statusText;
            return Promise.reject(error);
        }
        return response.data
    })
}

function getAllReviews() {
    let url = API_CONSTANT.GET_ALL_REVIEW
    return httpCommon.get(url,{}).then((response:any) => {
        if (response.statusText !== "OK") {
            const error = response.statusText;
            return Promise.reject(error);
        }
        return response.data
    })
}

function getCustomerReviewByProductId(id: any, data:any) {
    let url = API_CONSTANT.GET_CUSTOMER_REVIEW_BY_PRODUCT_ID + id
    return httpCommon.post(url,data).then((response:any) => {
        if (response.statusText !== "OK") {
            const error = response.statusText;
            return Promise.reject(error);
        }
        return response.data
    })
}

function getAverageReviewByProductId(id: any) {
    let url = API_CONSTANT.GET_AVERAGE_REVIEW_BY_PRODUCT_ID + id
    return httpCommon.get(url).then((response:any) => {
        if (response.statusText !== "OK") {
            const error = response.statusText;
            return Promise.reject(error);
        }
        return response.data
    })
}
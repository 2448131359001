import React, { useEffect } from 'react';
import './App.css';
import Routing from './routing/Routing';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "react-datepicker/dist/react-datepicker.css";
import { getUserData } from './core/Utils';
import ChatbotComponent from './components/chat-boat/ChatBoat';
import ChatBoat from './components/chat-boat/ChatBoat';
import { CONSTANT } from "./core/StaticConstant";
import { Localstorage } from './core/AuthService';
import { UserService } from "./services/user.service";

const App: React.FC = () => {
    const verifyToken = async () => {
        try {
            const data = {
                token: Localstorage(CONSTANT.GET, CONSTANT.USER_INFO)?.replace(/"/g, '')
            };
            const response = await UserService.verifyToken(data);
            if (response?.status === 403) {
                Localstorage(CONSTANT.REMOVE, CONSTANT.USER_INFO);
                Localstorage(CONSTANT.REMOVE, CONSTANT.SELECTED_MENU);
                Localstorage(CONSTANT.REMOVE,CONSTANT.USER_NAME);
                Localstorage(CONSTANT.REMOVE, CONSTANT.CUSTOMER_ID);
                window.location.href = "/home";
            }
        } catch (error: any) {
            if (error?.response?.status === 403) {
                Localstorage(CONSTANT.REMOVE, CONSTANT.USER_INFO);
                Localstorage(CONSTANT.REMOVE, CONSTANT.SELECTED_MENU);
                Localstorage(CONSTANT.REMOVE,CONSTANT.USER_NAME);
                Localstorage(CONSTANT.REMOVE, CONSTANT.CUSTOMER_ID);
                window.location.href = "/home";
            }
          
        }
    };

    useEffect(() => {
        const tokenAvailable = {
            token: Localstorage(CONSTANT.GET, CONSTANT.USER_INFO)?.replace(/"/g, '')
        };
        if(tokenAvailable && tokenAvailable.token){
            verifyToken();
        }
    }, []);

    const isAuthenticated: any = getUserData();

    return (
        <>
            <ToastContainer
                position="top-center"
                autoClose={2000}
                hideProgressBar={true}
                closeOnClick
                theme="colored"
            />
            <div className="app-container">
                {isAuthenticated && <ChatBoat />}
                <div>
                    <Routing />
                </div>
            </div>
        </>
    );
}

export default App;

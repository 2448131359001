import React, { useEffect, useState } from "react";
// import "./Filter.css"
import {
    Bars3BottomLeftIcon, FunnelIcon
} from "@heroicons/react/24/solid";
import blackDot from "../../assets/images/blackDot.svg";
import { useParams } from "react-router-dom";
import { BenefitService } from "../../services/benefit.service";
import { CONSTANT } from "../../core/StaticConstant";
import { decryptData } from "../../core/Utils";

const Filter = (props: any) => {
    let params: any = useParams();

    const { bgColor, getProductByCategory, pageName, sortingData, filteredData } = props;

    const [show, setShow] = useState(false);
    const [filterValue, setFilterValue] = useState("Select");
    const [showFilterValue, setShowFilterValue] = useState("Select");
    const [showFilter, setShowFilter] = useState(false);
    const [toolArray, setToolArray] = useState<any[]>([]);
    const [benefitData, setBenefitData] = useState<any[]>([]);
    const [benefitIds, setBenefitIds] = useState<number[]>([]);

    const handleClick = (id: number) => {
        let arr = [...toolArray];
        let updatedBenefitIds = [...benefitIds];
        const idIndex = updatedBenefitIds.indexOf(id);
        if (idIndex === -1) {
            updatedBenefitIds.push(id);
            arr.push(id);
        } else {
            updatedBenefitIds.splice(idIndex, 1);
            arr.splice(arr.indexOf(id), 1);
        }
        setToolArray(arr);
        setBenefitIds(updatedBenefitIds);

        let obj = {
            filter: decryptData(params?.Id),
            benefitIds: updatedBenefitIds,
        };
        getProductByCategory(obj);
        if (updatedBenefitIds.length === 0) {
            let obj = {
                filter: decryptData(params?.Id),
            };
            getProductByCategory(obj);
        }
    };

    useEffect(() => {
        let obj = {
            fields:["benefitName","benefitIcon","benefitImage","longDescription","shortDescription"]
        }
        getAllBenefit(obj);
    }, []);

    const filterData = (sortOrder: string, sortingField: string) => {
        let obj = {
            filter: decryptData(params?.Id),
            sortingOrder: sortOrder,
            sortingField: sortingField,
        };
        getProductByCategory(obj);
    };



    const getAllBenefit = async (obj:any) => {
        let response = await BenefitService.getAllBenefit(obj).then((data: any) => {
            if (data && data.status == CONSTANT.SUCCESS) {

                setBenefitData(data.data.benefits);

            }
        });
    };

    return (
        <>
            <div className={`${bgColor ? bgColor : "bg-whiteSmoke"}`}>
                {
                    pageName !== "self-train" && pageName !== "search-result" ?
                        <>
                            <div className="sm:px-12 px-4 pt-8 x:pt-6 s:block flex items-center justify-between ">
                                <p className="text-oxfordBlue text-[22px] font-bold">
                                    Top Categories
                                </p>
                                <div className="relative z-10">
                                    <div className="lg:col-span-3 xl:col-span-9 text-align-webkit-center text-tuatara h-[3rem] inline-flex items-center justify-end lg:mt-0">
                                        <Bars3BottomLeftIcon className="h-[50%] mr-[7px] rotate-icon" />
                                        <p className="font-bold text-[16px] mr-[7px]">Sort</p>
                                        <div className="group inline-block dropdown relative cursor-pointer">
                                            <button
                                                onClick={() => setShow(!show)}
                                                className="bg-black text-gray85 pl-[8px] pb-[2px] inline-flex items-center"
                                            >
                                                <span className="lg:mr-[40px] mr-[20px] font-medium text-[14px]">
                                                    {filterValue}
                                                </span>
                                                <span className="mt-[3px]">
                                                    <svg
                                                        className={`${show ? "-rotate-180" : "-rotate-270"
                                                            } fill-current h-5 w-5 transform-gpu duration-500 ease-in-out`}
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        viewBox="0 0 20 20"
                                                    >
                                                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                                    </svg>
                                                </span>
                                            </button>
                                            {show && (
                                                <div
                                                    onClick={() => setShow(false)}
                                                    className="fixed top-0 left-0 w-full h-screen"
                                                ></div>
                                            )}
                                            <ul
                                                className={`${show ? "block" : "hidden"
                                                    } absolute text-[12px] text-gray85  w-full text-start`}
                                            >
                                                <li className="">
                                                    <span
                                                        className="bg-black hover:text-white py-2 px-4 block whitespace-no-wrap"
                                                        onClick={(event) => {
                                                            filterData("asc", "productName");
                                                            setFilterValue("A-Z");
                                                            setShow(false);
                                                        }}
                                                    >
                                                        A-Z
                                                    </span>
                                                </li>
                                                <li className="">
                                                    <span
                                                        className="bg-black hover:text-white py-2 px-4 block whitespace-no-wrap"
                                                        onClick={() => {
                                                            filterData("desc", "productName");
                                                            setFilterValue("Z-A");
                                                            setShow(false);
                                                        }}
                                                    >
                                                        Z-A
                                                    </span>
                                                </li>
                                                <li className="">
                                                    <span
                                                        className="bg-black hover:text-white py-2 px-4 block whitespace-no-wrap"
                                                        onClick={() => {
                                                            filterData("desc", "createdAt");
                                                            setFilterValue("Newest");
                                                            setShow(false);
                                                        }}
                                                    >
                                                        Newest
                                                    </span>
                                                </li>
                                                <li className="">
                                                    <span
                                                        className="bg-black hover:text-white py-2 px-4 block whitespace-no-wrap"
                                                        onClick={() => {
                                                            filterData("asc", "createdAt");
                                                            setFilterValue("Oldest");
                                                            setShow(false);
                                                        }}
                                                    >
                                                        Oldest
                                                    </span>
                                                </li>
                                                <li className="">
                                                    <span
                                                        className="bg-black hover:text-white py-2 px-4 block whitespace-no-wrap"
                                                        onClick={(event) => {
                                                            filterData("asc", "mrp");
                                                            setFilterValue("Price: Highest");
                                                            setShow(false);
                                                        }}
                                                    >
                                                        Price: Highest
                                                    </span>
                                                </li>
                                                <li className="">
                                                    <span
                                                        className="bg-black hover:text-white py-2 px-4 block whitespace-no-wrap"
                                                        onClick={(event) => {
                                                            filterData("desc", "mrp");
                                                            setFilterValue("Price: Lowest");
                                                            setShow(false);
                                                        }}
                                                    >
                                                        Price: Lowest
                                                    </span>
                                                </li>
                                                <li className="">
                                                    <span
                                                        className="bg-black hover:text-white py-2 px-4 block whitespace-no-wrap"
                                                        onClick={(event) => {
                                                            filterData("desc", "productViewCount");
                                                            setFilterValue("Popular");
                                                            setShow(false);
                                                        }}
                                                    >
                                                        Popular
                                                    </span>
                                                </li>
                                                <li className="">
                                                    <span
                                                        onClick={(event) => {
                                                            setFilterValue("Best Seller");
                                                            setShow(false);
                                                        }}
                                                        className="bg-black hover:text-white py-2 px-4 block whitespace-no-wrap"
                                                    >
                                                        Best Seller
                                                    </span>
                                                </li>
                                                <li className="">
                                                    <span
                                                        onClick={(event) => {
                                                            setFilterValue("Best Reviews");
                                                            setShow(false);
                                                        }}
                                                        className="bg-black hover:text-white py-2 px-4 block whitespace-no-wrap"
                                                    >
                                                        Best Reviews
                                                    </span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="flex justify-between xs:flex-col s:flex-col sm:px-12 px-4 pt-8 s:pt-0 x:pt-6 sm:flex-row gap-4">
                                <div className="flex items-center">
                                    <div className="flex flex-wrap w-full sm:flex-row gap-2">
                                        {benefitData &&
                                            benefitData.map((ele: any, index: number) => {
                                                return (
                                                    <div
                                                        key={index}
                                                        className={`text-align-webkit-center cursor-pointer inline-flex w-fit lg:px-6 px-4 x:px-2 s:px-2 justify-center items-center text-tuatara sm:h-[3rem] h-[2rem] ${toolArray.includes(ele?._id)
                                                            ? "bg-oxfordBlue rounded-[27px]"
                                                            : ""
                                                            }`}
                                                        onClick={() => handleClick(ele?._id)}
                                                    >
                                                        <img
                                                            className={`sm:w-[28px] w-[20px] lg:mr-4 mr-2 ${toolArray.includes(ele?._id) ? " bg-white text-[white]" : ""
                                                                }`}
                                                            src={ele?.benefitIcon}
                                                            width={50}
                                                        />
                                                        <span
                                                            className={`font-semibold lg:text-[18px] sm:text-[16px] s:text-[14px] text-[16px] ${toolArray.includes(ele?._id) ? "text-[white]" : ""
                                                                }`}
                                                        >
                                                            {ele?.benefitName}
                                                        </span>
                                                    </div>
                                                );
                                            })}
                                    </div>
                                </div>
                            </div>
                        </> :
                        <>
                    
                            <div className="flex justify-between  px-4 2xl: && xl: && lg: && sm:pt-8  x:pt-6 gap-4">
                                {pageName !== "search-result" &&

                                    <div className="md:block hidden">
                                        <p className="text-white 2xl:ml-[100px] lg:text-[40px] text-[30px] font-bold">All Classes</p>
                                    </div>
                                }
                                <div className="relative z-10 md:w-auto w-full">
                                    <div className="flex justify-between gap-6 2xl:mr-[96px] xs:gap-4 s:gap-4 xxs:gap-0 x:gap-0">
                                        {/* <div className="flex items-center relative z-10"> */}
                                        {/* <div className="lg:col-span-3 xl:col-span-9 text-align-webkit-center text-tuatara h-[3rem] inline-flex items-center justify-end lg:mt-0"> */}
                                        {/* <Bars3BottomLeftIcon className="h-[50%] text-gray85 mr-[7px] rotate-icon" /> */}
                                        {/* <p className="font-[500] text-[16px] text-white mr-[7px]">Sort</p> */}
                                        {/* <div className="group inline-block dropdown relative cursor-pointer">
                          <button
                            onClick={() => setShow(!show)}
                            className="bg-darkBlack text-gray85 pl-[8px] ml-4 pb-[2px] inline-flex items-center"
                          >
                            <span className="lg:mr-[40px] mr-[20px] font-medium text-[14px]">
                              {filterValue}
                            </span>
                            <span className="mt-[3px]">
                              <svg
                                className={`${show ? "-rotate-180" : "-rotate-270"
                                  } fill-current h-5 w-5 transform-gpu duration-500 ease-in-out`}
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                              >
                                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                              </svg>
                            </span>
                          </button>
                          {show && (
                            <div
                              onClick={() => setShow(false)}
                              className="fixed top-0 left-0 w-full h-screen"
                            ></div>
                          )}
                          <ul
                            className={`${show ? "block" : "hidden"
                              } absolute text-[12px] text-gray85  w-full text-start`}
                          >
                            <li className="">
                              <span
                                className="bg-darkBlack hover:text-white py-1 px-3 block whitespace-no-wrap"
                                onClick={(event) => {
                                  filterData("asc", "productName");
                                  setFilterValue("A-Z");
                                  setShow(false);
                                }}
                              >
                                A-Z
                              </span>
                            </li>
                            <li className="">
                              <span
                                className="bg-darkBlack hover:text-white py-1 px-3 block whitespace-no-wrap"
                                onClick={() => {
                                  filterData("desc", "productName");
                                  setFilterValue("Z-A");
                                  setShow(false);
                                }}
                              >
                                Z-A
                              </span>
                            </li>
                            <li className="">
                              <span
                                className="bg-darkBlack hover:text-white py-1 px-3 block whitespace-no-wrap"
                                onClick={() => {
                                  filterData("desc", "createdAt");
                                  setFilterValue("Newest");
                                  setShow(false);
                                }}
                              >
                                Newest
                              </span>
                            </li>
                            <li className="">
                              <span
                                className="bg-darkBlack hover:text-white py-1 px-3 block whitespace-no-wrap"
                                onClick={() => {
                                  filterData("asc", "createdAt");
                                  setFilterValue("Oldest");
                                  setShow(false);
                                }}
                              >
                                Oldest
                              </span>
                            </li>
                            <li className="">
                              <span
                                className="bg-darkBlack hover:text-white py-1 px-3 block whitespace-no-wrap"
                                onClick={(event) => {
                                  filterData("asc", "mrp");
                                  setFilterValue("Price: Highest");
                                  setShow(false);
                                }}
                              >
                                Price: Highest
                              </span>
                            </li>
                            <li className="">
                              <span
                                className="bg-darkBlack hover:text-white py-1 px-3 block whitespace-no-wrap"
                                onClick={(event) => {
                                  filterData("desc", "mrp");
                                  setFilterValue("Price: Lowest");
                                  setShow(false);
                                }}
                              >
                                Price: Lowest
                              </span>
                            </li>
                            <li className="">
                              <span
                                className="bg-darkBlack hover:text-white py-1 px-3 block whitespace-no-wrap"
                                onClick={(event) => {
                                  filterData("desc", "productViewCount");
                                  setFilterValue("Popular");
                                  setShow(false);
                                }}
                              >
                                Popular
                              </span>
                            </li>
                            <li className="">
                              <span
                                onClick={(event) => {
                                  setFilterValue("Best Seller");
                                  setShow(false);
                                }}
                                className="bg-darkBlack hover:text-white py-1 px-3 block whitespace-no-wrap"
                              >
                                Best Seller
                              </span>
                            </li>
                            <li className="">
                              <span
                                onClick={(event) => {
                                  setFilterValue("Best Reviews");
                                  setShow(false);
                                }}
                                className="bg-darkBlack hover:text-white py-1 px-3 block whitespace-no-wrap"hover:text-white
                              >
                                Best Reviews
                              </span>
                            </li>
                          </ul>
                        </div> */}
                                        {/* <div>
                          <span className="mt-[3px]">
                              <svg
                                className={`${show ? "-rotate-180" : "-rotate-270"
                                  } fill-current h-5 w-5 transform-gpu duration-500 ease-in-out`}
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                              >
                                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                              </svg>
                            </span>
                          <select id="countries" className="bg-darkBlack border-darkBlack text-gray-900 text-sm rounded-lg focus:text-white focus:text-white block w-full p-2.5 dark:placeholder-gray-400 dark:text-white dark:focus:text-white dark:focus:text-white hover:text-white bg-darkBlack">
                            <option className="hover:bg-darkBlack focus:bg-darkBlack hover:text-white" selected>Choose a country</option>
                            <option value="US">United States</option>
                            <option value="CA">Canada</option>
                            <option value="FR">France</option>
                            <option value="DE">Germany</option>
                          </select>
                        </div> */}
                            {pageName !== "search-result" && 
                                        <div className="flex items-center relative z-10 gap-3">
                                            <div className="lg:col-span-3 xl:col-span-9 text-align-webkit-center text-tuatara h-[3rem] inline-flex items-center justify-end lg:mt-0">
                                                <Bars3BottomLeftIcon className="h-[50%] text-gray85 mr-[7px] rotate-icon" />
                                                <p className="font-[500] text-[16px] text-white mr-[7px]">Sort</p>
                                                <div className="group inline-block dropdown relative cursor-pointer">
                                                    <button
                                                        onClick={() => setShow(!show)}
                                                        className="bg-darkBlack text-gray85 pl-[8px] pb-[2px] inline-flex items-center"
                                                    >
                                                        <span className="min-w-[100px] text-left font-medium text-[14px]">
                                                            {filterValue}
                                                        </span>
                                                        <span className="mt-[3px]">
                                                            <svg
                                                                className={`${show ? "-rotate-180" : "-rotate-270"
                                                                    } fill-current h-5 w-5 transform-gpu duration-500 ease-in-out`}
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                viewBox="0 0 20 20"
                                                            >
                                                                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                                            </svg>
                                                        </span>
                                                    </button>
                                                    {show && (
                                                        <div
                                                            onClick={() => setShow(false)}
                                                            className="fixed top-0 left-0 w-full h-screen"
                                                        ></div>
                                                    )}
                                                    <ul
                                                        className={`${show ? "block" : "hidden"
                                                            } absolute text-[12px] text-gray85  w-full text-start`}

                                                    >
                                                        <li className="">
                                                            <span
                                                                className="bg-darkBlack hover:text-white py-2 px-4 block whitespace-no-wrap"
                                                                onClick={(event) => {
                                                                    // courseData("asc", "classTitle");
                                                                    sortingData("A-Z")
                                                                    setFilterValue("A-Z");
                                                                    setShow(false);
                                                                }}
                                                            >
                                                                A-Z
                                                            </span>
                                                        </li>
                                                        <li className="">
                                                            <span
                                                                className="bg-darkBlack hover:text-white py-2 px-4 block whitespace-no-wrap"
                                                                onClick={(event) => {
                                                                    // filterData("desc", "classTitle");
                                                                    sortingData("Z-A")
                                                                    setFilterValue("Z-A");
                                                                    setShow(false);
                                                                }}
                                                            >
                                                                Z-A
                                                            </span>
                                                        </li>
                                                        <li className="">
                                                            <span
                                                                className="bg-darkBlack hover:text-white py-2 px-4 block whitespace-no-wrap"
                                                                onClick={() => {
                                                                    sortingData("Newest");
                                                                    setFilterValue("Newest");
                                                                    setShow(false);
                                                                }}
                                                            >
                                                                Newest
                                                            </span>
                                                        </li>
                                                        <li className="">
                                                            <span
                                                                className="bg-darkBlack hover:text-white py-2 px-4 block whitespace-no-wrap"
                                                                onClick={() => {
                                                                    sortingData("Oldest");
                                                                    setFilterValue("Oldest");
                                                                    setShow(false);
                                                                }}
                                                            >
                                                                Oldest
                                                            </span>
                                                        </li>
                                                        <li className="">
                                                            <span
                                                                className="bg-darkBlack hover:text-white py-2 px-4 block whitespace-no-wrap"
                                                                onClick={() => {
                                                                    sortingData("Longest");
                                                                    setFilterValue("Longest");
                                                                    setShow(false);
                                                                }}
                                                            >
                                                                Longest
                                                            </span>
                                                        </li>
                                                        <li className="">
                                                            <span
                                                                className="bg-darkBlack hover:text-white py-2 px-4 block whitespace-no-wrap"
                                                                onClick={() => {
                                                                    sortingData("Shortest");
                                                                    setFilterValue("Shortest");
                                                                    setShow(false);
                                                                }}
                                                            >
                                                                Shortest
                                                            </span>
                                                        </li>
                                                        <li className="">
                                                            <span
                                                                className="bg-darkBlack hover:text-white py-2 px-4 block whitespace-no-wrap"
                                                                onClick={(event) => {
                                                                    sortingData("Price: Highest");
                                                                    setFilterValue("Price: Highest");
                                                                    setShow(false);
                                                                }}
                                                            >
                                                                Price: Highest
                                                            </span>
                                                        </li>
                                                        <li className="">
                                                            <span
                                                                className="bg-darkBlack hover:text-white py-2 px-4 block whitespace-no-wrap"
                                                                onClick={(event) => {
                                                                    sortingData("Price: Lowest");
                                                                    setFilterValue("Price: Lowest");
                                                                    setShow(false);
                                                                }}
                                                            >
                                                                Price: Lowest
                                                            </span>
                                                        </li>
                                                        <li className="">
                                                            <span
                                                                className="bg-darkBlack hover:text-white py-2 px-4 block whitespace-no-wrap"
                                                                onClick={(event) => {
                                                                    sortingData("Popular");
                                                                    setFilterValue("Popular");
                                                                    setShow(false);
                                                                }}
                                                            >
                                                                Popular
                                                            </span>
                                                        </li>
                                                        <li className="">
                                                            <span
                                                                onClick={(event) => {
                                                                    sortingData("Best Seller");
                                                                    setFilterValue("Best Seller");
                                                                    setShow(false);
                                                                }}
                                                                className="bg-darkBlack hover:text-white py-2 px-4 block whitespace-no-wrap"
                                                            >
                                                                Best Seller
                                                            </span>
                                                        </li>
                                                        <li className="">
                                                            <span
                                                                onClick={(event) => {
                                                                    sortingData("Best Reviews");
                                                                    setFilterValue("Best Reviews");
                                                                    setShow(false);
                                                                }}
                                                                className="bg-darkBlack hover:text-white py-2 px-4 block whitespace-no-wrap"
                                                            >
                                                                Best Reviews
                                                            </span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>}
                                        {/* </div> */}
                                        {/* </div> */}
                                        <div className="flex items-center relative z-10">
                                            <div className="lg:col-span-3 xl:col-span-9 text-align-webkit-center text-tuatara h-[3rem] inline-flex items-center justify-end lg:mt-0">
                                                <FunnelIcon className="h-[50%] text-gray85 mr-[7px] rotate-icon" />
                                                <p className="font-[500] text-[16px] text-white mr-[7px]">Filter</p>
                                                <div className="group inline-block dropdown relative cursor-pointer">
                                                    <button
                                                        onClick={() => setShowFilter(!showFilter)}
                                                        className="bg-darkBlack text-gray85 pl-[8px] pb-[2px] inline-flex items-center"
                                                    >
                                                        <span className="min-w-[100px] text-left font-medium text-[14px]">
                                                            {showFilterValue}
                                                        </span>
                                                        <span className="mt-[3px]">
                                                            <svg
                                                                className={`${showFilter ? "-rotate-180" : "-rotate-270"
                                                                    } fill-current h-5 w-5 transform-gpu duration-500 ease-in-out`}
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                viewBox="0 0 20 20"
                                                            >
                                                                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                                                            </svg>
                                                        </span>
                                                    </button>
                                                    {showFilter && (
                                                        <div
                                                            onClick={() => setShowFilter(false)}
                                                            className="fixed top-0 left-0 w-full h-screen"
                                                        ></div>
                                                    )}
                                                    <ul
                                                        className={`${showFilter ? "block" : "hidden"
                                                            } absolute text-[12px] text-gray85  w-full text-start`}
                                                    >
                                                        <li className="">
                                                            <span
                                                                className="bg-darkBlack hover:text-white py-1 px-3 block whitespace-no-wrap"
                                                                onClick={(event) => {
                                                                    // filteredData("Fitness");
                                                                    setShowFilterValue("Fitness");
                                                                    setShowFilter(false);
                                                                }}
                                                            >
                                                                Fitness
                                                            </span>
                                                        </li>
                                                        <li className="">
                                                            <span
                                                                className="bg-darkBlack hover:text-white py-1 px-3 block whitespace-no-wrap"
                                                                onClick={() => {
                                                                    // filteredData("Health");
                                                                    setShowFilterValue("Health");
                                                                    setShowFilter(false);
                                                                }}
                                                            >
                                                                Health
                                                            </span>
                                                        </li>
                                                        <li className="">
                                                            <span
                                                                className="bg-darkBlack hover:text-white py-1 px-3 block whitespace-no-wrap"
                                                                onClick={() => {
                                                                    // filteredData("Performance");
                                                                    setShowFilterValue("Performance");
                                                                    setShowFilter(false);
                                                                }}
                                                            >
                                                                Performance
                                                            </span>
                                                        </li>
                                                        <li className="">
                                                            <span
                                                                className="bg-darkBlack hover:text-white py-1 px-3 block whitespace-no-wrap"
                                                                onClick={() => {
                                                                    // filteredData("Wellness");
                                                                    setShowFilterValue("Wellness");
                                                                    setShowFilter(false);
                                                                }}
                                                            >
                                                                Wellness
                                                            </span>
                                                        </li>
                                                        <li className="">
                                                            <span
                                                                className="bg-darkBlack hover:text-white py-1 px-3 block whitespace-no-wrap"
                                                                onClick={(event) => {
                                                                    // filteredData("Beauty");
                                                                    setShowFilterValue("Beauty");
                                                                    setShowFilter(false);
                                                                }}
                                                            >
                                                                Beauty
                                                            </span>
                                                        </li>
                                                        <li className="">
                                                            <span
                                                                className="bg-darkBlack hover:text-white py-1 px-3 block whitespace-no-wrap"
                                                                onClick={(event) => {
                                                                    // filteredData("Relationship");
                                                                    setShowFilterValue("Relationship");
                                                                    setShowFilter(false);
                                                                }}
                                                            >
                                                                Relationship
                                                            </span>
                                                        </li>
                                                        <li className="">
                                                            <span
                                                                className="bg-darkBlack hover:text-white py-1 px-3 block whitespace-no-wrap"
                                                                onClick={(event) => {
                                                                    // filteredData(" Sexual Wellness");
                                                                    setShowFilterValue("Sexual Wellness");
                                                                    setShowFilter(false);
                                                                }}
                                                            >
                                                                Sexual Wellness
                                                            </span>
                                                        </li>
                                                        <li className="">
                                                            <span
                                                                onClick={(event) => {
                                                                    // filteredData(" Yoga");
                                                                    setShowFilterValue("Yoga");
                                                                    setShowFilter(false);
                                                                }}
                                                                className="bg-darkBlack hover:text-white py-1 px-3 block whitespace-no-wrap"
                                                            >
                                                                Yoga
                                                            </span>
                                                        </li>
                                                        <li className="">
                                                            <span
                                                                onClick={(event) => {
                                                                    // filteredData("Diet");
                                                                    setShowFilterValue("Best Reviews");
                                                                    setShowFilter(false);
                                                                }}
                                                                className="bg-darkBlack hover:text-white py-1 px-3 block whitespace-no-wrap"
                                                            >
                                                                Diet
                                                            </span>
                                                        </li>
                                                        <li className="">
                                                            <span
                                                                onClick={(event) => {
                                                                    // filteredData(" Weightless");
                                                                    setShowFilterValue("Weightless");
                                                                    setShowFilter(false);
                                                                }}
                                                                className="bg-darkBlack hover:text-white py-1 px-3 block whitespace-no-wrap"
                                                            >
                                                                Weightless
                                                            </span>
                                                        </li>
                                                        <li className="">
                                                            <span
                                                                onClick={(event) => {
                                                                    filteredData(" Medicine");
                                                                    setShowFilterValue("Medicine");
                                                                    setShowFilter(false);
                                                                }}
                                                                className="bg-darkBlack hover:text-white py-1 px-3 block whitespace-no-wrap"
                                                            >
                                                                Medicine
                                                            </span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                }
            </div>
        </>
    );
};

export default Filter;

import httpCommon from '../core/HttpCommonCore';
import { API_CONSTANT } from '../core/Constant';

export const CategoryService = {
    getAllCategoryForTopBar
};

function getAllCategoryForTopBar() {
    let url = API_CONSTANT.GET_ALL_CATEGORY_FOR_TOPBAR
    return httpCommon.get(url,{}).then((response:any) => {
        if (response.statusText !== "OK") {
            const error = response.statusText;
            return Promise.reject(error);
        }
        return response.data
    })
}
import React from 'react'
import UserChildren from '../../components/user-profile/UserChildren'
import LoginSecurity from '../../components/login-security/LoginSecurity'
import PaymentDetails from '../../components/payment-details/PaymentDetails'

const AccountSettings = () => {
  return (
    <UserChildren title="ACCOUNT SETTINGS">
      <div className="lg:flex gap-3">
        <LoginSecurity />
        <PaymentDetails />
      </div>
    </UserChildren>
  )
}

export default AccountSettings
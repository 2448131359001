import "./VideoBanner.css";
import ReactPlayer from 'react-player'

const VideoBanner = (props: any) => {
  const { courseData } = props

  return (
    <div >
      <div className="video-player video max-w-full bg-white">
        <ReactPlayer
          className=""
          width="100%"
          height="100%"
          url={courseData.courseHoverVideo ? courseData.courseHoverVideo : ""} 
          playing={true}
          loop={true}
        />
      </div>
    </div>
  )
}

export default VideoBanner
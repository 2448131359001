import React from "react";

const AboutUs = () => {
  return (
    <>
      <p className="text-silverChalice mb-4 sm:text-[16px] text-[12px]">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua. Volutpat commodo sed
        egestas egestas fringilla phasellus faucibus scelerisque. Augue mauris
        augue neque gravida in. Nisi vitae suscipit tellus mauris. Urna nunc id
        cursus metus aliquam eleifend. Sed risus pretium quam vulputate
        dignissim suspendisse in est ante. Odio eu feugiat pretium nibh ipsum
        consequat nisl vel pretium. Pharetra pharetra massa massa ultricies mi
        quis hendrerit dolor magna. Ac placerat vestibulum lectus mauris.
        Facilisi nullam vehicula ipsum a arcu cursus vitae congue mauris. Massa
        id neque aliquam vestibulum morbi. Condimentum mattis pellentesque id
        nibh tortor id aliquet lectus proin. Faucibus vitae aliquet nec
        ullamcorper sit amet risus. Ac turpis egestas sed tempus urna et
        pharetra pharetra.
      </p>
      <p className="text-silverChalice mb-4 sm:text-[16px] text-[12px]">
        Tortor vitae purus faucibus ornare suspendisse sed nisi lacus. Donec ac
        odio tempor orci. Nunc sed id semper risus in hendrerit. Nisl vel
        pretium lectus quam id Leo in vitae turpis. Eros donec ac odio tempor.
        Mauris in aliquam sem fringilla ut. Ac tortor vitae purus faucibus
        ornare suspendisse. Quam quisque id diam vel quam elementum pulvinar.
        Ultricies mi eget mauris pharetra et ultrices neque. Cursus in hac
        habitasse platea dictumst quisque sagittis. Libero volutpat sed cras
        ornare arcu. Placerat vestibulum lectus mauris ultrices eros in cursus
        turpis. Aliquam sem fringilla ut Nisl vel pretium lectus quam id morbi.
      </p>
      <p className="text-silverChalice mb-4 sm:text-[16px] text-[12px]">
        Tincidunt ornare massa eget egestas purus viverra accumsan in. Posuere
        urna nec tincidunt praesent semper feugiat. Non odio euismod lacinia at
        quis. Tortor vitae purus faucibus ornare suspendisse sed nisi. Ultrices
        gravida dictum fusce ut placerat orci nulla pellentesque dignissim.
        Interdum varius sit amet mattis vulputate enim nulla aliquet porttitor.
        Adipiscing vitae proin sagittis nisl rhoncus. Elit duis tristique
        sollicitudin nibh sit amet. Nunc mi ipsum faucibus vitae aliquet nec. Ut
        faucibus pulvinar elementum integer. Augue mauris augue neque gravida in
        fermentum et sollicitudin. Laoreet id donec ultrices tincidunt arcu non.
        Ut ornare lectus sit amet est placerat. Varius morbi enim nunc faucibus.
        Imperdiet dui accumsan sit amet nulla facilisi.
      </p>
    </>
  );
};

export default AboutUs;

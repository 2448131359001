import { useState } from "react";
import ProfileDetails from "../../components/profile-details/ProfileDetails";
import MedicalDetails from "../../components/medical-details/MedicalDetails";

const Profile = (props:any) => {
    const [isMedicalProfileClicked, setIsMedicalProfileClicked] = useState(false);

  return (
      <div className="lg:flex gap-3">
      <ProfileDetails
          isMedicalProfileClicked={isMedicalProfileClicked}
          setIsMedicalProfileClicked={setIsMedicalProfileClicked}
        />
        <MedicalDetails isMedicalProfileClicked={isMedicalProfileClicked} setIsMedicalProfileClicked={setIsMedicalProfileClicked} />
      </div>
  );
};

export default Profile;

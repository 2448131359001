import httpCommon from "../core/HttpCommonCore";
import { API_CONSTANT } from "../core/Constant";
import { AxiosError } from "axios";

export const UserService = {
    registerUser,
    loginUser,
    updateUserBasicDetails,
    getAllCategories,
    getUserBasicDetailsById,
    deleteUserById,
    signUpUser,
    updateCartByUserId,
    guestUser,
    resetPassword,
    forgotPassword,
    forgotUserPassword,
    verifyUserToken,
    resetPasswordWithToken,
    verifyToken,
    verifyUserOtp
};

function registerUser(data: any, id: any) {
    let url = API_CONSTANT.REGISTER_USER;
    return httpCommon.post(url, data).then((response: any) => {
        if (response.statusText !== "OK") {
            const error = response.statusText;
            return Promise.reject(error);
        }
        return response.data;
    });
}


function forgotPassword(data: any) {
    return httpCommon.post(API_CONSTANT.FORGOT_PASSWORD, data).then((response: any) => {
        if (response.statusText !== "OK") {
            const error = response.statusText;
            return Promise.reject(error);
        }
        return response.data;
    });
}

function verifyUserToken(data: any) {
    return httpCommon.post(API_CONSTANT.VERIFY_FORGOT_PASSWORD_OTP, data).then((response: any) => {
        if (response.statusText !== "OK") {
            const error = response.statusText;
            return Promise.reject(error);
        }
        return response.data;
    });
}

function verifyUserOtp(data: any) {
    return httpCommon.post(API_CONSTANT.VERIFY_USER_OTP, data).then((response: any) => {
        if (response.statusText !== "OK") {
            const error = response.statusText;
            return Promise.reject(error);
        }
        return response.data;
    });
}

function verifyToken(data: any) {
    let url = API_CONSTANT.VERIFY_TOKEN;
    return httpCommon.post(url, data).then((response: any) => {
        if (response.statusText !== "OK") {
            const error = response.statusText;
            return Promise.reject(error);
        }
        return response;
    }).catch((error: AxiosError) => {
        return Promise.reject(error);
      });
}



function forgotUserPassword(data: any) {
    return httpCommon.post(API_CONSTANT.FORGOT_USER_PASSWORD, data).then((response: any) => {
        if (response.statusText !== "OK") {
            const error = response.statusText;
            return Promise.reject(error);
        }
        return response.data;
    });
}



function signUpUser(data: any) {
    let url = API_CONSTANT.REGISTER_USER;
    return httpCommon.post(url, data).then((response: any) => {
        if (response.statusText !== "OK") {
            const error = response.statusText;
            return Promise.reject(error);
        }
        return response.data;
    });
}

function guestUser(data: any) {
    let url = API_CONSTANT.GUEST_USER;
    return httpCommon.post(url, data).then((response: any) => {
        if (response.statusText !== "OK") {
            const error = response.statusText;
            return Promise.reject(error);
        }
        return response.data;
    });
}

function loginUser(data: any) {
    let url = API_CONSTANT.LOGIN_USER;
    return httpCommon.post(url, data).then((response: any) => {
        if (response.statusText !== "OK") {
            const error = response.statusText;
            return Promise.reject(error);
        }
        return response.data;
    });
}

function updateUserBasicDetails(data: any) {
    let url = API_CONSTANT.UPDATE_USER_BASIC_DETAILS_BY_ID;
    return httpCommon.post(url, data).then((response: any) => {
        if (response.statusText !== "OK") {
            const error = response.statusText;
            return Promise.reject(error);
        }
        return response.data;
    });
}

function getUserBasicDetailsById() {
    let url = API_CONSTANT.GET_USER_BASIC_DETAILS_BY_ID;
    return httpCommon.get(url).then((response: any) => {
        if (response.statusText !== "OK") {
            const error = response.statusText;
            return Promise.reject(error);
        }
        return response.data;
    });
}

function deleteUserById(id: any) {
    let url = API_CONSTANT.DELETE_USER_BY_ID + id;
    return httpCommon.post(url).then((response: any) => {
        if (response.statusText !== "OK") {
            const error = response.statusText;
            return Promise.reject(error);
        }
        return response.data;
    });
}

function getAllCategories() {
    let url = API_CONSTANT.GET_ALL_USER;
    return httpCommon.get(url, {}).then((response: any) => {
        if (response.statusText !== "OK") {
            const error = response.statusText;
            return Promise.reject(error);
        }
        return response.data;
    });
}

function updateCartByUserId(id: any, data: any) {
    let url = API_CONSTANT.UPDATE_USER_CART_BY_USER_ID + id;
    return httpCommon.post(url, data).then((response: any) => {
        if (response.statusText !== "OK") {
            const error = response.statusText;
            return Promise.reject(error);
        }
        return response.data;
    });
}


function resetPassword(data: any) {
    let url = API_CONSTANT.RESET_PASSWORD;
    return httpCommon.post(url, data).then((response: any) => {
        if (response.statusText !== "OK") {
            const error = response.statusText;
            return Promise.reject(error);
        }
        return response.data;
    });
}

function resetPasswordWithToken(data: any) {
    let url = API_CONSTANT.RESET_PASSWORD_WITH_TOKEN;
    return httpCommon.post(url, data).then((response: any) => {
        if (response.statusText !== "OK") {
            const error = response.statusText;
            return Promise.reject(error);
        }
        return response.data;
    });
}
import React, { useRef, useState } from "react";
import {
  ArrowUpRightIcon,
  BoltIcon,
  FaceSmileIcon,
  HeartIcon,
  MoonIcon
} from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import BodyScansPackage from "../../../assets/images/body-scans-package.png";
import BodyScansPackage1 from "../../../assets/images/body-scans-package-1.png";
import BodyScansPackage2 from "../../../assets/images/body-scans-package-2.png";
import BodyScansPackage3 from "../../../assets/images/body-scans-package-3.png";

const Packages = (props: any) => {
  const { item, i } = props;
  const navigate = useNavigate();

  const images = [
    BodyScansPackage,
    BodyScansPackage1,
    BodyScansPackage2,
    BodyScansPackage3
  ];

  function getRandomImage() {
    const randomNumber = Math.floor(Math.random() * images.length);
    return images[randomNumber];
  }

  return (
    <li
      key={i}
      className="w-full lg:w-[calc(50%-6px)] sm:flex xs:flex gap-3 bg-greenFooter text-capeCod items-center cursor-pointer"
    >
      <div className="md:w-[150px] w-[120px] s:mx-auto">
        <img src={getRandomImage()} className="" />
      </div>
      <div className="md:w-[calc(100%-162px)] sx:w-[calc(100%-132px)] xs:w-[calc(100%-132px)] w-full 2xl:p-4 p-3">
        <div className="flex justify-between mb-3">
          <h2 className="2xl:text-[32px] sm:text-[24px] text-[20px] bold-font-heading">
            Full Body Scan
          </h2>
          <h2 className="2xl:text-[32px] sm:text-[24px] text-[20px] font-medium">
            $45
          </h2>
        </div>
        <div className="flex justify-between">
          <ul className="flex sm:gap-4 gap-3 sm:pr-4 flex-wrap">
            <li className="flex gap-2">
              <BoltIcon className="rotate-0 2xl:w-5 2xl:h-5 w-4 h-4 mt-1" />
              <span className="2xl:text-[19px] sm:text-[16px] text-[14px]">
                Energy
              </span>
            </li>
            <li className="flex gap-2">
              <MoonIcon className="rotate-0 2xl:w-5 2xl:h-5 w-4 h-4 mt-1" />
              <span className="2xl:text-[19px] sm:text-[16px] text-[14px]">
                Sleep
              </span>
            </li>
            <li className="flex gap-2">
              <FaceSmileIcon className="rotate-0 2xl:w-5 2xl:h-5 w-4 h-4 mt-1" />
              <span className="2xl:text-[19px] sm:text-[16px] text-[14px]">
                Fitness
              </span>
            </li>
          </ul>
          <button
            onClick={() => navigate("/body-scans/package")}
            className="sm:p-3 p-2"
            type="button"
          >
            <ArrowUpRightIcon className="w-5 h-5 stroke-[3]" />
          </button>
        </div>
        <p className="2xl:text-[18px] sm:text-[14px] text-[10px] pt-2 pb-2 pl-2 border-l-[5px] border-capeCod">
          Your body uses iron to make haemoglobin - a part of red blood cells
          that carries oxygen around your body. If you don’t get enough iron you
          might develop iron deficiency anamia
        </p>
      </div>
    </li>
  );
};

export default Packages;

import React from 'react'
import CategoryNav from '../../sub-nav/SubNav'
import ReactPlayer from 'react-player'

const ExpertComponent = () => {

    return (
        <div>
            <ReactPlayer
                className=''
                width="100%"
                height="100%"
                url="/video/ExpertsTeam.mp4"
                playing={true}
                loop={true}
            />
        </div>
    )
}

export default ExpertComponent
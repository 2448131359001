import httpCommon from "../core/HttpCommonCore";
import { API_CONSTANT } from "../core/Constant";

export const MarketingIngredientService = {
  getAllMarketingIngredients,
};

function getAllMarketingIngredients(data:any) {
  let url = API_CONSTANT.GET_ALL_INGREDIENT;
  return httpCommon.post(url,data).then((response: any) => {
    if (response.statusText !== "OK") {
      const error = response.statusText;
      return Promise.reject(error);
    }
    return response.data;
  });
}

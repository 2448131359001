import * as Yup from "yup";
import { CONSTANT } from "../../core/StaticConstant";

export const logInSchema = Yup.object({
    email: Yup.string().email().required(CONSTANT.ENTER_EMAIL),
    password: Yup.string().required(CONSTANT.ENTER_PASSWORD)
})

export const passwordSchema = Yup.object({
    password: Yup.string().required(CONSTANT.ENTER_PASSWORD),
    confirmPassword: Yup.string().required(CONSTANT.CONFIRM_PASSWORD)
})
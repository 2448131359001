import { useEffect, PropsWithChildren, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import {
    CheckIcon,
    ArrowUpRightIcon,
    HeartIcon,
} from "@heroicons/react/24/outline";
import {
    PencilIcon,
    HeartIcon as HeartSolidIcon,
} from "@heroicons/react/24/solid";
import { CartService, ProductService } from "../../../services";
import { CONSTANT } from "../../../core/StaticConstant";
import Slider from "react-slick";
import Rating from "@mui/material/Rating";
import Stack from "@mui/material/Stack";
import "./ProductCardDetails.css";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import {
    setCartProducts,
    setProductData,
} from "../../../redux/reducer/cartReducer";
import { decryptData, getUserData } from "../../../core/Utils";
import { Localstorage } from "../../../core/AuthService";

interface PostProps {
    loading: boolean;
}

function StarWrapper({ children }: PropsWithChildren<unknown>) {
    return (
        <div
            style={{
                display: "inline-block",
                clipPath:
                    "polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%)",
                width: "24px",
                height: "24px",
            }}
        >
            {children}
        </div>
    );
}

const ProductCardDetail = (props: any) => {
    const {
        isBodyScans,
        isSkeleton,
        productData,
        skuId,
        productSkuImg,
        reviewAverage,
        setProductSkuImg,
        skuData,
        selectedSkuItemData,
        setSelectedSkuItemData,
        dosageAssistanceData,
        className,
    } = props;

    const storageData = useSelector((state: any) => state);
    let params: any = useParams();
    const dispatch = useDispatch();
    let productId = decryptData(params?.Id);
    const cartProductsStore = storageData?.cartProducts;
    const [zipInputValue, setZipInputValue] = useState<string>("100101");
    const [isEdit, setIsEdit] = useState<boolean>(false);
    const [currentSlide, setCurrentSlide] = useState(0);
    const [num, setNum] = useState(1);
    const [likeUnlikeData, setLikeUnlike] = useState<any[]>([]);
    const [totalAmount, setTotalAmount] = useState<any>(0);
    const [isAddingToCart, setIsAddingToCart] = useState(false);
    const [selectedActualPrice, setSelectedActualPrice] = useState<any>(
        selectedSkuItemData?.productSingleAmount
    );
    const [dosageSelect, setDosageSelect] = useState(false);
    const [dosageSelectPrice, setDosageSelectPrice] = useState<any>();
    const [select, setSelect] = useState("2");
    const [userId, setUserId] = useState<any>("");
    const [cartData, setCartData] = useState<any>([]);

    dispatch(setProductData(selectedSkuItemData.productQty));

    let navigate = useNavigate();
    // const product = storageData?.cartProducts?.find(
    //   (product: any) => product?.productId === productId
    // );
    // const checkProduct = cartData?.find(
    //     (product: any) => product?.productId === productId
    //     );

    const cartProducts = storageData?.cartProducts || [];
    const product = Array.isArray(cartProducts)
        ? cartProducts.find((product: any) => product?.productId === productId)
        : null;

    const checkProduct = cartData?.find((product: any) => product?.productId === productId);


    useEffect(() => {
        const userData: any = getUserData();

        setUserId(userData?.userId);
        if (userData?.userId) {
            like();
        }
        const clickedStatus = Localstorage(CONSTANT.GET, `product_${productId}_clicked`);
        if (clickedStatus === "true" && !userId) {
        }
        if (userData?.userId) {
            // let obj = {
            //     userId: userData?.userId,
            //     productID: userData?.productId,
            // };
            //   getCartData(obj);
        }
    }, []);

    const getCartData = async () => {
        await CartService.getCartByUserId()
            .then((data: any) => {
                if (data && data.statusCode === 403) {
                    toast.error(data.message);
                    dispatch(setCartProducts([]));
                    Localstorage(CONSTANT.REMOVE, CONSTANT.USER_INFO);
                    Localstorage(CONSTANT.REMOVE, CONSTANT.SELECTED_MENU);
                    Localstorage(CONSTANT.REMOVE, CONSTANT.CUSTOMER_ID);
                    setTimeout(() => {
                        window.location.href = "/shop/cart";
                    }, 1000);
                } else {
                    if (data && data.status == CONSTANT.SUCCESS) {
                        setCartData(data?.data?.cart?.cart);
                        if (data?.data?.cart?.cart) {
                            let productFind = data?.data?.cart?.cart?.find(
                                (product: any) => product.productId === productId
                            );

                            dispatch(setCartProducts(data?.data?.cart?.cart));
                            if (productFind) {
                                const priceCalculate =
                                    productFind?.actualAmount * productFind?.quantity +
                                    (productFind?.dosageAssistancePrice || 0);
                                setTotalAmount(priceCalculate);
                                setSelectedSkuItemData(productFind?.skuId);
                                if (
                                    productFind?.actualAmount ===
                                    productFind?.skuId?.productSingleAmount ||
                                    productFind?.actualAmount +
                                    productFind.dosageAssistancePrice ===
                                    productFind?.skuId?.productSingleAmount
                                ) {
                                    setSelect("2");
                                } else {
                                    setSelect("1");
                                }
                            }
                        }
                    }
                }
            })
            .catch((err: any) => {
                if (err?.response?.data?.error !== CONSTANT.DATA_NOT_FOUND) {
                    toast.error(err?.response?.data?.error);
                }
            });
    };

    useEffect(() => {
        if (product && !checkProduct) {
            setSelectedSkuItemData(product?.skuId);
            setSelectedActualPrice(product.actualAmount);
            setDosageSelectPrice(product.dosageAssistancePrice);
        }
        if (
            product?.actualAmount === product?.skuId?.productSingleAmount ||
            product?.actualAmount + product.dosageAssistancePrice ===
            product?.skuId?.productSingleAmount
        ) {
            setSelect("2");
        } else {
            setSelect("1");
        }
    }, [product]);

    useEffect(() => {
        if (!checkProduct) {
            if (product) {
                if (product?.dosageAssistancePrice) {
                    let amount: any =
                        product.actualAmount * product?.quantity +
                        product?.dosageAssistancePrice;
                    setTotalAmount(amount);
                } else {
                    let amount: any = product.actualAmount * product?.quantity;
                    setTotalAmount(amount);
                }
            } else {
                if (dosageSelect) {
                    let amount: any =
                        selectedActualPrice * num +
                        dosageAssistanceData?.dosageAssistancePrice;
                    setTotalAmount(amount);
                } else {
                    let amount: any = selectedActualPrice * num;
                    setTotalAmount(amount);
                }
            }
            //   const isIdExists = cartProductsStore?.some(
            //     (product: any) => product?.productId === productId
            //   );
        }
    }, [
        num,
        product,
        totalAmount,
        selectedSkuItemData?.productSingleAmount,
        isAddingToCart,
    ]);

    useEffect(() => {
        if (userId) {
            getCartData();
        }
    }, [product?.quantity]);

    const createCart = async (data: any) => {
        let response = await CartService.createCart(data).then((data: any) => {
            setIsAddingToCart(true);

            if (data?.status === CONSTANT.SUCCESS) {
                getCartData();
            }
        });
    };

    const like = async () => {
        try {
            let response = await ProductService.GetlikeUnlikeProductByUser();
            if (response && response.status === CONSTANT.SUCCESS) {
                setLikeUnlike(response.data.product);
            }
        } catch (err: any) {
            console.error("Error occurred in like:", err);
            toast.error(err?.response?.data?.error);
        }
    };

    const likeUnlikeProduct = async (id: any) => {
        if (userId) {
            try {
                let obj = {
                    productId: productId, // Use productId instead of skuId
                    skuId: skuId._id,
                };
                let response = await ProductService.likeUnlikeProductByUser(obj);
                if (response.status === CONSTANT.SUCCESS) {
                    setLikeUnlike(response.data.product);
                    toast.success(response.message);

                }

                await like();
            } catch (err: any) {
                console.error("Error occurred in likeUnlikeProduct:", err);
                toast.error(err?.response?.data?.error);
            }
        } else {
            Localstorage(CONSTANT.SET, CONSTANT.LAST_VISITED_PAGE, window.location.href);
            navigate("/login");
        }
    };

    const onAddToCartClick = async () => {
        if (selectedSkuItemData.productQty > 0) {
            const isIdExists = userId
                ? cartData?.some((product: any) => product.productId === productId)
                : cartProductsStore?.some(
                    (product: any) => product.productId === productId
                );
            try {
                if (!isIdExists) {
                    let obj = {
                        productId: productId,
                        productName: productData?.productName,
                        productSkuImg: productSkuImg?.[0],
                        actualAmount: selectedActualPrice,
                        dosageAssistancePrice: dosageSelectPrice,
                        quantity: num,
                        totalAmount: totalAmount,
                        skuId: selectedSkuItemData,
                    };
                    dispatch(setCartProducts([...cartProductsStore, obj]));
                    if (userId) {
                        let cartData: any = {
                            cart: [obj],
                        };
                        createCart(cartData);
                    }
                }
            } catch (error) {
                console.error("Error occurred in onAddToCartClick:", error);
            }
        }
    };

    const removeProduct = async (data: any) => {
        try {
            let response = await CartService.deleteCartByUserIdAndProductId(data);
            if (response && response.status === CONSTANT.SUCCESS) {
                getCartData();
            }
        } catch (error) {
            console.error("Error occurred in removeProduct:", error);
        }
    };

    const onRemoveToCart = async () => {
        if (selectedSkuItemData.productQty > 0) {
            setIsAddingToCart(false);
            setNum(1);
            setTotalAmount(selectedSkuItemData.productSingleAmount);
            if (checkProduct && userId) {
                let obj = {
                    productId: productId,
                };
                removeProduct(obj);
            }
            const isIdExists = cartProductsStore?.some(
                (product: any) => product.productId === productId
            );
            try {
                if (isIdExists) {
                    const updatedCartProducts = cartProductsStore?.filter(
                        (product: any) => product?.productId !== productId
                    );
                    dispatch(setCartProducts(updatedCartProducts));
                } else {
                }
            } catch (error) {
                console.error("Error occurred in onRemoveToCart:", error);
            }
        }
    };

    // dosage select unselect check database and redux store //
    const onSelectDosageAssistance = (checked: any) => {
        const isChecked = checked;
        setDosageSelect(isChecked);

        const updatedCartProducts = isChecked
            ? userId && checkProduct
                ? cartData.map((product: any) => {
                    if (product.productId === productId) {
                        return {
                            ...product,
                            dosageAssistancePrice:
                                dosageAssistanceData?.dosageAssistancePrice,
                            totalAmount:
                                product.actualAmount * product.quantity +
                                dosageAssistanceData?.dosageAssistancePrice,
                        };
                    }
                    return product;
                })
                : cartProductsStore.map((product: any) => {
                    if (product.productId === productId) {
                        return {
                            ...product,
                            dosageAssistancePrice:
                                dosageAssistanceData?.dosageAssistancePrice,
                            totalAmount:
                                product.actualAmount * product.quantity +
                                dosageAssistanceData?.dosageAssistancePrice,
                        };
                    }
                    return product;
                })
            : product
                ? cartProductsStore.map((product: any) => {
                    if (product.productId === productId) {
                        return {
                            ...product,
                            dosageAssistancePrice: 0,
                            totalAmount: product.actualAmount * product.quantity,
                        };
                    }
                    return product;
                })
                : cartData.map((product: any) => {
                    if (product.productId === productId) {
                        return {
                            ...product,
                            dosageAssistancePrice: 0,
                            totalAmount: product.actualAmount * product.quantity,
                        };
                    }
                    return product;
                });

        dispatch(setCartProducts(updatedCartProducts));
        if (userId && checkProduct) {
            let obj = {
                cart: updatedCartProducts,
            };
            createCart(obj);
        }
        if (!checkProduct && !product) {
            if (isChecked) {
                let amount: any =
                    selectedActualPrice * num +
                    dosageAssistanceData?.dosageAssistancePrice;
                setTotalAmount(amount);
                setDosageSelectPrice(dosageAssistanceData.dosageAssistancePrice);
            } else {
                let amount: any = selectedActualPrice * num;
                setTotalAmount(amount);
                setDosageSelectPrice("");
            }
        }
    };

    const onEditClick = () => {
        setIsEdit(true);
    };

    const handleReviewClick = () => {
        const reviewsSection: any = document.getElementById("reviews-section");
        reviewsSection.scrollIntoView({ behavior: "smooth" });
    };

    const productVariantClick = (id: string) => {
        if (id) {
            let filterData = skuData.filter((ele: any) => ele?._id === id);
            setSelectedSkuItemData(filterData[0]);
            setProductSkuImg(filterData[0].productImage);
            setNum(1);
            setSelectedActualPrice(
                select === "2"
                    ? filterData[0].productSingleAmount
                    : filterData[0].productSubscribeAmount
            );
            let actualValueFind =
                select === "2"
                    ? filterData[0].productSingleAmount
                    : filterData[0].productSubscribeAmount;
            if (product && filterData[0]?.productQty > 0) {
                if (filterData[0].productQty < checkProduct?.quantity) {
                    const updatedCartProducts = cartProductsStore.map((product: any) => {
                        if (product.productId === productId) {
                            return {
                                ...product,
                                actualAmount: actualValueFind,
                                skuId: filterData[0],
                                quantity: filterData[0]?.productQty,
                                totalAmount:
                                    actualValueFind * filterData[0]?.quantity +
                                    product?.dosageAssistancePrice,
                            };
                        }
                        return product;
                    });
                    dispatch(setCartProducts(updatedCartProducts));
                } else {
                    const updatedCartProducts = cartProductsStore.map((product: any) => {
                        if (product.productId === productId) {
                            return {
                                ...product,
                                actualAmount: actualValueFind,
                                skuId: filterData[0],
                                totalAmount:
                                    actualValueFind * product.quantity +
                                    product?.dosageAssistancePrice,
                            };
                        }
                        return product;
                    });
                    dispatch(setCartProducts(updatedCartProducts));
                }
            }
            if (userId && checkProduct && filterData[0]?.productQty > 0) {
                if (filterData[0].productQty < checkProduct?.quantity) {
                    const updatedCartProducts = cartData.map((product: any) => {
                        if (product.productId === productId) {
                            return {
                                ...product,
                                actualAmount: actualValueFind,
                                skuId: filterData[0],
                                quantity: filterData[0]?.productQty,
                                totalAmount:
                                    actualValueFind * filterData[0]?.quantity +
                                    product?.dosageAssistancePrice,
                            };
                        }
                        return product;
                    });
                    const obj = {
                        cart: updatedCartProducts,
                    };
                    createCart(obj);
                } else {
                    const updatedCartProducts = cartData.map((product: any) => {
                        if (product.productId === productId) {
                            return {
                                ...product,
                                actualAmount: actualValueFind,
                                skuId: filterData[0],
                                totalAmount:
                                    actualValueFind * product.quantity +
                                    product?.dosageAssistancePrice,
                            };
                        }
                        return product;
                    });
                    const obj = {
                        cart: updatedCartProducts,
                    };
                    createCart(obj);
                }
            }
        }
        setCurrentSlide(0);
    };

    const onQuantityChange = (e: any) => {
        setNum(e.target.value);
    };

    // quantity increment //
    const incNum = () => {
        if (num < selectedSkuItemData.productQty && !product) {
            setNum(Number(num) + 1);
            let amount: any = selectedActualPrice * num;
            if (dosageSelectPrice) {
                amount += dosageAssistanceData?.dosageAssistancePrice;
            }
            setTotalAmount(amount);
        }
        if (product?.quantity < product?.skuId?.productQty) {
            const updatedCartProducts = cartProductsStore.map((product: any) =>
                product.productId === productId
                    ? {
                        ...product,
                        quantity: product?.quantity + 1,
                        totalAmount:
                            product.actualAmount * (product.quantity + 1) +
                            (product.dosageAssistancePrice || 0),
                    }
                    : product
            );
            dispatch(setCartProducts(updatedCartProducts));
        }
        if (userId && product?.quantity < product?.skuId?.productQty) {
            const updatedCartProducts = cartData.map((product: any) =>
                product.productId === productId
                    ? {
                        ...product,
                        quantity: product?.quantity + 1,
                        totalAmount:
                            product.actualAmount * (product.quantity + 1) +
                            (product.dosageAssistancePrice || 0),
                    }
                    : product
            );
            const obj = {
                cart: updatedCartProducts,
            };
            createCart(obj);
        }
    };

    // quantity decrement //
    const decNum = () => {
        if (num > 1 && !checkProduct && !product) {
            setNum(num - 1);
            const amount =
                selectedActualPrice * num +
                (dosageSelectPrice ? dosageAssistanceData?.dosageAssistancePrice : 0);
            setTotalAmount(amount);
        }
        if (userId && checkProduct && checkProduct.quantity > 1) {
            const updatedCartProducts = cartData.map((product: any) =>
                product.productId === productId
                    ? {
                        ...product,
                        quantity: product?.quantity - 1,
                        totalAmount:
                            product.actualAmount * (product.quantity - 1) +
                            (product.dosageAssistancePrice || 0),
                    }
                    : product
            );
            const obj = {
                cart: updatedCartProducts,
            };
            createCart(obj);
        }
        if (product && product.quantity > 1) {
            const updatedCartProducts = cartProductsStore.map((product: any) =>
                product.productId === productId
                    ? {
                        ...product,
                        quantity: product?.quantity - 1,
                        totalAmount:
                            product.actualAmount * (product.quantity - 1) +
                            (product.dosageAssistancePrice || 0),
                    }
                    : product
            );
            dispatch(setCartProducts(updatedCartProducts));
        }
    };

    // click on amount subscribe and buy once //
    const amountClick = (id: number, price: any) => {
        if (selectedSkuItemData.productQty > 0) {
            if (!product || !checkProduct) {
                setTotalAmount(
                    dosageSelectPrice
                        ? price + dosageAssistanceData?.dosageAssistancePrice
                        : price
                );
                setSelectedActualPrice(price);
                setNum(1);
            }
            if (id === 1) {
                setSelect("1");
                if (product && id === 1) {
                    const updatedCartProducts = cartProductsStore.map((product: any) => {
                        if (product.productId === productId) {
                            if (product?.dosageAssistancePrice) {
                                return {
                                    ...product,
                                    actualAmount: price,
                                    totalAmount:
                                        price * product?.quantity + product?.dosageAssistancePrice,
                                };
                            } else {
                                return {
                                    ...product,
                                    actualAmount: price,
                                    totalAmount: price * product?.quantity,
                                };
                            }
                        }
                        return product;
                    });
                    dispatch(setCartProducts(updatedCartProducts));
                }
                if (userId && checkProduct && id === 1) {
                    const updatedCartProducts = cartData.map((product: any) => {
                        if (product.productId === productId) {
                            if (product?.dosageAssistancePrice) {
                                return {
                                    ...product,
                                    actualAmount: price,
                                    totalAmount:
                                        price * product?.quantity + product?.dosageAssistancePrice,
                                };
                            } else {
                                return {
                                    ...product,
                                    actualAmount: price,
                                    totalAmount: price * product?.quantity,
                                };
                            }
                        }
                        return product;
                    });
                    const obj = {
                        cart: updatedCartProducts,
                    };
                    createCart(obj);
                }
            }
            if (id === 2) {
                setSelect("2");
                if (product && id === 2) {
                    const updatedCartProducts = cartProductsStore.map((product: any) => {
                        if (product.productId === productId) {
                            if (product?.dosageAssistancePrice) {
                                return {
                                    ...product,
                                    actualAmount: price,
                                    totalAmount:
                                        price * product?.quantity + product?.dosageAssistancePrice,
                                };
                            } else {
                                return {
                                    ...product,
                                    actualAmount: price,
                                    totalAmount: price * product?.quantity,
                                };
                            }
                        }
                        return product;
                    });
                    dispatch(setCartProducts(updatedCartProducts));
                }
                if (userId && checkProduct && id === 2) {
                    const updatedCartProducts = cartData.map((product: any) => {
                        if (product.productId === productId) {
                            if (product?.dosageAssistancePrice) {
                                return {
                                    ...product,
                                    actualAmount: price,
                                    totalAmount:
                                        price * product?.quantity + product?.dosageAssistancePrice,
                                };
                            } else {
                                return {
                                    ...product,
                                    actualAmount: price,
                                    totalAmount: price * product?.quantity,
                                };
                            }
                        }
                        return product;
                    });
                    const obj = {
                        cart: updatedCartProducts,
                    };
                    createCart(obj);
                }
            }
        }
    };

    const SamplePrevArrow = (props: any) => {
        const { onClick } = props;
        return (
            <button
                className="custom-prevArrow bg-greenFooter text-silver"
                onClick={onClick}
            >
                {/* <ChevronLeftIcon className='w-[14%]' /> */}
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="sm:w-[50px] sm:h-[50px] w-[35px] h-[35px] bg-[white] text-[#A8A8A8]"
                    viewBox="0 0 24 24"
                >
                    <path
                        d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z"
                        fill="silver"
                    />
                </svg>
            </button>
        );
    };

    const checkProductLikeOrNot = (productId: any) => {
        return (
            likeUnlikeData &&
            likeUnlikeData.length &&
            likeUnlikeData.some((a: any) => a.productId?._id === productId)
        );

    };

    const SampleNextArrow = (props: any) => {
        const { onClick } = props;
        return (
            <button
                className="custom-nextArrow bg-greenFooter text-silver"
                onClick={onClick}
            >
                {/* <ChevronRightIcon className='w-[14%]' /> */}
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="sm:w-[50px] sm:h-[50px] w-[35px] h-[35px] bg-[white]"
                    viewBox="0 0 24 24"
                >
                    <path
                        d="M7.33 24l-2.83-2.829 9.339-9.175-9.339-9.167 2.83-2.829 12.17 11.996z"
                        fill="silver"
                    />
                </svg>
            </button>
        );
    };

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        afterChange: (current: any) => setCurrentSlide(current),
        nextArrow: (
            <SampleNextArrow onClick={(props: any) => props.onArrowClick("left")} />
        ),
        prevArrow: (
            <SamplePrevArrow onClick={(props: any) => props.onArrowClick("right")} />
        ),
        responsive: [
            {
                breakpoint: 639,
                settings: {
                    dots: true,
                },
            },
        ],
    };

    return (
        <div
            className={`grid grid-cols-1 xl:grid-cols-2 gap-2 md:gap-4 lg:gap-[4.5rem] ${className || ""
                }`}
        >
            <div className="bg-white h-full text-lg font-bold text-center relative flex flex-col justify-center">
                {isSkeleton ? (
                    <Slider className="product-img-slider h-full" {...settings}>
                        {productSkuImg &&
                            productSkuImg.map((ele: any, index: number) => {
                                return (
                                    <div className="text-align-webkit-center h-full" key={index}>
                                        <div className="h-full flex items-center">
                                            <img
                                                className="h-auto sm:h-auto max-h-[750px] w-full object-contain mx-auto"
                                                src={ele}
                                                alt=""
                                            />
                                        </div>
                                    </div>
                                );
                            })}
                    </Slider>
                ) : (
                    <Skeleton
                        className="!leading-[28px] h-[22rem] xl:h-full"
                        containerClassName="h-full"
                    />
                )}
                <div className="absolute bottom-0 left-0 right-0 hidden sm:flex items-center justify-center h-16">
                    <p className="text-[#AA767C] text-center mt-[25px]">
                        <span className="text-[28px]">{currentSlide + 1}</span>/
                        {productSkuImg?.length}
                    </p>
                </div>
            </div>
            <div
                className={`${isBodyScans ? "" : "bg-whiteSmoke"
                    } text-lg sm:pt-0 sm:pt-[20px] pt-[30px]`}
            >
                <div>
                    {isSkeleton ? (
                        <h1
                            className={`text-[40px] xxs:text-[30px] x:text-[24px] s:text-[24px] font-bold ${isBodyScans ? "text-white" : "text-oxfordBlue"
                                }`}
                        >
                            {productData?.productName}
                            <span
                                className={`font-thin text-[44px] x:text-[30px] ${isBodyScans ? "text-white" : "text-oxfordBlue"
                                    }`}
                            >
                                {" "}
                                {selectedSkuItemData === "" ? "" : "|"}{" "}
                            </span>
                            <span
                                className={`text-xl x:text-[16px] font-bold ${isBodyScans ? "text-white" : "text-oxfordBlue"
                                    }`}
                            >
                                {selectedSkuItemData?.productVariant}
                            </span>
                        </h1>
                    ) : (
                        <Skeleton height="37px" containerClassName="h-full" />
                    )}
                </div>
                <div className="xl:w-[70%] sm:w-full">
                    {isSkeleton ? (
                        <p
                            className={`${isBodyScans ? "text-white" : "text-oxfordBlue"
                                } text-[14.5px] mt-1 font-[400] line-height-initial x:text-[12px] s:text-[12px]`}
                        >
                            {productData?.productTitle}
                        </p>
                    ) : (
                        <Skeleton height="14px" containerClassName="h-full" />
                    )}
                    <div className="flex my-0.5 mt-[10px] items-center">
                        {isSkeleton ? (
                            <Stack>
                                <Rating
                                    name="half-rating-read"
                                    value={reviewAverage?.toFixed(2) || 0}
                                    precision={0.5}
                                    readOnly
                                    sx={{
                                        "& .MuiRating-icon": {
                                            fontSize: "30px",
                                            color: `${isBodyScans ? "white" : "#465969"}`,
                                        },
                                    }}
                                />
                            </Stack>
                        ) : (
                            <Skeleton
                                count={5}
                                wrapper={StarWrapper}
                                height="100%"
                                baseColor="#ebebeb"
                                highlightColor="#f5f5f5"
                                duration={0.9}
                            />
                        )}
                        {isSkeleton ? (
                            <p
                                className={`ml-2 text-[20px] s:text-[16px] font-medium font-bold mt-[-3px] ${isBodyScans ? "text-white" : "text-oxfordBlue"
                                    }`}
                            >
                                {reviewAverage?.toFixed(2)}
                                {!isBodyScans && (
                                    <span className="border-r border-oxfordBlue h-[20px] pr-[8px]"></span>
                                )}
                            </p>
                        ) : (
                            <Skeleton
                                width="37px"
                                height="24px"
                                containerClassName="h-full ml-2"
                            />
                        )}

                        {!isBodyScans &&
                            (isSkeleton ? (
                                <span
                                    className="pl-[8px] text-[14px] x:text-[12px] s:text-[12px] x:w-full xs:w-full s:w-full text-oxfordBlue cursor-pointer hover:text-silver font-bold mt-0"
                                    onClick={() => handleReviewClick()}
                                >
                                    Read review
                                </span>
                            ) : (
                                <Skeleton
                                    width="50px"
                                    height="24px"
                                    containerClassName="h-full ml-2"
                                />
                            ))}
                        {/* {isSkeleton ? (
              <h2 className="text-[15px] text-end sm:hidden x:text-[10px] s:text-[10px] font-bold text-red-500 w-full ml-8 x:ml-[1rem] s:ml-[0.5rem] inline-block">
                Out of stock
              </h2>
            ) : (
              <Skeleton
                width="50px"
                height="24px"
                containerClassName="h-full ml-2"
              />
            )} */}
                    </div>
                </div>
                {!isBodyScans && (
                    <hr className="border-silver mt-[6px] border-t-[2px]" />
                )}
                {/* <div className='inline-flex border border-oxfordBlue mt-6'>
                    <div className='border-r border-oxfordBlue text-center px-[20px] pt-[8px] pb-[10px]'>
                        <h1 className='font-medium text-2xl text-oxfordBlue'>Contain Caffeine</h1>
                    </div>
                    <div className='border-r border-oxfordBlue inline-flex item-center'>
                        <div className='text-align-webkit-center'>
                            <CubeIcon className='xl:w-[15%] sm:w-[10%] xxs:w-[15%] x:w-[22%] text-oxfordBlue' />
                            <p className='text-[13px] x:text-[10px] text-center line-height-initial text-oxfordBlue'>Zero Added Sugars</p>
                        </div>
                        <div className='text-align-webkit-center'>
                            <CubeIcon className='xl:w-[15%] sm:w-[10%] xxs:w-[15%] x:w-[22%] text-oxfordBlue' />
                            <p className='text-[13px] x:text-[10px] text-center line-height-initial text-oxfordBlue'>100% Netural Product</p>
                        </div>
                        <div className='text-align-webkit-center'>
                            <CubeIcon className='xl:w-[15%] sm:w-[10%] xxs:w-[15%] x:w-[22%] text-oxfordBlue' />
                            <p className='text-[13px] x:text-[10px] text-center line-height-initial text-oxfordBlue'>100% Recyclable</p>
                        </div>
                    </div>
                    <div className='w-[42%] item-center text-align-webkit-center mt-[21px]'>
                        <div className="text-align-webkit-center border border-[3px] border-red-600 w-[20.5px] h-[21px]">
                            <div className='w-[78%] h-[78%] xs:h-[75%] xs:w-[70%] bg-red-600 rounded-[10px] mt-[2px]'></div>
                        </div>
                        <p className='font-bold text-red-600 text-[15px] xxs:text-[12px] x:text-[10px] line-height-initial'>Non Veg</p>
                    </div>
                </div> */}
                {!isBodyScans &&
                    (isSkeleton ? (
                        <h4 className="text-graymetal text-[16px] mt-[10px] font-bold">
                            {productData?.productType}
                        </h4>
                    ) : (
                        <div className="mt-[10px] mb-[5px]">
                            <Skeleton
                                width="200px"
                                height="24px"
                                containerClassName="h-full"
                            />
                        </div>
                    ))}
                {!isBodyScans && (
                    <div className="inline-flex gap-8">
                        <div>
                            {isSkeleton ? (
                                <p className="text-graymetal text-[12px] font-bold">NT.WT.</p>
                            ) : (
                                <Skeleton
                                    width="70px"
                                    height="24px"
                                    containerClassName="h-full"
                                />
                            )}
                            {isSkeleton ? (
                                <p className="text-graymetal text-[25px] font-bold">
                                    {(selectedSkuItemData && selectedSkuItemData.productWeight) ||
                                        0}
                                </p>
                            ) : (
                                <Skeleton
                                    width="140px"
                                    height="24px"
                                    containerClassName="h-full"
                                />
                            )}
                        </div>
                        <p className="border-r border-minigray"></p>
                        {isSkeleton ? (
                            productData && productData?.isVeg === "Yes" ? (
                                <div>
                                    <div className="text-align-webkit-center ml-1 mt-[8px] border border-[3px] border-[#008000] w-[23.5px] h-[24px]">
                                        <div className="w-[78%] h-[78%] xs:h-[75%] xs:w-[70%] bg-[#008000] rounded-[10px] mt-[2px]"></div>
                                    </div>
                                    <div className="text-[#008000] text-[16px] font-bold">
                                        Veg
                                    </div>
                                </div>
                            ) : (
                                <div>
                                    <div className="text-align-webkit-center ml-4 mt-[8px] border border-[3px] border-red-600 w-[23.5px] h-[24px]">
                                        <div className="w-[78%] h-[78%] xs:h-[75%] xs:w-[70%] bg-red-600 rounded-[10px] mt-[2px]"></div>
                                    </div>
                                    <div className="text-brightred text-[16px] font-bold">
                                        Non Veg
                                    </div>
                                </div>
                            )
                        ) : (
                            <Skeleton
                                width="60px"
                                height="60px"
                                containerClassName="h-full"
                            />
                        )}
                    </div>
                )}
                {isSkeleton ? (
                    <h2
                        className={`${isBodyScans ? "text-white" : "text-graymetal"
                            } font-medium text-[16px] mt-[20px]`}
                    >
                        {isBodyScans
                            ? "What's Covered in this blood test?"
                            : "About this product"}
                    </h2>
                ) : (
                    <div className="mt-3 mb-2">
                        <Skeleton width="180px" height="28px" containerClassName="h-full" />
                    </div>
                )}
                <div className="text-[15px] xs:text-[14px]">
                    {isSkeleton ? (
                        <p
                            className={`leading-snug ${isBodyScans ? "text-white" : "text-oxfordBlue"
                                }`}
                            dangerouslySetInnerHTML={{ __html: productData?.productAbout }}
                        ></p>
                    ) : (
                        <>
                            <Skeleton
                                width="280px"
                                height="16px"
                                containerClassName="h-full"
                            />
                            <Skeleton
                                width="200px"
                                height="16px"
                                containerClassName="h-full"
                            />
                            <Skeleton
                                width="140px"
                                height="16px"
                                containerClassName="h-full"
                            />
                            <Skeleton
                                width="250px"
                                height="16px"
                                containerClassName="h-full"
                            />
                            <Skeleton
                                width="220px"
                                height="16px"
                                containerClassName="h-full"
                            />
                        </>
                    )}
                </div>
                {isBodyScans &&
                    (isSkeleton ? (
                        productId && checkProductLikeOrNot(productId) === true ? (
                            <span className="inline-flex w-full justify-end hover:underline items-center">
                                {/* <img src={RedHeart} className='w-[4%] x:w-[8%] cursor-pointer' onClick={() => likeUnlikeProduct(productId)} alt='' /> */}
                                <HeartSolidIcon
                                    className="h-[17px] text-[red] hover:border-[black] cursor-pointer"
                                    onClick={() => likeUnlikeProduct(productId)}
                                />
                                <span
                                    className={`${isBodyScans ? "text-white" : "text-oxfordBlue"
                                        } text-[14px] x:text-[10px] s:text-[10px] mx-2 cursor-pointer line-height-initial`}
                                    onClick={() => likeUnlikeProduct(productId)}
                                >
                                    {" "}
                                    REMOVE FROM WISHLIST
                                </span>
                            </span>
                        ) : (
                            <span className="inline-flex w-full justify-end items-center">
                                <HeartIcon
                                    className={`${isBodyScans ? "text-white" : "text-oxfordBlue"
                                        } h-[17px] hover:border-[black] cursor-pointer`}
                                    onClick={() => likeUnlikeProduct(productId)}
                                />
                                <span
                                    className={`${isBodyScans ? "text-white" : "text-oxfordBlue"
                                        } text-[14px] x:text-[10px] s:text-[10px] mx-2 cursor-pointer hover:underline line-height-initial`}
                                    onClick={() => likeUnlikeProduct(productId)}
                                >
                                    {" "}
                                    ADD TO WISHLIST
                                </span>
                            </span>
                        )
                    ) : (
                        <span className="inline-flex w-full justify-end items-center">
                            <Skeleton
                                width="100px"
                                height="16px"
                                containerClassName="h-full"
                            />
                        </span>
                    ))}
                <hr className="border-silver mt-4 border-t-[2px]" />
                {!isBodyScans && (
                    <div className="flex gap: gap-[2px] mt-4">
                        {skuData &&
                            skuData.map((ele: any, index: number) => {
                                return (
                                    <div key={index}>
                                        {isSkeleton ? (
                                            <div
                                                className={`border-[1px] cursor-pointer border-solid px-4 text-smallgray text-[14px] ${selectedSkuItemData?._id === ele?._id
                                                    ? "border border-smallgray"
                                                    : ""
                                                    }`}
                                                onClick={() => productVariantClick(ele?._id)}
                                            >
                                                <span
                                                    className={`font-bold text-[14px] ${isBodyScans ? "text-white" : "text-oxfordBlue"
                                                        }`}
                                                >
                                                    {ele?.productVariant}
                                                </span>
                                            </div>
                                        ) : (
                                            <Skeleton
                                                width="60px"
                                                height="30px"
                                                containerClassName="h-full"
                                            />
                                        )}
                                    </div>
                                );
                            })}
                    </div>
                )}
                <div className="inline-flex mt-[10px] mb-[4px] items-center w-full">
                    {isSkeleton ? (
                        <>
                            <h2
                                className={`text-[15px] font-medium  hidden ${isBodyScans ? "text-white" : "text-oxfordBlue"
                                    }`}
                            >
                                ZIP
                            </h2>
                            {!isEdit ? (
                                <>
                                    <p
                                        className={`text-[15px] hidden ml-1  ${isBodyScans ? "text-white" : "text-oxfordBlue"
                                            }`}
                                    >
                                        {zipInputValue}
                                    </p>
                                    <PencilIcon
                                        className={`min-w-[20px]  hidden w-[20px] ml-2 cursor-pointer ${isBodyScans ? "text-white" : "text-oxfordBlue"
                                            }`}
                                        onClick={() => onEditClick()}
                                    />
                                </>
                            ) : (
                                <>
                                    <input
                                        className="text-sm  bg-transparent border-none focus:outline-0 w-[20%] x:w-[26%]"
                                        type="number"
                                        value={zipInputValue}
                                        autoComplete="off"
                                        placeholder="enter zip code"
                                        onChange={(e) => setZipInputValue(e.target.value)}
                                        onKeyDown={(e) => {
                                            if (
                                                (e.target as HTMLInputElement).value.length >= 6 &&
                                                e.keyCode !== 8
                                            ) {
                                                e.preventDefault();
                                            }
                                        }}
                                    />
                                    <span
                                        className={`text-sm hover:underline cursor-pointer ml-1 ${isBodyScans ? "text-white" : "text-oxfordBlue"
                                            }`}
                                        onClick={() => setIsEdit(false)}
                                    >
                                        Apply
                                    </span>
                                </>
                            )}
                            {selectedSkuItemData?.productQty > 0 ? (
                                <p className="text-[15px] font-bold text-darkgreen text-[500] sm:ml-1  mr-1 sm:mr-8 mr-[1rem]">
                                    {selectedSkuItemData?.productQty}

                                    <span className="ml-[8px]">Available</span>
                                </p>
                            ) : (
                                <h2 className="text-[15px] x:text-[10px] s:text-[10px] font-bold text-red-500 w-full mr-8 x:mr-[1rem] s:mr-[1rem] inline-block">
                                    Out of stock
                                </h2>
                            )}
                        </>
                    ) : (
                        <Skeleton width="160px" height="16px" containerClassName="h-full" />
                    )}

                    {!isBodyScans &&
                        (isSkeleton ? (
                            productId && checkProductLikeOrNot(productId) === true ? (
                                <span className="inline-flex w-full justify-end hover:underline items-center">
                                    {/* <img src={RedHeart} className='w-[4%] x:w-[8%] cursor-pointer' onClick={() => likeUnlikeProduct(productId)} alt='' /> */}
                                    <HeartSolidIcon
                                        className="h-[17px] text-[red] hover:border-[black]  cursor-pointer"
                                        onClick={() => likeUnlikeProduct(productId)}
                                    />
                                    <span
                                        className="text-[14px] x:text-[10px] s:text-[10px] text-oxfordBlue mx-2 cursor-pointer line-height-initial"
                                        onClick={() => likeUnlikeProduct(productId)}
                                    >
                                        {" "}
                                        REMOVE FROM WISHLIST
                                    </span>
                                </span>
                            ) : (
                                <span className="inline-flex w-full justify-end items-center">
                                    <HeartIcon
                                        className="h-[17px] text-oxfordBlue hover:border-[black]  cursor-pointer"
                                        onClick={() => likeUnlikeProduct(productId)}
                                    />
                                    <span
                                        className="text-[14px] x:text-[10px] s:text-[10px] text-oxfordBlue mx-2 cursor-pointer hover:underline line-height-initial"
                                        onClick={() => likeUnlikeProduct(productId)}
                                    >
                                        {" "}
                                        ADD TO WISHLIST
                                    </span>
                                </span>
                            )
                        ) : (
                            <span className="inline-flex w-full justify-end items-center">
                                <Skeleton
                                    width="100px"
                                    height="16px"
                                    containerClassName="h-full"
                                />
                            </span>
                        ))}
                </div>
                <>
                    {!isBodyScans &&
                        (isSkeleton ? (
                            <>
                                {selectedSkuItemData?.productQty > 0 && (
                                    <>
                                        <div className="flex x:flex-col s:flex-col  x:px-3 s:px-3 gap-16 xs:gap-8 x:gap-4 s:gap-4 backgroundset py-2 mt-[5px]">
                                            <div>
                                                <div className="flex gap-8 x:gap-4 s:gap-4">
                                                    <div className="m-auto x:m-0 s:m-0 pl-4 x:pl-0 s:pl-0">
                                                        <input
                                                            className="cursor-pointer w-5 h-4 border-black"
                                                            type="checkbox"
                                                            id="myCheckbox"
                                                            name="myCheckbox"
                                                            value="myValue"
                                                            checked={
                                                                checkProduct?.dosageAssistancePrice ||
                                                                (dosageSelectPrice && !checkProduct)
                                                            }
                                                            onChange={(e) =>
                                                                onSelectDosageAssistance(e.target.checked)
                                                            }
                                                        />
                                                    </div>
                                                    <div>
                                                        <h4
                                                            className="text-graymetal text-[16px] font-medium cursor-pointer whitespace-nowrap"
                                                            onClick={() =>
                                                                onSelectDosageAssistance(
                                                                    !(
                                                                        checkProduct?.dosageAssistancePrice ||
                                                                        (dosageSelectPrice && !checkProduct)
                                                                    )
                                                                )
                                                            }
                                                        >
                                                            Dosage Assistance
                                                        </h4>
                                                        <p className="text-graymetal text-[24px] font-bold">
                                                            £
                                                            {dosageAssistanceData &&
                                                                dosageAssistanceData?.dosageAssistancePrice
                                                                ? dosageAssistanceData?.dosageAssistancePrice.toFixed(
                                                                    2
                                                                )
                                                                : "N/A"}
                                                        </p>
                                                    </div>
                                                    <p className="border-r border-oxfordBlue x:hidden s:hidden"></p>
                                                </div>
                                            </div>
                                            <div>
                                                <p
                                                    className="text-graymetal text-[16px] x:text-[14px] s:text-[14px]"
                                                    dangerouslySetInnerHTML={{
                                                        __html: dosageAssistanceData?.longDescription,
                                                    }}
                                                ></p>
                                            </div>
                                        </div>
                                        <hr className="border-silver border-t-[1px]" />
                                    </>
                                )}
                            </>
                        ) : (
                            <Skeleton
                                width="100%"
                                height="88px"
                                containerClassName="h-full"
                            />
                        ))}
                    <div
                        className={`flex s:flex-wrap xs:flex-wrap gap-2 md:gap-2 lg:gap-[1rem] xl:gap-[1rem] ${isBodyScans ? "mt-8" : "mt-[10px]"
                            }`}
                    >
                        <div className="w-full text-lg font-semibold text-start">
                            {isSkeleton ? (
                                <p
                                    className={`text-sm ${isBodyScans ? "text-white" : "text-oxfordBlue"
                                        }`}
                                >
                                    Select Frequency
                                </p>
                            ) : (
                                <Skeleton
                                    width="120px"
                                    height="20px"
                                    containerClassName="h-full"
                                />
                            )}
                            {isSkeleton ? (
                                <button
                                    className={`${isBodyScans
                                        ? select === "1"
                                            ? "text-capeCod border border-white"
                                            : "border-white border"
                                        : "text-oxfordBlue"
                                        } ${select === "1"
                                            ? "font-bold text border-oxfordBlue font-bold"
                                            : "prize"
                                        } ${selectedSkuItemData?.productQty > 0
                                            ? "bg-white"
                                            : "bg-greenFooter text-oxfordBlue"
                                        } w-full mt-[5px] justify-between text-oxfordBlue amount py-[4px] px-[4px]`}
                                    onClick={() =>
                                        amountClick(1, selectedSkuItemData?.productSubscribeAmount)
                                    }
                                >
                                    <div
                                        className={
                                            select === "1"
                                                ? "col-span-2 sm:col-span-4 text-start font-bold"
                                                : "col-span-2 sm:col-span-4 text-start font-normal"
                                        }
                                    >
                                        Subscribe and Save
                                    </div>
                                    <div
                                        className={
                                            select === "1"
                                                ? "sm:col-span-1 ml-[4px] text-end font-bold justify-end flex"
                                                : "sm:col-span-1 ml-[4px] text-end flex justify-end font-normal"
                                        }
                                    >
                                        £
                                        {selectedSkuItemData &&
                                            selectedSkuItemData?.productSubscribeAmount
                                            ? selectedSkuItemData.productSubscribeAmount.toFixed(2)
                                            : "N/A"}
                                        <div className="sm:col-span-1 ml-[4px] text-end">
                                            <b className="float-right">
                                                <CheckIcon
                                                    className={`w-5 h-5 sm:w-4 sm:h-6 font-semibold rotate-10 stroke-4px ${select === "1" ? "" : "hidden"
                                                        }`}
                                                />
                                            </b>
                                        </div>
                                    </div>
                                </button>
                            ) : (
                                <Skeleton
                                    width="100%"
                                    height="36px"
                                    containerClassName="h-full"
                                />
                            )}
                            {isSkeleton ? (
                                <button
                                    onClick={() =>
                                        amountClick(2, selectedSkuItemData?.productSingleAmount)
                                    }
                                    className={`${isBodyScans
                                        ? select === "2"
                                            ? "text-capeCod border border-white font-bold"
                                            : "border-white border"
                                        : "text-oxfordBlue"
                                        } ${select === "2"
                                            ? "font-bold text border-oxfordBlue"
                                            : "prize"
                                        } ${selectedSkuItemData?.productQty > 0
                                            ? "bg-white"
                                            : "bg-greenFooter text-oxfordBlue"
                                        } justify-between w-full items-center mt-[5px] amount py-[4px] px-[4px]`}
                                >
                                    <div
                                        className={`
                                                ${selectedSkuItemData?.productQty > 0 && select === "2"
                                                ? "col-span-2 sm:col-span-4 text-start font-bold"
                                                : "col-span-2 sm:col-span-4 text-start font-normal"
                                            }
                    `}
                                    >
                                        Buy Once
                                    </div>
                                    <div
                                        className={
                                            selectedSkuItemData?.productQty > 0 && select === "2"
                                                ? "col-span-1 ml-[4px] text-end font-bold flex justify-end"
                                                : "col-span-1 ml-[4px] text-end font-normal flex justify-end"
                                        }
                                    >
                                        £
                                        {selectedSkuItemData &&
                                            selectedSkuItemData.productSingleAmount
                                            ? selectedSkuItemData.productSingleAmount.toFixed(2)
                                            : "N/A"}
                                        {selectedSkuItemData?.productQty > 0 && (
                                            <div className="col-span-1 ml-[4px] text-end">
                                                <b className="float-right">
                                                    <CheckIcon
                                                        className={`w-5 h-5 sm:w-4 font-semibold sm:h-6 rotate-10 stroke-4px ${select === "2" ? "" : "hidden"
                                                            }`}
                                                    />
                                                </b>
                                            </div>
                                        )}
                                    </div>
                                </button>
                            ) : (
                                <Skeleton
                                    width="100%"
                                    height="36px"
                                    containerClassName="h-full"
                                />
                            )}
                        </div>
                        <div className="sm:w-full w-[calc(50%-0.5rem)] text-lg font-bold m-auto text-align-webkit-center pt-[43px]">
                            <div className="text-start sm:w-fit">
                                {isSkeleton ? (
                                    <p
                                        className={`${isBodyScans ? "text-white" : "text-oxfordBlue"
                                            } text-[14px] text-left w-[87%]`}
                                    >
                                        Quantity
                                    </p>
                                ) : (
                                    <Skeleton
                                        width="70px"
                                        height="26px"
                                        containerClassName="h-full"
                                    />
                                )}
                                {isSkeleton ? (
                                    <div className="inline-flex align-center">
                                        <button
                                            className={`${isBodyScans
                                                ? (num || checkProduct?.quantity) === 1
                                                    ? "bg-transparent border-white text-white cursor-default"
                                                    : "bg-white text-capeCod"
                                                : product?.quantity === 1 ||
                                                    (num === 1 && !product?.quantity)
                                                    ? "bg-greenFooter border-none text-white cursor-default"
                                                    : "bg-oxfordBlue text-white cursor-pointer"
                                                } border w-[28px] h-[28px] border-oxfordBlue font-thin`}
                                            onClick={() => decNum()}
                                        >
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                strokeWidth={1.5}
                                                stroke="currentColor"
                                                className="w-5 h-5 mx-auto"
                                            >
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    d="M19.5 12h-15"
                                                />
                                            </svg>
                                        </button>
                                        <input
                                            className={`${isBodyScans ? "text-white bg-transparent" : "bg-white"
                                                } ${selectedSkuItemData?.productQty > 0
                                                    ? "text-oxfordBlue"
                                                    : "text-greenFooter"
                                                } border border-white w-[55px] h-[28px] mx-[2px] text-align-webkit-center focus:outline-0 quantity-value`}
                                            disabled
                                            type="num"
                                            value={
                                                product && selectedSkuItemData.productQty > 0
                                                    ? product?.quantity
                                                    : num
                                            }
                                            onChange={(e) => onQuantityChange(e)}
                                        />
                                        <button
                                            className={`
                        ${isBodyScans
                                                    ? num === selectedSkuItemData?.productQty
                                                        ? "bg-transparent border-white text-white"
                                                        : "bg-white text-capeCod"
                                                    : num === selectedSkuItemData?.productQty
                                                        ? "text-white"
                                                        : "text-white"
                                                } 
                        ${isBodyScans
                                                    ? selectedSkuItemData?.productQty > 0
                                                        ? selectedSkuItemData?.productQty !==
                                                            (product && selectedSkuItemData.productQty > 0
                                                                ? product?.quantity
                                                                : num)
                                                            ? "bg-oxfordBlue border-white cursor-pointer"
                                                            : "bg-greenFooter cursor-default"
                                                        : "bg-greenFooter cursor-default"
                                                    : selectedSkuItemData?.productQty > 0
                                                        ? selectedSkuItemData?.productQty !==
                                                            (product && selectedSkuItemData.productQty > 0
                                                                ? product?.quantity
                                                                : num)
                                                            ? "bg-oxfordBlue border-white cursor-pointer"
                                                            : "bg-greenFooter border-none cursor-default"
                                                        : "bg-greenFooter border-none cursor-default"
                                                } border w-[28px] h-[28px] border-oxfordBlue font-normal`}
                                            onClick={() => incNum()}
                                        >
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                strokeWidth="1.5"
                                                stroke="currentColor"
                                                className="w-5 h-5 mx-auto"
                                            >
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    d="M12 4.5v15m7.5-7.5h-15"
                                                />
                                            </svg>
                                        </button>
                                    </div>
                                ) : (
                                    <Skeleton
                                        width="115px"
                                        height="28px"
                                        containerClassName="h-full"
                                    />
                                )}
                            </div>
                        </div>
                        <div className="sm:w-full w-[calc(50%-0.5rem)] text-lg font-bold text-end lg:m-auto">
                            {isSkeleton ? (
                                <p
                                    className={`${isBodyScans ? "text-white" : "text-oxfordBlue"
                                        } sm:justify-start justify-end inline-flex w-full text-[12px] mt-[19px] xl:mt-[20px] mb-[10px]`}
                                >
                                    {" "}
                                    Total{" "}
                                    <span
                                        className={`${isBodyScans ? "text-white" : "text-oxfordBlue"
                                            } text-[28px] ml-[7px]`}
                                    >
                                        {" "}
                                        £ {totalAmount ? totalAmount?.toFixed(2) : "N/A"}
                                    </span>
                                </p>
                            ) : (
                                <div className="sm:text-left text-right w-full mt-[19px] xl:mt-[20px] mb-[10px]">
                                    <Skeleton
                                        width="100px"
                                        height="28px"
                                        containerClassName="h-full"
                                    />
                                </div>
                            )}
                            {isSkeleton ? (
                                (userId && !checkProduct && isAddingToCart === false) ||
                                    (!userId && !product && isAddingToCart === false) ? (
                                    <button
                                        className={`${isBodyScans ? "text-capeCod" : "text-white"
                                            } ${isBodyScans
                                                ? selectedSkuItemData?.productQty > 0
                                                    ? "bg-white text-capeCod"
                                                    : "bg-greenFooter"
                                                : selectedSkuItemData?.productQty > 0
                                                    ? "bg-oxfordBlue"
                                                    : "bg-greenFooter"
                                            } inline-flex p-[6px] 2xl:text-[16px] xl:text-[11px] md:text-[18px] sm:text-[16px] x:text-[16px] s:text-[16px] w-full justify-center items-center`}
                                        onClick={() => onAddToCartClick()}
                                    >
                                        Add To Cart
                                        <ArrowUpRightIcon className="h-[23px] pt-[4px] pl-[7px] stroke-[3]" />
                                    </button>
                                ) : (
                                    <button
                                        className={`${isBodyScans
                                            ? "bg-transparent border border-white text-white"
                                            : "text-silver bg-greenFooter"
                                            } inline-flex p-[6px] 2xl:text-[16px] xl:text-[11px] md:text-[18px] sm:text-[16px] x:text-[16px] s:text-[16px] w-full justify-center items-center`}
                                        onClick={() => onRemoveToCart()}
                                    >
                                        {selectedSkuItemData?.productQty > 0
                                            ? "Remove From Cart"
                                            : "Add To Cart"}
                                        <ArrowUpRightIcon className="h-[23px] pt-[4px] pl-[7px] stroke-[3]" />
                                    </button>
                                )
                            ) : (
                                <Skeleton
                                    width="100%"
                                    height="40px"
                                    containerClassName="h-full"
                                />
                            )}
                        </div>
                    </div>
                </>
                <hr
                    className={`${isBodyScans
                        ? "xl:mt-[22px] md:mt-16 mt-8"
                        : "mt-[22px] sm:block hidden"
                        } mt-[22px] border-silver md:border-t-[2px] sm:border-t-[2px] xs:border-t-[2px]`}
                />
            </div>
        </div>
    );
};

export default ProductCardDetail;

import React, { useState } from "react";
import Logo from "../../assets/images/white-greenlab-logo.png";
import "./Login.css";
import { ChevronRightIcon } from "@heroicons/react/24/outline";
import { UserService } from "../../services";
import { CONSTANT } from "../../core/StaticConstant";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { logInSchema } from "./LoginSchema";
import { setOrder, storeUserData } from "../../redux/reducer/cartReducer";
import { useDispatch, useSelector } from "react-redux";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/solid";
import { Divider } from "@mui/material";
import Loader from "../loader/Loader";
import { Localstorage } from "../../core/AuthService";
import OTPInput from "react-otp-input";

const initialValues = {
    email: "",
    password: "",
    confirm_password: "",
    userType: CONSTANT.ROLES.USER,
    mode: CONSTANT.MODE.NORMAL,
    otp: "",
    newPassword: "",
    confirmPassword: "",
};

const LoginUser = (props: any) => {
    const [isLogin, setIsLogin] = useState(true);
    const [isRegister, setIsRegister] = useState(false);
    const [isOtpScreen, setIsOtpScreen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const { setShowLoginModal } = props;
    const [userId, setuserId] = useState('');
    const [userEmail, setUserEmail] = useState('');
    const [otp, setOtp] = useState('');
    const cartProduct = useSelector((state: any) => state.cartProducts);

    const maskEmail = (email: any) => {
        const atIndex = email.indexOf('@');
        const username = email.slice(0, atIndex);
        const domain = email.slice(atIndex);

        const maskedUsername =
            username.charAt(0) +
            '*'.repeat(Math.max(0, username.length - 4)) +
            username.slice(-3);

        return maskedUsername + domain;
    };
    const maskedEmail = maskEmail(userEmail);


    function handleKeyPress(event: any) {
        if (event.key === "Enter") {
            handleSubmit();
        }
    }

    const [email, setEmail] = useState("");
    const guestUser = async () => {
        try {
            setIsLoading(true);
            const data = await UserService.guestUser({ email });
            setTimeout(async () => {
                setIsLoading(false);
            }, 2000)
            if (data && data.status === CONSTANT.SUCCESS) {
                dispatch(storeUserData(data.data));
                Localstorage(CONSTANT.SET, CONSTANT.USER_INFO, JSON.stringify(data.data.token));
                toast.success(data.message);

                window.location.href = "/shop/cart/order";

                dispatch(setOrder(cartProduct));
            } else {
                toast.error(data.message);
            }
        } catch (error: any) {
            console.error(error);
            toast.error(error?.response?.data?.error)
            setTimeout(async () => {
                setIsLoading(false);
            }, 2000)
        }
    };

    const dispatch = useDispatch();

    const { values, errors, touched, handleChange, handleSubmit, setFieldValue } = useFormik({
        initialValues,
        validationSchema: logInSchema,
        onSubmit: async (values, action) => {
            try {
                let data;

                if (isRegister) {
                    setIsLoading(true);
                    let obj = {
                        email: values.email,
                        password: values.password,
                        confirm_password: values.confirm_password,
                        userType: CONSTANT.ROLES.USER,
                        mode: CONSTANT.MODE.NORMAL,
                    }
                    data = await UserService.signUpUser(obj);
                    setTimeout(() => {
                        setIsLoading(false);
                    }, 2000)
                    if (data && data.status === CONSTANT.SUCCESS) {
                        toast.success(data.message);
                        setIsOtpScreen(true)
                        setuserId(data?.data?.user?._id)
                        setUserEmail(data?.data?.user?.email);
                        setIsRegister(false);
                    } else {
                        toast.error(data.message);
                    }
                } else {
                    setIsLoading(true);
                    let obj = {
                        email: values.email,
                        password: values.password,
                        userType: CONSTANT.ROLES.USER,
                        mode: CONSTANT.MODE.NORMAL,
                    }
                    data = await UserService.loginUser(obj);
                    setTimeout(() => {
                        setIsLoading(false);
                    }, 2000)
                    if (data && data.status === CONSTANT.SUCCESS) {
                        dispatch(storeUserData(data.data));
                        Localstorage(
                            CONSTANT.SET,
                            CONSTANT.USER_INFO,
                            JSON.stringify(data.data.token)
                        );
                        toast.success(data.message);
                        window.location.href = "/shop/cart/order";
                    } else {
                        toast.error(data.message);
                    }
                }
            } catch (err: any) {
                console.error(err);
                toast.error((err?.response?.data?.error));
                setTimeout(() => {
                    setIsLoading(false);
                }, 2000)
            }

            action.resetForm();
        },
    });

    const handleOtpChange = async () => {
        try {
            setIsLoading(true);
            let obj = {
                otp: otp,
                userId: userId
            }
            let data: any = await UserService.verifyUserOtp(obj).then((data: any) => {
                setIsLoading(false);
                if (data && data.status === CONSTANT.SUCCESS) {
                    toast.success(data.message);
                    setIsLogin(true);
                    setIsOtpScreen(false);
                } else {
                    toast.error(data.message);
                }
            }).catch((error: any) => {
                setIsLoading(false);
                toast.error(error?.response?.data?.error);
            });
        } catch (error: any) {
            setIsLoading(false);
            toast.error(error?.response?.data?.error);
        }
    }

    const handleOtpChange1 = (event: any) => {
        setFieldValue("otp", event.target.value);
    };

    const handlePasswordChange = (event: any) => {
        setFieldValue("newPassword", event.target.value);
    };

    const handleConfirmPasswordChange = (event: any) => {
        setFieldValue("confirmPassword", event.target.value);
    };

    const handleRegister = () => {
        setIsRegister(true);
        setIsLogin(false);
        setVerificationSuccess(false);
        setIsOtpScreen(false);
    };

    const handleLogin = () => {
        setIsLogin(true);
        setIsRegister(false);
        setVerificationSuccess(false);
        setIsOtpScreen(false);
    };

    const handleChangeOTP = (otpValue: any) => {
        setOtp(otpValue);
    };

    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [forgotPassword, setForgotPassword] = useState(false)
    const [forgetUserId, setForgetUserId] = useState("");
    const [verificationSuccess, setVerificationSuccess] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [dataToken, setDataToken] = useState("");

    const handleTogglePassword = () => {
        setShowPassword(!showPassword);
    };

    const handleToggleConfirmPassword = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    const resetPassword: any = async () => {
        try {
            setIsLoading(true)
            let obj = {
                email: values.email
            }
            const response: any = await UserService.forgotPassword(obj);
            setIsLoading(false)

            if (response && response.status === CONSTANT.SUCCESS) {
                toast.success(response.message);
                setForgetUserId(response.data.userId);
                setVerificationSuccess(true);
                setForgotPassword(false);
                setFieldValue("otp", "");
                setIsLogin(false);
                setIsRegister(false);
            }
        } catch (error) {
            setIsLoading(false)
            console.error(error);
        }
    }

    const verifyOtp = async () => {
        try {
            setIsLoading(true);

            const otpData = {
                otp: values.otp,
                userId: forgetUserId,
            };
            const response = await UserService.verifyUserToken(otpData);

            setIsLoading(false);

            if (response && response.status === CONSTANT.SUCCESS) {
                toast.success(response.message);
                setDataToken(response.data.token);
                setVerificationSuccess(true);
                setShowNewPassword(true);
            } else {
                toast.error(response.message);
            }
        } catch (err: any) {
            console.error('errorOtp', err.response);
            toast.error(err?.response?.data?.error);
            setIsLoading(false);
        }
    };

    const resetpassword = async () => {
        try {
            setIsLoading(true);

            const resetData = {
                // userId: forgetUserId,
                password: values.newPassword,
                confirmPassword: values.confirmPassword,
                token: dataToken,
            };

            const response = await UserService.resetPasswordWithToken(resetData);

            setIsLoading(false);

            if (response && response.status === CONSTANT.SUCCESS) {
                toast.success(response.message);
                setIsLogin(true);
                setVerificationSuccess(false);
                setForgotPassword(false);
                setForgetUserId("");
                setDataToken("");
                setFieldValue("newPassword", "");
                setFieldValue("confirmPassword", "");
            } else {
                toast.error(response.message);
            }
        } catch (err: any) {
            console.error(err);
            toast.error(err?.response?.data?.error);
            setIsLoading(false);
        }
    };

    return (
        <>
            {isLoading && (
                <div className="absolute top-[50%] z-50 left-[50%]  transform translate-x-[-50%] translate-y-[-50%]">
                    <Loader />
                </div>
            )}

            <div className=" min-h-screen">
                <div className="sm:flex mt-0 sm:justify-center pt-16 xs:pt-10 s:pt-10 lg:min-w-[200px] lg:max-w-[200px] max-w-[150px] mx-auto mb-3">
                    <img src={Logo} alt="GREEN LAB" />
                </div>

                <div className="sm:flex mt-0 sm:justify-center pb-8 xs:pb-[20px] s:pb-[20px]">
                    <p className="text-greenFooter xs:text-[9px] s:text-[9px] xs:tracking-[3.6px] s:tracking-[2.1px] text-[14px] tracking-[4.8px] text-center">
                        CHEMISTRY THAT UNDERSTANDS YOUR BIOLOGY
                    </p>
                </div>
                <div className="grid grid-cols-6 xs:p-[10px] p-4 gap-4">
                    <div className="xl:col-span-1"></div>
                    <div className="xl:col-span-4 col-span-6 ">
                        <div className="flex w-[full] m-[auto] grow-0  xs:block s:block background bg-graygreen 2xl:w-[1000px] xl:w-[840px] 2xl:h-[650px] grid-cols-3 gap-4 xxs:w-[430px] xs:w-[300px] ">
                            <div className="col-span-2 w-[500px] c-sm:w-[315px] xs:w-[315px] s:w-[300px] s:flex s:justify-center s:items-center s:p-[6px] xs:p-[6px] c-sm:p-4 c-md:p-8 p-8 c-md:w-[400px] 2xl:w-[600px] align">
                                {isRegister ? (
                                    <div className="xxs:w-[396px] xs:w-[272px] s:w-[270px]">
                                        <h4 className="text-greenFooter xs:text-[20px] p-8 s:text-[20px] 2xl:pt-[50px] 2xl:pb-[21px] font-bold text-3xl	">
                                            R E G I S T E R
                                        </h4>
                                        <hr className="border-silver w-[100%] border-t-[1px] px-8 xl:w-[98%] border-bottom" />
                                        <p className="text-greenFooter xs:text-[9px] s:text-[9px] xs:tracking-[3.6px] s:tracking-[2.1px] font-[12] 2xl:pt-[31px] tracking-[4.0px] text-[14px]">
                                            EMAIL
                                        </p>
                                        <input
                                            className="bg-capeCod w-full s:w-[92%] mt-2.5 text-[white] focus focus:outline-none md:px-8 xs:px-4 s:px-4 inner-shadow h-10 xs:h-9 s:h-9"
                                            value={values.email}
                                            name="email"
                                            onChange={handleChange}
                                        />
                                        {errors.email && touched.email ? (
                                            <p className="form-error text-[red]">{errors.email}</p>
                                        ) : null}
                                        <p className="mt-2 text-greenFooter xs:text-[9px] s:text-[9px] xs:tracking-[3.6px] s:tracking-[2.1px] font-[12] 2xl:pt-[31px] tracking-[4.0px] text-[14px]">
                                            PASSWORD
                                        </p>
                                        <div className="relative">
                                            <input
                                                className="bg-capeCod w-full s:w-[92%] mt-2.5 text-[white] focus focus:outline-none md:px-8 xs:px-4 s:px-4 inner-shadow h-10 xs:h-9 s:h-9"
                                                type={showPassword ? "text" : "password"}
                                                name="password"
                                                onChange={handleChange}
                                                value={values.password}
                                            />
                                            {showPassword ? (
                                                <EyeIcon
                                                    className="absolute cursor-pointer text-[white] top-[20px] s:right-[30px] right-[5px] w-[17px]"
                                                    onClick={() => handleTogglePassword()}
                                                />
                                            ) : (
                                                <EyeSlashIcon
                                                    className="absolute cursor-pointer text-[white] top-[20px] s:right-[30px] right-[5px] w-[17px]"
                                                    onClick={() => handleTogglePassword()}
                                                />
                                            )}
                                        </div>
                                        {errors.password && touched.password ? (
                                            <p className="xs:text-[9px] s:text-[9px] xs:tracking-[3.6px] s:tracking-[2.1px] font-[12] tracking-[4.0px] text-[14px] text-rose-800">
                                                {errors.password}
                                            </p>
                                        ) : null}
                                        <p className="mt-2 text-greenFooter xs:text-[9px] s:text-[9px] xs:tracking-[3.6px] s:tracking-[2.1px] font-[12] 2xl:pt-[31px] tracking-[4.0px] text-[14px]">
                                            CONFIRM PASSWORD
                                        </p>
                                        <div className="relative">
                                            <input
                                                className="bg-capeCod w-full s:w-[92%] mt-2.5 text-[white] focus focus:outline-none md:px-8 xs:px-4 s:px-4 inner-shadow h-10 xs:h-9 s:h-9"
                                                type={showConfirmPassword ? "text" : "password"}
                                                name="confirm_password"
                                                onChange={handleChange}
                                                value={values.confirm_password}
                                            />
                                            {showConfirmPassword ? (
                                                <EyeIcon
                                                    className="absolute cursor-pointer text-[white] top-[20px] s:right-[30px] right-[5px] w-[17px]"
                                                    onClick={() => handleToggleConfirmPassword()}
                                                />
                                            ) : (
                                                <EyeSlashIcon
                                                    className="absolute cursor-pointer text-[white] top-[20px] s:right-[30px] right-[5px] w-[17px]"
                                                    onClick={() => handleToggleConfirmPassword()}
                                                />
                                            )}
                                        </div>
                                        {errors.confirm_password && touched.confirm_password ? (
                                            <p className="xs:text-[9px] s:text-[9px] xs:tracking-[3.6px] s:tracking-[2.1px] font-[12] tracking-[4.0px] text-[14px] text-rose-800">
                                                {errors.confirm_password}
                                            </p>
                                        ) : null}
                                        <button
                                            className="text-greenFooter pt-8 font-bold hover:cursor-pointer inline-flex text-[25px]"
                                            onClick={() => handleSubmit()}
                                        >
                                            <span>Register</span>
                                            <span>
                                                <ChevronRightIcon className="h-6 w-8 mt-2" />
                                            </span>
                                        </button>
                                        {/* <button
                                            className="text-greenFooter w-full justify-center hidden pt-8 font-bold hover:cursor-pointer xs:inline-flex s:inline-flex text-[25px]"
                                            onClick={() => handleSubmit()}
                                        >
                                            <span>Register</span>
                                            <span>
                                                <ChevronRightIcon className="h-6 w-8 mt-2" />
                                            </span>
                                        </button> */}
                                    </div>
                                ) : (isLogin &&
                                    <div className="xxs:w-[396px] xs:w-[272px] s:w-[270px]">
                                        {forgotPassword ? <h4 className="text-greenFooter xs:text-[20px] p-8 s:text-[20px] 2xl:pt-[50px] 2xl:pb-[21px] font-bold text-3xl	">
                                            RESET PASSWORD
                                        </h4> : <h4 className="text-greenFooter xs:text-[20px] p-8 s:text-[20px] 2xl:pt-[50px] 2xl:pb-[21px] font-bold text-3xl	">
                                            L O G I N
                                        </h4>}
                                        <hr className="border-silver border-t-[1px] px-8 xl:w-[98%] border-bottom" />
                                        <p className="text-greenFooter xs:text-[9px] s:text-[9px] xs:tracking-[3.6px] s:tracking-[2.1px] font-[12] 2xl:pt-[31px] tracking-[4.0px] text-[14px]">
                                            EMAIL
                                        </p>
                                        <input
                                            className="bg-capeCod w-full s:w-[92%] mt-2.5 text-[white] focus focus:outline-none md:px-8 xs:px-4 s:px-4 inner-shadow h-10 xs:h-9 s:h-9"
                                            value={values.email}
                                            name="email"
                                            onChange={handleChange}
                                        />
                                        {errors.email && touched.email ? (
                                            <p className="form-error text-[red]">{errors.email}</p>
                                        ) : null}
                                        {!forgotPassword && <>
                                            <p className="text-greenFooter xs:text-[9px] s:text-[9px] xs:tracking-[3.6px] s:tracking-[2.1px] mt-2 font-[12] 2xl:pt-[21px] tracking-[4.0px] text-[14px]">
                                                PASSWORD
                                            </p>
                                            <input
                                                className="bg-capeCod w-full s:w-[92%] mt-2.5 text-[white] md:px-8 xs:px-4 s:px-4 focus:outline-none inner-shadow h-10 xs:h-9 s:h-9"
                                                type="password"
                                                value={values.password}
                                                name="password"
                                                onChange={handleChange}
                                                onKeyPress={handleKeyPress}
                                            />
                                            {errors.password && touched.password ? (
                                                <p className="form-error text-[red]">{errors.password}</p>
                                            ) : null} </>}
                                        <div className="grid w-[98%] grid-cols-2 gap-4 md:pt-[31px] s:pt-[31px] xxs:pt-[20px] xs:pt-[31px] c-sm:pt-[30px]">
                                            <div className="xl:col-span-1 col-span-1">
                                                <div className="mb-[0.125rem] flex items-center gap-1">
                                                    <input
                                                        className="bg-silver"
                                                        type="checkbox"
                                                        value=""
                                                        id="checkboxChecked"
                                                        defaultChecked
                                                    />{" "}
                                                    <label
                                                        className="text-greenFooter xs:text-[9px] s:text-[9px] xs:tracking-[3.6px] s:tracking-[2.1px] text-[14px] pl-[0.15rem] hover:cursor-pointer tracking-[4.0px]"
                                                        htmlFor="checkboxChecked"
                                                    >
                                                        REMEMBER ME
                                                    </label>
                                                </div>
                                            </div>
                                            {forgotPassword ? <div className="xl:col-span-1 col-span-1 flex items-center justify-end">
                                                <p className="text-greenFooter xs:text-[9px] s:text-[9px] xs:tracking-[3.6px] s:tracking-[2.1px] text-end hover:cursor-pointer tracking-[4.0px] text-[14px]">
                                                    <span onClick={() => setForgotPassword(false)}>BACK TO LOGIN</span>
                                                </p>
                                            </div> :
                                                <p className="text-greenFooter xs:text-[9px] s:text-[9px] xs:tracking-[3.6px] s:tracking-[2.1px] text-end hover:cursor-pointer tracking-[4.0px] text-[14px]">
                                                    <span onClick={() => setForgotPassword(true)}>FORGOT PASSWORD</span>
                                                </p>}
                                            {forgotPassword ? <button
                                                className="text-greenFooter pt-8 font-bold hover:cursor-pointer flex justify-center items-center s:text-[20px] x:text-[20px] xxs:text-[20px] text-[23.5px]"
                                                onClick={() => resetPassword()}
                                            >
                                                <span>Reset Password</span>
                                                <span>
                                                    <ChevronRightIcon className="h-6 w-8 mt-1" />
                                                </span>
                                            </button> : <button
                                                className="text-greenFooter pt-8 font-bold hover:cursor-pointer inline-flex text-[25px]"
                                                onClick={() => handleSubmit()}
                                            >
                                                <span>Login</span>
                                                <span>
                                                    <ChevronRightIcon className="h-6 w-8 mt-2" />
                                                </span>
                                            </button>}
                                        </div>
                                    </div>
                                )}
                                {isOtpScreen &&
                                    <div className="xxs:w-[396px] xs:w-[272px] s:w-[270px]">
                                        <h4 className="text-greenFooter text-center xs:text-[20px] p-4 s:text-[20px] 2xl:pt-[50px] 2xl:pb-[21px] font-bold text-3xl	">
                                            VERIFY YOUR ACCOUNT
                                        </h4>
                                        <hr className="border-silver border-t-[1px] px-8 xl:w-[98%] border-bottom" />
                                        <p className="text-center p-2 m-3 text-greenFooter">
                                            We emailed you the six digit code to <span className="text-sky-300">{maskedEmail}</span> <br />
                                            Enter the code below to confirm your email address.
                                        </p>
                                        <div className="flex justify-center">
                                            <OTPInput
                                                value={otp}
                                                onChange={handleChangeOTP}
                                                numInputs={6}
                                                shouldAutoFocus={true}
                                                inputStyle={{
                                                    border: "1px solid transparent",
                                                    borderRadius: "8px",
                                                    width: "40px",
                                                    height: "45px",
                                                    fontSize: "15px",
                                                    color: "#000",
                                                    marginLeft: "5px",
                                                    fontWeight: "400",
                                                    caretColor: "blue",
                                                }}
                                                renderInput={(props: any) => <input {...props} />}
                                            />
                                        </div>
                                        <div className="flex justify-center items-center flex-col mt-10">
                                            <button
                                                className="font-bold hover:cursor-pointer w-56 md:w-80 sm:w-56 x:w-56 xs:w-56 text-[23px] bg-midnightDream text-white p-2 rounded hover:bg-zinc-700 focus:outline-none flex justify-center"
                                                onClick={handleOtpChange}
                                            >
                                                <span className="text-base">Verify Email</span>
                                            </button>
                                            <button
                                                className="mt-1 font-bold hover:cursor-pointer w-56 md:w-80 sm:w-56 x:w-56 xs:w-56 text-[23px] text-white p-2 rounded hover:bg-neutral-800 focus:outline-none flex justify-center mb-3"
                                                onClick={() => {
                                                    setIsOtpScreen(false);
                                                    setIsRegister(true);
                                                    setVerificationSuccess(false);
                                                }}>
                                                <span className="text-base">Cancel</span>
                                            </button>
                                        </div>
                                    </div>
                                }
                                {verificationSuccess && !showNewPassword && (
                                    <div className="xxs:w-[396px] xs:w-[272px] s:w-[270px]">
                                        <h4 className="text-greenFooter xs:text-[20px] p-8 s:text-[20px] 2xl:pt-[50px] 2xl:pb-[21px] font-bold text-3xl	">
                                            ENTER OTP
                                        </h4>
                                        <hr className="border-silver border-t-[1px] px-8 xl:w-[98%] border-bottom" />
                                        <p className="text-greenFooter xs:text-[9px] s:text-[9px] xs:tracking-[3.6px] s:tracking-[2.1px] font-[12] 2xl:pt-[31px] tracking-[4.0px] text-[14px]">
                                            ENTER OTP
                                        </p>
                                        <input
                                            className="bg-capeCod w-full s:w-[92%] mt-2.5 text-[white] focus focus:outline-none md:px-8 xs:px-4 s:px-4 inner-shadow h-10 xs:h-9 s:h-9"
                                            value={values.otp}
                                            name="otp"
                                            onChange={handleOtpChange1}
                                        />
                                        <div className="flex mt-8  items-center justify-between">
                                            <button
                                                className={`font-bold hover:cursor-pointer inline-flex s:text-[20px] x:text-[20px] xxs:text-[20px] text-[23px] ${isLoading ? "text-gray-500" : "text-greenFooter"
                                                    }`}
                                                onClick={verifyOtp}
                                                disabled={isLoading}
                                            >
                                                <span>Submit OTP</span>
                                                <span>
                                                    <ChevronRightIcon className="h-6 w-8 mt-1" />
                                                </span>
                                            </button>
                                            <div className="xl:col-span-1 col-span-1">
                                                <p className="text-greenFooter xs:text-[9px] s:text-[9px] xs:tracking-[3.6px] s:tracking-[2.1px] text-end hover:cursor-pointer tracking-[4.0px] text-[14px]">
                                                    <span onClick={() => { setVerificationSuccess(false); setIsLogin(true) }}>
                                                        BACK
                                                    </span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {verificationSuccess && showNewPassword && (
                                    <div className="xxs:w-[396px] xs:w-[272px] s:w-[270px]">
                                        <h4 className="text-greenFooter xs:text-[20px] p-8 s:text-[20px] 2xl:pt-[50px] 2xl:pb-[21px] font-bold text-3xl	">
                                            RESET PASSWORD
                                        </h4>
                                        <hr className="border-silver border-t-[1px] px-8 xl:w-[98%] border-bottom" />
                                        <p className="text-greenFooter xs:text-[9px] s:text-[9px] xs:tracking-[3.6px] s:tracking-[2.1px] font-[12] 2xl:pt-[31px] tracking-[4.0px] text-[14px]">
                                            NEW PASSWORD
                                        </p>
                                        <input
                                            className="bg-capeCod w-full s:w-[92%] mt-2.5 text-[white] focus focus:outline-none md:px-8 xs:px-4 s:px-4 inner-shadow h-10 xs:h-9 s:h-9"
                                            type="password"
                                            value={values.newPassword}
                                            name="newPassword"
                                            onChange={handlePasswordChange}
                                        />
                                        <p className="text-greenFooter xs:text-[9px] s:text-[9px] xs:tracking-[3.6px] s:tracking-[2.1px] font-[12] 2xl:pt-[31px] tracking-[4.0px] text-[14px]">
                                            CONFIRM PASSWORD
                                        </p>
                                        <input
                                            className="bg-capeCod w-full s:w-[92%] mt-2.5 text-[white] focus focus:outline-none md:px-8 xs:px-4 s:px-4 inner-shadow h-10 xs:h-9 s:h-9"
                                            type="password"
                                            value={values.confirmPassword}
                                            name="confirmPassword"
                                            onChange={handleConfirmPasswordChange}
                                        />
                                        <div className="flex mt-8 items-center justify-between">
                                            <button
                                                className={`font-bold hover:cursor-pointer s:text-[20px] x:text-[20px] xxs:text-[20px] inline-flex text-[23px] ${isLoading ? "text-gray-500" : "text-greenFooter"
                                                    }`}
                                                onClick={resetpassword}
                                                disabled={isLoading}
                                            >
                                                <span>RESET PASSWORD</span>
                                                <span>
                                                    <ChevronRightIcon className="h-6 w-8 mt-1" />
                                                </span>
                                            </button>
                                            <div className="xl:col-span-1 col-span-1 flex items-center justify-end">
                                                <p className="text-greenFooter xs:text-[9px] s:text-[9px] xs:tracking-[3.6px] s:tracking-[2.1px] text-end hover:cursor-pointer tracking-[4.0px] text-[14px]">
                                                    <span onClick={() => {
                                                        setShowNewPassword(false);
                                                        setVerificationSuccess(true);
                                                    }}>
                                                        BACK
                                                    </span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <Divider
                                orientation="vertical"
                                variant="middle"
                                flexItem
                                className="bg-[#0a2519b5]"
                            />
                            <div className="col-span-1 flex flex-col xxs:max-w-[600px] m-[10px] c-md:w-[526px] c-sm:w-[365px] max-w-[700px]">
                                <div
                                    onClick={() => setShowLoginModal(false)}
                                    className="h-10 flex justify-end items-end xxs:absolute s:absolute top-32 s:w-[280px] xs:w-[400px] x:w-[280px] x:absolute"
                                >
                                    <svg
                                        className="h-8 w-8 text-greenFooter cursor-pointer text-right"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        strokeWidth="2"
                                        stroke="currentColor"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    >
                                        {" "}
                                        <path stroke="none" />{" "}
                                        <line x1="18" y1="6" x2="6" y2="18" />{" "}
                                        <line x1="6" y1="6" x2="18" y2="18" />
                                    </svg>
                                </div>
                                <div className="flex flex-col w-20 mt-10"></div>
                                <div className="xs:col-span-2 s:col-span-2 c-md:col-span-2 c-sm:col-span-2">
                                    <button className="text-greenFooter  xs:text-[9px] s:text-[9px] xs:tracking-[3.6px] s:tracking-[2.1px] text-[19px] hover:cursor-pointer tracking-[4.0px] text-left">
                                        NEW TO GREENLAB ?
                                    </button>
                                </div>

                                <div className="mt-[20px] xs:col-span-2 s:col-span-2 c-md:col-span-2 c-sm:col-span-2  text-white">
                                    Sign Up for a greenlab account to gain access to the lowest
                                    priced supplements
                                </div>

                                {isRegister ? (
                                    <button
                                        className="text-[15px] mt-[10px] mb-[20px] px-10 py-3 font-bold text-white bg-midnightDream"
                                        onClick={handleLogin}
                                    >
                                        Login
                                    </button>
                                ) : (
                                    <button
                                        className="text-[15px] mt-[10px] mb-[20px] px-10 py-3 font-bold text-white bg-midnightDream"
                                        onClick={handleRegister}
                                    >
                                        Register
                                    </button>
                                )}
                                <hr className="border-silver border-t-[1px] px-8 xl:w-[98%] border-bottom" />

                                <div className="xs:col-span-2 text-white s:col-span-2 text-[20px] c-md:col-span-2 c-sm:col-span-2 ">
                                    Checkout as guest
                                </div>
                                <div className=" text-white mt-[20px]">
                                    <li>No need to register</li>
                                    <li>Come back and create an account later if you wish</li>
                                </div>

                                <div>
                                    <form>
                                        <p className="text-greenFooter mt-[20px] xs:text-[9px] s:text-[9px] xs:tracking-[3.6px] s:tracking-[2.1px] font-[12] tracking-[4.0px] text-[14px]">
                                            EMAIL ADDRESS
                                        </p>
                                        <input
                                            className="bg-capeCod w-[98%] mt-2.5 text-[white] focus focus:outline-none md:px-8 xs:px-4 s:px-4 inner-shadow h-10 xs:h-9 s:h-9"
                                            name="email"
                                            onChange={(e) => setEmail(e.target.value)}
                                            value={email}
                                        />
                                    </form>
                                </div>

                                <button
                                    className="text-[15px] mt-[10px] px-10 py-3 font-bold text-white bg-midnightDream"
                                    onClick={guestUser}
                                >
                                    Continue as guest
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="xl:col-span-1"></div>
                </div>
            </div>
        </>
    );
};

export default LoginUser;

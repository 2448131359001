import React from "react";
import UserChildren from "../../components/user-profile/UserChildren";

const ExpertClasses = () => {
  return (
    <UserChildren title="Expert Classes">
      <div className="lg:flex gap-3"></div>
    </UserChildren>
  );
};

export default ExpertClasses;

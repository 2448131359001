import React, { useEffect, useState } from "react";
import { CONSTANT } from "../../../core/StaticConstant";
import { AddressService } from "../../../services/address.service";
import { useDispatch } from "react-redux";
import {
    selectedAddressId,
    setDeliveryCharge,
    setOrder,
} from "../../../redux/reducer/cartReducer";
import { Localstorage } from "../../../core/AuthService";

const DeliveryOptions = (props: any) => {
    const { deliveryOptionData } = props;
    const dispatch = useDispatch();
    const [selectedCardIndex, setSelectedCardIndex] = useState(0);

    //   const handleCardClick = (index: number, ele: any) => {
    //     setSelectedCardIndex(index);
    //     let deliveryObj = {
    //       amount: ele.amount,
    //       title: ele.title,
    //     };
    //     dispatch(setDeliveryCharge(deliveryObj));
    //   };


    useEffect(() => {
        const storedIndex:any = Localstorage(CONSTANT.GET,CONSTANT.SELECTED_CARD_INDEX);
        if (storedIndex !== null) {
            setSelectedCardIndex(parseInt(storedIndex, 10));
        }
    
        const storedDeliveryCharge = Localstorage(CONSTANT.GET,CONSTANT.DELIVERY_CHARGE);
        if (storedDeliveryCharge) {
            const parsedDeliveryCharge = JSON.parse(storedDeliveryCharge);
            dispatch(setDeliveryCharge(parsedDeliveryCharge));
        }
    }, [dispatch]);
    
    

    const handleCardClick = (index: number, ele: any) => {
        setSelectedCardIndex(index);
        Localstorage(CONSTANT.SET,CONSTANT.SELECTED_CARD_INDEX, index.toString());

        let deliveryObj = {
            amount: ele.amount,
            title: ele.title,
        };
        dispatch(setDeliveryCharge(deliveryObj));
    
        Localstorage(CONSTANT.SET,CONSTANT.DELIVERY_CHARGE, JSON.stringify(deliveryObj));
    };

    const onClickAddressSelect = (id: any) => {
        window.scrollTo(0, 0);
        dispatch(setOrder(id));
        dispatch(selectedAddressId());
    };

    return (
        <div>
            {deliveryOptionData?.map((ele: any, index: number) => {
                return (
                    <div
                        key={index}
                        className={`bg-lessgray w-full m-auto md:px-8 px-4 mt-4 py-4 cursor-pointer lg:w-[432px] ${selectedCardIndex === index ? "bg-nero text-white" : ""
                            }`}
                        onClick={() => handleCardClick(index, ele)}
                    >
                        <div className="max-w-[300px]">
                            <h2 className="md:font-[700] font-[500] text-[12px] md:text-[15px] tracking-normal">
                                {ele?.title ? ele.title : "N/A"}
                            </h2>
                            <div className="md:flex my-auto gap-4 mt-1">
                                <p className="md:font-[400] font-[300] text-[12px] md:text-[13px] tracking-normal">
                                    {ele?.shortDescription ? ele.shortDescription : "N/A"}
                                </p>
                            </div>
                            <p className="text-green md:font-[700] mt-1 font-[300] text-[12px] md:text-[13px] tracking-normal">
                                {ele.amount ? `£${ele.amount?.toFixed(2)}` : "N/A"}
                            </p>
                        </div>

                        <div className="md:flex my-auto mt-1 gap-4">
                            <p className="md:font-[400] font-[300] text-[12px] md:text-[13px] tracking-normal">
                                {ele?.longDescription ? ele.longDescription : "N/A"}
                            </p>
                        </div>
                    </div>
                );
            })}
            <div className="text-end mt-6">
                <button
                    className="text-[15px] px-10 py-3 font-bold bg-midnightDream text-white tracking-wide"
                    onClick={() => onClickAddressSelect("paymentMethod")}
                >
                    Select Delivery options
                </button>
            </div>
        </div>
    );
};

export default DeliveryOptions;

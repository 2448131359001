export const CONSTANT = Object.freeze({
  GET:"get",
  SET:"set",
  REMOVE:"remove",
  USER_INFO: "userInfo",
  USER_NAME:'UserName',
  USER_AGE:'UserAge',
  PERCENTAGE:'PERCENTAGE',
  SELECTED_CARD_INDEX:"selectedCardIndex",
  DELIVERY_CHARGE:"deliveryCharge",
  REDIRECT_FROM_ORDER_SUCCESS:"redirectFromOrderSuccess",
  REDIRECT_FROM_ORDER_PENDING:"redirectFromOrderPending",
  CUSTOMER_ID:"customerId",
  TRUE:"true",
  FALSE:"false",
  LAST_VISITED_PAGE:"lastVisitedPage",
  USER: "token",
  SUCCESS: "success",
  NO_MORE_CONVERSATION:"No more conversations found",
  FAIL: "fail",
  ERROR: "error",
  UNDEFINED: "undefined",
  ADD: "Add",
  UPDATE: "Update",
  ACTIVE: "Active",
  DE_ACTIVE: "Deactive",
  ENTER_EMAIL: "Please enter a email",
  DOCUMENTS_DELETE_SUCCESSFULLY: "Medical document is deleted successfully!",
  ENTER_PASSWORD: "Please enter a password",
  CONFIRM_PASSWORD: "Please enter a confirm password",
  SAME_PASSWORD: "Password does not match",
  ONLY_PDF: "You can upload only PDF files!",
  FILE_ALREADY_EXIST:"File already exist with same name. Please use different file or remove previous one and re-upload again..",
  ERROR_OCCURRED: "Something went error",
  SELECTED_MENU: "SelectedMenuName",
  LIKE_SUCCESSFULLY: "Product like successfully!",
  UNLIKE_SUCCESSFULLY: "Product unlike successfully!",
  ENTER_FIRST_NAME: "Please enter a first name",
  ENTER_LAST_NAME: "Please enter a last name",
  MOBILE_NUMBER: "Please enter a mobile number",
  PRIMARY_ADDRESS: "Please enter a address",
  BLOOD_GROUP: "Please enter a blood group",
  DATE_OF_BIRTH: "Please enter a date of birth",
  WEIGHT: "Please enter a weight",
  HEIGHT: "Please enter a height",
  MEDICAL_CONDITIONS: "Please enter a medical conditions",
  DISABILITY: "Please enter a disability",
  USERNAME: "Please enter a username",
  FULL_NAME: "Please enter a full name",
  CONTACT_NO: "Please enter a contact no",
  ADDRESS: "Please enter a address",
  LANDMARK: "Please enter a landmark",
  POST_CODE: "Please enter a post code",
  CHECK_CVV: /^\d{3}$/,
  SELF_TRAIN_PATH: "/self-train",
  YOGA_DETAILS_PATH: "/self-train/yoga-details",
  VIEW_ALL_CLASSES_PATH: "/self-train/view-all-classes",
  EXPERTS_PATH: "/self-train/experts",
  CARD_ERROR: "Something Went Wrong Please Try Again",
  DELETE_CONFIRMATION: "Are you sure to delete card?",
  CANCEl_CONFIRMATION : "Are you sure to cancel your order?",
  CUSTOMER_NOT_FOUND:"Customer not found",
  DATA_NOT_FOUND:"Data not found",
  REVIEW_NOT_FOUND:"Review Data not found",
  ADDRESS_NOT_FOUND:"Address not found with ID",
  REQUIRES_ACTION : "requires_action",
  COURSE_NOT_FOUND:"Course not found",
  TRAINER_NOT_FOUND:"Trainer not found",
  PASSWORD_MISMATCH:"New password and Confirm password are not match",
  EMAIL_UPDATE:"User Can't Update Email",

  ROLES: {
    ADMIN: "ADMIN",
    USER: "USER",
    GUEST_USER: "GUEST-USER",
  },

  MODE:{
    NORMAL:"NORMAL"
  },

  PENDING: "PENDING",
  PAYMENT_ISSUE: "PAYMENT_ISSUE",
  CANCEL : "CANCELLED",

  REASON_LIST: [
    { key: "duplicate", value: "Duplicate" },
    { key: "fraudulent", value: "Fraudulent" },
    { key: "requested_by_customer", value: "Requested by customer" },
    { key: "abandoned", value: "Abandoned" },
    { key: "failed_invoice", value: "Failed Invoice" },
  ],
});

import React, { useEffect, useState } from "react"
import ProductCard from "../product-card/ProductCard"
import { useNavigate, useParams } from "react-router-dom";
import { CONSTANT } from "../../core/StaticConstant";
import VideoComponent from "../self-train/video-component/VideoComponent";
import { selfTrainService } from "../../services/self-train.service";
import Filter from "../filter/Filter";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/solid";
import "./Navbar.css"
import Slider from "react-slick";
import { ProductService } from "../../services";
import { toast } from "react-toastify";
import { decryptData, encryptData, getUserData } from "../../core/Utils";
import { Localstorage } from "../../core/AuthService";


const SearchResult = () => {

    const params = useParams()
    let productId = decryptData(params?.Id);
    const [categoryData, setCategoryData] = useState([]);
    const [courseData, setCourseData] = useState<any[]>([]);
    const [totalProducts, setTotalProducts] = useState<any>();
    const [likeUnlikeData, setLikeUnlike] = useState<any[]>([]);
    const [totalClass, setTotalClass] = useState<any>()
    const itemsPerPage = 4;
    const [userId, setUserId] = useState<any>("");
    const [sliderRef, setSliderRef] = useState<any>(null);

    const navigate = useNavigate()

    useEffect(() => {
        let newPage;
        fetchData(newPage);
        const userData: any = getUserData();
        const userId: any = userData?.userId;
        if (userId) {
            like();
        }
    }, []);

    const fetchData = async (direction: any) => {

        let obj = {
            keyword: params.id
        }
        try {
            const response = await selfTrainService.getProductAndClass(obj);
            if (response.status === CONSTANT.SUCCESS) {
                setCourseData(response.data.courses);
                setCategoryData(response.data.products)
                setTotalProducts(response.data.totalProducts);
                setTotalClass(response.data.totalCourses)

            }
        } catch (error) {
            console.error(error);
        }
    };

    const like = async () => {
        try {
            let response = await ProductService.GetlikeUnlikeProductByUser();
            if (response && response.status === CONSTANT.SUCCESS) {
                setLikeUnlike(response.data.product);
            }
        } catch (err: any) {
            console.error("Error occurred in like:", err);
            toast.error(err?.response?.data?.error);
        }
    };

    const likeUnlikeProduct = async (id: any, skuId: any) => {
        if (userId) {
            try {
                let obj = {
                    productId: productId, // Use productId instead of skuId
                    skuId: skuId._id,
                };
                let response = await ProductService.likeUnlikeProductByUser(obj);
                if (response.status === CONSTANT.SUCCESS) {
                    setLikeUnlike(response.data.product);

                    toast.success(response.message);

                }
                await like();
            } catch (err: any) {
                console.error("Error occurred in likeUnlikeProduct:", err);
                toast.error(err?.response?.data?.error);
            }
        } else {
            Localstorage(CONSTANT.SET,CONSTANT.LAST_VISITED_PAGE,window.location.href);
            navigate("/login");
        }
    };


    const onAddToCart = (id: any) => {
        navigate(`/shop/productdetail/${encryptData(id)}`);
    };


    const sliderSettings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: itemsPerPage,
        slidesToScroll: itemsPerPage,
      
        responsive: [
          {
            breakpoint: 1280,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              initialSlide: 2,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      };



    const handleSliderNavigation = (direction: any) => {
        if (sliderRef) {
            if (direction === "next") {
                sliderRef.slickNext();
            } else if (direction === "previous") {
                sliderRef.slickPrev();
            }
        }
    };


    return (
        <>
            <div className=" bg-[#36403C]">

                <div className="2xl: && xl: && lg: && sm:flex justify-between">

                    <div className="text-gray-300 ml-4 p-7 text-[40px] font-bold">
                        Search Result
                        <span className="font-light text-gray-300 ml-4 2xl:text-[50px] xl:text-[20px] md:text-[20px]">
                            {totalProducts || totalClass ? "| " : ""}
                        </span>{" "}
                        <span className="text-gray-300 text-[20px]">
                            {totalProducts || totalClass ? `${totalProducts + totalClass} results found` : ""}
                        </span>
                    </div>
                    <div>
                        <Filter pageName="search-result" bgColor="bg-[#36403C]" />
                    </div>
                </div>
                <div className="bg-white text-[20px] rounded-2xl p-1 text-center w-[200px] h-[40px] ml-10">
                    {params.id}
                </div>
                <div className=" bg-[#36403C]">
                    <>
                        <div className="text-gray-300 text-[30px] flex ml-20 mt-10 s:ml-3 gap-1">
                            Products
                            <span className="font-light text-gray-300 ml-4 2xl:text-[30px] xl:text-[20px] md:text-[20px]">
                                {totalProducts ? "|" : ""}
                            </span>
                            <span className="text-gray-300">
                                {totalProducts ? `${totalProducts} results found` : ""}
                            </span>
                            <div className="w-20  flex ml-auto cursor-pointer
                            ">
                                <ChevronLeftIcon onClick={() => handleSliderNavigation("previous")} />
                                <ChevronRightIcon onClick={() => handleSliderNavigation("next")} />
                            </div>
                        </div>

                        <>
                            {
                                categoryData && categoryData.length > 0 ?
                                    <>

                                        <div className="m-auto grid lg:grid-cols-1 slider-div md:grid-cols-1 sm:grid-cols-1 xs:grid-cols-1 bg-[#36403c] py-[3rem] sm:px-[2.5rem] px-3">
                                            <Slider  {...sliderSettings} ref={(slider) => setSliderRef(slider)}>
                                                {categoryData && categoryData.map((ele: any, i: any) => {
                                                    return (
                                                        <React.Fragment key={i}>
                                                            <ProductCard
                                                                key={i}
                                                                productId={ele && ele._id || ""}
                                                                image={ele && ele.skuIds && ele.skuIds[0] && ele.skuIds[0].productImage[0]}
                                                                name={ele.productName}
                                                                description={ele && ele.productSubCategory && ele.productSubCategory.subCategoryName}
                                                                productTitle={ele.productTitle}
                                                                productFormat={
                                                                    ele?.productFormat
                                                                        ? ele?.productFormat
                                                                        : "N/A"
                                                                }
                                                                ratings={ele && ele?.rating.toFixed(2)}
                                                                mrp={ele && ele.skuIds && ele.skuIds[0] && ele.skuIds[0].productSingleAmount}
                                                                deliverDays={ele.benefitIds}
                                                                skuId={ele && ele.skuIds && ele.skuIds[0] && ele.skuIds[0]._id}
                                                                onAddToCart={() => onAddToCart(ele._id)}
                                                                isveg={ele && ele.isVeg || null}
                                                                likeUnlikeData={likeUnlikeData}
                                                                css={"lg:mb-[5rem] sm:mb-[1rem] xs:mb-[1rem]"}
                                                            />
                                                        </React.Fragment>
                                                    );
                                                })}
                                            </Slider>
                                        </div>
                                    </> :
                                    <div className="flex justify-center items-center mb-5 text-gray-300">
                                        No data found
                                    </div>
                            }
                        </>


                        <div className=" text-black font-bold">
                            <hr className="h-0.5 mb-10 border-0  dark:bg-gray-600" />
                        </div>

                        <div className="text-gray-300 text-[30px] ml-20 mt-10">
                            Classes
                            <span className="font-light text-gray-300 ml-4 2xl:text-[30px] xl:text-[20px] md:text-[20px]">

                                {totalClass ? "| " : ""}

                            </span>{" "}
                            <span className="text-gray-300 text-[20px]">
                                {totalClass ? `${totalClass} results found` : ""}
                            </span>
                        </div>

                        {courseData?.length > 0 ?
                            <>
                                <div className='sm:px-20'>
                                            <VideoComponent data={courseData}  pageName="search-result" /><br /><br /><br />
                                </div>

                            </>
                            : <div className="flex justify-center items-center p-5 text-gray-300">
                                No data found
                            </div>
                        }

                    </>
                </div>
            </div>
        </>
    )

}


export default SearchResult
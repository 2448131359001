import { createSlice } from "@reduxjs/toolkit";

interface CartState {
  userData: any;
  order: string;
  track: string;
  cartProducts: any[];
  clearCart: any[];
  addressId: string;
  orderId: any;
  editAddressId: string;
  addressLength: number;
  dosageSelectPrice: number;
  productId: string;
  deliveryCharge: number;
  selectDeliveryAddress: any;
  setProductData: number;
  setExpectedDate: any;
  setPromoCode: number
}

const initialState: CartState = {
  userData: {},
  order: "1",
  track: "1",
  cartProducts: [],
  clearCart: [],
  addressId: "",
  orderId: "",
  editAddressId: "",
  addressLength: 0,
  dosageSelectPrice: 0,
  productId: "",
  deliveryCharge: 0,
  selectDeliveryAddress: {},
  setProductData: 0,
  setExpectedDate: "",
  setPromoCode: 0
};

export const reducer: any = createSlice({
  name: "basicConfigForm",
  initialState,
  reducers: {
    storeUserData: (state, { payload }) => {
      state.userData = payload;
    },

    setOrder: (state, { payload }) => {
      state.order = payload;
    },
    setProductTrack: (state, { payload }) => {
      state.track = payload;
    },
    setCartProducts: (state, { payload }) => {
      state.cartProducts = payload;
    },
    clearCart: (state, { payload }) => {
      state.cartProducts = payload;
    },
    selectedAddressId: (state, { payload }) => {
      state.addressId = payload;
    },
    selectEditAddressId: (state, { payload }) => {
      state.editAddressId = payload;
    },
    orderId: (state, { payload }) => {
      state.orderId = payload;
    },
    addressLength: (state, { payload }) => {
      state.addressLength = payload;
    },
    dosageSelectPrice: (state, { payload }) => {
      state.productId = payload.productId;
      state.dosageSelectPrice = payload;
    },
    setDeliveryCharge: (state, { payload }) => {
      state.deliveryCharge = payload;
    },
    setSelectDeliveryAddress: (state, { payload }) => {
      state.selectDeliveryAddress = payload;
    },

    setProductData: (state, { payload }) => {
      state.setProductData = payload;
    },
    setExpectedDate: (state, { payload }) => {
      state.setExpectedDate = payload;
    },

    setPromoCode: (state, { payload }) => {
        // Ensure payload is always treated as a number
        state.setPromoCode = Number(payload);
      },
  },
});

export const {
  storeUserData,
  setPromoCode,
  setOrder,
  setProductTrack,
  setCartProducts,
  clearCart,
  selectedAddressId,
  orderId,
  selectEditAddressId,
  addressLength,
  dosageSelectPrice,
  setDeliveryCharge,
  setSelectDeliveryAddress,
  setProductData,
  setExpectedDate,
} = reducer.actions;

export default reducer.reducer;

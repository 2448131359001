import React from 'react'

const MeetExpert = () => {
  return (
    <div className='bg-darkBlack h-[600px]'>
        <h2 className='text-white text-center font-bold text-[40px] md:text-[48px] py-12'>Meet The Expert</h2>
    </div>
  )
}

export default MeetExpert
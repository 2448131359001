import moment from "moment";
import { encryptData } from "../../core/Utils";
import { useNavigate } from "react-router-dom";

const ProductCart = (props: any) => {
    const { index, ele, likeUnlikeProduct } = props;
    const futureDate = moment().add(5, "days").format("MMM DD YYYY");

    const navigate = useNavigate();
    return (
        <div key={index}>
            <div className="mt-4">
                <hr className="border-silver mt-4 border-t-[1px]" />
                <div className="grid xl:grid-cols-2 md:grid-cols-4 sm:grid-cols-6 grid-cols-7">
                    <div className="xl:col-span-1 md:col-span-3 sm:col-span-5 col-span-6">
                        <div className="grid grid-cols-3">
                            <div className="col-span-1 bg-white">
                                <img
                                    className="w-[100%] h-[200px] object-contain"
                                    src={ele?.skuId?.productImage?.[0] || "https://drive.google.com/uc?export=view&id=1F64VND3vJuFtCPxekGycvTrfr-xcRab1"}
                                    alt=""
                                />
                            </div>
                            <div className="col-span-2 my-auto pl-8">
                                <h2 className="text-black font-bold xl:text-[25px] md:text-[18px] text-[13px] cursor-pointer" onClick={() =>
                                                                        navigate(
                                                                            `/shop/productdetail/${encryptData(ele?.productId?._id)}`
                                                                        )
                                                                    }>
                                    {ele?.productId?.productName} | <span>30 Tablets</span>
                                </h2>
                                {ele?.skuId?.productQty ? (
                                    <p className="text-green font-[500] xl:text-[15px] md:[14px] text-[10px]">
                                        {/* Hurry, Only {ele?.skuId?.productQty} Available */}
                                        {ele.skuId?.productQty > 0 ? (
                                                            <p className="text-[15px] font-bold text-darkgreen text-[500] sm:ml-1  mr-1 sm:mr-8 mr-[1rem]">Hurry up,Only {ele.skuId?. productQty}

                                                                <span className="ml-[8px]"> Available</span>
                                                            </p>
                                                        ) : (
                                                            <h2 className="text-[15px] x:text-[10px] s:text-[10px] font-bold text-red-500 w-full mr-8 x:mr-[1rem] s:mr-[1rem] inline-block">
                                                                Out of stock
                                                            </h2>
                                                        )}
                                    </p>
                                ) : (
                                    <p className="text-[red] font-[500] xl:text-[15px] md:[14px] text-[10px]">
                                        Out of stock
                                    </p>
                                )}
                                <p className="text-black xl:text-[15px] md:text-[14px] text-[10px] font-[700]">
                                    Estimated delivery: {futureDate}
                                </p>
                                <div className="text-lg font-bold pt-[20px]">
                                    <div>
                                        <div className="inline-flex">
                                            <span
                                                className="text-[12px] content cursor-pointer text-capeCod"
                                                onClick={() =>
                                                    likeUnlikeProduct(
                                                        ele?.productId?._id,
                                                        ele?.skuId
                                                    )
                                                }
                                            >
                                                Remove
                                            </span>
                                            <span className="border-r content pl-[12px]"></span>
                                            {ele?.skuId?.productQty ? (
                                                <span
                                                    className="text-[12px] cursor-pointer pl-[12px] content text-capeCod"
                                                    onClick={() =>
                                                        navigate(
                                                            `/shop/productdetail/${encryptData(ele?.productId?._id)}`
                                                        )
                                                    }
                                                >
                                                    Add To Cart
                                                </span>
                                            ) : (
                                                <span className="text-[12px] cursor-pointer pl-[12px] content text-silver">
                                                    Add To Cart
                                                </span>
                                            )}
                                        </div>
                                        {/* <div className="max-w-[640px] sm:hidden">
                                            <span
                                                className="text-[12px] cursor-pointer text-capeCod"
                                                onClick={() =>
                                                    likeUnlikeProduct(ele?.productId?._id, ele?.skuId)
                                                }
                                            >
                                                Remove
                                            </span>
                                            <span className="border-r pl-[12px]"></span>
                                            <span
                                                className="text-[12px] pl-[12px] text-capeCod cursor-pointer"
                                                onClick={() =>
                                                    navigate(`/shop/productdetail/${ele?.productId?._id}`)
                                                }
                                            >
                                                Move to Wishlist
                                            </span>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="xl;col-span-1 md:col-span-1 sm:mt-12 mt-[2.5rem] xl:mt-8 2xl:mt-4 sm:col-span-1 col-span-1">
                        <h2 className="text-black font-bold xl:text-[25px] lg:[18px] md:text-[15px] text-[13px] text-end">
                            £ {ele?.skuId?.productSingleAmount ? ele.skuId.productSingleAmount?.toFixed(2) : "N/A"}
                        </h2>
                    </div>
                </div>
                <hr className="border-silver border-t-[1px]" />
            </div>
        </div>
        // <></>
    );
};

export default ProductCart;

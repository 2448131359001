import { ArrowSmallUpIcon } from "@heroicons/react/24/outline";
import arrow from "../../assets/images/arrow1.png";
import { Link } from "react-router-dom";
import Logo from "../../assets/images/white-greenlab-logo.png";

const Footer = () => {
  const isProductCategory = window.location.pathname.includes("/productCategory/");
  const isShop = window.location.pathname === "/shop";

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };

  const facebook = (
    <svg
      className="lg:w-7 lg:h-7 w-5 h-5"
      fill="currentColor"
      viewBox="0 0 24 24"
      aria-hidden="true"
    >
      <path
        fillRule="evenodd"
        d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
        clipRule="evenodd"
      />
    </svg>
  );

  const instagram = (
    <svg
      className="lg:w-7 lg:h-7 w-5 h-5"
      fill="currentColor"
      viewBox="0 0 24 24"
      aria-hidden="true"
    >
      <path
        fillRule="evenodd"
        d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
        clipRule="evenodd"
      />
    </svg>
  );

  const github = (
    <svg
      className="lg:w-7 lg:h-7 w-5 h-5"
      fill="currentColor"
      viewBox="0 0 24 24"
      aria-hidden="true"
    >
      <path
        fillRule="evenodd"
        d="M12 2C6.477 2 2 6.484 2 12.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0112 6.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.202 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.943.359.309.678.92.678 1.855 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0022 12.017C22 6.484 17.522 2 12 2z"
        clipRule="evenodd"
      />
    </svg>
  );

  const dribbbel = (
    <svg
      className="lg:w-7 lg:h-7 w-5 h-5"
      fill="currentColor"
      viewBox="0 0 24 24"
      aria-hidden="true"
    >
      <path
        fillRule="evenodd"
        d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10c5.51 0 10-4.48 10-10S17.51 2 12 2zm6.605 4.61a8.502 8.502 0 011.93 5.314c-.281-.054-3.101-.629-5.943-.271-.065-.141-.12-.293-.184-.445a25.416 25.416 0 00-.564-1.236c3.145-1.28 4.577-3.124 4.761-3.362zM12 3.475c2.17 0 4.154.813 5.662 2.148-.152.216-1.443 1.941-4.48 3.08-1.399-2.57-2.95-4.675-3.189-5A8.687 8.687 0 0112 3.475zm-3.633.803a53.896 53.896 0 013.167 4.935c-3.992 1.063-7.517 1.04-7.896 1.04a8.581 8.581 0 014.729-5.975zM3.453 12.01v-.26c.37.01 4.512.065 8.775-1.215.25.477.477.965.694 1.453-.109.033-.228.065-.336.098-4.404 1.42-6.747 5.303-6.942 5.629a8.522 8.522 0 01-2.19-5.705zM12 20.547a8.482 8.482 0 01-5.239-1.8c.152-.315 1.888-3.656 6.703-5.337.022-.01.033-.01.054-.022a35.318 35.318 0 011.823 6.475 8.4 8.4 0 01-3.341.684zm4.761-1.465c-.086-.52-.542-3.015-1.659-6.084 2.679-.423 5.022.271 5.314.369a8.468 8.468 0 01-3.655 5.715z"
        clipRule="evenodd"
      />
    </svg>
  );

  return (
    <>
      {(isProductCategory || isShop) && (
        <div className="sm:flex mt-0 sm:justify-end bg-greenFooter items-center hidden">
          <h2
            className="text-silver text-greenFooter text-[18px] cursor-pointer font-bold"
            onClick={() => scrollToTop()}
          >
            Back to Top
          </h2>
          <a>
            <ArrowSmallUpIcon
              className="h-[25px] mt-[3px] ml-[4px] mr-[4rem] cursor-pointer stroke-[2px] text-silver"
              onClick={() => scrollToTop()}
            />
          </a>
        </div>
      )}
      <footer className="p-8 bg-[#384957] xs:hidden s:hidden">
        <div className="sm:flex mt-0 sm:justify-start lg:min-w-[200px] lg:max-w-[200px] max-w-[150px]">
          <img src={Logo} alt="GREEN LAB" />
        </div>
        <div className="md:flex mt-5 mb-5 md:justify-start">
          <div className="grid xl:gap-20 sm:grid-cols-5 lg:gap-8 gap-4 mt-[30px]">
            <div>
              <h1 className="text-[#C2D4E3] 2xl:text-[27px] xl:text-[22px] lg:text-[20px] font-bold">
                "You yourself, as much as anybody in the entire universe,
                deserve your love and affection"
              </h1>
              <p className="text-[#C2D4E3]">- Buddha</p>
            </div>
            <div>
              <ul className="text-gray-600 text-sm">
                <li className="mb-5">
                  <a href="" className="text-white">
                    Category 1 - TBD
                  </a>
                </li>
                <li className="mb-5">
                  <a href="" className="text-[#C2D4E3]">
                    Comprehensive annual health plan
                  </a>
                </li>
                <li className="mb-5">
                  <a href="" className="text-[#C2D4E3]">
                    Comprehensive prime health plan
                  </a>
                </li>
                <li className="mb-5">
                  <a href="" className="text-[#C2D4E3]">
                    Full body vitamin scan
                  </a>
                </li>
                <li className="mb-5">
                  <a href="" className="text-[#C2D4E3]">
                    Get a lifestyle consultant
                  </a>
                </li>
                <li className="mb-5">
                  <a href="" className="text-[#C2D4E3]">
                    Products
                  </a>
                </li>
              </ul>
            </div>
            <div>
              <ul className="text-gray-600 text-sm">
                <li className="mb-5">
                  <a href="" className="text-white">
                    Services
                  </a>
                </li>
                <li className="mb-5">
                  <a href="" className="text-[#C2D4E3]">
                    Diagnostic
                  </a>
                </li>
                <li className="mb-5">
                  <a href="" className="text-[#C2D4E3]">
                    Blood text packages
                  </a>
                </li>
                <li className="mb-5">
                  <a href="" className="text-[#C2D4E3]">
                    Consult
                  </a>
                </li>
                <li className="mb-5">
                  <a href="" className="text-[#C2D4E3]">
                    Shop
                  </a>
                </li>
                <li className="mb-5">
                  <a href="" className="text-[#C2D4E3]">
                    Expert classes
                  </a>
                </li>
              </ul>
            </div>
            <div>
              <ul className="text-gray-600 text-sm">
                <li className="mb-5">
                  <Link to="/about" className="text-white">
                    About
                  </Link>
                </li>
                <li className="mb-5">
                  <Link to="/user/about" className="text-[#C2D4E3]">
                    About us
                  </Link>
                </li>
                <li className="mb-5">
                  <Link to="/user/contact" className="text-[#C2D4E3]">
                    Contact
                  </Link>
                </li>
                <li className="mb-5">
                  <a href="" className="text-[#C2D4E3]">
                    Partner
                  </a>
                </li>
                <li className="mb-5">
                  <a href="" className="text-[#C2D4E3]">
                    Jobs
                  </a>
                </li>
                <li className="mb-5">
                  <a href="" className="text-[#C2D4E3]">
                    Become an affiliate
                  </a>
                </li>
              </ul>
            </div>
            <div>
              <a className="text-white text-[14px]">
                Follow us on Social Media
              </a>
              <p className="text-[#C2D4E3] text-[14px] mt-5">
                Stay connected with us to hear the latest news and updates
              </p>
              <div className="sm:flex sm:items-center">
                <div className="flex mt-4 xl:space-x-6 lg:space-x-4 space-x-[10px] sm:justify-between">
                  <a href="#" className="text-[#C2D4E3] hover:text-white">
                    {facebook}
                  </a>
                  <a href="#" className="text-[#C2D4E3] hover:text-white">
                    {instagram}
                  </a>
                  <a href="#" className="text-[#C2D4E3] hover:text-white">
                    {github}
                  </a>
                  <a href="#" className="text-[#C2D4E3] hover:text-white">
                    {dribbbel}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr className="border-[#C2D4E3] border-t-[3px]" />
        <div className="grid md:gap-20 sm:grid-cols-5 gap-8 mt-[30px]">
          <span className="text-sm text-[#C2D4E3] col-span-1 sm:text-center">
            © 2023{" "}
            <a href="https://flowbite.com/" className="hover:underline">
              Flowbite™
            </a>
            . All Rights Reserved.
          </span>
          <div className="lg:col-span-3 md:col-span-5 sm:col-span-6">
            <ul className="flex text-gray-600 text-sm justify-between">
              <li>
                <a
                  href=""
                  className="text-[#C2D4E3] xl:border-r xl:pr-4 xl:pr-8"
                >
                  Terms of Sale
                </a>
              </li>
              <li>
                <a
                  href=""
                  className="text-[#C2D4E3] xl:border-r xl:pr-4  xxl:pr-8"
                >
                  Terms of Use
                </a>
              </li>
              <li>
                <a
                  href=""
                  className="text-[#C2D4E3] xl:border-r xl:pr-4  xxl:pr-8"
                >
                  Disclaimer
                </a>
              </li>
              <li className="mb-5">
                <a
                  href=""
                  className="text-[#C2D4E3] xl:border-r xl:pr-4  xxl:pr-8"
                >
                  Privacy Policy
                </a>
              </li>
              <li className="mb-5">
                <a
                  href=""
                  className="text-[#C2D4E3] xl:border-r xl:pr-4  xxl:pr-8"
                >
                  Modern Slavery
                </a>
              </li>
              <li className="mb-5">
                <a
                  href=""
                  className="text-[#C2D4E3] xl:border-r xl:pr-4  xxl:pr-8"
                >
                  Data Collection
                </a>
              </li>
              <li className="mb-5">
                <a href="" className="text-[#C2D4E3]">
                  Cookies
                </a>
              </li>
            </ul>
            <div></div>
          </div>
        </div>
      </footer>
      <footer className="sm:hidden xs:block sm:bg-capeCod bg-[#384957]">
        <div
          className="bg-tuatara text-silver w-full h-[50px] py-[13px] justify-center tracking-[5px] inline-flex"
          onClick={() => scrollToTop()}
        >
          BACK TO TOP
          <ArrowSmallUpIcon className="w-[6%]" />
        </div>
        <div className="py-[28px] px-[10px]">
          <a href="" className="text-white text-[13px] leading-[50px]">
            Comprehensive annual health plan
          </a>
          <br />
          <a href="" className="text-white text-[13px] leading-[50px]">
            Comprehensive prime health plan
          </a>
          <br />
          <a href="" className="text-white text-[13px] leading-[50px]">
            Full body vitamin scan
          </a>
          <br />
          <a href="" className="text-white text-[13px] leading-[50px]">
            Get a lifestyle consultant
          </a>
          <br />
          <a href="" className="text-white text-[13px] leading-[50px]">
            Products
          </a>
          <br />
          <a href="" className="text-white text-[13px] leading-[50px]">
            Diagnostic
          </a>
          <br />
          <a href="" className="text-white text-[13px] leading-[50px]">
            Blood text packages
          </a>
          <br />
          <a href="" className="text-white text-[13px] leading-[50px]">
            Consult
          </a>
          <br />
          <a href="" className="text-white text-[13px] leading-[50px]">
            Shop
          </a>
          <br />
          <a href="" className="text-white text-[13px] leading-[50px]">
            The routine
          </a>
          <br />
          <a href="" className="text-white text-[13px] leading-[50px]">
            Full body scan
          </a>
          <br />
          <a href="" className="text-white text-[13px] leading-[50px]">
            Build your text package
          </a>
          <br />
          <a href="" className="text-white text-[13px] leading-[50px]">
            Comprehensive Blood test
          </a>
          <br />
          <a href="" className="text-white text-[13px] leading-[50px]">
            Classes
          </a>
          <br />
          <a href="" className="text-white text-[13px] leading-[50px]">
            App
          </a>
          <br />
        </div>
        <div className="inline-flex w-full justify-between px-[10px]">
          <a
            href="/user/about"
            className="text-white hover:text-white cursor-pointer text-[13px]"
          >
            About
          </a>
          <a
            href="/privacy-policy"
            className="text-white hover:text-white cursor-pointer text-[13px]"
          >
            Privacy Policy
          </a>
          <a
            href="/user/contact"
            className="text-white hover:text-white cursor-pointer text-[13px]"
          >
            Contact Us
          </a>
        </div>
        <div className="flex mt-4 space-x-6 w-full justify-between px-[25px]">
          <a
            href="#"
            className="text-gray-500 hover:text-gray-900 dark:hover:text-white"
          >
            {facebook}
            <span className="sr-only">Facebook page</span>
          </a>
          <a
            href="#"
            className="text-gray-500 hover:text-gray-900 dark:hover:text-white"
          >
            {instagram}
            <span className="sr-only">Instagram page</span>
          </a>
          <a
            href="#"
            className="text-gray-500 hover:text-gray-900 dark:hover:text-white"
          >
            {github}
            <span className="sr-only">GitHub account</span>
          </a>
          <a
            href="#"
            className="text-gray-500 hover:text-gray-900 dark:hover:text-white"
          >
            {dribbbel}
            <span className="sr-only">Dribbbel account</span>
          </a>
        </div>
        <div className="w-full text-align-webkit-center mt-[8px]">
          <p className="text-white text-[13px]">
            Copyright 2022. All rights reserved
          </p>
        </div>
        <div className="mt-[14px]">
          <img src={arrow} />
        </div>
      </footer>
    </>
  );
};

export default Footer;

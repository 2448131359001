import React from 'react';

const ProductUse = (props: any) => {
    const { productData } = props;
    let productUseData = productData?.productHowItWorkIds?.[0]
    
    return (

        <div className='flex sm:grid lg:grid-cols-3 sm:grid-cols-1 xs:grid-cols-1 gap-10 flex-wrap-reverse py-[50px] md:px-[70px] c-sm:px-8 sm:mt-[6rem] mt-[4rem]' id='productUse'>
            <div className='col-span-1 text-align-webkit-center'>
                <img className='h-full object-cover' src={productUseData?.productHowImage || "https://us.123rf.com/450wm/pavelstasevich/pavelstasevich1811/pavelstasevich181101065/112815953-no-image-available-icon-flat-vector.jpg?ver=6"} alt='' />
            </div>
            <div className='col-span-2'>
                <h1 className='xl:text-[28px] lg:text-[20px] font-bold text-oxfordBlue'>How it works:</h1>
                <p className='text-oxfordBlue xl:text-[17px] lg:text-[14px] sm:text-[14px] text-[12px]' dangerouslySetInnerHTML={{ __html: productUseData?.productHelps }}></p>
                <h1 className='xl:text-[28px] lg:text-[20px] font-bold text-oxfordBlue xl:pt-[4rem] lg:pt-[2rem] pt-[1rem]'>How to use:</h1>
                <p className='text-oxfordBlue xl:text-[17px] lg:text-[14px] sm:text-[14px] text-[12px]' dangerouslySetInnerHTML={{ __html: productUseData?.productHow }}></p>
            </div>
        </div>
    );
};

export default ProductUse;
import React from "react";

import { css } from "@emotion/react";
import '../../App.css';
import { BounceLoader } from "react-spinners";

const Loader = () => {
  return (
    <div className="loader-div">
      <BounceLoader
        color="#b79528"
        loading={true}
        className="bg-grey"
        size={50}
      />
    </div>
  );
};

export default Loader;

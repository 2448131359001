import { useFormik } from "formik";
import * as Yup from "yup";
import React, { useEffect, useState } from "react";
import { loginSecuritySchema } from "../../schema/Schema";
import { UserService } from "../../services";
import { CONSTANT } from "../../core/StaticConstant";
import { getUserData, isValidEmail } from "../../core/Utils";
import { toast } from "react-toastify";
import Loader from "../loader/Loader";
import { Localstorage } from "../../core/AuthService";
import { useUser } from "../Contexts/UserContext";

const LoginSecurity = () => {
    const { updateUsername }: any = useUser();
    const [edit, setEdit] = useState(false);
    const [editPassword, setEditPassword] = useState(false);
    const [userId, setUserId] = useState<String>("");
    const [isLoading, setIsLoading] = useState(false);
    const [userData, setUserData] = useState<any>();

    const initialValues = {
        firstName: "",
        lastName: "",
        contactNo: "",
        email: "",
        recoveryEmail: "",
        currentPassword: "",
        newPassword: "",
        confirmPassword: "",
    };

    const { values, errors, touched, handleChange, setValues, handleSubmit } =
        useFormik({
            initialValues,
            validationSchema: loginSecuritySchema,
            onSubmit: async (values) => {
                try {
                    const response: any = await UserService.updateUserBasicDetails(values);
                    if (response && response.status === CONSTANT.SUCCESS) {
                        toast.success(response.message);
                        setUserData(response.data.user);
                        setValues(response.data.user);
                        let username = response.data.user.firstName;
                        updateUsername(username);
                        Localstorage(CONSTANT.SET, CONSTANT.USER_NAME, username)
                    }
                } catch (error) {
                    console.error(error);
                }
            },
        });

    useEffect(() => {
        const userData: any = getUserData();
        setUserId(userData?.userId);
        getUserDetailsById();
    }, [setValues]);

    const getUserDetailsById = async () => {
        try {
            setIsLoading(true);
            const response: any = await UserService.getUserBasicDetailsById();
            setTimeout(() => {
                setIsLoading(false);
            }, 2000);
            if (response && response.status === CONSTANT.SUCCESS) {
                setUserData(response.data.user);
                setValues({
                    ...values,
                    ...response.data.user,
                });
            } else {
                toast.error(response.message);
            }
        } catch (err: any) {
            toast.error(err?.response?.data?.error);
            setIsLoading(false);
        }
    };
    function handleInput(event: any) {
        const input = event.target;
        const inputValue = input.value.trim();
        const numericValue = inputValue.replace(/\D/g, "");
        const restrictedValue = numericValue.slice(0, 10);
        input.value = restrictedValue;
    }

    const updateUser: any = async () => {
        try {
            if (!isValidEmail(values.recoveryEmail)) {
                toast.error('Invalid email format');
                return; 
            } else {
                setIsLoading(true);
                const response: any = await UserService.updateUserBasicDetails(values);
                setTimeout(() => {
                    setIsLoading(false);
                }, 2000);
                if (response && response.status === CONSTANT.SUCCESS) {
                    toast.success(response.message);
                    setEdit(false);
                    setUserData(response.data.user);
                    setValues(response.data.user);
                    let username = response.data.user.firstName;
                    updateUsername(username);
                    Localstorage(CONSTANT.SET, CONSTANT.USER_NAME, username)
                }
            }
        } catch (err: any) {
            toast.error(err?.response?.data?.error);
            setIsLoading(false);
        }
    };

    const resetPassword = async () => {
        try {
            if (values.newPassword !== values.confirmPassword) {
                toast.error(CONSTANT.PASSWORD_MISMATCH)
            } else {
                const response: any = await UserService.resetPassword(values);
                if (response && response.status === CONSTANT.SUCCESS) {

                    toast.success(response.message);
                    setEditPassword(false);
                }
                else {
                    toast.error(response.message);
                }
            }
        } catch (err: any) {
            toast.error(err.response?.data?.error);
        }
    };

    return (
        <>
            {isLoading && (
                <div className="absolute top-[50%] z-50 left-[50%] transform translate-x-[-50%] translate-y-[-50%]">
                    <Loader />
                </div>
            )}
            <div className="lg:w-6/12 w-full lg:mb-0 mb-4 bg-graygreen 2xl:p-8 p-4 x:p-3 s:p-3">
                <div className="flex justify-between gap-4 mb-5">
                    <h4 className="text-silverChalice uppercase x:text-[14px] s:text-[14px] font-medium	tracking-wide">
                        LOGIN & SECURITY
                    </h4>
                    {edit ? (
                        <>
                            <div className="flex gap-4">
                                <button
                                    type="submit"
                                    onClick={() => updateUser()}
                                    className="bg-capeCod text-silverChalice sm:px-6 px-4 py-2 text-[12px] min-w-[120px] x:min-w-[100px] s:min-w-[100px]"
                                >
                                    Save
                                </button>

                                <button
                                    type="button"
                                    onClick={() => setEdit(false)}
                                    className="bg-capeCod text-silverChalice sm:px-6 px-4 py-2 text-[12px] min-w-[120px] x:min-w-[100px] s:min-w-[100px]"
                                >
                                    Cancel
                                </button>
                            </div>
                        </>
                    ) : (
                        <button
                            type="button"
                            onClick={() => setEdit(true)}
                            className="bg-capeCod text-silverChalice sm:px-6 px-4 py-2 text-[12px] min-w-[120px] x:min-w-[100px] s:min-w-[100px] hover:text-white"
                        >
                            Edit Details
                        </button>
                    )}
                </div>
                <div>
                    <ul>
                        {edit ? (
                            <>
                                <li className="flex sm:py-4 py-2 items-center">
                                    <div className="w-[35%]">
                                        <h6 className="sm:text-[14px] text-[12px] text-mantle">
                                            First Name
                                        </h6>
                                    </div>
                                    <div className="w-[65%]">
                                        <input
                                            type="text"
                                            disabled={!edit}
                                            value={values.firstName}
                                            name="firstName"
                                            onChange={handleChange}
                                            className={`${edit
                                                ? "text-gray85 bg-capeCod inner-shadow"
                                                : "text-mantle bg-transparent"
                                                } focus focus:outline-none w-full h-10 xs:h-9 s:h-9 sm:text-[14px] text-[12px] px-4`}
                                        />
                                        {errors.firstName && touched.firstName && (
                                            <div className="text-[#e53e34]">{errors.firstName}</div>
                                        )}
                                    </div>
                                </li>
                                <li className="flex sm:py-4 py-2 items-center">
                                    <div className="w-[35%]">
                                        <h6 className="sm:text-[14px] text-[12px] text-mantle">
                                            Last Name
                                        </h6>
                                    </div>
                                    <div className="w-[65%]">
                                        <input
                                            type="text"
                                            disabled={!edit}
                                            value={values.lastName}
                                            name="lastName"
                                            onChange={handleChange}
                                            className={`${edit
                                                ? "text-gray85 bg-capeCod inner-shadow"
                                                : "text-mantle bg-transparent"
                                                } focus focus:outline-none w-full h-10 xs:h-9 s:h-9 sm:text-[14px] text-[12px] px-4`}
                                        />
                                        {errors.lastName && touched.lastName && (
                                            <div className="text-[#e53e34]">{errors.lastName}</div>
                                        )}
                                    </div>
                                </li>
                            </>
                        ) : (
                            <>
                                <li className="flex sm:py-4 py-2">
                                    <div className="w-[35%]">
                                        <h6 className="sm:text-[14px] text-[12px] text-mantle">
                                            User Name
                                        </h6>
                                    </div>
                                    <div className="w-[65%]">
                                        <p className="text-mantle bg-transparent w-full h-10 xs:h-9 s:h-9 sm:text-[14px] text-[12px] px-4">
                                            {userData?.firstName} {userData?.lastName}
                                        </p>
                                    </div>
                                </li>
                            </>
                        )}
                        <li className="flex sm:py-4 py-2 items-center">
                            <div className="w-[35%]">
                                <h6 className="sm:text-[14px] text-[12px] text-mantle">
                                    Recovery email
                                </h6>
                            </div>
                            <div className="w-[65%]">
                                <input
                                    type="email"
                                    disabled={!edit}
                                    value={values.recoveryEmail}
                                    name="recoveryEmail"
                                    onChange={handleChange}
                                    className={`${edit
                                        ? "text-gray85 bg-capeCod inner-shadow"
                                        : "text-mantle bg-transparent"
                                        } focus focus:outline-none w-full h-10 xs:h-9 s:h-9 sm:text-[14px] text-[12px] px-4`}
                                />
                            </div>
                        </li>
                        <li className="flex sm:py-4 py-2 items-center">
                            <div className="w-[35%]">
                                <h6 className="sm:text-[14px] text-[12px] text-mantle">
                                    Recovery phone
                                </h6>
                            </div>
                            <div className="w-[65%]">
                                <input
                                    type="number"
                                    disabled={!edit}
                                    maxLength={10}
                                    value={values.contactNo}
                                    name="contactNo"
                                    onInput={handleInput}
                                    onChange={handleChange}
                                    className={`${edit
                                        ? "text-gray85 bg-capeCod inner-shadow"
                                        : "text-mantle bg-transparent"
                                        } focus focus:outline-none w-full h-10 xs:h-9 s:h-9 sm:text-[14px] text-[12px] px-4`}
                                />
                                {edit && errors.contactNo && touched.contactNo && (
                                    <div className="text-[#e53e34]">{errors.contactNo}</div>
                                )}
                            </div>
                        </li>
                    </ul>
                    <div className="flex justify-between gap-4 mb-5">
                        <h4 className="text-silverChalice uppercase x:text-[14px] s:text-[14px] font-medium	tracking-wide">
                            Password Details
                        </h4>
                        {editPassword ? (
                            <>
                                <div className="flex gap-4">
                                    <button
                                        type="button"
                                        onClick={() => resetPassword()}
                                        className="bg-capeCod text-silverChalice sm:px-6 px-4 py-2 text-[12px] min-w-[120px] x:min-w-[100px] s:min-w-[100px] hover:text-white"
                                    >
                                        Save
                                    </button>

                                    <button
                                        type="button"
                                        onClick={() => setEditPassword(false)}
                                        className="bg-capeCod text-silverChalice sm:px-6 px-4 py-2 text-[12px] min-w-[120px] x:min-w-[100px] s:min-w-[100px] hover:text-white"
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </>
                        ) : (
                            <button
                                type="button"
                                onClick={() => setEditPassword(true)}
                                className="bg-capeCod text-silverChalice sm:px-6 px-4 py-2 text-[12px] min-w-[120px] x:min-w-[100px] s:min-w-[100px] hover:text-white"
                            >
                                Edit Password
                            </button>
                        )}
                    </div>

                    {!editPassword && (
                        <li className="flex sm:py-4 py-2 items-center">
                            <div className="w-[35%]">
                                <h6 className="sm:text-[14px] text-[12px] text-mantle">
                                    Current Password
                                </h6>
                            </div>
                            <div className="w-[65%]">
                                <input
                                    type="password"
                                    disabled={!editPassword}
                                    name="password"
                                    onChange={handleChange}
                                    placeholder={"************"}
                                    className={`${editPassword
                                        ? "text-gray85 bg-capeCod inner-shadow"
                                        : "text-mantle bg-transparent"
                                        } focus focus:outline-none w-full h-10 xs:h-9 s:h-9 sm:text-[14px] text-[12px] px-4`}
                                />
                            </div>
                        </li>
                    )}

                    {editPassword && (
                        <>
                            <li className="flex sm:py-4 py-2 items-center">
                                <div className="w-[35%]">
                                    <h6 className="sm:text-[14px] text-[12px] text-mantle">
                                        Current Password
                                    </h6>
                                </div>
                                <div className="w-[65%]">
                                    <input
                                        type="password"
                                        disabled={!editPassword}
                                        // value={values.currentPassword}
                                        name="currentPassword"
                                        onChange={handleChange}
                                        placeholder={"************"}
                                        className={`${editPassword
                                            ? "text-gray85 bg-capeCod inner-shadow"
                                            : "text-mantle bg-transparent"
                                            } focus focus:outline-none w-full h-10 xs:h-9 s:h-9 sm:text-[14px] text-[12px] px-4`}
                                    />
                                </div>
                            </li>

                            <li className="flex sm:py-4 py-2 items-center">
                                <div className="w-[35%]">
                                    <h6 className="sm:text-[14px] text-[12px] text-mantle">
                                        New Password
                                    </h6>
                                </div>
                                <div className="w-[65%]">
                                    <input
                                        type="password"
                                        disabled={!editPassword}
                                        // value={values.newPassword}
                                        name="newPassword"
                                        onChange={handleChange}
                                        placeholder={"************"}
                                        className={`${editPassword
                                            ? "text-gray85 bg-capeCod inner-shadow"
                                            : "text-mantle bg-transparent"
                                            } focus focus:outline-none w-full h-10 xs:h-9 s:h-9 sm:text-[14px] text-[12px] px-4`}
                                    />
                                </div>
                            </li>
                            <li className="flex sm:py-4 py-2 items-center">
                                <div className="w-[35%]">
                                    <h6 className="sm:text-[14px] text-[12px] text-mantle">
                                        Confirm Password
                                    </h6>
                                </div>
                                <div className="w-[65%]">
                                    <input
                                        type="password"
                                        disabled={!editPassword}
                                        placeholder={"************"}
                                        // value={values.confirmPassword}
                                        name="confirmPassword"
                                        onChange={handleChange}
                                        className={`${editPassword
                                            ? "text-gray85 bg-capeCod inner-shadow"
                                            : "text-mantle bg-transparent"
                                            } focus focus:outline-none w-full h-10 xs:h-9 s:h-9 sm:text-[14px] text-[12px] px-4`}
                                    />
                                </div>
                            </li>
                        </>
                    )}
                </div>
            </div>
        </>
    );
};

export default LoginSecurity;

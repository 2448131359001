import React, { useState } from "react";
import img from "../../assets/images/login-background.png";
import mobileImg from "../../assets/images/mobile-login.png";
import Logo from "../../assets/images/white-greenlab-logo.png";
import "./Login.css";
import { ChevronRightIcon } from "@heroicons/react/24/outline";
import { UserService } from "../../services";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { passwordSchema } from "./LoginSchema";
import { CONSTANT } from "../../core/StaticConstant";
import { useNavigate, useParams } from "react-router-dom";


const ForgotPassword = () => {
    function handleKeyPress(event: any) {
        if (event.key === "Enter") {
            handleSubmit();
        }
    }
    const { id } = useParams()
    const navigate = useNavigate()
    const initialValues = {
        userId: id,
        password: "",
        confirmPassword: "",

    };

    const [isLoading, setIsLoading] = useState(false);

    const { values, errors, touched, handleChange, handleSubmit } = useFormik({
        initialValues,
        validationSchema: passwordSchema,
        onSubmit: async (values, action) => {
            try {
                setIsLoading(true);
                let data = await UserService.forgotUserPassword(values);
                setIsLoading(false);
                if (data && data.status === CONSTANT.SUCCESS) {
                    toast.success(data.message);
                    navigate("/login");

                } else {
                    toast.error(data.message);
                }
            } catch (error) {
                console.error(error);
                setIsLoading(false);
            }

            action.resetForm();
        },
    });





    return (
        <div className="bg-capeCod min-h-screen">
            <div className="sm:flex mt-0 sm:justify-center pt-16 xs:pt-10 s:pt-10 lg:min-w-[200px] lg:max-w-[200px] max-w-[150px] mx-auto mb-3">
                <img src={Logo} alt="GREEN LAB" />
            </div>
            <div className="sm:flex mt-0 sm:justify-center pb-8 xs:pb-[20px] s:pb-[20px]">
                <p className="text-greenFooter xs:text-[9px] s:text-[9px] xs:tracking-[3.6px] s:tracking-[2.1px] text-[14px] tracking-[4.8px] text-center">
                    CHEMISTRY THAT UNDERSTANDS YOUR BIOLOGY
                </p>
            </div>
            <div className="grid grid-cols-6 xs:p-[10px] s:p-[10px] p-4 gap-4">
                <div className="xl:col-span-1"></div>
                <div className="xl:col-span-4 col-span-6">
                    <div className="grid xs:block s:block background bg-graygreen 2xl:w-[full] 2xl:h-[685px] grid-cols-3 gap-4">
                        <div className="col-span-1">
                            <img
                                className="w-full h-full xs:hidden s:hidden object-cover p-[6px]"
                                src={img}
                                alt="Sunset in the mountains"
                            />
                            <img
                                className="w-full h-full xs:block s:block hidden object-fit p-[6px]"
                                src={mobileImg}
                                alt="Sunset in the mountains"
                            />
                        </div>
                        <div className="col-span-2 s:p-[6px] xs:p-[6px] c-sm:p-4 c-md:p-8 p-16 align">


                            <h4 className="text-greenFooter text-2xl xs:text-[20px] s:text-[20px] 2xl:pt-[50px] 2xl:pb-[21px] font-bold text-3xl">
                                RESET PASSWORD
                            </h4>
                            <hr className="border-silver border-t-[1px] px-8 xl:w-[98%] border-bottom" />
                            <p className="text-greenFooter xs:text-[9px] s:text-[9px] xs:tracking-[3.6px] s:tracking-[2.1px] mt-2 font-[12] 2xl:pt-[21px] tracking-[4.0px] text-[14px]">
                                NEW PASSWORD
                            </p>
                            <input
                                className="bg-capeCod w-[98%] mt-2.5 text-[white] md:px-8 xs:px-4 s:px-4 focus:outline-none inner-shadow h-10 xs:h-9 s:h-9"
                                type="password"
                                value={values.password}
                                name="password"
                                onChange={handleChange}
                                onKeyPress={handleKeyPress}
                            />
                            {errors.password && touched.password ? (
                                <p className="form-error text-[red]">{errors.password}</p>
                            ) : null}
                            <p className="text-greenFooter xs:text-[9px] s:text-[9px] xs:tracking-[3.6px] s:tracking-[2.1px] mt-2 font-[12] 2xl:pt-[21px] tracking-[4.0px] text-[14px]">
                                CONFIRM PASSWORD
                            </p>
                            <input
                                className="bg-capeCod w-[98%] mt-2.5 text-[white] md:px-8 xs:px-4 s:px-4 focus:outline-none inner-shadow h-10 xs:h-9 s:h-9"
                                type="password"
                                value={values.confirmPassword}
                                name="confirmPassword"
                                onChange={handleChange}
                                onKeyPress={handleKeyPress}
                            />
                            {errors.confirmPassword && touched.confirmPassword ? (
                                <p className="form-error text-[red]">{errors.confirmPassword}</p>
                            ) : null}
                            <div className="grid w-[98%] grid-cols-2 gap-4 md:pt-[31px]">


                                <button
                                    className={` s:hidden xs:hidden pt-8 font-bold hover:cursor-pointer inline-flex text-[25px] ${isLoading ? "text-gray-500" : "text-greenFooter"
                                        }`}
                                    onClick={() => handleSubmit()}
                                    disabled={isLoading}
                                >
                                    <span>RESET PASSWORD</span>
                                    <span>
                                        <ChevronRightIcon className="h-6 w-8 	mt-2" />
                                    </span>
                                </button>
                            </div>

                        </div>
                    </div>


                </div>
                <div className="xl:col-span-1"></div>
            </div>
        </div>
    );
};

export default ForgotPassword;

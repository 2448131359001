import React, { useEffect, useState, useCallback } from "react";
import { OrderService } from "../../services/order.services";
import { CONSTANT } from "../../core/StaticConstant";
import Loader from "../loader/Loader";
import { Pagination } from "antd";
import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { showConfirmDialog } from "../../core/Constant";
import { toast } from "react-toastify";

const Order = () => {

    const [openOrders, setOpenOrders] = useState<number[]>([]);
    const [orderData, setOrderData] = useState<any[]>();
    const [isLoading, setIsLoading] = useState(false);
    const [isDisable, setIsDisable] = useState(false);
    const [selectedOrderId, setSelectedOrderId] = useState('');
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [perPage, setPerPage] = useState<number>(10);
    const [totalRecords, setTotalRecords] = useState(0);
    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        let obj = {
            limit: perPage,
            pageCount: currentPage,
        };
        OrderByUser(obj);
    }, [currentPage]);

    const handleChangePage = useCallback((newPage: number) => {
        setCurrentPage(newPage);
    }, []);

    const handleChangeRowsPerPage = useCallback(async (page: number, pageSize: number) => {
        if (pageSize !== perPage) {
            setCurrentPage(1);
            setPerPage(pageSize);
            let obj = {
                limit: pageSize,
                pageCount: 1,
            };
            await OrderByUser(obj);
        }
    }, [perPage]);

    const toggleModal = (id: any) => {
        setSelectedOrderId(id)
        setIsModalOpen(!isModalOpen);
    };
    const closeModal = () => {
        setIsModalOpen(false);
    };
    const OrderByUser = async (obj: any) => {
        try {
            setIsLoading(true);
            const response: any = await OrderService.getOrderIdByUserId(obj);
            setIsLoading(false);
            if (response && response.status === CONSTANT.SUCCESS) {
                setOrderData(response.data.orders);
                setTotalRecords(response.data.totalRecords);

            }
        } catch (error) {
            setIsLoading(false);
        }
    };
    const onClickArrow = useCallback((orderId: any) => {
        setOpenOrders(prevOpenOrders => {
            if (prevOpenOrders.includes(orderId)) {
                return prevOpenOrders.filter(id => id !== orderId);
            } else {
                return [...prevOpenOrders, orderId];
            }
        });
    }, []);

    const navigate = useNavigate();
    const orderProcess = useSelector((state: any) => state);
    let selectId = orderProcess.order;

    const doPayment = useCallback((ele: any) => {
        navigate("/shop/cart/order/" + ele._id);
    }, [navigate]);

    const cancelOrder = useCallback(async (ele: any) => {
        try {
            showConfirmDialog(CONSTANT.CANCEl_CONFIRMATION, async (confirmed: any) => {
                if (confirmed) {
                    const response: any = await OrderService.CancelOrderById(ele)
                    if (response && response.status === CONSTANT.SUCCESS) {
                        toast.success(response.message)

                        setIsModalOpen(false);

                        let obj = {
                            limit: perPage,
                            pageCount: currentPage,
                        };
                        OrderByUser(obj);
                    }
                    else {
                        toast.error(response.error)
                    }
                }
            })
        } catch (err) {
            console.error("An Error Occurred in:", err)
        }
    }, []);

    const refundPayment = useCallback(async (ele: any, ele1: any, values: any, paymentId: any) => {
        let obj = {
            paymentId: paymentId,
            amount: ele,
            reason: values,
            orderId: ele1
        }
        try {
            setIsDisable(true)
            const response: any = await OrderService.paymentRefund(obj)
            setIsDisable(false)
            if (response && response.status === CONSTANT.SUCCESS) {
                cancelOrder(ele1)
            } else {
                toast.error(response.error)
            }
        }
        catch {
            setIsDisable(false)
        }
    }, []);

    let upArrow = (
        <svg
            className="fill-current h-10 w-10 -rotate-270
              transition duration-150 ease-in-out"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
        >
            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
        </svg>
    );

    let downArrow = (
        <svg
            className="fill-current h-10 w-10 -rotate-180
              transition duration-150 ease-in-out"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
        >
            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
        </svg>
    );

    return (
        <div className="overflow-x-auto overflow-y-hidden ">
            {isLoading ? (
                <div className="absolute top-[50%] z-50 left-[50%] transform translate-x-[-50%] translate-y-[-50%]">
                    <Loader />
                </div>
            ) : (
                <div className="m-4">
                    <div className="">
                        {orderData && orderData?.length > 0 ?
                            orderData.map((ele: any, index: number) => {

                                const isOrderOpen = openOrders.includes(index);
                                return (
                                    <div key={index}>
                                        <div className="flex  text-gray-500 dark:text-gray-400">
                                            <div
                                                onClick={() => onClickArrow(index)}
                                                className="cursor-pointer"
                                            >
                                                {isOrderOpen ? downArrow : upArrow}
                                            </div>
                                            <div className="flex mt-2 ">
                                                <p className="text-medium  whitespace-nowrap">
                                                    Order Status :
                                                </p>

                                                <p className="px-6  min-w-[170px] ">
                                                    <span
                                                        className={`text-xs font-medium mr-2 px-2.5 py-0.5 min-w-[100px] block text-center rounded-[4px]  roundedwhitespace-nowrap ${ele && ele.orderStatus === "PENDING"
                                                            ? "bg-yellow-100 text-yellow-800 dark:bg-yellow-900 dark:text-yellow-300"
                                                            : ele && ele.orderStatus === CONSTANT.PAYMENT_ISSUE
                                                                ? "bg-red-100 text-red-800 dark:bg-red-900 dark:text-red-300"
                                                                : ele && ele.orderStatus === CONSTANT.CANCEL ? "bg-red-100 text-red-800 dark:bg-red-900 dark:text-red-300" : ""
                                                            }`}
                                                    >
                                                        {ele && ele.orderStatus ? ele.orderStatus : "N/A"}
                                                    </span>
                                                </p>

                                                <p className="text-medium  whitespace-nowrap">
                                                    Order Number :
                                                </p>

                                                <p className="px-6 min-w-[150px]  whitespace-nowrap">
                                                    {ele && ele.orderNumber ? ele.orderNumber : "N/A"}
                                                </p>

                                                <p className="text-medium whitespace-nowrap">
                                                    Order Date :
                                                </p>
                                                <p className="px-6  whitespace-nowrap">
                                                    {ele && ele.createdAt
                                                        ? ` ${moment(ele.createdAt).format(
                                                            "MMM DD YYYY"
                                                        )},at ${moment(ele.createdAt).format("h:mm A")}`
                                                        : "N/A"}
                                                </p>

                                                <p className="text-medium whitespace-nowrap">
                                                    Total Amount:
                                                </p>
                                                <p className="px-6 min-w-[120px] whitespace-nowrap">
                                                    {ele && !isNaN(parseFloat(ele.amount))
                                                        ? `£${parseFloat(ele.amount)?.toFixed(2)}`
                                                        : "N/A"}
                                                </p>

                                                {ele && ele.orderStatus === "PAYMENT_ISSUE" && (
                                                    <div className="flex items-center whitespace-nowrap">
                                                        <p className="px-6 min-w-[100px]">
                                                            <span
                                                                className="text-xs cursor-pointer font-medium mr-2 px-2.5 py-1.5 min-w-[60px] block text-center rounded-[4px]  roundedwhitespace-nowrap bg-blue-100 min-w-[100px] text-blue-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300"
                                                                onClick={() => doPayment(ele)}
                                                            >
                                                                Complete Your Payment
                                                            </span>
                                                        </p>
                                                    </div>
                                                )}

                                                {ele.orderStatus !== CONSTANT.CANCEL &&

                                                    <p className="px-6 min-w-[120px]  whitespace-nowrap">
                                                        <span
                                                            className="text-xs cursor-pointer font-medium mr-2 px-2.5 py-1.5 min-w-[100px] block text-center rounded-[4px]  roundedwhitespace-nowrap  bg-red-100 text-red-800 dark:bg-red-900 dark:text-red-300"
                                                            onClick={() => { ele.orderStatus === CONSTANT.PAYMENT_ISSUE ? cancelOrder(ele._id) : toggleModal(ele._id) }
                                                            }
                                                        >
                                                            Cancel Your Order
                                                        </span>
                                                    </p>
                                                }
                                            </div>
                                        </div>
                                        <div>
                                            {isOrderOpen && (
                                                <table className="w-full text-sm text-left text-greenFooter dark:text-gray-400">
                                                    <thead className="text-xs  uppercase  dark:text-white">
                                                        <tr>
                                                            <th scope="col" className="px-6 py-3 ">
                                                                Product name
                                                            </th>
                                                            <th scope="col" className="px-6 py-3">
                                                                Product Image
                                                            </th>
                                                            <th scope="col" className="px-6 py-3">
                                                                Product Quantity
                                                            </th>
                                                            <th scope="col" className="px-6 py-3">
                                                                Product Actual Amount
                                                            </th>
                                                            <th scope="col" className="px-6 py-3">
                                                                Expected Delivery Date
                                                            </th>
                                                            <th scope="col" className="px-6 py-3">
                                                                Dosage Assistance Price
                                                            </th>
                                                            <th scope="col" className="px-6 py-3">
                                                                Delivery Charge
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {ele.cartProducts &&
                                                            ele.cartProducts.map((product: any) => {
                                                                return (
                                                                    <>
                                                                        <tr className=" dark:bg-gray-800 dark:border-gray-700 ">
                                                                            <td
                                                                                scope="row"
                                                                                className="px-6  font-sm text-greenFooter whitespace-nowrap dark:text-white"
                                                                            >
                                                                                {product && product.productName
                                                                                    ? product.productName
                                                                                    : "N/A"}
                                                                            </td>
                                                                            <td
                                                                                scope="row"
                                                                                className="px-6  pb-[9px]"
                                                                            >
                                                                                <img
                                                                                    src={
                                                                                        product &&
                                                                                            product.skuId.productImage[0]
                                                                                            ? product.skuId.productImage[0]
                                                                                            : "https://drive.google.com/uc?export=view&id=1F64VND3vJuFtCPxekGycvTrfr-xcRab1"
                                                                                    }
                                                                                    height="70px"
                                                                                    width="100px"
                                                                                />
                                                                            </td>

                                                                            <td
                                                                                scope="row"
                                                                                className="px-6  font-sm text-greenFooter whitespace-nowrap dark:text-white"
                                                                            >
                                                                                {product && product.quantity
                                                                                    ? product.quantity
                                                                                    : "N/A"}
                                                                            </td>

                                                                            <td
                                                                                scope="row"
                                                                                className="px-6 font-sm text-greenFooter whitespace-nowrap dark:text-white"
                                                                            >
                                                                                {product && product.actualAmount
                                                                                    ? `£${parseFloat(
                                                                                        product.actualAmount
                                                                                    )?.toFixed(2)}`
                                                                                    : "N/A"}
                                                                            </td>
                                                                            <td
                                                                                scope="row"
                                                                                className="px-6 font-sm text-greenFooter whitespace-nowrap dark:text-white"
                                                                            >
                                                                                {ele && ele.expectedDate
                                                                                    ? ele.expectedDate
                                                                                    : "N/A"}
                                                                            </td>
                                                                            <td
                                                                                scope="row"
                                                                                className="px-6 font-sm text-greenFooter whitespace-nowrap dark:text-white"
                                                                            >
                                                                                {product &&
                                                                                    product.dosageAssistancePrice
                                                                                    ? ` £${parseFloat(
                                                                                        product.dosageAssistancePrice
                                                                                    )?.toFixed(2)}`
                                                                                    : "N/A"}
                                                                            </td>
                                                                            <td
                                                                                scope="row"
                                                                                className="px-6 font-sm text-greenFooter whitespace-nowrap dark:text-white"
                                                                            >
                                                                                {ele && ele.deliveryCharge
                                                                                    ? `  £${parseFloat(
                                                                                        ele.deliveryCharge
                                                                                    )?.toFixed(2)}`
                                                                                    : "N/A"}{" "}
                                                                            </td>
                                                                        </tr>
                                                                    </>
                                                                );
                                                            })}
                                                    </tbody>
                                                </table>
                                            )}
                                        </div>
                                    </div>
                                );
                            }) : <div>
                                <h1 className="mb-4 text-[24px] font-medium text-graygreen tracking-wide">No Order Found</h1>
                            </div>}
                        {orderData?.length !== undefined && (
                            <div className="flex justify-end text-white">
                                <div className="mt-3 ml-auto">
                                    <Pagination
                                        className="pagination"
                                        current={currentPage}
                                        pageSize={perPage}
                                        total={totalRecords}
                                        onChange={handleChangePage}
                                        showSizeChanger
                                        pageSizeOptions={['10', '20', '50', '100']}
                                        onShowSizeChange={handleChangeRowsPerPage}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            )}
            {isModalOpen &&
                <CancelOrderModal orderData={orderData} selectedOrderId={selectedOrderId} refundPayment={refundPayment} closeModal={closeModal} setIsModalOpen={setIsModalOpen} isDisable={isDisable} />}
        </div>
    );
};

const CancelOrderModal = (props: any) => {
    const { selectedOrderId, orderData, refundPayment, closeModal, setIsModalOpen, isDisable } = props;
    const [select, setSelect] = useState("")
    const selectedDataList = orderData.filter((item: any) => item._id === selectedOrderId)

    return (
        <div
            className="fixed inset-0 flex items-center justify-center z-50"
            onClick={closeModal}
        >
            <div
                className="bg-white p-4 w-[28rem]"
                onClick={(e) => e.stopPropagation()}
            >

                <div className="flex justify-between mb-2">
                    <label
                        htmlFor="countries"
                        className="block  mb-2 text-lg font-medium text-gray-900"
                    >
                        Cancellation Reason
                    </label>

                    <div
                        onClick={() => setIsModalOpen(false)}
                        className="h-10 "
                    >
                        <svg
                            className="h-8 w-8  cursor-pointer"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            strokeWidth="2"
                            stroke="currentColor"
                            stroke-linecap="round"
                            strokeLinejoin="round"
                        >
                            {" "}
                            <path stroke="none" />{" "}
                            <line x1="18" y1="6" x2="6" y2="18" />{" "}
                            <line x1="6" y1="6" x2="18" y2="18" />
                        </svg>
                    </div>
                </div>


                <select
                    onChange={(e: any) => setSelect(e.target.value)}
                    id="countries"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                >
                    <option selected>
                        {" "}
                        Please select reason for order cancellation
                    </option>
                    <option value={CONSTANT.REASON_LIST[0].key}>
                        {CONSTANT.REASON_LIST[0].value}
                    </option>
                    <option value={CONSTANT.REASON_LIST[1].key}>
                        {CONSTANT.REASON_LIST[1].value}
                    </option>
                    <option value={CONSTANT.REASON_LIST[2].key}>
                        {CONSTANT.REASON_LIST[2].value}
                    </option>
                    <option value={CONSTANT.REASON_LIST[3].key}>
                        {CONSTANT.REASON_LIST[3].value}
                    </option>
                </select>

                <button
                    className={`w-full mt-7 text-white font-bold py-2 px-4 ${!(select) || isDisable
                        ? "bg-greenFooter text-white"
                        : "bg-midnightDream text-white"
                        } tracking-wide`}
                    onClick={() => refundPayment(selectedDataList[0].amount, selectedDataList[0]._id, select, selectedDataList[0].paymentId)}
                    type="button"
                    disabled={!select || isDisable}
                >
                    Submit
                </button>
            </div>
        </div>
    )
}

export default Order;
